import React, { useEffect, useState } from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper, Grid, Box, TextField } from "@material-ui/core";
import axios from "axios";
import Calendar from "react-calendar";
import styled from "styled-components";
import BloquesDeTrabajo from "./BloquesDeTrabajo";
import Avatar from "@material-ui/core/Avatar";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const ButtonGrande = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 270px;
  height: 102px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 220px;
  height: 80px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonOcupadoSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #f46d43" : "1px solid #3c77e3"};;
  width: 220px;
  height: 80px;
  fontWeight: "normal",

  background: #ffffff;
`;

const openZoom = (url) => {
  console.log("openZoom", url);
  const win = window.open(url, "_blank");
  if (win != null) {
    win.focus();
  }
};

function calcularDuracion(inicio, duracionMinutos) {
  let partesInicio = inicio.split(":");

  let hora = parseInt(partesInicio[0]);
  let minutos = parseInt(partesInicio[1]);

  duracionMinutos = parseInt(duracionMinutos);

  let minutosFinPeriodo = (minutos + duracionMinutos) % 60;
  let restoHoras = Math.floor((minutos + duracionMinutos) / 60);

  let horaFinal = hora + restoHoras;

  if (minutosFinPeriodo < 10) {
    minutosFinPeriodo = "0" + minutosFinPeriodo;
  }

  return "" + horaFinal + ":" + minutosFinPeriodo;
}

async function buscarCitasZooom(rut) {}

function callbackDibujoSlot(slot, index, array) {
  let fin = calcularDuracion("aaa");

  if (slot.horaTomada) {
    return (
      <ButtonOcupadoSlot
        value={index}
        key={"agendamiento-slot-" + index}
        active={this.padre.state.indiceSlotSeleccionado == index}
        onClick={() =>
          this.padre.handlerSeleccionarSlot(
            index,
            slot.rutPaciente,
            slot.inicio,
            this.padre.state.diaSeleccionado,
            this.padre.state.mesSeleccionado,
            this.padre.state.anioSeleccionado
          )
        }
      >
        <img
          style={{ float: "left" }}
          height="30"
          src="https://us04st1.zoom.us/static/5.2.2957/image/new/home/meetings.png"
        />
        de {slot.inicio} a {calcularDuracion(slot.inicio, slot.duracionMinutos)}{" "}
        Paciente:{slot.rutPaciente}
      </ButtonOcupadoSlot>
    );
  }

  return (
    <ButtonSlot
      value={index}
      key={"agendamiento-slot-" + index}
      active={this.padre.state.indiceSlotSeleccionado == index}
    >
      de {slot.inicio} a {calcularDuracion(slot.inicio, slot.duracionMinutos)}
      <p>Libre</p>
    </ButtonSlot>
  );
}

class CalendarioHorasMedicas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disponibilidades: [],
      datosMedico: null,
      indiceSlotSeleccionado: -1,
      slotsDisponiblesSegunSeleccionDia: [],
      diaSeleccionado: "",
      mesSeleccionado: "",
      anioSeleccionado: "",
      abrirPopup: false,
      dibujarCalendario: true,
      fechaSeleccionada: null,
      idProfesional: "",
      dibujarCalendario: true,
      vistaCalendario: "month",
    };
    this.ref = React.createRef();
  }

  componentDidMount() {}

  handlerDiasDesactivados = ({ activeStartDate, date, view }) => {
    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    let diaDelPasado = false;

    if (date.getTime() >= ahora.getTime()) {
      return false;
    }

    let activarDia = false;

    let anioEnRevision = date.getFullYear();
    let mesEnRevision = date.getMonth() + 1;
    let diaEnRevision = date.getDate();

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in this.state.disponibilidades) {
      let diaString = this.state.disponibilidades[indice].dia;

      let partes = diaString.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioEnRevision);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesEnRevision);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaEnRevision);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        activarDia = true;
        return !activarDia;
      }
    }

    return !activarDia;
  };

  handlerClassNameCalendario = ({ activeStartDate, date, view }) => {
    let anioEnRevision = date.getFullYear();
    let mesEnRevision = date.getMonth() + 1;
    let diaEnRevision = date.getDate();

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in this.state.disponibilidades) {
      let diaDisponible = this.state.disponibilidades[indice].dia;

      let partes = diaDisponible.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioEnRevision);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesEnRevision);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaEnRevision);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        return "diaDisponible";
      }
    }

    if (
      anioEnRevision == this.state.anioSeleccionado &&
      mesEnRevision == this.state.mesSeleccionado &&
      diaEnRevision == this.state.diaSeleccionado
    ) {
      return "diaSeleccionado";
    }

    return null;
  };

  handlerAbrirPopup = () => {
    console.log("abriendo!!!");
    this.setState({ abrirPopup: true });
  };

  handlerSeleccionarSlot = (
    indice,
    rutPaciente,
    inicio,
    diaSeleccionado,
    mesSeleccionado,
    anioSeleccionado
  ) => {
    console.log("indice");
    console.log(indice);
    console.log(rutPaciente);

    const header = {
      headers: {
        "access-control-allow-headers": "*",
        "access-control-allow-methods": "*",
        "access-control-allow-origin": "*",
        "access-control-expose-headers": "*",
        "content-type": "application/json",
      },
    };

    axios
      .get(
        `https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/zoom?rut=${rutPaciente}`,
        header
      )
      .then((res) => {
        console.log("TelemedicinaX");
        console.log(res);
        if (res.data.statusCode === 200) {
          let posibles = res["data"]["data"];
          console.log(posibles);

          for (let p in posibles) {
            let dia =
              anioSeleccionado + "-" + mesSeleccionado + "-" + diaSeleccionado;

            if (dia != posibles[p]["diaReserva"]) {
              continue;
            }

            if (inicio != posibles[p]["horaReserva"]) {
              continue;
            }

            console.log("aca!!!!encontrado!");
            let zoom = posibles[p]["detailZoomMeating"]["body"]["start_url"];
            console.log("zoom");
            console.log(zoom);

            openZoom(zoom);
          }
        }
      })
      .catch((err) => {
        console.log("Telemedicina", err);
      });
  };

  handlerActualizarCalendario = () => {
    this.cambiarMedico(this.state.idMedico);

    this.setState({ vistaCalendario: "year", diaSeleccionado: "" }, () => {
      /*refresca calendario */
      this.setState({ vistaCalendario: "month" });
    });
  };

  handlerCerrarPopup = () => {
    axios
      .get(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/professional/" +
          this.state.idMedico,
        header
      )
      .then((res) => {
        let datos = res.data.body;
        this.setState({ datosMedico: datos });

        if (typeof datos.disponibilidad !== "undefined") {
          this.setState({ disponibilidades: [] });
        } else {
          this.setState({ disponibilidades: datos.disponibilidad });
        }

        this.setState({ dibujarCalendario: false });
        this.setState({ dibujarCalendario: true });
        let dia = new Date(
          this.state.anioSeleccionado,
          this.state.mesSeleccionado - 1,
          this.state.diaSeleccionado
        );
        this.setState({ fechaSeleccionada: dia });
        this.handlerSeleccionDia(dia, null);

        return res;
      })
      .catch((err) => console.log(err));
  };

  handlerCerrarVentana = () => {
    this.setState({ abrirPopup: false });
  };

  handlerSeleccionDia = (value, event) => {
    this.setState({ indiceSlotSeleccionado: -1 });

    let anioSeleccionado = value.getFullYear();
    let mesSeleccionado = value.getMonth() + 1;
    let diaSeleccionado = value.getDate();

    console.log("====HANDLER SELECCION FECHA===");
    console.log("seleccionando anio: " + anioSeleccionado);
    console.log("seleccionando mes: " + mesSeleccionado);
    console.log("seleccionando dia: " + diaSeleccionado);

    let disponibilidades = this.state.disponibilidades;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let slotsDisponibles = [];

    for (var indice in disponibilidades) {
      let diaDisponible = disponibilidades[indice].dia;
      let partes = diaDisponible.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioSeleccionado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesSeleccionado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaSeleccionado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        slotsDisponibles.push(disponibilidades[indice]);
      }
    }

    this.setState({ slotsDisponiblesSegunSeleccionDia: slotsDisponibles });
    this.setState({
      diaSeleccionado: diaSeleccionado,
      mesSeleccionado: mesSeleccionado,
      anioSeleccionado: anioSeleccionado,
    });

    this.setState({ vistaCalendario: "year" }, () => {
      /*refresca calendario */
      this.setState({ vistaCalendario: "month" });
    });
  };

  cambiarMedico = (id) => {
    axios
      .get(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/professional/" +
          id,
        header
      )
      .then((res) => {
        let datos = res.data.body;
        this.setState({ datosMedico: datos });
        this.setState({ disponibilidades: datos.disponibilidad });
        this.setState({ idProfesional: id }, () => {
          /*  this.handlerCerrarPopup();*/
        });
        return res;
      })
      .catch((err) => console.log(err));
  };

  render() {
    let idProfesional = localStorage.getItem("professional");

    /*    console.log("el idProfession en render");
    console.log(idProfesional);
    */

    if (this.state.idProfesional != idProfesional && idProfesional != "") {
      this.cambiarMedico(idProfesional);
    }
    let disponibilidadesDefinidas = false;

    if (typeof this.state.disponibilidades !== "undefined") {
      disponibilidadesDefinidas = true;
    }

    return (
      <Box>
        {disponibilidadesDefinidas == true &&
          this.state.disponibilidades.length >= 0 && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography pt={20} ml={10} variant="h6">
                  Agenda
                </Typography>

                <br />

                <Typography pt={20} ml={10} variant="body1">
                  En verde se despliegan los días donde hay horas reservadas
                  para trabajar.
                </Typography>

                <br />

                {this.state.idProfesional != "" && (
                  <Calendar
                    key={"calendarioHoras"}
                    locale="ES-es"
                    value={this.state.fechaSeleccionada}
                    tileDisabled={this.handlerDiasDesactivados}
                    tileClassName={this.handlerClassNameCalendario}
                    onChange={this.handlerSeleccionDia}
                    view={this.state.vistaCalendario}
                    inputRef={this.ref}
                  />
                )}
                <br />
                <Button
                  disabled={this.state.diaSeleccionado == ""}
                  onClick={this.handlerAbrirPopup}
                >
                  Agregar horas
                </Button>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                {this.state.slotsDisponiblesSegunSeleccionDia.length > 0 && (
                  <Box>
                    <Typography pt={20} ml={10} variant="h6">
                      Horarios de trabajo para el dia:{" "}
                      {this.state.diaSeleccionado}
                    </Typography>

                    <Typography pt={20} ml={10} variant="body1">
                      Haga click en la cita con videollamada para iniciar la
                      llamada.
                    </Typography>
                  </Box>
                )}
                <br />
                {this.state.slotsDisponiblesSegunSeleccionDia.length > 0 && (
                  <span>
                    {this.state.slotsDisponiblesSegunSeleccionDia.map(
                      callbackDibujoSlot,
                      { padre: this }
                    )}
                  </span>
                )}
              </Grid>
            </Grid>
          )}

        <br />
        <br />

        {this.state.abrirPopup && (
          <BloquesDeTrabajo
            handlerActualizarCalendario={this.handlerActualizarCalendario}
            handlerCerrarVentana={this.handlerCerrarVentana}
            open={this.state.abrirPopup}
            handlerCerrarPopup={this.handlerCerrarPopup}
            idMedico={this.state.datosMedico.id}
            diaSeleccionado={this.state.diaSeleccionado}
            mesSeleccionado={this.state.mesSeleccionado}
            anioSeleccionado={this.state.anioSeleccionado}
          />
        )}
      </Box>
    );
  }
}

export default CalendarioHorasMedicas;
