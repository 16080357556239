import React from "react";
import {Grid,Box,TextField,Typography} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class EtiquetaSelect extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
      etiqueta: props.etiqueta,
      valor: props.valor,
      handlerExterno: props.handlerSelect,
      alternativas:props.alternativas
    };
  }

   handleChange = (event) => {
    this.setState({valor:event.target.value});
    this.state.handlerExterno(event.target.value);
  };

  render(){
    return (
      <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box width={200}>
            <Typography variant="body1">
              {this.state.etiqueta}
            </Typography>
          </Box>
          <Select value={this.state.valor}
               onChange={this.handleChange}
          >
              {this.state.alternativas}
            </Select>
      </Box>
    );
  }
};

export default EtiquetaSelect;
