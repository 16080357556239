import React from "react";
import { Container, Box, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

class Comprobante extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: 0,
      voucher: {},
      pasoActual: 4,
      error: null,
    };
  }

  async componentDidMount() {
    const tokenValue = this.props.pathvalue
      .replace("/pago/comprobante/", "")
      .replace("/", "");
    console.log("tokenValue", tokenValue, "kieiee");
    if (tokenValue) {
      axios
        .post(
          "https://v7e3jqoqj3.execute-api.us-east-1.amazonaws.com/dev/getVaucher",
          {
            token: tokenValue,
          },
          header
        )
        .then((res) => {
          const voucher = res.data.voucher;
          console.log("TRANSBANK comprobante:", voucher);
          this.setState({
            loading: false,
            status: voucher.transbankStatus == "payed" ? 200 : 400,
            voucher: voucher,
          });
        })
        .catch((err) => {
          console.log("TRANSBANK comprobante ERR:", err);
          this.setState({ loading: false, status: 400 });
        });
    }
  }

  render() {
    return (
      <Paper>
        <Box pl={4} pr={4} pt={0} pb={4}>
          <Container maxWidth="lg">
            {this.state.loading ? (
              <>
                <Box mt={4}>
                  <Typography variant="h6">Cargando información...</Typography>
                </Box>
              </>
            ) : this.state.status == 200 ? (
              <>
                <Box mt={4}>
                  <Typography variant="h6">Confirmación</Typography>
                </Box>

                <Box pb={3}>
                  <Box mt={4}>
                    <Typography variant="body1">
                      Su reserva con el médico{" "}
                      <strong>
                        {this.state.voucher.cmvDetail.nombreMedico}
                      </strong>
                    </Typography>
                  </Box>
                  <Box mt={2} mt={3}>
                    <Typography variant="body1">
                      Para el día{" "}
                      <strong>{this.state.voucher.cmvDetail.diaReserva}</strong>{" "}
                      a las{" "}
                      <strong>
                        {this.state.voucher.cmvDetail.horaReserva}
                      </strong>{" "}
                      hora ha sido reservada.
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box mt={4}>
                  <Typography variant="h6">
                    Ha ocurrido un error en su pago, favor vuelva a intentarlo.
                  </Typography>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </Paper>
    );
  }
}

export default Comprobante;
