import React from "react";
import {
  Select,
  Modal,
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DataGrid } from "@material-ui/data-grid";
import styled, { ThemeProvider } from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import EtiquetaTextField from "../../DashboardView/comun/EtiquetaTextField";
import EtiquetaSelect from "../../DashboardView/comun/EtiquetaSelect";
import SelectorFecha from "../../DashboardView/comun/SelectorFecha";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const estiloDivModal = {
  position: "absolute",
  top: "5%",
  left: "30%",
  right: "30%",
  bottom: "55%",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "4px",
  outline: "none",
  padding: "40px",
};

class FijarPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };
  }

  componentDidMount() {}

  handlerCerrar = (e) => {};

  handlerAceptar = () => {
    this.props.handlerAceptar(this.state.password);
  };

  handlerPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handlerCancelar = () => {
    this.props.handlerCancelar();
  };

  render() {
    return (
      <Modal
        open={this.props.visible}
        onClose={this.handlerCerrar}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={estiloDivModal}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Box pb={2}>
                <Typography variant="h6">Nuevo Password</Typography>
              </Box>

              <EtiquetaTextField
                etiqueta="Ingrese password"
                value={this.state.password}
                handlerTextField={this.handlerPassword}
              />
            </Box>

            <Box display="flex" flexDirection="row" pt={10}>
              <Box pr={1}>
                <Button
                  disabled={this.state.password == ""}
                  onClick={this.handlerAceptar}
                >
                  Aceptar
                </Button>
              </Box>

              <Box>
                <Button onClick={this.handlerCancelar}>Cancelar</Button>
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
    );
  }
}

export default FijarPassword;
