import React from "react";
import {Typography,Grid,Box,ButtonBase} from '@material-ui/core';
import HealingIcon from '@material-ui/icons/Healing';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

class BotonConIcono extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      titulo: props.titulo,
      icono: props.icono,
      clickHandler: props.clickHandler,
      seleccionado:props.seleccionado
    };
  }

  setSeleccionado=(valor)=>{
    this.state.seleccionado=valor;
    console.log("aca!!!");
  }

  render(){
    return (
      <ButtonBase onClick={this.state.clickHandler} >

            <Box display="flex" flexDirection="column" alignItems="center" pb={2}  >
                { this.state.icono==3 &&(
                  <PersonAddIcon style={{ fontSize: 15,color:"#3f51b5" }} pt={0.7}  pb={30}/>
                )}

                { this.state.icono==2 &&(
                  <HealingIcon style={{ fontSize: 15,color:"#3f51b5"   }} pt={0.7}  pb={30}/>
                )}

                { this.state.icono==1 &&(
                  <LocalHospitalIcon style={{ fontSize: 15,color:"#3f51b5" }} pt={0.7}  pb={30}/>
                )}

                <Typography variant="body2">
                  {this.state.titulo}

                </Typography>
                { this.state.seleccionado==true &&(
                  <hr style={{
                      color: "#3f51b5",
                      backgroundColor: "#3f51b5",
                      height: 2,
                      width:70,
                      marginTop:13
                    }}/>
                  )
                }
            </Box>
        </ButtonBase>
    );
  }
};

export default BotonConIcono;
