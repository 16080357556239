import React, { useEffect, useState } from 'react';
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Paper,Grid,Box,TextField,Container} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TarjetaReceta from './TarjetaReceta';
import { ServiceCrearRME } from '../../../../../api/services';
import SweetAlert from 'sweetalert2-react';
import BusquedaMedicamento from './BusquedaMedicamento';
import BusquedaMedicamentoLocal from './BusquedaMedicamentoLocal';
import Rut from './../../../DashboardView/comun/Rut.js'
import axios from "axios";

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    table: {
      minWidth: 300,
      maxWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '38ch',
      },
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



const updateMedicamento = (obj, pos,padre) =>{
  console.log("aaaaaaaaaaaaaaactualizando");
  console.log(obj);
  console.log(pos);


  let filas=padre.state.filas;
  filas[pos]=obj;
  padre.actualizarFilas(filas);

  /*
  let filas=padre.state.medicamentosEnReceta;
  filas[pos]=obj;
  padre.actualizarMedicamentosEnRecetas(filas);
  */

}

function callbackTarjetaReceta(medicamento, index, array){
    console.log("el medicamento:");
    console.log(medicamento);

    return <TarjetaReceta index={index} key={index} medicamento={medicamento} updateItem={updateMedicamento} padre={this.padre} />
}

class Recetas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rutPaciente:"",
      emailPaciente:"",
      rutValido:false,
      medicamentosEnReceta:[],
      nuevoMedicamento:false,
      urlReceta:"",
      nombresPaciente:"",
      apellidosPaciente:"",
      edadPaciente:"",
      filas:[]
    };
  }

  actualizarFilas = (filas)=>{
    console.log("filas!!!");
    console.log(filas);
    this.setState({filas:filas});
  }

  actualizarMedicamentosEnRecetas= (filas)=>{
    console.log("filas!!!");
    console.log(filas);
    this.setState({medicamentosEnReceta:filas});
  }

  handlerRutPaciente = (rutValido,valorRut) =>{
    if (rutValido){
      this.setState({rutValido:true});
      this.setState({rutPaciente:valorRut});

      let rutBuscar=valorRut.replaceAll(".", "");
      axios
        .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/patient?rut="+rutBuscar, header)
        .then((res) =>{
              console.log(res);
              let data=res["data"];
              let body=data["body"];
              data=body["data"];

              let fechaNacimientoString=data["fechaNacimiento"];

              let partes = fechaNacimientoString.split("-");
              let fechaNacimiento= new Date(parseInt(partes[0]),parseInt(partes[1]),parseInt(partes[2]));

              let diff_ms = Date.now() - fechaNacimiento.getTime();
              let age_dt = new Date(diff_ms);

              let edad= Math.abs(age_dt.getUTCFullYear() - 1970);

              let partesNombre=data["nombre"].split(" ");

              let nombres="";
              let apellidos="";

              console.log("partes");
              console.log(partesNombre.length);

              if (partesNombre.length==2){
                nombres=partesNombre[0];
                apellidos=partesNombre[1];
              }else if (partesNombre.length==4){
                nombres=partesNombre[0]+" "+partesNombre[1];
                apellidos=partesNombre[2]+" "+partesNombre[3];
              }else if (partesNombre.length==5){
                nombres=partesNombre[0]+" "+partesNombre[1]+" "+partesNombre[2];
                apellidos=partesNombre[3]+" "+partesNombre[4];
              }else{
                  nombres=data["nombre"];
              }

              this.setState({nombresPaciente:nombres,apellidosPaciente:apellidos,mailPaciente:data["email"],edadPaciente: edad});
            })
         .catch((err) => console.log(err));


    }else{
      this.setState({rutValido:false});
    }
  }

  handlerNombresPaciente = (e) =>{
    let nombre=e.target.value;
    this.setState({nombresPaciente:nombre})
  }

  handlerApellidosPaciente = (e) =>{
    let apellidos=e.target.value;
    this.setState({apellidosPaciente:apellidos})
  }

  handlerEdadPaciente = (e) =>{
    let edad=e.target.value;
    this.setState({edadPaciente:edad})
  }

  handlerEmailPaciente = (e) =>{
    let emailPaciente=e.target.value;
    this.setState({emailPaciente:emailPaciente})
  }

  setMedicina= (m) =>{
    this.state.medicinaSeleccionada=m;
  }

  agregarMedicamento = (newMedicina) =>{

      console.log("=============");
      console.log(newMedicina);

      let hoy=new Date();

      let anioEnRevision=hoy.getFullYear();
      let mesEnRevision=hoy.getMonth()+1;
      let diaEnRevision=hoy.getDate();

      let medication = {
          "medication": newMedicina,
          "units": 1,
          "unitType": "Gotas",
          "frecuencyType": "Diario",
          "frecuency": 8,
          "frecuencyUnit": "Horas",
          "duration": 8,
          "durationUnit": "Dias",
          "observation": "",
          "commercialRecommendation": null,
          "indicationStartDate": ""+diaEnRevision+"/"+mesEnRevision+"/"+anioEnRevision,
      }

      this.state.medicamentosEnReceta.push(medication);
      this.setState({medicamentosEnReceta:this.state.medicamentosEnReceta});

      console.log(this.state.medicamentosEnReceta.length);

      let filas=this.state.filas;
      filas.push(medication);

      this.setState({filas:filas});
  }

  updateMedicamento = (obj, pos) =>{
      console.log("actualuizando!!!!!!!!!!!!!!!1111");
      let newArray = medicinaSeleccionada;
      newArray[pos] = obj;
      this.state.medicinaSeleccionada=newArray;
  }

  eliminarMedicamento = (index) => {
      console.log("eliminarMedicamento", index);
      let dataFiltrada = medicinaSeleccionada.filter((elm, indx)=>indx!=index);
      this.state.medicinaSeleccionada=dataFiltrada;
  }

  handlerGenerarReceta = async (e) => {

      let datosProfesional=localStorage.getItem('professional');

      console.log("-------datos profesional-------");
      console.log(datosProfesional);

      let id = Date.now();

      let professional =  {
          "email": "medico1@kuery.cl",
          "firstName": "Francisco",
          "id": "83c5bd26-fd97-4c14-ac31-a93b01497cda",
          "lastName": "Labra",
          "run": "11111111-1",
          "specialities": [
            {
              "id": "100",
              "name": "MEDICINA GENERAL"
            }
          ]
      };

      let patient = {
          "run": this.state.rutPaciente,
          "firstName": this.state.nombresPaciente,
          "lastName": this.state.apellidosPaciente,
          "age": this.state.edadPaciente,
          "email": this.state.emailPaciente,
          "genre": "Masculino",
          "id": "XXX"
      };

      let hoy=new Date();

      let anioEnRevision=hoy.getFullYear();
      let mesEnRevision=hoy.getMonth()+1;
      let diaEnRevision=hoy.getDate();

      let payload = {
          "patient": patient,
          "professional": professional,
          "appointmentId": null,
          "creation_day": ""+diaEnRevision+"/"+mesEnRevision+"/"+anioEnRevision,
          "id": id,
          "preview": false,
          indications:this.state.medicamentosEnReceta
      }
      let response = await ServiceCrearRME(payload);
      console.log("antes");
      /*let response = ServiceCrearRME(payload);*/
      console.log("despues");
      console.log(response);
      if(response.code == "200"){
          this.setState({urlReceta:`http://d3d5zvnihsts57.cloudfront.net/verificarPdf/${response.data.id}`});

      }
  }

  handlerVerReceta = (e) => {
    window.open(this.state.urlReceta);
  }

  render() {

    const { medicamentosEnReceta} = this.state;

    return (
      <Paper>
        <Box p={4}>
          <Container maxWidth="lg">
            <Typography pt={20} ml={10}  variant="h6">
              Datos del Paciente
            </Typography>

            <br/>

            <Grid container spacing={0}>
                 <Grid item xs={3}>
                   <Typography pt={20} ml={10} variant="body1">
                     Rut del paciente:
                   </Typography>
                 </Grid >
                 <Grid item xs={2}>
                    <Rut value={this.state.rutPaciente} handlerRut={this.handlerRutPaciente} ></Rut>
                 </Grid >
            </Grid>
            <Grid container spacing={0}>
                 <Grid item xs={3}>
                   <Typography pt={20} ml={10} variant="body1">
                     Nombres paciente:
                   </Typography>
                 </Grid >
                 <Grid item xs={2}>
                      <TextField value={this.state.nombresPaciente} label="" onChange={this.handlerNombresPaciente} ></TextField>
                 </Grid >
            </Grid>
            <Grid container spacing={0}>
                 <Grid item xs={3}>
                   <Typography pt={20} ml={10} variant="body1">
                     Apellidos paciente:
                   </Typography>

                 </Grid >
                 <Grid item xs={2}>
                      <TextField value={this.state.apellidosPaciente} label="" onChange={this.handlerApellidosPaciente} ></TextField>
                 </Grid >
            </Grid>
            <Grid container spacing={0}>
                 <Grid item xs={3}>
                   <Typography pt={20} ml={10} variant="body1">
                     Edad:
                   </Typography>

                 </Grid >
                 <Grid item xs={2}>
                      <TextField value={this.state.edadPaciente} label="" onChange={this.handlerEdadPaciente} ></TextField>
                 </Grid >
            </Grid>
            <Grid container spacing={0}>
                 <Grid item xs={3}>
                   <Typography pt={20} ml={10} variant="body1">
                     Email:
                   </Typography>

                 </Grid >
                 <Grid item xs={6}>
                      <TextField value={this.state.emailPaciente} label="" onChange={this.handlerEmailPaciente} ></TextField>
                 </Grid >
            </Grid>
            <br/>
          </Container>

          <Container maxWidth="lg">
            <Typography pt={20} ml={10} variant="h6">
              Datos de la receta
            </Typography>

            <Table aria-label="customized table">
                <TableBody>
                    <StyledTableRow hover >
                        <StyledTableCell component="th" scope="row">
                            <BusquedaMedicamentoLocal onFijarMedicamentoSeleccionado={this.agregarMedicamento} />
                        </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
            </Table>

            <Grid container spacing={0}>
              <Grid item xs={12}>
              {this.state.medicamentosEnReceta.length>0&&
                  this.state.medicamentosEnReceta.map(callbackTarjetaReceta,{padre:this})
              }
              </Grid >
            </Grid >

            <Button variant="contained" color="primary" disabled={this.state.medicamentosEnReceta.length>0?false:true} onClick={this.handlerGenerarReceta}> Generar receta </Button>

            <Button variant="contained" color="primary" disabled={this.state.urlReceta==""} onClick={this.handlerVerReceta}> Ver receta </Button>

          </Container>

        </Box>
      </Paper>
    );
  }
}

export default Recetas;
