import React from "react";
import { Container,Grid,Typography} from '@material-ui/core';

const CabeceraTabla = () => {

  return (
        <Container maxWidth="xl" p={0} >
          <Grid pl={0} container spacing={0}  justify = "center" alignItems = "center"  >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Medicamentos
              </Typography>
            </Grid >
          </Grid>
        </Container>
  );
};

export default CabeceraTabla;
