import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    rootInput: {
        '& > *': {
            margin: theme.spacing(0),
            width: '25ch',
        },
    },
    root: {
        maxWidth: "100%",
        margin:10,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function TarjetaReceta({medicamento, eliminarMedicamento, index, updateItem,padre}) {
    const classes = useStyles();
    const [posologia, setPosologia] = React.useState(medicamento?.medication.composition?.medicationForm[0].desc);
    const [posologiaNumber, setPosologiaNumber] = React.useState(0);
    const [cada, setCada] = React.useState("Hora");
    const [cadaNumber, setCadaNumber] = React.useState(0);
    const [duracion, setDuracion] = React.useState("Hora");
    const [duracionNumber, setDuracionNumber] = React.useState(0);
    const [obs, setObs] = React.useState("");
    const hoy = new Date().toISOString().split("T")[0];
    const [initTratamiento, setInitTratamiento] = React.useState(hoy);

    const updateLocalObj = (value, setFunc)=>{
        setFunc(value);
        let medication = {
            "medication": medicamento,
            "units":parseInt(posologiaNumber, 10),
            "unitType": posologia,
            "frecuencyType": cada,
            "frecuency": parseInt(cadaNumber, 10),
            "frecuencyUnit": duracion,
            "duration":parseInt(duracionNumber, 10),
            "durationUnit": duracion,
            "observation": obs,
            "commercialRecommendation": null,
            "indicationStartDate": initTratamiento
        }
        updateItem(medication, index,padre);
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {medicamento?.name?.substring(0, 1).toUpperCase()}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings" onClick={()=>{eliminarMedicamento(index)}}>
                        <DeleteIcon />
                    </IconButton>
                }
                title={medicamento.name}
                subheader={medicamento.id}
            />
            <CardContent>
                <div className={classes.rootInput} noValidate autoComplete="off">
                    <TextField
                        id="outlined-basic"
                        label="Posologia"
                        variant="outlined"
                        style={{width:100}} type="number"
                        value={posologiaNumber}
                        onChange={e=>updateLocalObj(e.target.value, setPosologiaNumber)}/>
                        <TextField
                        id="outlined-select-currency-native"
                        select
                        label=""
                        value={posologia}
                        onChange={e=>setPosologia(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Please select your currency"
                        variant="outlined"
                        >
                        {medicamento.medication.composition.medicationForm.map((option) => (
                            <option key={option.desc} value={option.desc}>
                            {option.desc}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                    id="outlined-basic"
                    label="Cada" variant="outlined"
                    style={{marginLeft:12, width:100}}
                    type="number"
                    value={cadaNumber}
                    onChange={e=>updateLocalObj(e.target.value, setCadaNumber)}
                    />
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label=""
                        value={cada}
                        onChange={e=>setCada(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Please select your currency"
                        variant="outlined"
                        >
                        {["Hora", "Día", "Semana"].map((option) => (
                            <option key={option} value={option}>
                            {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-basic"
                        label="Duración" variant="outlined"
                        style={{marginLeft:12, width:100}}
                        type="number"
                        value={duracionNumber}
                        onChange={e=>updateLocalObj(e.target.value, setDuracionNumber)}
                        />
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label=""
                        value={duracion}
                        onChange={e=>setDuracion(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Please select your currency"
                        variant="outlined"
                        >
                        {["Hora", "Día", "Semana"].map((option) => (
                            <option key={option+"1"} value={option}>
                            {option}
                            </option>
                        ))}
                    </TextField>
                </div>
                <br/>
                <div className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="date"
                        label="Inicio del tratamiento"
                        type="date"
                        value={initTratamiento}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => updateLocalObj(e.target.value,setInitTratamiento)}
                    />
                    <TextField
                        style={{marginLeft:12, width:"70%"}}
                        id="outlined-multiline-static"
                        label="Observaciones"
                        multiline
                        rows={4}
                        value={obs}
                        defaultValue=" "
                        variant="outlined"
                        onChange={e => updateLocalObj(e.target.value,setObs)}
                    />
                </div>
            </CardContent>
        </Card>
    );
}
