import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Toast } from "../../../util/notifications";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import VideocamIcon from "@material-ui/icons/Videocam";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import logo_kuery2 from "../../../assets/kuery_portal.png";
import {
  ServiceConsultaProfesional,
  ServiceGetCurrentUser,
} from "../../../api/services";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core";
import { format } from "rut.js";
import EventIcon from "@material-ui/icons/Event";
import Horarios from "./horarios/Horarios";
import Pagos from "./pagos/Pagos";
import Integraciones from "./Integraciones/integraciones";
import Usuarios from "./usuarios/Usuarios";
import PeopleIcon from "@material-ui/icons/People";
import PaymentIcon from "@material-ui/icons/Payment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InsertLinkIcon from "@material-ui/icons/Link";
import Precios from "./precios/Precios.js";

import Postulaciones from "./postulaciones/Postulaciones";
import { useGetLogoURL } from "../../../util/logo";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#286db2",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 300,
    maxWidth: 600,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
  },
}));

export default function AdminControlPanel(props) {
  const classes = useStyles();
  const history = useHistory();
  const [rutPaciente, setRutPaciente] = useState("");

  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [dataProfessional, setDataProfessional] = useState({});
  const [screen, setScreen] = useState("Ficha");
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);

  const professionalID = localStorage.getItem("professional");
  const dominio =
    localStorage.getItem("dominio") || localStorage.getItem("clinica");

  const constructor = async () => {
    if (constructorHasRun) return;
    try {
      console.log(history);
      getProfessional(professionalID);
    } catch (e) {
      console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };

  useEffect(() => constructor(), []);

  const getProfessional = async (id) => {
    let response = await ServiceConsultaProfesional(id);
    console.log("response", response);
    /**
     *  DATA EJEMPLO
      {
        "run": "11111111-1",
        "id": "83c5bd26-fd97-4c14-ac31-a93b01497cda",
        "email": "medico1@kuery.cl",
        "specialities": [
          {
            "name": "MEDICINA GENERAL",
            "id": "100"
          }
        ],
        "firstName": "MEDICO1",
        "lastName": "KUERY1"
      }
     */
    setDataProfessional(response.body);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      Toast("Cerrar Sesion", "Sesion finalizada", "success");
      history.push("/signin");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
  };

  const changeScreen = (view) => {
    setScreen(view);
  };

  document.body.style = "background-image: none;";

  const logoUrl = useGetLogoURL();

  useEffect(() => {
    ServiceGetCurrentUser().then((r) => {
      setIsOrgAdmin(r.administradorOrganizacion);
    });
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ paddingLeft: "0px" }}>
          <img style={{ width: 300, height: 60 }} src={logoUrl} alt="logo" />
          <Typography variant="h6" noWrap className={classes.title}>
            Portal del Administrador
          </Typography>

          <Button color="inherit" onClick={handleLogout}>
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem button onClick={() => changeScreen("postulaciones")}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Postulaciones"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("horarios")}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={"Horas Tomadas"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("pagos")}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary={"Pagos"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("usuarios")}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={"Usuarios"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("precios")}>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary={"Precios"} />
            </ListItem>
            {isOrgAdmin && (
              <ListItem button onClick={() => changeScreen("integraciones")}>
                <ListItemIcon>
                  <InsertLinkIcon />
                </ListItemIcon>
                <ListItemText primary={"Integraciones"} />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {screen === "precios" ? (
          <Precios />
        ) : screen === "postulaciones" ? (
          <Postulaciones />
        ) : screen === "horarios" ? (
          <Horarios />
        ) : screen === "integraciones" ? (
          <Integraciones />
        ) : screen === "pagos" ? (
          <Pagos />
        ) : screen === "usuarios" ? (
          <Usuarios />
        ) : (
          <>
            {dominio ? (
              <>
                Su url de clínica para sus usuarios es:{" "}
                <a href={`https://kuery.cl/clinica/${dominio}`} target="_blank">
                  https://kuery.cl/clinica/{dominio}
                </a>
              </>
            ) : null}
          </>
        )}
      </main>
    </div>
  );
}
