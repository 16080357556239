import React from "react";
import { Container, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import "./Agendamiento.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
});

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const ButtonGrande = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 270px;
  height: 100px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 200px;
  height: 60px;
  fontWeight: "normal",

  background: #ffffff;
`;

function calcularDuracion(inicio, duracionMinutos) {
  let partesInicio = inicio.split(":");

  let hora = parseInt(partesInicio[0]);
  let minutos = parseInt(partesInicio[1]);

  duracionMinutos = parseInt(duracionMinutos);

  let minutosFinPeriodo = (minutos + duracionMinutos) % 60;
  let restoHoras = Math.floor((minutos + duracionMinutos) / 60);

  let horaFinal = hora + restoHoras;

  if (minutosFinPeriodo < 10) {
    minutosFinPeriodo = "0" + minutosFinPeriodo;
  }

  return "" + horaFinal + ":" + minutosFinPeriodo;
}

class AgendamientoTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      proyectosCargados: false,
      listaProyectos: [],
      value: 1,
      rutPaciente: "",
      rutValido: false,
      pasoActual: 3,
      tipoBusqueda: "",
      listadoMedicos: [],
      medicoSeleccionado: false,
      diasDisponibilidad: [],
      slotsDisponiblesSegunSeleccionDia: [],
      diaSeleccionado: "",
      mesSeleccionado: "",
      anioSeleccionado: "",
      indiceSlotSeleccionado: -1,
      correoPaciente: "",
      correoValido: false,
      cargas: [],
      datosUsuario: {},
    };
  }

  limpiarMedicosSinSlotsDisponibles = (listadoMedicos) => {
    let medicosPosibles = [];

    for (let i = 0; i < listadoMedicos.length; i++) {
      let medico = listadoMedicos[i];
      let disponibilidades = medico.disponibilidad;

      let diaPosible = false;

      if (typeof disponibilidades === "undefined") {
        continue;
      }

      for (let j = 0; j < disponibilidades.length; j++) {
        let disponibilidad = disponibilidades[j];

        if (disponibilidad.horaTomada) {
          continue;
        }

        let diaString = disponibilidad.dia;

        let partes = diaString.split("-");
        let anioDisponible = parseInt(partes[0]);
        let mesDisponible = parseInt(partes[1]) - 1;
        let diaDisponible = parseInt(partes[2]);

        let fechaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);
        let ahora = new Date();

        if (fechaSlot.getTime() < ahora.getDate()) {
          continue;
        }

        diaPosible = true;
        break;
      }

      if (diaPosible) {
        medicosPosibles.push(medico);
      }
    }
    return medicosPosibles;
  };

  async componentDidMount() {}

  handlerRutPaciente2 = (rutValido, valorRut) => {
    if (rutValido) {
      this.setState({ rutValido: true });
      //let nuevoRut=this.reformat("",e.target.value);*/
      this.setState({ rutPaciente: valorRut });
    } else {
      this.setState({ rutValido: false });
    }
  };

  handlerCorreoPaciente = (correoValido, valorCorreo) => {
    if (correoValido) {
      this.setState({ correoValido: true, correoPaciente: valorCorreo });
    } else {
      this.setState({ correoValido: false });
    }
  };

  handlerSiguientePaso0 = () => {
    this.setState({ pasoActual: 1 });
  };

  handlerSiguientePaso1 = () => {
    this.setState({ pasoActual: 2 });
  };

  handlerAnteriorPaso1 = () => {
    this.setState({ pasoActual: 0 });
  };

  handlerAnteriorPaso2 = () => {
    this.setState({ pasoActual: 1 });
  };

  handlerSiguientePaso2 = () => {
    this.setState({ pasoActual: 3 });
  };

  handlerAnteriorPaso3 = () => {
    this.setState({ pasoActual: 2 });
  };

  handlerBusquedaPorNombre = () => {
    this.setState({ tipoBusqueda: "porNombre" });
  };

  handlerBusquedaPorEspecialidad = () => {
    this.setState({ tipoBusqueda: "porEspecialidad" });
  };

  handlerSeleccionarMedico = (event, newValue) => {
    console.log("el medico seleccionado!");
    console.log(newValue);
    this.setState({ medicoSeleccionado: newValue });
  };

  handlerDiasDesactivados = ({ activeStartDate, date, view }) => {
    let activarDia = false;

    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      activarDia = false;
      return !activarDia;
    }

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let s = "procesando dia de calendario " + date;

    for (var indice in disponibilidades) {
      let diaString = disponibilidades[indice].dia;

      let partes = diaString.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let diaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      //el dia en revision corresponde con el que se esta revisando, y el slot no esta tomado
      //entonces habilitar el dia en el calendario
      if (diaEncontrado && disponibilidades[indice].horaTomada == false) {
        activarDia = true;
        return !activarDia;
      }
    }

    console.log("    [DIAS DESACTIVADOS4]   dia del calendario " + date);
    return !activarDia;
  };

  handlerClassNameCalendario = ({ activeStartDate, date, view }) => {
    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      //console.log("[CLASSNAME]slot del pasado, me lo salto: " + date);
      return null;
    }

    //console.log("");
    //console.log("##########CALCULANDO CLASSNAME");
    //console.log("[CLASSNAME] dia/mes del calendario: " + date);

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in disponibilidades) {
      let diaDisponible = disponibilidades[indice].dia;

      if (disponibilidades[indice].horaTomada == true) {
        //console.log("   [CLASSNAME]slot hora tomada, me la salto: ");
        continue;
      }

      let partes = diaDisponible.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let fechaSlot = new Date(
        parseInt(anioDisponible),
        parseInt(mesDisponible) - 1,
        parseInt(diaDisponible)
      );

      /*    if (fechaSlot.getTime() < ahora.getTime()) {
        //console.log("    [CLASSNAME]slot del pasado , me lo salto: " + fechaSlot);
        continue;
      }
*/
      //console.log("[CLASSNAME]     conitnuo!, el slot es: dia/mes disponible " + diaDisponible + "/" + mesDisponible);

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        //console.log("[CLASSNAME]diponible!!!!!!!!!!!!!!!!1");
        return "diaDisponible";
      }
    }

    return null;
  };

  handlerSeleccionDia = (value, event) => {
    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (value.getTime() < ahora.getTime()) {
      //console.log("[handlerSeleccionDia]slot del pasado, me lo salto: " + date);
      return null;
    }

    this.setState({ indiceSlotSeleccionado: -1 });

    let anioSeleccionado = value.getFullYear();
    let mesSeleccionado = value.getMonth() + 1;
    let diaSeleccionado = value.getDate();

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let slotsDisponibles = [];

    for (var indice in disponibilidades) {
      let horaTomada = disponibilidades[indice].horaTomada;

      if (horaTomada == true) {
        continue;
      }

      let diaDisponible = disponibilidades[indice].dia;
      let partes = diaDisponible.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioSeleccionado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesSeleccionado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaSeleccionado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        slotsDisponibles.push(disponibilidades[indice]);
      }
    }

    this.setState({ slotsDisponiblesSegunSeleccionDia: slotsDisponibles });
    this.setState({
      diaSeleccionado: diaSeleccionado,
      mesSeleccionado: mesSeleccionado,
      anioSeleccionado: anioSeleccionado,
    });
  };

  handlerSeleccionarSlot = (indice) => {
    this.setState({ indiceSlotSeleccionado: indice });
  };

  handlerPagar = (e) => {
    //console.log('handlerPagar init:');
    let dataMedico = this.state.medicoSeleccionado;
    delete dataMedico.disponibilidad;
    axios
      .post(
        "https://v7e3jqoqj3.execute-api.us-east-1.amazonaws.com/dev/payCMV-2",
        {
          dataMedico: null,
          id_medico: "1111111111",
          nombreMedico: "MedicoTestTransbank",
          diaReserva: "10/10/2022",
          horaReserva: "00:00",
          email_paciente: "test_test_transbank@mail.com",
          rutPaciente: "11111111-1",
          id: uuidv4(),
        },
        header
      )
      .then((res) => {
        console.log("TRANSBANK RES:", res);
        const url = res.data.urlWebPay;
        window.location.replace(url);
      })
      .catch((err) => {
        console.log("TRANSBANK ERR:", err);
      });
  };

  render() {
    const { pasoActual, tipoBusqueda } = this.state;
    return (
      <Container maxWidth="lg">
        <Box mt={4}>
          <Typography variant="h6">Confirmación</Typography>
        </Box>

        <Box pb={3}>
          <Box mt={4}>
            <Typography variant="body1">
              Se va a realizar una reserva de hora con el médico MEDICO TEST
              TRANSBANK
            </Typography>
          </Box>
          <Box mt={2} mt={3}>
            <Typography variant="body1">
              Para el día <b>10-10-2022</b> a las 13:00 horas
            </Typography>
          </Box>
        </Box>
        <span> </span>
        <Button onClick={this.handlerPagar}>Pagar</Button>
      </Container>
    );
  }
}

export default AgendamientoTest;
