import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { MenuItem, Paper, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  ServiceActualizaPaciente,
  ServiceConsultaPaciente,
} from "../../../../api/services";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 500,
    maxWidth: 600,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Ficha(props) {
  console.log("Ficha", props);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [constructorHasRun, setConstructorHasRun] = useState(false);

  const [valueNombre, setValueNombre] = React.useState("");
  const [valueFechaNac, setValueFechaNac] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [valueGenero, setValueGenero] = React.useState("");
  const [valuePrevision, setValuePrevision] = React.useState(
    props.paciente.prevision
  );
  const [valueEstadoCivil, setValueEstadoCivil] = React.useState(
    props.paciente.estadoCivil
  );
  const [valueDireccion, setValueDireccion] = React.useState(
    props.paciente.direccion
  );
  const [valueTelefono, setValueTelefono] = React.useState(
    props.paciente.telefono
  );
  const [valueEmail, setValueEmail] = React.useState(props.paciente.email);

  const handleOpen = () => {
    setOpen(true);
    setValueNombre(props.paciente.nombre);
    setValueGenero(props.paciente.genero);
    setValuePrevision(props.paciente.prevision);
    setValueEstadoCivil(props.paciente.estadoCivil);
    setValueDireccion(props.paciente.direccion);
    setValueTelefono(props.paciente.telefono);
    setValueEmail(props.paciente.email);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actualizarDatos = async () => {
    let paciente = props.paciente;

    paciente.rut = localStorage.getItem("rut");
    paciente.nombre = valueNombre;
    paciente.fechaNacimiento = valueFechaNac;
    paciente.genero = valueGenero;
    paciente.prevision = valuePrevision;
    paciente.estadoCivil = valueEstadoCivil;
    paciente.direccion = valueDireccion;
    paciente.telefono = valueTelefono;
    paciente.email = valueEmail;
    paciente.organizacion = localStorage.getItem("clinica");

    let response = await ServiceActualizaPaciente(paciente);
    setOpen(false);

    props.setData(response, 1);
  };

  const search_array = (array, valuetofind) => {
    console.log("buscando");
    console.log(valuetofind);

    for (let i = 0; i < array.length; i++) {
      console.log(array[i]["id"]);
      if (array[i]["id"] === valuetofind) {
        return array[i];
      }
    }
    return -1;
  };

  const dataPrevision = [
    { id: "0", value: "-- SELECCIONE --" },
    { id: "20001", value: "FONASA" },
    { id: "33702", value: "ISALUD ISAPRE DE CODELCO LTDA" },
    { id: "30101", value: "ISAPRE BANMEDICA S.A." },
    { id: "30501", value: "ISAPRE COLMENA GOLDEN CROSS S.A." },
    { id: "30201", value: "ISAPRE CONSALUD" },
    { id: "30301", value: "ISAPRE CRUZ BLANCA S.A." },
    { id: "31201", value: "ISAPRE FUNDACION BANCO DEL ESTADO" },
    { id: "26", value: "ISAPRE RIO BLANCO LTDA." },
    { id: "30701", value: "ISAPRE VIDA TRES S.A." },
    { id: "30402", value: "NUEVA MAS VIDA S.A" },
    { id: "10001", value: "PARTICULAR" },
  ];

  const eventData = (e) => {
    console.log("e", e);
  };

  let prevision = "";

  if (typeof props.paciente.prevision !== "undefined") {
    prevision = search_array(dataPrevision, props.paciente.prevision)["value"];
  }

  return (
    <Paper>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              NOMBRE:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.nombre}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              RUT:
            </StyledTableCell>
            <StyledTableCell align="left">{props.paciente.rut}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              FECHA DE NACIMIENTO:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.fechaNacimiento}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              GENERO:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.genero}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              PREVISIÓN:
            </StyledTableCell>
            <StyledTableCell align="left">{prevision}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              ESTADO CIVIL:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.estadoCivil}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              DIRECCIÓN:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.direccion}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              TELEFONO:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.telefono}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              EMAIL:
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.paciente.email}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <br />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpen}
      >
        Editar
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Actualiza tus datos</h2>
            <TextField
              style={{ width: "50%", maxWidth: "50%" }}
              id="outlined-basic"
              label="RUT"
              variant="outlined"
              disabled
              value={localStorage.getItem("rut")}
            />
            <TextField
              style={{ width: "50%", maxWidth: "50%" }}
              id="outlined-basic"
              label="Nombre completo"
              variant="outlined"
              value={valueNombre}
              onChange={(e) => setValueNombre(e.target.value)}
            />
            <br />
            <div style={{ marginTop: "12px" }}>
              <TextField
                type="date"
                style={{ width: "50%", maxWidth: "50%" }}
                id="outlined-basic"
                label="Fecha de nacimiento"
                variant="outlined"
                value={valueFechaNac}
                onChange={(e) => setValueFechaNac(e.target.value)}
              />
              <TextField
                id="outlined-select-currency"
                select
                label="Genero"
                value={valueGenero}
                onChange={(e) => setValueGenero(e.target.value)}
                variant="outlined"
                style={{ width: "50%", maxWidth: "50%" }}
              >
                <MenuItem value={"Masculino"}>Masculino</MenuItem>
                <MenuItem value={"Femenino"}>Femenino</MenuItem>
              </TextField>
            </div>
            <div style={{ marginTop: "12px" }}>
              <TextField
                id="outlined-select-currency"
                select
                label="Prevision"
                value={valuePrevision}
                onChange={(e) => setValuePrevision(e.target.value)}
                variant="outlined"
                style={{ width: "50%", maxWidth: "50%" }}
              >
                {dataPrevision.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                select
                label="Estado civil"
                value={valueEstadoCivil}
                onChange={(e) => setValueEstadoCivil(e.target.value)}
                variant="outlined"
                style={{ width: "50%", maxWidth: "50%" }}
              >
                <MenuItem value={"Soltero/a"}>Soltero/a</MenuItem>
                <MenuItem value={"Casado/a"}>Casado/a</MenuItem>
              </TextField>
            </div>
            <div style={{ marginTop: "12px" }}>
              <TextField
                style={{ width: "50%", maxWidth: "50%" }}
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                value={valueDireccion}
                onChange={(e) => setValueDireccion(e.target.value)}
              />
              <TextField
                style={{ width: "50%", maxWidth: "50%" }}
                id="outlined-basic"
                label="Telefono"
                variant="outlined"
                value={valueTelefono}
                onChange={(e) => setValueTelefono(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "12px" }}>
              <TextField
                style={{ width: "100%", maxWidth: "100%" }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={valueEmail}
                onChange={(e) => setValueEmail(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "12px" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={actualizarDatos}
              >
                Actualizar
              </Button>
              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Paper>
  );
}
