import React, { useEffect, useState } from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Paper, Grid, Box, TextField } from "@material-ui/core";

class Rut extends React.Component {
  constructor(props) {
    super(props);

    let valido = this.rutValido(props.value);

    this.state = {
      rutPersona: props.value,
      handlerRutExterno: props.handlerRut,
      rutValido: valido,
    };
  }

  formatoRutSinPuntos = (rut) => {
    if (!rut | (typeof rut !== "string")) return false;
    return /^\d{1,2}\d{3}\d{3}-[k|K|\d]{1}$/.test(rut);
  };

  formatoRutConPuntos = (rut) => {
    if (!rut | (typeof rut !== "string")) return false;
    return /^\d{1,2}.\d{3}.\d{3}-[k|K|\d]{1}$/.test(rut);
  };

  digitoVerificador = (rut) => {
    let multiplos = [2, 3, 4, 5, 6, 7];
    let digitos = rut.split("-")[0].replace(/\./g, "").split("").reverse();
    let digitoVerificador = rut.split("-")[1].toUpperCase();
    let digito =
      11 -
      (digitos.reduce(
        (acc, elem, index) =>
          acc + Number(elem) * multiplos[index % multiplos.length],
        0
      ) %
        11);
    let digimap = [NaN, "1", "2", "3", "4", "5", "6", "7", "8", "9", "K", "0"];
    return digimap[digito] === digitoVerificador;
  };

  rutValido = (rut) => {
    let r1 = this.formatoRutSinPuntos(rut) && this.digitoVerificador(rut);
    let r2 = this.formatoRutConPuntos(rut) && this.digitoVerificador(rut);
    return r1 || r2;
  };

  reformat = (rutViejo, rutNuevo) => {
    let digitos = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "K", "k"];
    let digitoValido = (digito) => digitos.includes(digito);
    let quitarDV = (digito, index, array) =>
      index < array.length - 1 ? !["K", "k"].includes(digito) : true;

    if (rutNuevo !== "") {
      let chars = rutNuevo.split("").filter(digitoValido).filter(quitarDV);
      if (chars.length > 9) return rutViejo;
      let digito = chars[chars.length - 1];

      if (digitoValido(digito)) {
        [1, 5, 9].forEach((index) => {
          if (chars.length > index) {
            chars.splice(chars.length - index, 0, index === 1 ? "-" : ".");
          }
        });
        return chars.join("").toUpperCase();
      } else {
        return rutViejo;
      }
    }
    return "";
  };

  handlerrutPersona = (e) => {
    let rut = e.target.value;

    this.setState({ rutPersona: e.target.value });

    if (this.rutValido(rut)) {
      this.setState({ rutValido: true });
      let nuevoRut = this.reformat("", e.target.value);
      this.setState({ rutPersona: nuevoRut });
      this.state.handlerRutExterno(true, nuevoRut);
    } else {
      this.setState({ rutValido: false });
      this.state.handlerRutExterno(false, rut);
    }
  };

  render() {
    return (
      <TextField
        error={!(this.state.rutPersona === "") && !this.state.rutValido}
        helperText={
          !(this.state.rutPersona === "") && !this.state.rutValido
            ? "Rut incorrecto!"
            : ""
        }
        value={this.state.rutPersona}
        label="Rut (XX.XXX.XXX-X)"
        onChange={this.handlerrutPersona}
      ></TextField>
    );
  }
}

export default Rut;
