import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
  color: #fff;
  background: #3c77e3;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;
`;

const SubirArchivo = props => {

  const hiddenFileInput = React.useRef(null);
  const [urlArchivo, setUrlArchivo] = React.useState("");

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const archivo = event.target.files[0];

    let urlArchivo=URL.createObjectURL(archivo);
    setUrlArchivo(urlArchivo);

    var reader = new FileReader();
    reader.readAsDataURL(archivo);

    reader.onload = function () {
          props.handlerArchivo(urlArchivo,reader.result);
    };

    reader.onerror = function (error) {
        console.log('Error: ', error);
    };

  };
  return (
    <>
      <Button onClick={handleClick}>
        {props.etiquetaBoton}
      </Button>
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}}
             accept=".jpg"
      />
      <img src={urlArchivo} style={{width:300}}/>
    </>
  );
};

export default SubirArchivo;
