import React from "react";
import {MenuItem,Container,Grid,Typography,TextField,Select,Box,Paper} from '@material-ui/core';
import {Link} from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled,{ThemeProvider} from 'styled-components';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 100px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

class FilaCarga extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descripcionMaterial: props.descripcionMaterial,
      porcentajeMaterial: props.porcentajeMaterial,
      pesoMaterial: props.pesoMaterial,
      cantidadMaterial: Math.round((props.porcentajeMaterial/100.0)*props.pesoMaterial),
      cantidadImpurezas: props.cantidadImpurezas,
      puertoDescarga: props.puertoDescarga,
      estado:"mostrar",
      };
  }

  handlerEntrar=()=>{
    console.log("entrando!");
    this.setState({estado:"editar"});
  }

  handlerSalir=()=>{
    console.log("saliendo!");
    this.setState({estado:"mostrar"});
  }

  handlerBorrar = ()=>{
    console.log("aaaaaaaaaaaaaaqui");
    this.props.handlerBorrar(this.props.fila);
  }

  handlerVerificarPlanAccion = ()=>{
    console.log("verificar!");
    this.props.handlerMostrarPanelVerificarPlanDeAccion(this.props.fila);
  }

  render(){

    let responsable=this.props.fila.responsable_plan_accion;
    let verificador=this.props.fila.verificador_plan_accion;
    let labelFecha="Fecha Vencimiento:";
    let fecha=this.props.fila.fecha_vencimiento;

    let mostrarBotonEjecutarPlanAccion=false;
    let mostrarBotonVerificarPlanAccion=false;

    let usuario=localStorage.getItem("usuario");

    if (this.props.fila.estado=="vigente" && this.props.fila.responsable_plan_accion==usuario){
      mostrarBotonEjecutarPlanAccion=true;
    }

    if (this.props.fila.estado=="ejecutado" && this.props.fila.verificador_plan_accion==usuario){
      mostrarBotonVerificarPlanAccion=true;
      responsable=this.props.fila.responsable_plan_accion;
    }

    return (
          <Container maxWidth="xl"   >
            <Box bgcolor={this.props.estilo} pt={1} pb={1}>
              <Grid container spacing={0}   align = "center" alignItems = "center"  >
                <Grid item xs={8}>
                  <Box  display="flex" flexDirection="row" alignItems="flex-end" flexWrap="wrap">
                    <Box pr={1}>
                      {this.props.fila.id}
                    </Box>
                    <Box pr={1}>
                      {this.props.fila.primerNombre}
                    </Box>
                    <Box pr={1}>
                      {this.props.fila.segundoNombre}
                    </Box>
                    <Box pr={1}>
                      {this.props.fila.apellidoPaterno}
                    </Box>
                    <Box pr={1}>
                      {this.props.fila.apellidoMaterno}
                    </Box>
                  </Box>
                </Grid >

                <Grid item xs={4} justify = "center" alignItems = "center" >
                  <Box mr={0.5} >
                      <Button onClick={this.handlerBorrar}>Borrar</Button>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Container>
    );
  }
};

export default FilaCarga;
