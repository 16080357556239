import React,  {useState }from "react";
import { Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../../util/forms";
import { Toast } from "./../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import SubirArchivo from './DashboardView/comun/SubirArchivo';
import Rut from './DashboardView/comun/Rut';

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const RecuperarContrasena = () => {
  const [loading, setLoading] = React.useState(false);
  const [postulacionEnviada, setPostulacionEnviada] = React.useState(false);

  const [imagenCarnet64, setImagenCarnet64] = useState("");

  const history = useHistory();

  const { value: primer_nombre, bind: bindPrimerNombre } = useInput("");
  const { value: segundo_nombre, bind: bindSegundoNombre } = useInput("");
  const { value: apellido_paterno, bind: bindApellidoPaterno } = useInput("");
  const { value: apellido_materno, bind: bindApellidoMaterno } = useInput("");
  const { value: rol, bind: bindRol } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");

  const [valueRut, setValueRut] = React.useState("");
  const [valueRutValido, setValueRutValido] = React.useState(false);

  const [rut, setRut] = React.useState("");

  const [fotoCarnet, setFotoCarnet] = React.useState("");
  const [fotoTitulo, setFotoTitulo] = React.useState("");

  const [mostrarMensaje, setMostrarMensaje] = React.useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };

      axios
        .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion',  {
              "id": ""+rut,
              "primer_nombre": ""+primer_nombre,
              "segundo_nombre": ""+segundo_nombre,
              "apellido_paterno": ""+apellido_paterno,
              "apellido_materno": ""+apellido_materno,
              "email": ""+email,
              "telefono": ""+phone,
              "rol": ""+rol,
              "fotoCarnet":""+fotoCarnet,
              "fotoTitulo":""+fotoTitulo,
              "estado":"revision"
            },header)
       .then(res=>{
           setPostulacionEnviada(true);
       });

    setLoading(false);
  };

  const handlerSubidaFotoCarnet = (url,archivo) => {
    setFotoCarnet(archivo);
  }

  const handlerSubidaFotoTitulo = (url,archivo) => {
    setFotoTitulo(archivo);
  }

  const buscarDatosUsuario= async (rut,nuevoPassword) =>{
    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };
    console.log("por aca!!!");

    axios
      .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/patient?rut="+rut, header)
      .then((res) =>{
            console.log(res);
            let data=res["data"];
            let body=data["body"];
            data=body["data"];
            console.log(data);

            let json={
              "nuevoPassword": nuevoPassword,
              "nombreUsuario": rut,
              "correo":data["email"],
              "nombrePaciente":data["nombre"]
            }

            axios
              .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/reset-password',json ,header)
              .then(res=>{
                console.log("cambiado!");
                setMostrarMensaje(true);

             });


          })
       .catch((err) => console.log(err));


  }


/*
axios
  .post("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/todos", header)
  .then((res) =>{
            console.log("respuesta!!!!!!");
              let data=res["data"];
              let body=data["body"];
              let usuarios=body["Users"];

              let resultado=[];
              for (let i =0;i<usuarios.length;i++){
                let elemento=usuarios[i];
                elemento["id"]=elemento["Username"];

                if (elemento["Username"]!=rut ){
                  continue
                }

                let correo=""
                let nombre="";
                let apellido="";

                for (let j=0;j<elemento["Attributes"].length;j++){
                  let atributo=elemento["Attributes"][j];

                  if (atributo.Name=="email"){
                      correo=atributo.Value;
                  }

                  if (atributo.Name=="name"){
                      nombre=atributo.Value;
                  }

                  if (atributo.Name=="family_name"){
                      apellido=atributo.Value;
                  }
                }
                console.log("correo");
                console.log(correo);
                console.log("nombre");
                console.log(nombre);
                console.log("apellido");
                console.log(apellido);

                let json={
                  "nuevoPassword": nuevoPassword,
                  "nombreUsuario": rut,
                  "correo":correo,
                  "nombrePaciente":nombre+" "+apellido
                }

                axios
                  .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/reset-password',json ,header)
                  .then(res=>{
                    console.log("cambiado!");
                    setMostrarMensaje(true);

                 });
              }
            }
          )
   .catch((err) => console.log(err));

*/

  const generatePassword =() =>{
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handlerRecuperarContrasena = ()=>{
    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };



    let nuevoRut=valueRut.replaceAll("\.","");

    let nuevoPassword= generatePassword();
    console.log("nuevo passowrd");
    console.log(nuevoPassword);

    console.log("recuperando!");

    buscarDatosUsuario(nuevoRut,nuevoPassword);
  }

  const handlerRut = (rutValido,valorRut) =>{
    if (rutValido){
      setValueRutValido(true);
      setValueRut(valorRut);
    }else{
      setValueRutValido(false);
    }
  }

  return (
    <Box  >
      {postulacionEnviada==false && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSignUp}
        >
          <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
            {" "}
            Recuperar Contrasena
          </h1>

          <Typography variant="body1">
            Ingrese su rut. Una nueva contraseña sera enviada a su mail de contacto.
          </Typography>

          <Box display="flex" flexDirection="column" mt={3} >
            <Box display="flex" flexDirection="column" style={{width:600}}>
              <Box>
                <Rut  value={valueRut} handlerRut={handlerRut} ></Rut>
              </Box>
            </Box>
            <Box style={{width:400}}>
               <Box display="flex" flexDirection="row" mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handlerRecuperarContrasena}
                    disabled={valueRutValido==false }
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
                    Recuperar Contraseña
                  </Button>



              </Box>

              {mostrarMensaje&&(
              <Box mt={3}>
                <Typography variant="body1" >
                  Su contraseña ha sido reiniciada. Por favor revise su correo.
                </Typography>
              </Box>
              )}

            </Box>
          </Box>

          <Box mt={3}>
          </Box>

          <DLink to="/signin">Volver al inicio &rarr;</DLink>
        </form>
      )}

      {postulacionEnviada==true && (
        <Paper  >
          <Box m={3}  pt={3} >
            <Typography variant="h6">
              Postulación enviada
            </Typography>
          </Box>

          <Box m={3} pb={3}>
            <Box mt={4}>
              <Typography variant="body1">
                Su postulación ha sido enviada a la plataforma. Uno de nuestros colaboradores se contactará con ud para los procesos de verificación.
              </Typography>
              <br/>
              <DLink to="/signin">Volver al inicio &rarr;</DLink>
            </Box>
          </Box>
        </Paper>
      )}
    </Box>

  );
};

export default RecuperarContrasena;
