import React from "react";
import { Box, Paper } from "@material-ui/core";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import DetallePago from "./DetallePago";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const columns = [
  { field: "id", headerName: "Id", width: 90 },
  {
    field: "rutPaciente",
    headerName: "Rut Paciente",
    width: 200,
    editable: false,
  },
  {
    field: "nombreMedico",
    headerName: "Medico",
    width: 200,
    editable: false,
  },
  {
    field: "fechaReserva",
    headerName: "Fecha Reserva",
    width: 200,
    editable: false,
  },
  {
    field: "updateDatetime",
    headerName: "Fecha Pago",
    width: 200,
    editable: false,
  },
  {
    field: "amount",
    headerName: "Monto",
    width: 160,
    flex: 1,
  },
  {
    field: "transbankStatus",
    headerName: "Estado Transbank",
    width: 160,
    flex: 1,
  },
];

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

class Pagos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filasSeleccionadas: [],
      mostrarPanelAgregar: false,
      diaNacimiento: "",
      mesNacimiento: "",
      anioNacimiento: "",

      descripcionSeleccionada: "",

      listaPlanesAccion: [],

      listaSectores: [],
      sectorSeleccionado: null,

      listaResponsables: [],
      responsablesPosibles: [],
      responsableSeleccionado: null,

      vencimientoSeleccionado: null,

      mostrarPanelCerrarPlanAccion: false,
      idEditar: -1,
      listaElementos: [],
      jsonSeleccionado: null,
    };
  }

  refrescarDatosTabla = () => {
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/pagos/todos",
        header
      )
      .then((res) => {
        let data = res["data"];
        let body = data["body"];
        console.log(JSON.stringify(body));
        /*  console.log("====================================");
                  console.log(JSON.stringify(data));
*/
        let resultado = [];
        for (let i = 0; i < body.length; i++) {
          let elemento = body[i];
          elemento["rutPaciente"] = elemento["cmvDetail"]["rutPaciente"];
          elemento["nombreMedico"] = elemento["cmvDetail"]["nombreMedico"];
          elemento["fechaReserva"] =
            elemento["cmvDetail"]["diaReserva"] +
            " " +
            elemento["cmvDetail"]["horaReserva"];
          resultado.push(elemento);
        }

        this.setState({ listaElementos: resultado });
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.refrescarDatosTabla();
  }

  handlerSeleccionFilas = (e) => {
    this.setState({ filasSeleccionadas: e });
  };

  handlerMostrarPanelAgregar = (e) => {
    this.setState({
      descripcionSeleccionada: "",
      sectorSeleccionado: null,
      responsableSeleccionado: null,
      vencimientoSeleccionado: null,
    });
    this.setState({ mostrarPanelAgregar: true });
  };

  handlerMostrarPanelCerrarPlanDeAccion = (e) => {
    let ids = [];
    let jsonSeleccionado = null;

    for (let j = 0; j < this.state.listaPlanesAccion.length; j++) {
      let encontrado = false;
      let actual = this.state.listaPlanesAccion[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        console.log(this.state.filasSeleccionadas[i]);

        if (actual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
          ids.push(actual["id"]);
          jsonSeleccionado = actual;
        }
      }
    }

    this.setState({
      mostrarPanelCerrarPlanAccion: true,
      idEditar: ids[0],
      jsonSeleccionado: jsonSeleccionado,
    });
  };

  handlerBorrarPlanAccion = (e) => {
    let ids = [];

    for (let j = 0; j < this.state.listaPlanesAccion.length; j++) {
      let encontrado = false;
      let actual = this.state.listaPlanesAccion[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        console.log(this.state.filasSeleccionadas[i]);

        if (actual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
          ids.push(actual["id"]);
        }
      }
    }

    for (let i = 0; i < ids.length; i++) {
      let idBorrar = ids[i];

      axios
        .delete("api/plan-de-accion/" + idBorrar + "/", header)
        .then((res) => {
          console.log("borrado");
        });
    }
    this.refrescarDatosTabla();
  };

  getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  handlerAceptar = (json) => {
    axios.post("api/plan-de-accion/", json, header).then((res) => {
      this.setState({ mostrarPanelAgregar: false });
      this.refrescarDatosTabla();
    });
  };

  handlerCerrarPlanDeAccion = (json) => {
    axios
      .put("api/plan-de-accion/" + json["id"] + "/", json, header)
      .then((res) => {
        this.setState({ mostrarPanelCerrarPlanAccion: false });
        this.refrescarDatosTabla();
      });
  };

  handlerDetallePago = (e) => {
    console.log("aaaaaaaaaaaaaaaaaacqui");

    let ids = [];
    let jsonSeleccionado = null;

    for (let j = 0; j < this.state.listaElementos.length; j++) {
      let encontrado = false;
      let actual = this.state.listaElementos[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        console.log(this.state.filasSeleccionadas[i]);

        if (actual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
          ids.push(actual["id"]);
          jsonSeleccionado = actual;
        }
      }
    }

    console.log("rut seleccionado por aca!" + ids[0]);
    this.setState({
      mostrarPanelDetallePago: true,
      idSeleccionado: ids[0],
      jsonSeleccionado: jsonSeleccionado,
    });
  };

  handlerCerrarPanelDetallePago = () => {
    this.setState({ mostrarPanelDetallePago: false, idSeleccionado: "" });
  };

  render() {
    return (
      <Paper>
        <Box p={4}>
          <Box mb={2}>
            <Button
              disabled={this.state.filasSeleccionadas.length !== 1}
              onClick={this.handlerDetallePago}
            >
              Ver detalle
            </Button>
            <span> </span>
          </Box>

          <div style={{ height: 600, width: "100%" }}>
            {this.state.listaElementos.length > 0 ? (
              <DataGrid
                rows={this.state.listaElementos}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                checkboxSelection
                onSelectionModelChange={this.handlerSeleccionFilas}
              />
            ) : (
              <p></p>
            )}
          </div>

          <br />
          <br />
        </Box>

        {this.state.mostrarPanelDetallePago &&
          !(this.state.idSeleccionado == "") && (
            <DetallePago
              jsonSeleccionado={this.state.jsonSeleccionado}
              idSeleccionado={this.state.idSeleccionado}
              visible={this.state.mostrarPanelDetallePago}
              key={"agregar-plan-de-accion"}
              handlerCerrarPanel={this.handlerCerrarPanelDetallePago}
            />
          )}
      </Paper>
    );
  }
}

export default Pagos;
