import React from "react";
import {
  Select,
  Modal,
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DataGrid } from "@material-ui/data-grid";
import styled, { ThemeProvider } from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import EtiquetaTextField from "../../DashboardView/comun/EtiquetaTextField";
import EtiquetaSelect from "../../DashboardView/comun/EtiquetaSelect";
import SelectorFecha from "../../DashboardView/comun/SelectorFecha";
import Rut from "../../DashboardView/comun/Rut";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const BotonGrande = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 250px;
  height: 75px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const BotonGrandeRojo = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 250px;
  height: 75px;
  background: ${(props) => (props.disabled ? "#ff0000" : "#ff0000")};
`;

const estiloDivModal = {
  position: "absolute",
  top: "5%",
  left: "25%",
  right: "25%",
  bottom: "10%",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "4px",
  outline: "none",
  padding: "40px",
};

class RevisarPostulacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
      rut: props.rutSeleccionado,
      externoHandlerAprobarPostulacion: props.handlerAprobarPostulacion,
      externoHandlerRechazarPostulacion: props.handlerRechazarPostulacion,
      handlerCerrarPanel: props.handlerCerrarPanel,
      id: null,
      primerNombre: null,
      segundoNombre: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      email: null,
      telefono: null,
      rol: null,
      fotoCarnet: null,
      fotoTitulo: null,
      pasoActual: 1,
    };
  }

  componentDidMount() {
    console.log("RevisarPostulacion", "componentDidMount");
    axios
      .get(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion/" +
          this.state.rut
      )
      .then((res) => {
        let data = res.data.body;
        let id = data["id"];
        let primerNombre = data["primer_nombre"];
        let segundoNombre = data["segundo_nombre"];
        let apellidoPaterno = data["apellido_paterno"];
        let apellidoMaterno = data["apellido_materno"];
        let email = data["email"];
        let telefono = data["telefono"];
        let rol = data["rol"];
        let fotoCarnet = data["fotoCarnet"];
        let fotoTitulo = data["fotoTitulo"];

        this.setState({
          id: id,
          primerNombre: primerNombre,
          segundoNombre: segundoNombre,
          apellidoPaterno: apellidoPaterno,
          apellidoMaterno: apellidoMaterno,
          email: email,
          telefono: telefono,
          rol: rol,
          fotoCarnet: fotoCarnet,
          fotoTitulo: fotoTitulo,
        });
      })
      .catch((err) => console.log(err));
  }

  handlerCerrar = (e) => {
    this.setState({ visible: false });
  };

  handlerSeleccionarSector = (sector) => {
    this.setState({ sectorSeleccionado: sector });
  };

  handlerSeleccionarResponsable = (responsable) => {
    this.setState({ responsableSeleccionado: responsable });
  };

  handlerDescripcion = (e) => {
    this.setState({ descripcionSeleccionada: e.target.value });
  };

  handlerPrimerNombre = (e) => {
    this.setState({ primerNombre: e.target.value });
  };

  handlerSegundoNombre = (e) => {
    this.setState({ segundoNombre: e.target.value });
  };

  handlerApellidoPaterno = (e) => {
    this.setState({ apellidoPaterno: e.target.value });
  };

  handlerApellidoMaterno = (e) => {
    this.setState({ apellidoMaterno: e.target.value });
  };

  handlerTelefono = (e) => {
    this.setState({ telefono: e.target.value });
  };

  handlerRelacion = (relacion) => {
    this.setState({ relacionSeleccionada: relacion });
  };

  handlerRechazarPostulacion = () => {
    let jsonFinal = {
      id: "" + this.state.id,
      primer_nombre: "" + this.state.primerNombre,
      segundo_nombre: "" + this.state.segundoNombre,
      apellido_paterno: "" + this.state.apellidoPaterno,
      apellido_materno: "" + this.state.apellidoMaterno,
      email: "" + this.state.email,
      telefono: "" + this.state.telefono,
      rol: "" + this.state.rol,
      fotoCarnet: "" + this.state.fotoCarnet,
      fotoTitulo: "" + this.state.fotoTitulo,
      estado: "rechazada",
      organizacion: localStorage.getItem("clinica"),
    };

    this.state.externoHandlerAprobarPostulacion(jsonFinal);
    this.setState({ visible: false });
  };

  handlerAprobarPostulacion = () => {
    let jsonFinal = {
      id: "" + this.state.id,
      primer_nombre: "" + this.state.primerNombre,
      segundo_nombre: "" + this.state.segundoNombre,
      apellido_paterno: "" + this.state.apellidoPaterno,
      apellido_materno: "" + this.state.apellidoMaterno,
      email: "" + this.state.email,
      telefono: "" + this.state.telefono,
      rol: "" + this.state.rol,
      fotoCarnet: "" + this.state.fotoCarnet,
      fotoTitulo: "" + this.state.fotoTitulo,
      estado: "aprobada",
      organizacion: localStorage.getItem("clinica"),
    };
    console.log("por dentro: " + this.state.apellido_paterno);
    this.state.externoHandlerAprobarPostulacion(jsonFinal);
    this.setState({ visible: false });
  };

  handlerSeleccionFecha = (value, event) => {
    let anio = value.getFullYear();
    let mes = value.getMonth() + 1; //en javascript parte en 0 el primer mes.
    let dia = value.getDate();

    this.setState({
      vencimientoSeleccionado: "" + anio + "-" + mes + "-" + dia + " 23:59:00",
    });
  };

  handlerAceptar = () => {
    let ahora = new Date();

    let anio = ahora.getFullYear();
    let mes = ahora.getMonth() + 1; //en javascript parte en 0 el primer mes.
    let dia = ahora.getDate();

    var segundos = ahora.getSeconds();
    var minutos = ahora.getMinutes();
    var horas = ahora.getHours();

    let creacion =
      "" +
      anio +
      "-" +
      mes +
      "-" +
      dia +
      " " +
      horas +
      ":" +
      minutos +
      ":" +
      minutos;

    let jsonValores = {
      fecha_creacion: creacion,
      descripcion: this.state.descripcionSeleccionada,
      sector: this.state.sectorSeleccionado,
      responsable_plan_accion: this.state.responsableSeleccionado,
      fecha_vencimiento: this.state.vencimientoSeleccionado,
      estado: "vigente",
      organizacion: localStorage.getItem("clinica"),
    };

    this.state.handlerAprobarPostulacion(jsonValores);
    this.setState({ visible: false });
  };

  handlerCancelar = () => {
    this.setState({ visible: false });
    this.state.handlerCerrarPanel();
  };

  handlerSiguiente = () => {
    this.setState({ pasoActual: this.state.pasoActual + 1 });
  };

  handlerAnterior = () => {
    this.setState({ pasoActual: this.state.pasoActual - 1 });
  };

  render() {
    return (
      <Modal
        open={this.state.visible}
        onClose={this.handlerCerrar}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={estiloDivModal}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box pb={2}>
              <Typography variant="h6">Revisar postulación</Typography>
            </Box>

            <Box>
              <Stepper activeStep={this.state.pasoActual}>
                <Step key={1}>
                  <StepLabel>Datos del Postulante</StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel>Carnet</StepLabel>
                </Step>
                <Step key={3}>
                  <StepLabel>Título</StepLabel>
                </Step>
                <Step key={4}>
                  <StepLabel>Resultado Evaluación</StepLabel>
                </Step>
              </Stepper>
            </Box>

            {this.state.pasoActual == 1 && (
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Box width={200}>
                    <Typography variant="body1">Rut del paciente:</Typography>
                  </Box>
                  {!(this.state.id == null) && (
                    <Rut
                      value={this.state.id}
                      handlerRut={this.handlerRutCarga}
                    ></Rut>
                  )}
                </Box>

                {this.state.primerNombre != null && (
                  <EtiquetaTextField
                    etiqueta="Primer Nombre"
                    valor={this.state.primerNombre}
                    handlerTextField={this.handlerPrimerNombre}
                  />
                )}
                {this.state.segundoNombre != null && (
                  <EtiquetaTextField
                    etiqueta="Segundo Nombre"
                    valor={this.state.segundoNombre}
                    handlerTextField={this.handlerSegundoNombre}
                  />
                )}
                {this.state.apellidoPaterno != null && (
                  <EtiquetaTextField
                    etiqueta="Apellido Paterno"
                    valor={this.state.apellidoPaterno}
                    handlerTextField={this.handlerApellidoPaterno}
                  />
                )}
                {this.state.apellidoMaterno != null && (
                  <EtiquetaTextField
                    etiqueta="Apellido Materno"
                    valor={this.state.apellidoMaterno}
                    handlerTextField={this.handlerApellidoMaterno}
                  />
                )}
                {this.state.rol != null && (
                  <EtiquetaTextField
                    etiqueta="RNPIS"
                    valor={this.state.rol}
                    handlerTextField={this.handlerApellidoMaterno}
                  />
                )}
                {this.state.email != null && (
                  <EtiquetaTextField
                    etiqueta="email"
                    valor={this.state.email}
                    handlerTextField={this.handlerApellidoMaterno}
                  />
                )}
                {this.state.telefono != null && (
                  <EtiquetaTextField
                    etiqueta="telefono"
                    valor={this.state.telefono}
                    handlerTextField={this.handlerTelefono}
                  />
                )}
              </Box>
            )}

            {this.state.pasoActual == 2 && (
              <Box>
                <img src={this.state.fotoCarnet} style={{ width: 600 }} />
              </Box>
            )}

            {this.state.pasoActual == 3 && (
              <Box>
                <img src={this.state.fotoTitulo} style={{ width: 600 }} />
              </Box>
            )}

            {this.state.pasoActual == 4 && (
              <Box display="flex" flexDirection="row" mt={3} mb={15}>
                <Box mr={1}>
                  <BotonGrande onClick={this.handlerAprobarPostulacion}>
                    Aprobar Postulación
                  </BotonGrande>
                </Box>
                <Box ml={1}>
                  <BotonGrandeRojo onClick={this.handlerRechazarPostulacion}>
                    Rechazar Postulación
                  </BotonGrandeRojo>
                </Box>
              </Box>
            )}

            <Box display="flex" flexDirection="row" pt={10}>
              <Box pr={1}>
                <Button
                  disabled={this.state.pasoActual == 1}
                  onClick={this.handlerAnterior}
                >
                  Anterior
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={this.handlerSiguiente}
                  disabled={this.state.pasoActual == 4}
                >
                  Siguiente
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
    );
  }
}

export default RevisarPostulacion;
