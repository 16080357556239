import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ServiceActualizaPaciente, ServiceConsultaPaciente } from '../../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
        minWidth: 200,
    },
    title: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
        maxWidth: 600,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function FactoresRiesgo(props) {
    const classes = useStyles();
    const [dataFactores, setDataFactores] = useState({});
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const [valueAlcohol, setValueAlcohol] = React.useState("");
    const [valueCigarros, setValueCigarros] = React.useState("");
    const [valueDrogas, setValueDrogas] = React.useState("");

    const constructor = async () => {
        if (constructorHasRun) return;
        try {

            let factores={}

            if (typeof props.paciente.factores != "undefined") {
              factores=props.paciente.factores;
            }else{
              factores.alcohol='0';
              factores.cigarros='0';
              factores.dogras='0';
            }

            setDataFactores(factores);
            setValueAlcohol(factores.alcohol);
            setValueCigarros(factores.cigarros);
            setValueDrogas(factores.dogras);

        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    useEffect(() => constructor(), []);

    const actualizarDatosDB = async (nuevosFactores) => {
        let paciente = props.paciente;
        paciente.factores=nuevosFactores;

        let response = await ServiceActualizaPaciente(paciente);
        props.setData(response, 1);
    }


    const actualizarDatos = async (tipo, valor) => {

        let newFactores = dataFactores;
        if (tipo == 'A') {
            newFactores.alcohol = valor;
            setValueAlcohol(valor);
        }
        if (tipo == 'C') {
            newFactores.cigarros = valor;
            setValueCigarros(valor);
        }
        if (tipo == 'D') {
            newFactores.dogras = valor;
            setValueDrogas(valor);
        }

        await actualizarDatosDB(newFactores);
    }

    const dataListMedicamento = [
        { id: "0", value: "-" },
        { id: "1", value: "1" },
        { id: "2", value: "2" },
        { id: "3", value: "3" },
        { id: "4", value: "4" },
        { id: "5", value: "5" },
        { id: "6", value: "6" },
        { id: "7", value: "7" },
    ]

    return (
        <Paper>
            <List component="nav" className={classes.root} aria-label="contacts">
                <ListItem button>
                    <ListItemIcon>
                        {/*<DeleteIcon />*/}
                    </ListItemIcon>
                    <ListItemText primary={'Consumo de alcohol'} secondary={'Cantidad de días a la semana'} />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cantidad"
                        value={valueAlcohol}
                        onChange={(e) => actualizarDatos('A', e.target.value)}
                        variant="outlined"
                        style={{ width: "100%", maxWidth: "100%" }}
                    >
                        {dataListMedicamento.map(elm =>
                            <MenuItem key={elm.id} value={elm.value}>
                                {elm.value}
                            </MenuItem>
                        )}</TextField>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        {/*<DeleteIcon />*/}
                    </ListItemIcon>
                    <ListItemText primary={'Consumo de cigarros'} secondary={'Cantidad de días a la semana'} />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cantidad"
                        value={valueCigarros}
                        onChange={(e) => actualizarDatos('C', e.target.value)}
                        variant="outlined"
                        style={{ width: "100%", maxWidth: "100%" }}
                    >
                        {dataListMedicamento.map(elm =>
                            <MenuItem key={elm.id} value={elm.value}>
                                {elm.value}
                            </MenuItem>
                        )}</TextField>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        {/*<DeleteIcon />*/}
                    </ListItemIcon>
                    <ListItemText primary={'Consumo de drogas'} secondary={'Cantidad de días a la semana'} />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cantidad"
                        value={valueDrogas}
                        onChange={(e) => actualizarDatos('D', e.target.value)}
                        variant="outlined"
                        style={{ width: "100%", maxWidth: "100%" }}
                    >
                        {dataListMedicamento.map(elm =>
                            <MenuItem key={elm.id} value={elm.value}>
                                {elm.value}
                            </MenuItem>
                        )}</TextField>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </Paper>
    )
}
