import React, { useEffect, useState } from 'react';
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Paper,Grid,Box,TextField} from '@material-ui/core';

class Correo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      correoPaciente: props.value,
      handlerCorreoExterno: props.handlerCorreo,
      key: props.key
    };
    console.log("el correo!");
    console.log(props.value);

    this.correoValido(props.value);
  }

   correoValido = (correo) => {
     const emailRegex = /\S+@\S+\.\S+/;
     return emailRegex.test(correo);
  }

  reformat = (rutViejo,rutNuevo) => {
		let digitos = ['0','1','2','3','4','5','6','7','8','9','K','k']
		let digitoValido = (digito) => digitos.includes(digito)
		let quitarDV = (digito,index,array) => (index<(array.length-1))?!(['K','k'].includes(digito)):true

		if(rutNuevo !==''){

			let chars = rutNuevo.split('').filter(digitoValido).filter(quitarDV)
			if(chars.length>9) return rutViejo
			let digito = chars[chars.length-1]

			if(digitoValido(digito)){
				[1,5,9].forEach(index => { if(chars.length>index){ chars.splice(chars.length-index,0,(index===1)?'-':'.') }} )
				return chars.join('').toUpperCase();
			} else {
				return rutViejo;
			}
		}
		return '';
	}

  handlerCorreoPaciente = (e) => {

    let correo=e.target.value;

    this.setState({correoPaciente:e.target.value});

    if (this.correoValido(correo)){
        this.setState({correoValido:true});
        this.setState({correoPaciente:correo});
        this.state.handlerCorreoExterno(true,correo);
    }else{
        this.setState({correoValido:false});
        this.state.handlerCorreoExterno(false,correo);
    }
  }

  render() {
    return (

       <TextField
       error={! (this.state.correoPaciente === "") && !(this.state.correoValido)}
       helperText={(! (this.state.correoPaciente === "") && !(this.state.correoValido)) ? 'Correo invalido!' : ''}
       keys={"textfield"+this.state.key} value={this.state.correoPaciente} label="hola@correo.com" onChange={this.handlerCorreoPaciente} ></TextField>

    );
  }
}

export default Correo;
