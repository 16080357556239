import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Toast } from "../../util/notifications";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Atenciones from '../web/DashboardView/Atenciones';
import Recetas from '../web/DashboardView/Recetas';
import Ordenes from '../web/DashboardView/Ordenes';

import VideocamIcon from '@material-ui/icons/Videocam';
import ChatIcon from '@material-ui/icons/Chat';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import VerificarFoto from '../web/DashboardView/VerificaFoto';
import EvaluacionRemota from '../web/DashboardView/EvaluacionRemota';
import VideoChat from '../web/DashboardView/VideoChat';
import Triage from '../web/DashboardView/Triage';
import logo_kuery from '../../assets/logo_kuery_medio.png';
import logo_kuery2 from '../../assets/kuery_portal.png';
import Telemedicina from '../web/DashboardView/Telemedicina';
import AgendamientoMobile from './agendamiento/AgendamientoMobile';
import EventIcon from '@material-ui/icons/Event';
import CargasMobile from './cargas/CargasMobile';
import Comprobante from './comprobante/Comprobante';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FichaMobile from './FichaMobile';
import DatosPersonalesMobile from './DatosPersonalesMobile';
import DatosPersonalesMobile2 from './ficha/DatosPersonalesMobile2';
import BuscadorPacientesMobile from './medico/BuscadorPacientesMobile';
import RecetaDigitalMobile from './recetaDigital/RecetaDigitalMobile';
import AgendaMedicoMobile from './agendamientoMedico/AgendaMedicoMobile';
import GestureIcon from '@material-ui/icons/Gesture';
import Firma from './firma/Firma';
import { ServiceConsultaPaciente } from '../../api/services';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#286db2",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 300,
    maxWidth: 600,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PrincipalMobile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [screen, setScreen] = useState('Ficha');
  const [patient, setPatient] = useState({});
  const [dataPaciente, setDataPaciente] = useState({});

  const [menuAbierto, setMenuAbierto] = useState(false);

  const traerPaciente = async () => {
      let response = await ServiceConsultaPaciente(localStorage.getItem('rut'));
      console.log("response", response);
      setDataPaciente(response);

      if (response.statusCode === 400) {
      //    handleOpen();
      }
      setPatient(response.body.data);
  }

  const constructor = async () => {
    if (constructorHasRun) return;
    try {
      if (typeof variable === 'undefined' ){

      }else if (props.location.pathname.includes('/pago/comprobante/')) {
        setScreen('Comprobante');
      }
      console.log(history);
      try {
          traerPaciente();
      } catch (e) {
          console.log("ERR:", e);
      }
    } catch (e) {
      console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };

  const actualizarPaciente = (newData) => {
    console.log("actualizarPaciente", newData);
    setPatient(newData);
  }
  useEffect(() => constructor(), []);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      Toast("Cerrar Sesion", "Sesion finalizada", "success");
      history.push("/signin");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
  };

  const changeScreen = (view) => {
    setScreen(view);
  }

  const abrirMenuLateral = () => {
    setMenuAbierto(true);
  };

  const cerrarMenuLateral = () => {
    setMenuAbierto(false);
  };


  document.body.style = 'background-image: none;';

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
          position="fixed"
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={abrirMenuLateral}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Kuery
            </Typography>

            <Box style={{"marginLeft":"auto"}}>
              <Button color="inherit" onClick={handleLogout}>Salir</Button>
            </Box>
          </Toolbar>
        </AppBar>

      <Drawer
        open={menuAbierto}
        anchor="left"
        className={classes.drawer}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar disableGutters={!open}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={cerrarMenuLateral}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
          </Typography>
        </Toolbar>

        <div className={classes.drawerContainer}>
          <List>
            <ListItem button onClick={() => changeScreen('BuscadorPacientes')}>
              <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
              <ListItemText primary={'Buscador Pacientes'} />
            </ListItem>

            <ListItem button onClick={()=>changeScreen('Recetas')}>
              <ListItemIcon><EnhancedEncryptionIcon /></ListItemIcon>
              <ListItemText primary={'Receta Digital'} />
            </ListItem>

            <ListItem button onClick={()=>changeScreen('AgendaMedico')}>
             <ListItemIcon><EventIcon /></ListItemIcon>
             <ListItemText primary={'Agenda'} />
           </ListItem>

           <ListItem button onClick={()=>changeScreen('Firma')}>
            <ListItemIcon><GestureIcon /></ListItemIcon>
            <ListItemText primary={'Firma'} />
          </ListItem>

          </List>
        </div>
      </Drawer>
      <main style={{ width: '100%' }} >
        <Toolbar />
        {
          screen==='Firma'?
          <Firma />:
          screen==='BuscadorPacientes'?
          <BuscadorPacientesMobile  />:
          screen==='AgendaMedico'?
          <AgendaMedicoMobile />:
          screen==='Recetas'?
          <RecetaDigitalMobile/>:
          <>.</>
          /*

          screen==='Teleconsulta'?:
          */

        }
      </main>
    </div>
  );
}
