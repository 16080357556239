import React from "react";
import {Select,Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import SubirArchivo from '../../../../web/DashboardView/comun/SubirArchivo';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

class Firma extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nuevaContrasena1:"",
      cambioEjecutado:false,
      imagenFirma:""
    };
  }

  handlerNuevaContrasena1 = (e) =>{
      this.setState({nuevaContrasena1:e.target.value});
  }

  handlerActualizarFirma = ()=>{

    let id=localStorage.getItem('professional');

    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };

    console.log(id);

    let idProfesional=localStorage.getItem('professional');

    let json={
      "id": idProfesional,
      "firma": this.state.imagenFirma
    };

    axios
      .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/actualizar-firma-doctor',json ,header)
      .then(res=>{
        this.setState({cambioEjecutado:true});
     });
  }

  handlerSubidaFirma=(url,archivo) => {
    console.log(archivo);
    this.setState({imagenFirma:archivo});
  }

  render() {

    return (
      <Paper style={{backgroundColor:'#f8f9fa', minHeight: "800px"}}>
        <Box p={3}>
          <Typography variant="h5" display="inline">
              Suba una captura fotográfica de su firma.
          </Typography>

          <Box pt={2}>
            <SubirArchivo etiquetaBoton="Visualizar Imagen" handlerArchivo={this.handlerSubidaFirma}></SubirArchivo>
          </Box>

           <Box pt={5}>
            <Button onClick={this.handlerActualizarFirma} >Actualizar Firma</Button>
          </Box>

          {this.state.cambioEjecutado&&(
            <Box pt={3}>
              <Typography variant="body" display="inline">
                  La firma ha sido actualizada.
              </Typography>
            </Box>
          )}

        </Box>
      </Paper>
    );
  }
}

export default Firma;
