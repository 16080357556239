import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Document, Page, pdfjs } from "react-pdf";
import { ServiceGetPDF } from "../../api/services";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 300,
    maxWidth: 600,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function VerificaPDF(props) {
  const classes = useStyles();
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [dataPDF, setDataPDF] = useState({});

  const constructor = async () => {
    if (constructorHasRun) return;
    try {
      traerPDF();
    } catch (e) {
      console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };
  useEffect(() => constructor(), []);

  const traerPDF = async () => {
    console.log("ID", props.match.params.id);
    let response = await ServiceGetPDF(props.match.params.id);
    console.log("response", response);
    setDataPDF(response);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ background: "#2E3B55" }}>
          <Typography variant="h6" className={classes.title}>
            Visualizador de PDF
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: "600px", overflowY: "scroll" }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid>
            <Paper>
              <Document
                file={`data:application/pdf;base64,${dataPDF.base64}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
