import React from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RevisarPostulacion from "./RevisarPostulacion";
import styled from "styled-components";
import { ServiceGetPostulaciones } from "../../../../api/services";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const columns = [
  { field: "id", headerName: "Rut", width: 150 },
  {
    field: "nombre_completo",
    headerName: "Primer Nombre",
    width: 350,
    editable: false,
  },
  {
    field: "rol",
    headerName: "RNPIS",
    width: 130,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 290,
    editable: false,
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
  },
  {
    field: "estado",
    headerName: "Estado",
    width: 150,
    editable: false,
  },
];

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

class Postulaciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filasSeleccionadas: [],
      listaElementos: [],
      mostrarPanelAgregar: false,
      mostrarPanelRevisarPostulacion: false,
      rutSeleccionado: "",
      estadosPosibles: ["aprobada", "revision", "rechazada"],
      estadosSeleccionados: [],
    };
  }

  actualizarTabla = () => {
    try {
      ServiceGetPostulaciones().then(({ Items }) => {
        const cleanPostulaciones = Items.map(
          ({
            id,
            primer_nombre,
            segundo_nombre,
            apellido_paterno,
            apellido_materno,
            rol,
            email,
            telefono,
            estado,
          }) => ({
            id,
            nombre_completo: `${primer_nombre} ${segundo_nombre} ${apellido_paterno} ${apellido_materno}`,
            rol,
            email,
            telefono,
            estado,
          })
        );
        if (this.state.estadosSeleccionados.length == 0) {
          this.setState({ listaElementos: cleanPostulaciones });
          return;
        }

        this.setState({
          listaElementos: cleanPostulaciones.filter(({ estado }) =>
            this.state.estadosSeleccionados.includes(estado)
          ),
        });
      });
    } catch (err) {
      console.log("[ERR]", err);
    }
  };

  componentDidMount() {
    this.actualizarTabla();
  }

  handlerSeleccionFilas = (e) => {
    this.setState({ filasSeleccionadas: e });
  };

  handlerAprobarPostulacion = (json) => {
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion",
        json,
        header
      )
      .then((res) => {
        this.setState({ mostrarPanelRevisarPostulacion: false });
        this.setState({ listaElementos: [] });
        this.actualizarTabla();
      });
  };

  handlerRechazarPostulacion = (json) => {
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion",
        json,
        header
      )
      .then((res) => {
        this.setState({ mostrarPanelRevisarPostulacion: false });
        this.setState({ listaElementos: [] });
        this.actualizarTabla();
      });
  };

  handlerRevisarPostulacion = (e) => {
    let ids = [];

    for (let j = 0; j < this.state.listaElementos.length; j++) {
      let encontrado = false;
      let actual = this.state.listaElementos[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        if (actual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
          ids.push(actual["id"]);
        }
      }
    }
    this.setState({
      mostrarPanelRevisarPostulacion: true,
      rutSeleccionado: ids[0],
    });
  };

  handlerCerrarAprobarPostulacion = () => {
    this.setState({ mostrarPanelRevisarPostulacion: false });
  };

  handlerChangeEstadoPosible = (event, newValue) => {
    this.setState({ estadosSeleccionados: newValue }, () => {
      this.actualizarTabla();
    });
  };

  render() {
    return (
      <Paper>
        <Box p={4}>
          <Box mb={2}>
            <Button
              disabled={!(this.state.filasSeleccionadas.length == 1)}
              onClick={this.handlerRevisarPostulacion}
            >
              Revisar Postulación
            </Button>
            <span> </span>
          </Box>

          <Box mb={2}>
            <Autocomplete
              multiple
              id="checkboxes-estadisticas"
              options={this.state.estadosPosibles}
              disableCloseOnSelect
              onChange={this.handlerChangeEstadoPosible}
              getOptionLabel={(estado) => estado}
              renderOption={(estado, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {estado}
                </React.Fragment>
              )}
              style={{ width: 500 }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Estados"
                  placeholder="Estados"
                />
              )}
            />
          </Box>

          <div style={{ height: 400, width: "100%" }}>
            {this.state.listaElementos.length > 0 ? (
              <DataGrid
                rows={this.state.listaElementos}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                checkboxSelection
                onSelectionModelChange={this.handlerSeleccionFilas}
              />
            ) : (
              <p></p>
            )}
          </div>

          {this.state.mostrarPanelRevisarPostulacion &&
            !(this.state.rutSeleccionado == "") && (
              <RevisarPostulacion
                rutSeleccionado={this.state.rutSeleccionado}
                visible={this.state.mostrarPanelRevisarPostulacion}
                key={"agregar-plan-de-accion"}
                handlerAprobarPostulacion={this.handlerAprobarPostulacion}
                handlerRechazarPostulacion={this.handlerRechazarPostulacion}
                handlerCerrarPanel={this.handlerCerrarAprobarPostulacion}
              />
            )}
        </Box>
      </Paper>
    );
  }
}

export default Postulaciones;
