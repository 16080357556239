import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';

export default function VideoChat(props){
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = async () => {
        if (constructorHasRun) return;
      try {
        console.log("props", props);
        console.log("props.medico", props.medico);
        }catch(e){
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };


    useEffect(() => constructor(), []);
    return (
        <Paper>
          <iframe  allow="camera; microphone"  src={`https://dw670f0b4u6we.cloudfront.net/zoom/${props.medico.firstName+' '+props.medico.lastName}/${props.medico.run}/${2386893551}/${0}/${'yLnX80'}`} style={{width:'78vw', height:'80vh'}}></iframe>
        </Paper>
    )
}
