import React from "react";
import {
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import DatosPaciente from "../../DashboardView/DatosPaciente";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Horarios.css";
import Rut from "../../DashboardView/comun/Rut.js";
import Correo from "../../DashboardView/comun/Correo.js";
import {
  ServiceConsultaPaciente,
  ServiceGetCurrentUser,
} from "../../../../api/services";
import { Toast } from "../../../../util/notifications";
import axios from "axios";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
});

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const ButtonGrande = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 270px;
  height: 100px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 200px;
  height: 60px;
  fontWeight: "normal",

  background: #ffffff;
`;

const BotonCancelar = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

function calcularDuracion(inicio, duracionMinutos) {
  let partesInicio = inicio.split(":");

  let hora = parseInt(partesInicio[0]);
  let minutos = parseInt(partesInicio[1]);

  duracionMinutos = parseInt(duracionMinutos);

  let minutosFinPeriodo = (minutos + duracionMinutos) % 60;
  let restoHoras = Math.floor((minutos + duracionMinutos) / 60);

  let horaFinal = hora + restoHoras;

  if (minutosFinPeriodo < 10) {
    minutosFinPeriodo = "0" + minutosFinPeriodo;
  }

  return "" + horaFinal + ":" + minutosFinPeriodo;
}

function callbackDibujoSlot(slot, index, array) {
  if (slot.horaTomada == false) {
    return <span></span>;
  }

  console.log(slot);

  return (
    <ButtonSlot
      value={index}
      key={"agendamiento-slot-" + index}
      active={this.padre.state.indiceSlotSeleccionado == index}
      onClick={() => this.padre.handlerSeleccionarSlot(index, slot)}
    >
      de {slot.inicio} a {calcularDuracion(slot.inicio, slot.duracionMinutos)}{" "}
      <br /> paciente: {slot.rutPaciente}{" "}
    </ButtonSlot>
  );
}

function callbackDibujoCargas(carga, index, array) {
  return (
    <FormControlLabel
      key={"agendamiento-cargas-" + index}
      value={carga.id}
      control={<Radio />}
      label={
        carga.primerNombre +
        " " +
        carga.segundoNombre +
        " " +
        carga.apellidoPaterno +
        " " +
        carga.apellidoMaterno
      }
    />
  );
}

class Horarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      proyectosCargados: false,
      listaProyectos: [],
      value: 1,
      rutPaciente: "",
      rutValido: false,
      pasoActual: 1,
      tipoBusqueda: "",
      listadoMedicos: [],
      medicoSeleccionado: false,
      diasDisponibilidad: [],
      slotsOcupadosSegunSeleccionDia: [],
      diaSeleccionado: "",
      mesSeleccionado: "",
      anioSeleccionado: "",
      indiceSlotSeleccionado: -1,
      slotSeleccionado: {},
      correoPaciente: "",
      correoValido: false,
      cargas: [],
      datosUsuario: {},
      nombrePaciente: "",
      correoPaciente: "",
      dateSeleccionado: {},
    };
  }

  limpiarMedicosSinslotsOCupados = (listadoMedicos) => {
    let medicosPosibles = [];

    for (let i = 0; i < listadoMedicos.length; i++) {
      let medico = listadoMedicos[i];
      let disponibilidades = medico.disponibilidad;

      let diaPosible = false;

      if (typeof disponibilidades === "undefined") {
        continue;
      }

      for (let j = 0; j < disponibilidades.length; j++) {
        let disponibilidad = disponibilidades[j];

        if (disponibilidad.horaTomada) {
          continue;
        }

        let diaString = disponibilidad.dia;

        let partes = diaString.split("-");
        let anioDisponible = parseInt(partes[0]);
        let mesDisponible = parseInt(partes[1]) - 1;
        let diaDisponible = parseInt(partes[2]);

        let fechaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);
        let ahora = new Date();

        if (fechaSlot.getTime() < ahora.getDate()) {
          continue;
        }

        diaPosible = true;
        break;
      }

      if (diaPosible) {
        medicosPosibles.push(medico);
      }
    }
    return medicosPosibles;
  };

  cargarDatos = async () => {
    const currentAccount = await ServiceGetCurrentUser();
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/professional/todos",
        header
      )
      .then((res) => {
        let todosLosMedicos = res.data.body;
        todosLosMedicos = this.limpiarMedicosSinslotsOCupados(
          todosLosMedicos.filter(
            ({ organizacion: docOrganizacion }) =>
              docOrganizacion === currentAccount.organizacion
          )
        );

        this.setState({ listadoMedicos: todosLosMedicos });
        return res;
      })
      .catch((err) => console.log(err));

    let response = await ServiceConsultaPaciente(currentAccount.rut);

    if (typeof response.body === "string") return;

    let data = response.body.data;

    this.setState({ correoPaciente: data?.["email"], correoValido: true });
    this.setState({ cargas: data["cargas"] });
    this.setState({ nombrePaciente: data["nombre"] });

    let jsonUsuario = {
      nombre: data["nombre"],
      rut: data["rut"],
      email: data["email"],
    };

    this.setState({ datosUsuario: jsonUsuario });
  };

  async componentDidMount() {
    this.cargarDatos();
  }

  handlerRutPaciente2 = (rutValido, valorRut) => {
    if (rutValido) {
      this.setState({ rutValido: true });
      //let nuevoRut=this.reformat("",e.target.value);*/
      this.setState({ rutPaciente: valorRut });
    } else {
      this.setState({ rutValido: false });
    }
  };

  handlerCorreoPaciente = (correoValido, valorCorreo) => {
    if (correoValido) {
      this.setState({ correoValido: true, correoPaciente: valorCorreo });
    } else {
      this.setState({ correoValido: false });
    }
  };

  handlerBusquedaPorNombre = () => {
    this.setState({ tipoBusqueda: "porNombre" });
  };

  handlerBusquedaPorEspecialidad = () => {
    this.setState({ tipoBusqueda: "porEspecialidad" });
  };

  handlerSeleccionarMedico = (event, newValue) => {
    console.log("el medico seleccionado!");
    console.log(newValue);
    this.setState({ medicoSeleccionado: newValue });
  };

  handlerDiasDesactivados = ({ activeStartDate, date, view }) => {
    let activarDia = false;

    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      activarDia = false;
      return !activarDia;
    }

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let s = "procesando dia de calendario " + date;

    for (var indice in disponibilidades) {
      let diaString = disponibilidades[indice].dia;

      let partes = diaString.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let diaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      //el dia en revision corresponde con el que se esta revisando, y el slot no esta tomado
      //entonces habilitar el dia en el calendario
      if (diaEncontrado && disponibilidades[indice].horaTomada == false) {
        activarDia = true;
        return !activarDia;
      }
    }

    /*  console.log("    [DIAS DESACTIVADOS2]   dia del calendario " + date);*/
    return !activarDia;
  };

  handlerDiasDesactivados2 = ({ activeStartDate, date, view }) => {
    let activarDia = false;

    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();

    if (date.getTime() < ahora.getTime()) {
      activarDia = false;
      return !activarDia;
    }

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in disponibilidades) {
      let diaString = disponibilidades[indice].dia;

      let partes = diaString.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let diaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);

      if (diaSlot.getTime() < ahora.getTime()) {
        activarDia = false;
        return !activarDia;
      }

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      //el dia en revision corresponde con el que se esta revisando, y el slot esta tomado
      //entonces habilitar el dia en el calendario
      if (diaEncontrado && disponibilidades[indice].horaTomada == true) {
        activarDia = true;
        return !activarDia;
      }
    }
    return !activarDia;
  };

  handlerClassNameCalendario = ({ activeStartDate, date, view }) => {
    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      //console.log("[CLASSNAME]slot del pasado, me lo salto: " + date);
      return null;
    }

    //console.log("");
    //console.log("##########CALCULANDO CLASSNAME");
    //console.log("[CLASSNAME] dia/mes del calendario: " + date);

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in disponibilidades) {
      let diaDisponible = disponibilidades[indice].dia;

      return "diaDisponible";

      /*
      if (disponibilidades[indice].horaTomada == true) {
        continue;
      }

      let partes = diaDisponible.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let fechaSlot = new Date(parseInt(anioDisponible), parseInt(mesDisponible) - 1, parseInt(diaDisponible));


      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        return "diaDisponible";
      }
      */
    }

    return null;
  };

  handlerClassNameCalendario2 = ({ activeStartDate, date, view }) => {
    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();

    if (date.getTime() < ahora.getTime()) {
      return null;
    }

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in disponibilidades) {
      let diaDisponible = disponibilidades[indice].dia;

      if (disponibilidades[indice].horaTomada == false) {
        continue;
      }

      let partes = diaDisponible.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let fechaSlot = new Date(
        parseInt(anioDisponible),
        parseInt(mesDisponible) - 1,
        parseInt(diaDisponible)
      );

      if (fechaSlot.getTime() < ahora.getTime()) {
        continue;
      }

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        return "diaDisponible";
      }
    }

    return null;
  };

  handlerSeleccionDia2 = (value, event) => {
    console.log("------------------------------SELECCION");
    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (value.getTime() < ahora.getTime()) {
      //console.log("[handlerSeleccionDia]slot del pasado, me lo salto: " + date);
      return null;
    }

    this.setState({ indiceSlotSeleccionado: -1 });

    let anioSeleccionado = value.getFullYear();
    let mesSeleccionado = value.getMonth() + 1;
    let diaSeleccionado = value.getDate();

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let slotsDisponibles = [];

    for (var indice in disponibilidades) {
      let horaTomada = disponibilidades[indice].horaTomada;

      if (horaTomada == true) {
        continue;
      }

      let diaDisponible = disponibilidades[indice].dia;
      let partes = diaDisponible.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioSeleccionado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesSeleccionado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaSeleccionado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        slotsDisponibles.push(disponibilidades[indice]);
      }
    }

    this.setState({ slotsDisponiblesSegunSeleccionDia: slotsDisponibles });
    this.setState({
      diaSeleccionado: diaSeleccionado,
      mesSeleccionado: mesSeleccionado,
      anioSeleccionado: anioSeleccionado,
    });
  };

  handlerSeleccionDia = (value, event) => {
    console.log("............................................");
    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (value.getTime() < ahora.getTime()) {
      console.log("[handlerSeleccionDia]slot del pasado, me lo salto: " + date);
      return null;
    }

    console.log("sigo!");

    this.setState({ indiceSlotSeleccionado: -1 });

    let anioSeleccionado = value.getFullYear();
    let mesSeleccionado = value.getMonth() + 1;
    let diaSeleccionado = value.getDate();

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let slotsOCupados = [];

    for (var indice in disponibilidades) {
      /*console.log("          aaaaaaaaaaaaaaaaaaaa");*/
      let horaTomada = disponibilidades[indice].horaTomada;

      if (horaTomada == false) {
        /*console.log("     no esta tomada")*/
        continue;
      }

      let diaDisponible = disponibilidades[indice].dia;
      let partes = diaDisponible.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioSeleccionado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesSeleccionado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaSeleccionado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        slotsOCupados.push(disponibilidades[indice]);
      }
    }

    console.log(slotsOCupados);

    this.setState({ slotsOcupadosSegunSeleccionDia: slotsOCupados });
    this.setState({
      diaSeleccionado: diaSeleccionado,
      mesSeleccionado: mesSeleccionado,
      anioSeleccionado: anioSeleccionado,
    });
    this.setState({ dateSeleccionado: value });
  };

  handlerSeleccionarSlot = (indice, slot) => {
    this.setState({ indiceSlotSeleccionado: indice, slotSeleccionado: slot });
  };

  handlerCancelarHora = (e) => {
    console.log("el slot a cancelar: ");
    console.log(this.state.slotSeleccionado);
    console.log(this.state.medicoSeleccionado);

    let jsonPeticion = {
      id_medico: this.state.medicoSeleccionado["id"],
      horaReserva: this.state.slotSeleccionado["inicio"],
      diaReserva: this.state.slotSeleccionado["dia"],
      rutPaciente: this.state.slotSeleccionado["rutPaciente"],
      nombrePaciente: this.state.nombrePaciente,
      emailPaciente: this.state.correoPaciente,
    };

    console.log("el json " + JSON.stringify(jsonPeticion));

    let slots = this.state.slotsOcupadosSegunSeleccionDia;

    console.log("=====slots");
    console.log(slots);

    let slotsRestantes = [];

    for (let i = 0; i < slots.length; i++) {
      let elSlot = slots[i];
      console.log("el slot: " + elSlot);

      if (this.state.slotSeleccionado == elSlot) {
        console.log("encontrado!!!!!!!!!!!!!!!");
      } else {
        slotsRestantes.push(elSlot);
      }
    }

    this.setState({ slotsOcupadosSegunSeleccionDia: slotsRestantes });

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/cancelar-hora",
        jsonPeticion,
        header
      )
      .then((res) => {
        console.log("hora cancelada");
      });
  };

  render() {
    const {
      error,
      proyectosCargados,
      listaProyectos,
      value,
      rutPaciente,
      pasoActual,
      tipoBusqueda,
      slotsOcupadosSegunSeleccionDia,
    } = this.state;

    return (
      <Paper>
        <Box pr={4} pb={4}>
          <Box pl={3} pb={3}>
            <Typography variant="h6">
              Seleccione el médico para revisar su horario.
            </Typography>
          </Box>

          <Box pl={3} pb={3}>
            <Autocomplete
              key={"agendamiento-medico-autocompletar"}
              options={this.state.listadoMedicos}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              onChange={this.handlerSeleccionarMedico}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione el médico" />
              )}
            />
          </Box>

          {!this.state.medicoSeleccionado == "" && (
            <Box pl={3} display="flex" flexDirection="rows">
              <Box>
                <Calendar
                  locale="ES-es"
                  onChange={this.handlerSeleccionDia}
                  tileDisabled={this.handlerDiasDesactivados}
                  tileClassName={this.handlerClassNameCalendario}
                />
              </Box>
              <Box pl={4}>
                {this.state.slotsOcupadosSegunSeleccionDia.length > 0 && (
                  <Box mt={0}>
                    <Typography variant="h6">
                      Horarios tomados para el dia: {this.state.diaSeleccionado}
                    </Typography>
                  </Box>
                )}
                <br />
                {this.state.slotsOcupadosSegunSeleccionDia.length > 0 && (
                  <Box>
                    {this.state.slotsOcupadosSegunSeleccionDia.map(
                      callbackDibujoSlot,
                      { padre: this }
                    )}
                  </Box>
                )}

                {this.state.slotsOcupadosSegunSeleccionDia.length > 0 && (
                  <Box pt={4}>
                    <BotonCancelar
                      onClick={this.handlerCancelarHora}
                      disabled={this.state.indiceSlotSeleccionado == -1}
                    >
                      Cancelar Hora Seleccionada
                    </BotonCancelar>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    );
  }
}

export default Horarios;
/*

*/
