import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MobileApp from './components/mobile/MobileApp'
import WebApp from './components/web/WebApp'
import WebAppMultiple from './components/web/WebAppMultiple'
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {isMobile} from 'react-device-detect';
import ReactGA from 'react-ga';
import '@fontsource/roboto';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactGA.initialize('UA-168013996-1');

//document.getElementById('zmmtg-root')?.style?.display = 'contents';
console.log("kuery version: 1.0.0");
console.log("mobile?");
console.log(isMobile);


ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    {
      isMobile
      ?
      <MobileApp/>
      :
      <Router>
        <WebAppMultiple/>
      </Router>
    }
  </React.StrictMode>,
  document.getElementById('root')
);


/*



isMobile
?
<MobileApp/>
:
<Router>
  <WebAppMultiple/>
</Router>

isMobile
?
<MobileApp/>
:
<Router>
  <WebAppMultiple/>
</Router>







isMobile
?
<MobileApp/>
:
<Router>
  <WebAppMultiple/>
</Router>

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    {
      isMobile
      ?
      <MobileApp/>
      :
      <WebAppMultiple/>
    }
  </React.StrictMode>,
  document.getElementById('root')
);

*/
