import axios from "axios";
import {
  ENV,
  GenerarConsultaGET,
  GenerarConsultaPDFGET,
  GenerarConsultaPOST,
  GenerarConsultaProGet,
} from "./api";

export const ServiceGuardarTriage = async (request) => {
  let response = await GenerarConsultaPOST(ENV.Triage, request);
  return response;
};

export const ServiceConsultaPaciente = async (request) => {
  let response = await GenerarConsultaGET(ENV.Paciente, request);
  return response;
};

export const ServiceActualizaPaciente = async (request) => {
  let response = await GenerarConsultaPOST(ENV.Paciente, request);
  return response;
};

export const ServiceConsultaFoto = async (request) => {
  let response = await GenerarConsultaPOST(ENV.FotoPicadura, request);
  return response;
};

export const ServiceAnalizarImagenPicadura = async (request) => {
  let response = await GenerarConsultaPOST(ENV.AnalizarFotoPicadura, request);
  return response;
};

export const ServiceProfessionalCount = async (request) => {
  let response = await GenerarConsultaPOST(ENV.ProfessionalAcount, request);
  return response;
};

export const ServiceLoginAdmin = async (request) => {
  let response = await GenerarConsultaPOST(ENV.LoginAdmin, request);
  return response;
};

export const ServiceConsultaProfesional = async (id) => {
  let response = await GenerarConsultaProGet(ENV.Professional, id);
  return response;
};

export const ServiceCrearRME = async (request) => {
  let response = await GenerarConsultaPOST(ENV.Rme, request);
  return response;
};

export const ServiceGetPDF = async (rmeId) => {
  let response = await GenerarConsultaPDFGET(ENV.PdfRME, rmeId);
  return response;
};

export const ServiceGenerarPreinforme = async (request) => {
  let response = await GenerarConsultaPOST(ENV.GenerarPreinforme, request);
  return response;
};

export const ServiceGetOrganizaciones = (current = false) => {
  return axios
    .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/entity", {
      params: {
        TableName: "organizacion",
      },
    })
    .then((res) => {
      const organizaciones = res.data.body;

      if (current) {
        return organizaciones.Items.find(
          ({ id }) => id === localStorage.getItem("clinica")
        );
      }
      return organizaciones;
    });
};

export const ServiceGetPostulaciones = () => {
  return axios
    .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/entity", {
      params: {
        TableName: "postulacion",
      },
    })
    .then((res) => {
      const postulaciones = res.data.body;

      return Promise.all([postulaciones, ServiceGetCurrentUser()]);
    })
    .then(([postulaciones, usuario]) => {
      if (usuario.administradorOrganizacion) {
        const newItems = postulaciones.Items.filter(({ organizacion: org }) => {
          return org === usuario.organizacion;
        });
        return { ...postulaciones, Items: newItems };
      }
      return postulaciones;
    });
};

export const ServiceGetCurrentUser = () => {
  return axios
    .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/entity", {
      params: {
        TableName: "account",
      },
    })
    .then((res) => {
      const accounts = res.data.body;
      return accounts.Items.find(
        ({ id }) =>
          id ===
          (localStorage.getItem("professional") || localStorage.getItem("rut"))
      );
    });
};
