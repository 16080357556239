import React from "react";
import {
  Modal,
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { formatearRut } from "../../../../lib/formats";

import { DataGrid } from "@mui/x-data-grid";
//import { DataGrid } from '@material-ui/data-grid';
import styled, { ThemeProvider } from "styled-components";

import Rut from "../comun/Rut.js";
import Correo from "../comun/Correo.js";
import SelectorFecha from "../comun/SelectorFecha.js";
import EtiquetaSelect from "../comun/EtiquetaSelect.js";
import { ServiceActualizaPaciente } from "../../../../api/services";

import EtiquetaTextField from "../comun/EtiquetaTextField.js";
import MenuItem from "@material-ui/core/MenuItem";

const estiloDivModal = {
  position: "absolute",
  top: "5%",
  left: "30%",
  right: "30%",
  bottom: "5%",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "4px",
  outline: "none",
  padding: "40px",
};

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const columns = [
  { field: "id", headerName: "id", width: 150 },
  { field: "profesional", headerName: "Médico", width: 350 },
  { field: "fecha", headerName: "Fecha", width: 150 },
];

const datos = ["Hijo", "Hija", "Esposo", "Esposa"];

const alternativas = datos.map((item) => (
  <MenuItem key={item} value={item}>
    {item}
  </MenuItem>
));

function callbackDibujarDetalleCarga(slot, index, array, padre) {
  return (
    <DetalleIncidente
      idIncidente={this.padre.state.listaIncidentes[index].id}
      fecha={this.padre.state.listaIncidentes[index].fecha}
      involucrados={this.padre.state.listaIncidentes[index].involucrados}
      duracion={this.padre.state.listaIncidentes[index].duracion}
      sector={this.padre.state.listaIncidentes[index].sector}
      accionSubestandar={
        this.padre.state.listaIncidentes[index].accionSubestandar
      }
      urlImagen={this.padre.state.listaIncidentes[index].url_imagen}
    />
  );
}

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const actualizarDatosDB = async (newdata) => {
  console.log("actualidando!!!!!!");
  let response = await ServiceActualizaPaciente(newdata);
  console.log("respuesta");
};

class RecetaHistorica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listaRecetas: [],
      filasSeleccionadas: [],
    };
  }

  componentDidMount() {
    const header = {
      headers: {
        "access-control-allow-headers": "*",
        "access-control-allow-methods": "*",
        "access-control-allow-origin": "*",
        "access-control-expose-headers": "*",
        "content-type": "application/json",
      },
    };

    let json = {
      id: formatearRut(localStorage.getItem("rut")),
    };

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/receta-historica",
        json,
        header
      )
      .then((res) => {
        let recetas = res.data.body.lista_recetas;

        if (!recetas) return;

        let recetas_con_id = [];

        for (let i = 0; i < recetas.length; i++) {
          let actual = recetas[i];
          actual["id"] = "" + (i + 1);
          recetas_con_id.push(actual);
        }

        this.setState({ listaRecetas: recetas_con_id });
      });
  }

  handlerSeleccionFilas = (e) => {
    this.setState({ filasSeleccionadas: e });
  };

  handlerAgregarCarga = (e) => {
    this.setState({ mostarPanelAgregar: true });
  };

  handlerBorrarCarga = (e) => {
    let temp = [];

    for (let j = 0; j < this.state.listaRecetas.length; j++) {
      let encontrado = false;
      let recetaActual = this.state.listaRecetas[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        console.log(this.state.filasSeleccionadas[i]);

        if (recetaActual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
        }
      }

      if (encontrado == false) {
        temp.push(recetaActual);
      }
    }

    let cargasJson = {
      cargas: temp,
    };

    this.state.datosPacienteRemoto.cargas = cargasJson.cargas;

    actualizarDatosDB(this.state.datosPacienteRemoto);

    this.setState({ listaRecetas: [] }); //TODO: esto es para que se refresque la tabla
    this.setState({ listaRecetas: temp });
  };

  getModalStyle = () => {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  handlerVerReceta = () => {
    let temp = [];

    for (let j = 0; j < this.state.listaRecetas.length; j++) {
      let encontrado = false;
      let cargaActual = this.state.listaRecetas[j];

      for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
        console.log(this.state.filasSeleccionadas[i]);

        if (cargaActual["id"] == this.state.filasSeleccionadas[i]) {
          encontrado = true;
        }
      }

      if (encontrado == encontrado) {
        temp.push(cargaActual["link"]);
      }
    }

    window.open(temp[0]);
  };

  render() {
    const { classes } = this.props;
    const { filasSeleccionadas } = this.state;

    return (
      <Paper>
        <Box p={4}>
          <Button
            disabled={this.state.filasSeleccionadas.length == 0}
            onClick={this.handlerVerReceta}
          >
            Ver Receta
          </Button>
          <span> </span>

          <br />
          <br />

          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={this.state.listaRecetas}
              columns={columns}
              pageSize={5}
              checkboxSelection
              onSelectionModelChange={this.handlerSeleccionFilas}
              rowsPerPageOptions={[5]}
            />
          </div>

          <br />
          <br />
        </Box>

        <Modal
          open={this.state.mostarPanelAgregar}
          onClose={this.handlerCerrarPanelAgregarCarga}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={estiloDivModal}>
            <Box pb={2}>
              <Typography variant="h6">Agregar carga familiar</Typography>
            </Box>

            <EtiquetaTextField
              etiqueta="Primer Nombre"
              value={this.state.primerNombre}
              handlerTextField={this.handlerPrimerNombre}
            />
            <EtiquetaTextField
              etiqueta="Segundo Nombre"
              value={this.state.segundoNombre}
              handlerTextField={this.handlerSegundoNombre}
            />
            <EtiquetaTextField
              etiqueta="Apellido Paterno"
              value={this.state.apellidoPaterno}
              handlerTextField={this.handlerApellidoPaterno}
            />
            <EtiquetaTextField
              etiqueta="Apellido Materno"
              value={this.state.apellidoMaterno}
              handlerTextField={this.handlerApellidoMaterno}
            />

            <Box display="flex" flexDirection="row" alignItems="flex-end">
              <Box width={200}>
                <Typography variant="body1">Rut del paciente:</Typography>
              </Box>
              <Rut
                value={this.state.rutCarga}
                handlerRut={this.handlerRutCarga}
              ></Rut>
            </Box>

            <EtiquetaSelect
              etiqueta="Relacion"
              alternativas={alternativas}
              valor={this.state.relacionSeleccionada}
              handlerSelect={this.handlerRelacion}
            ></EtiquetaSelect>

            <SelectorFecha
              etiqueta="Fecha nacimiento"
              handlerSeleccionFecha={this.handlerSeleccionFecha}
            >
              {" "}
            </SelectorFecha>

            <br />

            <Button
              onClick={() => {
                this.handlerAceptar();
              }}
            >
              Aceptar
            </Button>
            <span> </span>
            <Button onClick={this.handlerCancelar}>Cancelar</Button>
          </div>
        </Modal>
      </Paper>
    );
  }
}

export default RecetaHistorica;
