import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, TextField } from '@material-ui/core';
import { ServiceActualizaPaciente } from '../../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        minWidth: 200,
    },
    title: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
        maxWidth: 600,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function Alergias(props) {

    const classes = useStyles();

    const [dataAlergias, setDataAlergias] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const [valueTipo, setValueTipo] = React.useState("");
    const [valueDetalle, setValueDetalle] = React.useState("");
    const [valueDetalleOtro, setValueDetalleOtro] = React.useState("");

    const constructor = async () => {
        if (constructorHasRun) return;
        try {
            setDataAlergias(props.paciente.alergias ? props.paciente.alergias : []);
        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    useEffect(() => constructor(), []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValueTipo("");
        setValueDetalle("");
        setValueDetalleOtro("");
        setOpen(false);
    };

    const actualizarDatosDB = async (nuevasAlergias) => {

        let paciente = props.paciente;
        paciente.alergias=nuevasAlergias;

        let response = await ServiceActualizaPaciente(paciente);
        props.setData(response, 1);
    }

    const actualizarDatos = async () => {

        if (valueTipo && valueDetalle) {

            let newElm = {
                tipo: valueTipo,
                descripcion: valueDetalle != 'Otro' ? valueDetalle : valueDetalleOtro
            }

            setDataAlergias([...dataAlergias, newElm])

            let nuevasAlergias = [...dataAlergias, newElm];
            await actualizarDatosDB(nuevasAlergias);
        }
        handleClose();
    }

    const dataListMedicamento = [
        { tipo: "Medicamento", value: "Penicilina" },
        { tipo: "Medicamento", value: "Cefalosporinas" },
        { tipo: "Medicamento", value: "Fenitoína" },
        { tipo: "Medicamento", value: "Carbamazepina" },
        { tipo: "Medicamento", value: "Otro" },
    ]
    const dataListAlimento = [
        { tipo: "Alimento", value: "Mariscos" },
        { tipo: "Alimento", value: "Pescados" },
        { tipo: "Alimento", value: "Nueces" },
        { tipo: "Alimento", value: "Mani" },
        { tipo: "Alimento", value: "Huevo" },
        { tipo: "Alimento", value: "Leche" },
        { tipo: "Alimento", value: "Otro" },
    ]
    const dataListAmbiental = [
        { tipo: "Ambiental", value: "Polen" },
        { tipo: "Ambiental", value: "Pasto" },
        { tipo: "Ambiental", value: "Hongos" },
        { tipo: "Ambiental", value: "Acaros" },
        { tipo: "Ambiental", value: "Otro" },
    ]

    const dataListPicaduras = [
        { tipo: "Picaduras", value: "Abejas" },
        { tipo: "Picaduras", value: "Avispas" },
        { tipo: "Picaduras", value: "Pulgas" },
        { tipo: "Picaduras", value: "Otro" },
    ]
    const dataListSustancias = [
        { tipo: "Sustancias", value: "Productos Quimicos" },
        { tipo: "Sustancias", value: "Metales" },
        { tipo: "Sustancias", value: "Latex" },
        { tipo: "Sustancias", value: "Otro" },
    ]

    const deleteItem = async (elm) => {
        let newdataAlergias = dataAlergias.filter(function (item) {
            return item.descripcion !== elm.descripcion && item.tipo !== elm.tipo
        });
        setDataAlergias(newdataAlergias);

        let nuevasAlergias = [...newdataAlergias];
        await actualizarDatosDB(nuevasAlergias);
    }

    return (
        <Paper>
            <List component="nav" className={classes.root} aria-label="contacts">
                {
                    dataAlergias.map((elm, index) =>
                        <>
                            <ListItem button key={index}>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary={elm.descripcion} secondary={<p ref={element => {
                                    if (element) element.style.setProperty('font-size', '1rem', 'important');
                                }}>{elm.tipo}</p>} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                }

            </List>
            <br />
            {
                dataAlergias.length > 5 ? <></> : <Button variant="contained" color="primary" fullWidth onClick={handleOpen}>AGREGAR</Button>
            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Agregar alergia</h2>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo"
                            value={valueTipo}
                            onChange={(e) => setValueTipo(e.target.value)}
                            variant="outlined"
                            style={{ width: "100%", maxWidth: "100%" }}
                        >
                            <MenuItem value={'Medicamento'}>
                                Medicamento
                            </MenuItem>
                            <MenuItem value={'Alimento'}>
                                Alimento
                            </MenuItem>
                            <MenuItem value={'Ambiental'}>
                                Ambiental
                            </MenuItem>
                            <MenuItem value={'Picadura'}>
                                Picadura
                            </MenuItem>
                            <MenuItem value={'Sustancias'}>
                                Sustancias
                            </MenuItem>
                        </TextField>
                        <br /><br />

                        {
                            valueTipo == "Medicamento" ?
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Detalle"
                                    value={valueDetalle}
                                    onChange={(e) => setValueDetalle(e.target.value)}
                                    variant="outlined"
                                    style={{ width: "100%", maxWidth: "100%" }}
                                >
                                    {dataListMedicamento.map(elm =>
                                        <MenuItem key={elm.id} value={elm.value}>
                                            {elm.value}
                                        </MenuItem>
                                    )}</TextField> :

                                valueTipo == "Alimento" ?
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Detalle"
                                        value={valueDetalle}
                                        onChange={(e) => setValueDetalle(e.target.value)}
                                        variant="outlined"
                                        style={{ width: "100%", maxWidth: "100%" }}
                                    >
                                        {dataListAlimento.map(elm =>
                                            <MenuItem key={elm.id} value={elm.value}>
                                                {elm.value}
                                            </MenuItem>
                                        )}</TextField> :
                                    valueTipo == "Ambiental" ?
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Detalle"
                                            value={valueDetalle}
                                            onChange={(e) => setValueDetalle(e.target.value)}
                                            variant="outlined"
                                            style={{ width: "100%", maxWidth: "100%" }}
                                        >
                                            {dataListAmbiental.map(elm =>
                                                <MenuItem key={elm.id} value={elm.value}>
                                                    {elm.value}
                                                </MenuItem>
                                            )}</TextField> :
                                        valueTipo == "Picadura" ?
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Detalle"
                                                value={valueDetalle}
                                                onChange={(e) => setValueDetalle(e.target.value)}
                                                variant="outlined"
                                                style={{ width: "100%", maxWidth: "100%" }}
                                            >
                                                {dataListPicaduras.map(elm =>
                                                    <MenuItem key={elm.id} value={elm.value}>
                                                        {elm.value}
                                                    </MenuItem>
                                                )}</TextField> :
                                            valueTipo == "Sustancias" ?
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label="Detalle"
                                                    value={valueDetalle}
                                                    onChange={(e) => setValueDetalle(e.target.value)}
                                                    variant="outlined"
                                                    style={{ width: "100%", maxWidth: "100%" }}
                                                >
                                                    {dataListSustancias.map(elm =>
                                                        <MenuItem key={elm.id} value={elm.value}>
                                                            {elm.value}
                                                        </MenuItem>
                                                    )}</TextField> : <></>
                        }
                        <br /><br />
                        {
                            valueDetalle == "Otro" ?
                                <TextField style={{ width: "100%", maxWidth: "100%" }} id="outlined-basic" label="Escribir otro" variant="outlined" value={valueDetalleOtro} onChange={(e) => setValueDetalleOtro(e.target.value)} />
                                :
                                <></>
                        }
                        <br />
                        <div style={{ marginTop: "12px" }}>
                            <Button variant="contained" color="primary" fullWidth disabled={!valueDetalle != "" && valueTipo != ""} onClick={actualizarDatos}>Agregar</Button>
                            <Button style={{ marginTop: "8px" }} variant="contained" fullWidth onClick={handleClose}>Cancelar</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Paper>
    )
}
