import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper,Box,CircularProgress} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ServiceConsultaFoto,ServiceAnalizarImagenPicadura, ServiceGenerarPreinforme } from '../../../api/services';
import axios from 'axios';
import FileUploader from './FileUploader';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    table: {
      minWidth: 300,
      maxWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function VerificarFoto(props){
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [respuesta, setRespuesta] = useState("");
  const [estadoCarga,setEstadoCarga] = useState(0);

  const [file, setFile] = useState(false);

  const [url_file, setUrlFile] = useState("");

  const [imagen64, setImagen64] = useState("");

  const constructor = async () => {
      if (constructorHasRun) return;
      try{
        //traerRecetas();
      }catch(e){
          console.log("ERR:", e);
      }
      setConstructorHasRun(true);
  };

    useEffect(() => constructor(), []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const API_URL_FOTO = "http://100.24.130.30:5000/file-upload";

    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };

    const enviarFoto = async (bytesImagen) =>{

       setEstadoCarga(1);
       setRespuesta("");

       var promesa1 = Promise.resolve(1);

       promesa1.then(async res=>{

            let parametro_imagen={
	             "imagen":bytesImagen
            }

            console.log("#############33");
            console.log(imagen64);

            /*let responde = await ServiceAnalizarImagenPicadura(parametro_imagen);
            */

            axios
              .post("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/foto-picadura",parametro_imagen, header)
              .then((res) =>{
                    setRespuesta(res.resultado)
                    console.log("el resultado");
                    let json_resultado=res.data.resultado
                    const obj = JSON.parse(json_resultado);

                    setRespuesta(obj["tipo_picadura"])

                    setEstadoCarga(2);
                  }
                )
               .catch((err) => console.log(err));

        }).catch(e=>{
            console.log("ERROR [enviarFoto]:", e);
            setEstadoCarga(2);
        })
    }

     const handlerArchivo = archivo => {

        setEstadoCarga(1);
        console.log("En handler!");
        console.log(archivo);

        setFile(archivo)
        setUrlFile(URL.createObjectURL(archivo))

        var reader = new FileReader();
        reader.readAsDataURL(archivo);

        reader.onload = function () {
	          setImagen64(reader.result);

            console.log(reader.result);
            enviarFoto(reader.result);
        };
         reader.onerror = function (error) {
	          console.log('Error: ', error);
        };
    }

    return (
        <Paper>
          <Box p={4}>

            <h1> INSTRUCCIONES </h1>
            <br/>
            <p> Suba una fotografía de la zona de la piel que desea analizar.</p>
            <p> La fotografía debe estar bien enfocada y con una iluminación adecuada.</p>

            <div>
              <br/>
              <FileUploader handleFile={handlerArchivo}></FileUploader>
            </div>

            <br/>

            {estadoCarga>=1 && (

                <Box >
                    <LoadingOverlay
                      active={estadoCarga==1}
                      spinner
                      text='Procesando imagen...'
                      >
                      <img src={url_file}/>
                    </LoadingOverlay>
                    <br/>

                    {estadoCarga>=2 &&(
                      <p>La lesión identificada corresponde a: {respuesta.toUpperCase()}</p>
                    )}
                </Box>

            )}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              BackdropComponent={Backdrop}
            >
            <Fade in={open}>

            </Fade>
            </Modal>
          </Box>
        </Paper>
    )
}
