import React from "react";
import {
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import DatosPaciente from "./DatosPaciente";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./AgendamientoMobile.css";
import Rut from "../../web/DashboardView/comun/Rut.js";
import Correo from "../../web/DashboardView/comun/Correo.js";
import { ServiceConsultaPaciente } from "../../../api/services";
import { Toast } from "../../../util/notifications";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
});

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const ButtonGrande = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 270px;
  height: 100px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 200px;
  height: 60px;
  fontWeight: "normal",

  background: #ffffff;
`;

function calcularDuracion(inicio, duracionMinutos) {
  let partesInicio = inicio.split(":");

  let hora = parseInt(partesInicio[0]);
  let minutos = parseInt(partesInicio[1]);

  duracionMinutos = parseInt(duracionMinutos);

  let minutosFinPeriodo = (minutos + duracionMinutos) % 60;
  let restoHoras = Math.floor((minutos + duracionMinutos) / 60);

  let horaFinal = hora + restoHoras;

  if (minutosFinPeriodo < 10) {
    minutosFinPeriodo = "0" + minutosFinPeriodo;
  }

  return "" + horaFinal + ":" + minutosFinPeriodo;
}

function callbackDibujoSlot(slot, index, array) {
  if (slot.horaTomada == true) {
    return <span></span>;
  }

  return (
    <ButtonSlot
      value={index}
      key={"agendamiento-slot-" + index}
      active={this.padre.state.indiceSlotSeleccionado == index}
      onClick={() => this.padre.handlerSeleccionarSlot(index)}
    >
      de {slot.inicio} a {calcularDuracion(slot.inicio, slot.duracionMinutos)}{" "}
    </ButtonSlot>
  );
}

function callbackDibujoCargas(carga, index, array) {
  return (
    <FormControlLabel
      key={"agendamiento-cargas-" + index}
      value={carga.id}
      control={<Radio />}
      label={
        carga.primerNombre +
        " " +
        carga.segundoNombre +
        " " +
        carga.apellidoPaterno +
        " " +
        carga.apellidoMaterno
      }
    />
  );
}

class Agendamiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      proyectosCargados: false,
      listaProyectos: [],
      value: 1,
      rutPaciente: "",
      rutValido: false,
      pasoActual: 0,
      tipoBusqueda: "porNombre",
      listadoMedicos: [],
      medicoSeleccionado: false,
      diasDisponibilidad: [],
      slotsDisponiblesSegunSeleccionDia: [],
      diaSeleccionado: "",
      mesSeleccionado: "",
      anioSeleccionado: "",
      indiceSlotSeleccionado: -1,
      correoPaciente: "",
      correoValido: false,
      cargas: [],
      datosUsuario: {},
    };
  }

  limpiarMedicosSinSlotsDisponibles = (listadoMedicos) => {
    let medicosPosibles = [];

    for (let i = 0; i < listadoMedicos.length; i++) {
      let medico = listadoMedicos[i];
      let disponibilidades = medico.disponibilidad;

      let diaPosible = false;

      if (typeof disponibilidades === "undefined") {
        continue;
      }

      for (let j = 0; j < disponibilidades.length; j++) {
        let disponibilidad = disponibilidades[j];

        if (disponibilidad.horaTomada) {
          continue;
        }

        let diaString = disponibilidad.dia;

        let partes = diaString.split("-");
        let anioDisponible = parseInt(partes[0]);
        let mesDisponible = parseInt(partes[1]) - 1;
        let diaDisponible = parseInt(partes[2]);

        let fechaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);
        let ahora = new Date();

        if (fechaSlot.getTime() < ahora.getDate()) {
          continue;
        }

        diaPosible = true;
        break;
      }

      if (diaPosible) {
        medicosPosibles.push(medico);
      }
    }
    return medicosPosibles;
  };

  async componentDidMount() {
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/professional/todos",
        header
      )
      .then((res) => {
        let todosLosMedicos = res.data.body;
        todosLosMedicos =
          this.limpiarMedicosSinSlotsDisponibles(todosLosMedicos);

        this.setState({ listadoMedicos: todosLosMedicos });
        return res;
      })
      .catch((err) => console.log(err));

    let response = await ServiceConsultaPaciente(localStorage.getItem("rut"));

    let data = response.body.data;
    try {
      this.setState({ correoPaciente: data["email"], correoValido: true });
      this.setState({ cargas: data["cargas"] ? data["cargas"] : [] });
    } catch (e) {
      console.log(e);
    }

    let jsonUsuario = {
      nombre: data["nombre"],
      rut: data["rut"],
      email: data["email"],
    };

    this.setState({ datosUsuario: jsonUsuario });
  }

  handlerRutPaciente2 = (rutValido, valorRut) => {
    if (rutValido) {
      this.setState({ rutValido: true });
      //let nuevoRut=this.reformat("",e.target.value);*/
      this.setState({ rutPaciente: valorRut });
    } else {
      this.setState({ rutValido: false });
    }
  };

  handlerCorreoPaciente = (correoValido, valorCorreo) => {
    if (correoValido) {
      this.setState({ correoValido: true, correoPaciente: valorCorreo });
    } else {
      this.setState({ correoValido: false });
    }
  };

  handlerSiguientePaso0 = () => {
    this.setState({ pasoActual: 1 });
  };

  handlerSiguientePaso1 = () => {
    this.setState({ pasoActual: 2 });
  };

  handlerAnteriorPaso1 = () => {
    this.setState({ pasoActual: 0 });
  };

  handlerAnteriorPaso2 = () => {
    this.setState({ pasoActual: 1 });
  };

  handlerSiguientePaso2 = () => {
    this.setState({ pasoActual: 3 });
  };

  handlerAnteriorPaso3 = () => {
    this.setState({ pasoActual: 2 });
  };

  handlerBusquedaPorNombre = () => {
    this.setState({ tipoBusqueda: "porNombre" });
  };

  handlerBusquedaPorEspecialidad = () => {
    this.setState({ tipoBusqueda: "porEspecialidad" });
  };

  handlerSeleccionarMedico = (event, newValue) => {
    console.log("el medico seleccionado!");
    console.log(newValue);
    this.setState({ medicoSeleccionado: newValue });
  };

  handlerDiasDesactivados = ({ activeStartDate, date, view }) => {
    let activarDia = false;

    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      activarDia = false;
      return !activarDia;
    }

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let s = "procesando dia de calendario " + date;

    for (var indice in disponibilidades) {
      let diaString = disponibilidades[indice].dia;

      let partes = diaString.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let diaSlot = new Date(anioDisponible, mesDisponible, diaDisponible);

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      //el dia en revision corresponde con el que se esta revisando, y el slot no esta tomado
      //entonces habilitar el dia en el calendario
      if (diaEncontrado && disponibilidades[indice].horaTomada == false) {
        activarDia = true;
        return !activarDia;
      }
    }

    console.log("    [DIAS DESACTIVADOS1]   dia del calendario " + date);
    return !activarDia;
  };

  handlerClassNameCalendario = ({ activeStartDate, date, view }) => {
    let anioRevisado = date.getFullYear();
    let mesRevisado = date.getMonth() + 1;
    let diaRevisado = date.getDate();

    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (date.getTime() < ahora.getTime()) {
      //console.log("[CLASSNAME]slot del pasado, me lo salto: " + date);
      return null;
    }

    //console.log("");
    //console.log("##########CALCULANDO CLASSNAME");
    //console.log("[CLASSNAME] dia/mes del calendario: " + date);

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    for (var indice in disponibilidades) {
      let diaDisponible = disponibilidades[indice].dia;

      if (disponibilidades[indice].horaTomada == true) {
        //console.log("   [CLASSNAME]slot hora tomada, me la salto: ");
        continue;
      }

      let partes = diaDisponible.split("-");
      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let fechaSlot = new Date(
        parseInt(anioDisponible),
        parseInt(mesDisponible) - 1,
        parseInt(diaDisponible)
      );

      /*    if (fechaSlot.getTime() < ahora.getTime()) {
        //console.log("    [CLASSNAME]slot del pasado , me lo salto: " + fechaSlot);
        continue;
      }
*/
      //console.log("[CLASSNAME]     conitnuo!, el slot es: dia/mes disponible " + diaDisponible + "/" + mesDisponible);

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioRevisado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesRevisado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaRevisado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        //console.log("[CLASSNAME]diponible!!!!!!!!!!!!!!!!1");
        return "diaDisponible";
      }
    }

    return null;
  };

  handlerSeleccionDia = (value, event) => {
    let ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    if (value.getTime() < ahora.getTime()) {
      //console.log("[handlerSeleccionDia]slot del pasado, me lo salto: " + date);
      return null;
    }

    this.setState({ indiceSlotSeleccionado: -1 });

    let anioSeleccionado = value.getFullYear();
    let mesSeleccionado = value.getMonth() + 1;
    let diaSeleccionado = value.getDate();

    let disponibilidades = this.state.medicoSeleccionado.disponibilidad;

    let encontrado = false;

    let anioDisponible = -1;
    let mesDisponible = -1;
    let diaDisponible = -1;

    let slotsDisponibles = [];

    for (var indice in disponibilidades) {
      let horaTomada = disponibilidades[indice].horaTomada;

      if (horaTomada == true) {
        continue;
      }

      let diaDisponible = disponibilidades[indice].dia;
      let partes = diaDisponible.split("-");

      anioDisponible = partes[0];
      mesDisponible = partes[1];
      diaDisponible = partes[2];

      let aniosIguales = parseInt(anioDisponible) == parseInt(anioSeleccionado);
      let mesesIguales = parseInt(mesDisponible) == parseInt(mesSeleccionado);
      let diasIguales = parseInt(diaDisponible) == parseInt(diaSeleccionado);

      let diaEncontrado = aniosIguales && mesesIguales && diasIguales;

      if (diaEncontrado) {
        slotsDisponibles.push(disponibilidades[indice]);
      }
    }

    this.setState({ slotsDisponiblesSegunSeleccionDia: slotsDisponibles });
    this.setState({
      diaSeleccionado: diaSeleccionado,
      mesSeleccionado: mesSeleccionado,
      anioSeleccionado: anioSeleccionado,
    });
  };

  handlerSeleccionarSlot = (indice) => {
    this.setState({ indiceSlotSeleccionado: indice });
  };

  handlerPagar = (e) => {
    axios
      .post(
        "https://m8508ahjy0.execute-api.us-east-1.amazonaws.com/dev/payCMV",
        {
          id_medico: "" + this.state.medicoSeleccionado.id,
          nombreMedico:
            "" +
            this.state.medicoSeleccionado.firstName +
            " " +
            this.state.medicoSeleccionado.lastName,
          diaReserva:
            "" +
            this.state.anioSeleccionado +
            "-" +
            this.state.mesSeleccionado +
            "-" +
            this.state.diaSeleccionado,
          horaReserva:
            "" +
            this.state.slotsDisponiblesSegunSeleccionDia[
              this.state.indiceSlotSeleccionado
            ].inicio,
          email_paciente: "" + this.state.correoPaciente,
          rutPaciente: "" + localStorage.getItem("rut"),
          id: uuidv4(),
        },
        header
      )
      .then((res) => {
        console.log("TRANSBANK RES:", res);
        const url = res.data.urlWebPay;
        window.location.replace(url);
      })
      .catch((err) => {
        console.log("TRANSBANK ERR:", err);
      });
  };

  render() {
    const {
      error,
      proyectosCargados,
      listaProyectos,
      value,
      rutPaciente,
      pasoActual,
      tipoBusqueda,
      slotsDisponiblesSegunSeleccionDia,
    } = this.state;
    //console.log('render');
    let component = null;
    switch (pasoActual) {
      case 1:
        component = (
          <Container maxWidth="lg">
            <Box mt={4}>
              <Typography variant="h5">Paso 2 de 5</Typography>
            </Box>

            <Box mt={4}>
              <Typography variant="h6">Selecciona un profesional</Typography>
            </Box>

            {tipoBusqueda == "porNombre" && (
              <Box>
                <br />
                <br />

                <Autocomplete
                  key={"agendamiento-medico-autocompletar"}
                  options={this.state.listadoMedicos}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName
                  }
                  onChange={this.handlerSeleccionarMedico}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escriba el nombre del médico"
                    />
                  )}
                />
              </Box>
            )}

            <br />
            <br />
            <br />

            <Button
              key={"agendamiento-medico-atras"}
              onClick={this.handlerAnteriorPaso1}
            >
              Anterior
            </Button>

            <span> </span>

            <Button
              key={"agendamiento-medico-siguiente"}
              disabled={!this.state.medicoSeleccionado}
              onClick={this.handlerSiguientePaso1}
            >
              Siguiente
            </Button>
          </Container>
        );
        break;
      case 2:
        component = (
          <Container maxWidth="lg">
            <Box mt={4}>
              <Typography variant="h5">Paso 3 de 5</Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box>
                <Box mt={4}>
                  <Typography variant="h6">
                    Seleccione el día para buscar una hora.
                  </Typography>
                </Box>
                <Box mt={2} mt={3}>
                  <Typography variant="body1">
                    Los dias con horas disponibles se muestran en verde.
                  </Typography>
                </Box>

                <br />

                <Calendar
                  locale="ES-es"
                  tileDisabled={this.handlerDiasDesactivados}
                  tileClassName={this.handlerClassNameCalendario}
                  onChange={this.handlerSeleccionDia}
                />
              </Box>
              <Box pl={4}>
                {this.state.slotsDisponiblesSegunSeleccionDia.length > 0 && (
                  <Box mt={4}>
                    <Typography variant="h6">
                      Horarios disponibles para el dia:{" "}
                      {this.state.diaSeleccionado}
                    </Typography>
                  </Box>
                )}
                <br />
                {this.state.slotsDisponiblesSegunSeleccionDia.length > 0 && (
                  <Box>
                    {this.state.slotsDisponiblesSegunSeleccionDia.map(
                      callbackDibujoSlot,
                      { padre: this }
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <br />
            <br />
            <br />

            <Button onClick={this.handlerAnteriorPaso2}>Anterior</Button>

            <span> </span>

            <Button
              disabled={this.state.indiceSlotSeleccionado == -1}
              onClick={this.handlerSiguientePaso2}
            >
              Siguiente
            </Button>
          </Container>
        );
        break;
      case 3:
        component = (
          <Container maxWidth="lg">
            <Box mt={4}>
              <Typography variant="h5">Paso 4 de 5</Typography>
            </Box>

            <Box mt={4}>
              <Typography variant="h6">Confirmación</Typography>
            </Box>

            <Box pb={3}>
              <Box mt={4}>
                <Typography variant="body1">
                  Se va a realizar una reserva de hora con el médico{" "}
                  {this.state.medicoSeleccionado.firstName}{" "}
                  {this.state.medicoSeleccionado.lastName}
                </Typography>
              </Box>
              <Box mt={2} mt={3}>
                <Typography variant="body1">
                  Para el día{" "}
                  <b>
                    {this.state.diaSeleccionado}-{this.state.mesSeleccionado}-
                    {this.state.anioSeleccionado}
                  </b>{" "}
                  a las{" "}
                  {
                    this.state.slotsDisponiblesSegunSeleccionDia[
                      this.state.indiceSlotSeleccionado
                    ].inicio
                  }{" "}
                  horas
                </Typography>
              </Box>
            </Box>

            <Button onClick={this.handlerAnteriorPaso3}>Anterior</Button>

            <span> </span>

            <Button onClick={this.handlerPagar}>Pagar</Button>
          </Container>
        );
        break;
      case 4:
        component = (
          <Container maxWidth="lg">
            <Box mt={4}>
              <Typography variant="h6">Confirmación</Typography>
            </Box>

            <Box pb={3}>
              <Box mt={4}>
                <Typography variant="body1">
                  Su reserva con el médico{" "}
                  {this.state.medicoSeleccionado.firstName}{" "}
                  {this.state.medicoSeleccionado.lastName}
                </Typography>
              </Box>
              <Box mt={2} mt={3}>
                <Typography variant="body1">
                  Para el día {this.state.diaSeleccionado}-
                  {this.state.mesSeleccionado}-{this.state.anioSeleccionado} a
                  las{" "}
                  {
                    this.state.slotsDisponiblesSegunSeleccionDia[
                      this.state.indiceSlotSeleccionado
                    ].inicio
                  }{" "}
                  hora ha sido reservada.
                </Typography>
              </Box>
            </Box>
          </Container>
        );
        break;
      default:
        component = (
          <Container maxWidth="lg">
            <Box mt={4}>
              <Typography variant="h5">Paso 1 de 5</Typography>
            </Box>

            <Box mt={4}>
              <Typography variant="h6">
                Ingresa los datos del paciente que quiere reservar una hora
                médica.
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" mt={3} pb={6}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box width={200}>
                  <Typography variant="body1">Paciente: </Typography>
                </Box>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="paciente" name="paciente">
                    <FormControlLabel
                      key={1000}
                      value={this.state.datosUsuario?.rut}
                      control={<Radio />}
                      label={this.state.datosUsuario?.nombre}
                    />
                    {this.state.cargas.map(callbackDibujoCargas, {
                      padre: this,
                    })}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-end">
                <Box width={200}>
                  <Typography variant="body1">
                    Correo del notificación:{" "}
                  </Typography>
                </Box>
                <Correo
                  key="correo-agendamiento"
                  value={this.state.correoPaciente}
                  handlerCorreo={this.handlerCorreoPaciente}
                />
              </Box>
            </Box>

            <Button onClick={this.handlerSiguientePaso0}>Siguiente</Button>
          </Container>
        );
    }

    return (
      <Paper>
        <Box pl={1} pr={1} pt={0} pb={4}>
          {component}
        </Box>
      </Paper>
    );
  }
}

export default Agendamiento;

/*

<Box mt={6} display="flex" flexDirection="row" alignItems="flex-start">
  <ButtonGrande key={"agendamiento-medico-nombre"} onClick={this.handlerBusquedaPorNombre} active={this.state.tipoBusqueda == "porNombre"} >
    BUSCAR PROFESIONAL POR NOMBRE
  </ButtonGrande>
  <ButtonGrande disabled={true} key={"agendamiento-medico-especialidad"} onClick={this.handlerBusquedaPorEspecialidad} active={this.state.tipoBusqueda == "porEspecialidad"} >
    BUSCAR PROFESIONAL POR ESPECIALIDAD
  </ButtonGrande>
</Box>
<Stepper activeStep={pasoActual} orientation="vertical">
  <Step key={1}>
    <StepLabel >Datos del Paciente</StepLabel>
  </Step>
  <Step key={2}>
    <StepLabel >Médico</StepLabel>
  </Step>
  <Step key={3}>
    <StepLabel >Horas Disponibles</StepLabel>
  </Step>
  <Step key={4}>
    <StepLabel >Pago</StepLabel>
  </Step>
  <Step key={5}>
    <StepLabel >Finalizar</StepLabel>
  </Step>
</Stepper>
*/
