import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Ficha from '../web/DashboardView/ficha/Ficha';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Alergias from '../web/DashboardView/ficha/Alergias';
import EnfermedadBase from '../web/DashboardView/ficha/EnfermedadBase';
import FactoresRiesgo from '../web/DashboardView/ficha/FactoresRiesgo';
import Medicamentos from '../web/DashboardView/ficha/Medicamentos';
import { ServiceConsultaPaciente } from '../../api/services';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function MainFicha(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [dataPaciente, setDataPaciente] = useState({});
    const [paciente, setPaciente] = useState({});

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = async () => {
        if (constructorHasRun) return;
        try {
            traerPaciente();
        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    const traerPaciente = async () => {
        let response = await ServiceConsultaPaciente(localStorage.getItem('rut'));
        console.log("response", response);
        setDataPaciente(response);

        if (response.statusCode === 400) {
        //    handleOpen();
        }
        setPaciente(response.body.data);
        /*props.actualizarPaciente(response.body.data);*/
    }

    useEffect(() => constructor(), []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange2 = (event, newValue) => {
        setValue(newValue+3);
    };

    const updateData = (data, tipo) => {
        if (tipo == 1) {
            traerPaciente();
        } else {
            setPaciente(data);
        }
    }

    return (
        <>
            <div className={classes.root}>
            <AppBar position="static" p={0} m={0}>
              <Tabs buttonStyle={{ width: '30px' }} value={value} p={0} m={0} onChange={handleChange} aria-label="">
               <Tab label="Ficha" {...a11yProps(0)} />
               <Tab label="Alergias" {...a11yProps(1)} />
               <Tab label="Factores" {...a11yProps(2)} />
             </Tabs>
           </AppBar>
           <AppBar position="static" p={0} m={0}>
             <Tabs buttonStyle={{ width: '30px' }} value={value} p={0} m={0} onChange={handleChange2} aria-label="">
              <Tab label="Medicamentos" {...a11yProps(3)}  />
              <Tab label="Enfermedades" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
           <TabPanel value={value} index={0}>
               <Ficha paciente={paciente} setData={updateData} />
           </TabPanel>
           <TabPanel value={value} index={1}>
               <Alergias paciente={paciente} setData={updateData} />
           </TabPanel>
           <TabPanel value={value} index={2}>
               <FactoresRiesgo paciente={paciente} setData={updateData} />
           </TabPanel>
           <TabPanel value={value} index={3}>
              <Medicamentos paciente={paciente} setData={updateData} />
           </TabPanel>

           <TabPanel value={value} index={4}>
             <EnfermedadBase paciente={paciente} setData={updateData} />
           </TabPanel>
            </div>
        </>

    );
}
