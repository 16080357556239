import React from "react";
import {Select,Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import EtiquetaPassword from './DashboardView/comun/EtiquetaPassword';
import EtiquetaSelect from './DashboardView/comun/EtiquetaSelect';
import SelectorFecha from './DashboardView/comun/SelectorFecha';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

class CambiarContrasena extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nuevaContrasena1:"",
      nuevaContrasena2:"",
      cambioEjecutado:false
    };
  }

  handlerNuevaContrasena1 = (e) =>{
      this.setState({nuevaContrasena1:e.target.value});
  }

  handlerNuevaContrasena2 = (e) =>{
      this.setState({nuevaContrasena2:e.target.value});
  }

  handlerCambiarContrasena = ()=>{

    let username=localStorage.getItem('rut');

    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };

     let json={
      "nuevoPassword": this.state.nuevaContrasena1,
      "nombreUsuario": username
    }

    axios
      .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/fijar-password',json ,header)
      .then(res=>{
        this.setState({cambioEjecutado:true});
     });
  }

  render() {

    return (
      <Paper style={{backgroundColor:'#f8f9fa', minHeight: "800px"}}>
        <Box p={6}>
          <Typography variant="h5" display="inline">
              Ingrese una clave de al menos 8 carácteres.
          </Typography>

          <EtiquetaPassword etiqueta="Nueva Contraseña"  value={this.state.nuevaContrasena1} handlerTextField={this.handlerNuevaContrasena1}  />
          <EtiquetaPassword etiqueta="Repita Contraseña"  value={this.state.nuevaContrasena2} handlerTextField={this.handlerNuevaContrasena2}  />
          <Box pt={3}>
            <Button onClick={this.handlerCambiarContrasena} disabled={this.state.nuevaContrasena1!=this.state.nuevaContrasena2 || this.state.nuevaContrasena1.length<8}>Cambiar contraseña</Button>
          </Box>

          {this.state.cambioEjecutado&&(
            <Box pt={3}>
              <Typography variant="body" display="inline">
                  El cambio de contraseña ha sido efectuado.
              </Typography>
            </Box>
          )}

        </Box>
      </Paper>
    );
  }
}

export default CambiarContrasena;
