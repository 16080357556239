import React from "react";
import { Container,Grid,Typography} from '@material-ui/core';

const CabeceraCarga = () => {

  return (
        <Container maxWidth="xl" >
          <Grid container spacing={0}  align = "center" alignItems = "center"  >
            <Grid item xs={8}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Datos Personales
              </Typography>
            </Grid >
            <Grid item xs={4}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Acción
              </Typography>
            </Grid >
          </Grid>
        </Container>
  );
};

export default CabeceraCarga;
