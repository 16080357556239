import React from "react";
import {Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {
  DataGrid,
} from '@mui/x-data-grid';
//import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import './Cargas.css';
import Rut from '../comun/Rut.js';
import Correo from '../comun/Correo.js';
import SelectorFecha from '../comun/SelectorFecha.js';
import EtiquetaSelect from '../comun/EtiquetaSelect.js';
import { ServiceActualizaPaciente } from '../../../../api/services';

import EtiquetaTextField from '../comun/EtiquetaTextField.js';
import MenuItem from '@material-ui/core/MenuItem';

const estiloDivModal={
      position: 'absolute',
      top: '5%',
      left: '30%',
      right: '30%',
      bottom: '5%',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px',
}

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const columns = [
  { field: 'id', headerName: 'Rut', width: 150 },
  {
    field: 'primerNombre',
    headerName: 'Primer Nombre',
    width: 200,
    editable: false,
  },
  {
    field: 'segundoNombre',
    headerName: 'Segundo Nombre',
    width: 200,
    editable: false,
  },
  {
    field: 'apellidoPaterno',
    headerName: 'Paterno',
    width: 200,
    editable: false,
   },
   {
     field: 'apellidoMaterno',
     headerName: 'Materno',
     width: 200,
     editable: false,
    },
  {
    field: 'relacion',
    headerName: 'Relación',
    width: 160,
    flex:1
  },

];

const datos = ["Hijo","Hija","Esposo","Esposa"];

const alternativas = datos.map(item => (
  <MenuItem key={item} value={item}>{item}</MenuItem>
));

function callbackDibujarDetalleCarga(slot, index, array,padre){

    return  <DetalleIncidente idIncidente={this.padre.state.listaIncidentes[index].id}
                fecha={this.padre.state.listaIncidentes[index].fecha}

                involucrados={this.padre.state.listaIncidentes[index].involucrados}
                duracion={this.padre.state.listaIncidentes[index].duracion}
                sector={this.padre.state.listaIncidentes[index].sector}
                accionSubestandar={this.padre.state.listaIncidentes[index].accionSubestandar}
                urlImagen={this.padre.state.listaIncidentes[index].url_imagen} />;

}

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

const actualizarDatosDB = async (newdata) => {
    console.log("actualidando!!!!!!");
    let response = await ServiceActualizaPaciente(newdata);
    console.log("respuesta");
}

class Cargas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listaCargas:[],
      filasSeleccionadas:[],
      mostarPanelAgregar:false,
      primerNombre:"",
      segundoNombre:"",
      apellidoPaterno:"",
      apellidoMaterno:"",
      diaNacimiento:"",
      mesNacimiento:"",
      anioNacimiento:"",
      relacion:"",
      rutValido:false,
      rutCarga:"",
      datosPacienteRemoto:null,
      relacionSeleccionada:""
    };
  }

  componentDidMount() {
    axios
      .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/patient?rut="+localStorage.getItem('rut'), header)
      .then((res) =>{
                  let data=res["data"];
                  let body=data["body"];
                  data=body["data"];
                  if (typeof data["cargas"] == 'undefined'){
                      this.setState({listaCargas:[]});
                  }else{
                      this.setState({listaCargas:data["cargas"]});
                  }

                  this.setState({datosPacienteRemoto:data});

                }
              )
       .catch((err) => console.log(err));
  }

  handlerSeleccionFilas = (e) =>{
       this.setState({filasSeleccionadas:e});
  }

  handlerAgregarCarga = (e) =>{
      this.setState({mostarPanelAgregar:true});
  }

  handlerBorrarCarga = (e) =>{
    let temp=[];

    for (let j=0; j < this.state.listaCargas.length;j++){

      let encontrado=false;
      let cargaActual=this.state.listaCargas[j];

        for (let i = 0; i < this.state.filasSeleccionadas.length; i++) {
          console.log(this.state.filasSeleccionadas[i])

          if (cargaActual["id"]==this.state.filasSeleccionadas[i]){
            encontrado=true;
          }
      }

      if (encontrado==false){
          temp.push(cargaActual);
      }
    }

    let cargasJson={
      cargas:temp
    };

    this.state.datosPacienteRemoto.cargas=cargasJson.cargas;

    actualizarDatosDB(this.state.datosPacienteRemoto);

    this.setState({listaCargas:[]}); //TODO: esto es para que se refresque la tabla
    this.setState({listaCargas:temp});
  }

  handlerCerrarPanelAgregarCarga = (e) =>{
      this.setState({mostarPanelAgregar:false});
  }

  getModalStyle = ()=> {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  handlerPrimerNombre = (e) =>{
      this.setState({primerNombre:e.target.value});
  }

  handlerSegundoNombre = (e) =>{
      this.setState({segundoNombre:e.target.value});
  }

  handlerApellidoPaterno = (e) =>{
      this.setState({apellidoPaterno:e.target.value});
  }

  handlerApellidoMaterno = (e) =>{
      this.setState({apellidoMaterno:e.target.value});
  }

  handlerRelacion = (relacion) =>{
      this.setState({relacionSeleccionada:relacion});
  }

  handlerRutCarga = (rutValido,valorRut) =>{
    if (rutValido){
      this.setState({rutValido:true});
      this.setState({rutCarga:valorRut});
    }else{
      this.setState({rutValido:false});
    }
  }

  handlerAceptar = () => {

    let jsonValores={
      primerNombre:this.state.primerNombre,
      segundoNombre:this.state.segundoNombre,
      apellidoPaterno:this.state.apellidoPaterno,
      apellidoMaterno:this.state.apellidoMaterno,
      diaNacimiento:this.state.diaNacimiento,
      mesNacimiento:this.state.mesNacimiento,
      anioNacimiento:this.state.anioNacimiento,
      relacion:this.state.relacion,
      id:this.state.rutCarga,
    };

    let temp=[];

    for (let i = 0; i < this.state.listaCargas.length; i++) {
      temp.push(this.state.listaCargas[i]);
    }

    temp.push(jsonValores);

    let cargasJson={
      cargas:temp
    };

    this.state.datosPacienteRemoto.cargas=cargasJson.cargas;

    actualizarDatosDB(this.state.datosPacienteRemoto);
    this.setState({mostarPanelAgregar:false});

    this.setState({listaCargas:[]}); //TODO: esto es para que se refresque la tabla
    this.setState({listaCargas:temp});
  }

  handlerCancelar = () => {
    this.setState({mostarPanelAgregar:false});
    this.setState({primerNombre:""});
    this.setState({segundoNombre:""});
    this.setState({apellidoPaterno:""});
    this.setState({apellidoMaterno:""});
    this.setState({rutValido:false});
    this.setState({rutCarga:""});
    this.setState({mostarPanelAgregar:false});
  }

  handlerSeleccionFecha =  (value, event) => {

    let anioNacimiento=value.getFullYear();
    let mesNacimiento=value.getMonth()+1;  //en javascript parte en 0 el primer mes.
    let diaNacimiento=value.getDate();

    this.setState({anioNacimiento:anioNacimiento});
    this.setState({mesNacimiento:mesNacimiento});
    this.setState({diaNacimiento:diaNacimiento});
  }

  render() {
    const { classes } = this.props;
    const { filasSeleccionadas} = this.state;

    return (
      <Paper>
        <Box p={4}>

          <Button onClick={this.handlerAgregarCarga}>Agregar Carga</Button><span>  </span>
          <Button disabled={this.state.filasSeleccionadas.length==0} onClick={this.handlerBorrarCarga}>Borrar Carga</Button><span>  </span>

          <br/>
          <br/>

          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                    rows={this.state.listaCargas}
                    columns={columns}
                    pageSize={5}

                    checkboxSelection
                    onSelectionModelChange={this.handlerSeleccionFilas}
                    rowsPerPageOptions={[5]}
                  />
          </div>

          <br/>
          <br/>


        </Box>

        <Modal
          open={this.state.mostarPanelAgregar}
          onClose={this.handlerCerrarPanelAgregarCarga}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            >
            <div style={estiloDivModal}>
              <Box pb={2}>
                <Typography variant="h6">
                  Agregar carga familiar
                </Typography>
              </Box>

              <EtiquetaTextField etiqueta="Primer Nombre" value={this.state.primerNombre} handlerTextField={this.handlerPrimerNombre} />
              <EtiquetaTextField etiqueta="Segundo Nombre" value={this.state.segundoNombre} handlerTextField={this.handlerSegundoNombre}  />
              <EtiquetaTextField etiqueta="Apellido Paterno" value={this.state.apellidoPaterno} handlerTextField={this.handlerApellidoPaterno}  />
              <EtiquetaTextField etiqueta="Apellido Materno" value={this.state.apellidoMaterno} handlerTextField={this.handlerApellidoMaterno} />

              <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Box width={200}>
                    <Typography variant="body1">
                      Rut del paciente:
                    </Typography>
                  </Box>
                   <Rut  value={this.state.rutCarga} handlerRut={this.handlerRutCarga} ></Rut>
              </Box>

              <EtiquetaSelect etiqueta="Relacion" alternativas={alternativas} valor={this.state.relacionSeleccionada} handlerSelect={this.handlerRelacion} ></EtiquetaSelect>

              <SelectorFecha etiqueta="Fecha nacimiento" handlerSeleccionFecha={this.handlerSeleccionFecha}  > </SelectorFecha>

              <br/>

              <Button   onClick={()=>{this.handlerAceptar()}} >
                Aceptar
              </Button>
              <span> </span>
              <Button onClick={this.handlerCancelar} >
                Cancelar
              </Button>

            </div>
         </Modal>
      </Paper>
    );
  }
}

export default Cargas;
