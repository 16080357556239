import React from "react";
import {
  Select,
  Modal,
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DataGrid } from "@material-ui/data-grid";
import styled, { ThemeProvider } from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ServiceActualizaPaciente,
  ServiceConsultaPaciente,
} from "../../../api/services";
import Fila from "./Fila";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

class DatosPersonalesFichaMedico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rutPaciente: "",
      paciente: {},
    };
  }

  refrescarDatos = () => {
    console.log("BUSCANDO PACIENTE2");
    const traerPaciente = async () => {
      let response = await ServiceConsultaPaciente(this.props.rut);
      this.setState({
        rutPaciente: response["body"]["id"],
        paciente: response["body"]["data"],
      });
    };
    traerPaciente();
  };

  componentDidMount() {
    console.log("BUSCANDO PACIENTE");
    this.refrescarDatos();
  }

  handlerMostrarPanelAgregar = (e) => {};

  handlerBorrarPlanAccion = (e) => {
    this.refrescarDatosTabla();
  };

  handlerEditar = (e) => {
    this.setState({ editar: true });
  };

  handlerClose = (e) => {
    this.setState({ editar: false });
  };

  getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  /*  dataPaciente.rut = localStorage.getItem('rut');
    dataPaciente.nombre = valueNombre;
    dataPaciente.fechaNacimiento = valueFechaNac;
    dataPaciente.genero = valueGenero;
    dataPaciente.prevision = valuePrevision;
    dataPaciente.estadoCivil = valueEstadoCivil;
    dataPaciente.direccion = valueDireccion;
    dataPaciente.telefono = valueTelefono;
    dataPaciente.email = valueEmail;*/

  render() {
    return (
      <Box>
        <Box pl={1} pr={1} pt={2}>
          <Fila clave={"Rut:"} valor={this.props.rut} />
          <Fila clave={"Nombre:"} valor={this.state.paciente["nombre"]} />
          <Fila
            clave={"Fecha Nacimiento:"}
            valor={this.state.paciente["fechaNacimiento"]}
          />
          <Fila clave={"Género:"} valor={this.state.paciente["genero"]} />
          <Fila clave={"Previsión:"} valor={this.state.paciente["prevision"]} />
          <Fila
            clave={"Estado Civil:"}
            valor={this.state.paciente["estadoCivil"]}
          />
          <Fila clave={"Dirección:"} valor={this.state.paciente["direccion"]} />
          <Fila clave={"Teléfono:"} valor={this.state.paciente["telefono"]} />
          <Fila clave={"Email:"} valor={this.state.paciente["email"]} />
        </Box>
        <Box pl={1} pr={1} pt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.handlerEditar}
          >
            Editar
          </Button>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.editar}
          onClose={this.handlerClose}
          BackdropComponent={Backdrop}
        >
          <Fade in={this.state.editar}>
            <div>
              <h2 id="transition-modal-title">Actualiza tus datos</h2>
              <TextField
                style={{ width: "50%", maxWidth: "50%" }}
                id="outlined-basic"
                label="RUT"
                variant="outlined"
                disabled
                value={localStorage.getItem("rut")}
              />
              <TextField
                style={{ width: "50%", maxWidth: "50%" }}
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                value={valueNombre}
                onChange={(e) => setValueNombre(e.target.value)}
              />
              <br />
              <div style={{ marginTop: "12px" }}>
                <TextField
                  type="date"
                  style={{ width: "50%", maxWidth: "50%" }}
                  id="outlined-basic"
                  label="Fecha de nacimiento"
                  variant="outlined"
                  value={valueFechaNac}
                  onChange={(e) => setValueFechaNac(e.target.value)}
                />
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Genero"
                  value={valueGenero}
                  onChange={(e) => setValueGenero(e.target.value)}
                  variant="outlined"
                  style={{ width: "50%", maxWidth: "50%" }}
                >
                  <MenuItem value={"Masculino"}>Masculino</MenuItem>
                  <MenuItem value={"Femenino"}>Femenino</MenuItem>
                </TextField>
              </div>
              <div style={{ marginTop: "12px" }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Prevision"
                  value={valuePrevision}
                  onChange={(e) => setValuePrevision(e.target.value)}
                  variant="outlined"
                  style={{ width: "50%", maxWidth: "50%" }}
                >
                  {dataPrevision.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Estado civil"
                  value={valueEstadoCivil}
                  onChange={(e) => setValueEstadoCivil(e.target.value)}
                  variant="outlined"
                  style={{ width: "50%", maxWidth: "50%" }}
                >
                  <MenuItem value={"Soltero/a"}>Soltero/a</MenuItem>
                  <MenuItem value={"Casado/a"}>Casado/a</MenuItem>
                </TextField>
              </div>
              <div style={{ marginTop: "12px" }}>
                <TextField
                  style={{ width: "50%", maxWidth: "50%" }}
                  id="outlined-basic"
                  label="Dirección"
                  variant="outlined"
                  value={valueDireccion}
                  onChange={(e) => setValueDireccion(e.target.value)}
                />
                <TextField
                  style={{ width: "50%", maxWidth: "50%" }}
                  id="outlined-basic"
                  label="Telefono"
                  variant="outlined"
                  value={valueTelefono}
                  onChange={(e) => setValueTelefono(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "12px" }}>
                <TextField
                  style={{ width: "100%", maxWidth: "100%" }}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={valueEmail}
                  onChange={(e) => setValueEmail(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "12px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={actualizarDatos}
                >
                  Actualizar
                </Button>
                <Button
                  style={{ marginTop: "8px" }}
                  variant="contained"
                  fullWidth
                  onClick={this.handlerClose}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </Box>
    );
  }
}

export default DatosPersonalesFichaMedico;
