import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import ChatIcon from '@material-ui/icons/Chat';
import ChatBot from '../chatbot/ChatBot';
import {Tabs,Container,Paper,Tab,Typography,Box,AppBar,Toolbar,Grid,Card} from '@material-ui/core';
import logo from '../../../assets/logo_kuery_grande.png';
import { Link, useHistory } from "react-router-dom";
import { Button, CssBaseline } from '@material-ui/core';

import BotonConIcono from '../../web/DashboardView/comun/BotonConIcono';

//#0077b0
import logo_kuery from '../../../assets/logo_kuery_blanco.png';
import { styled } from "@material-ui/core/styles";

// LOGOS COLABORADORES
import BARBAS from '../../../assets/colaboradores/2BARBAS.png';
import ASECH from '../../../assets/colaboradores/ASECH.png';
import CORFO from '../../../assets/colaboradores/Corfo.png';
import TTEXIS from '../../../assets/colaboradores/cyberdoc.png';
import EvaluacionRemota from '../../web/DashboardView/EvaluacionRemota';
import TEXIS2 from '../../../assets/colaboradores/cyberdoc.png';
import fondo from '../../../assets/fondo3.jpg';

const DLinkAzul = styled(Link)({
  margin: "14px 0",
  color:"#fff",
});

const DLink = styled(Link)({

  margin: "0px 0",
  padding: "14px 0px",
  textAlign: "center",
  color:"#fff",
  textDecoration: "none",
  border: "1px solid #3c77e3",
  background:"#3c77e3",
  width: "100%",
  display:"inline-block",
  fontSize: '17px',
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    height: '100%',
    paddingBottom: '50px'
  },
  paper2: {
    height: '100%',
    overflow: 'auto'
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background:'#0077b0',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  chat: {
      height: '100%'
  },
  home:{

    fontFamily: 'Encode Sans, Sans-serif',
    fontSize: '20px',
    padding:"0 10px"
  },
  title:{

    minHeight: "inherit",
    display: "block",
    height: "inherit",
  },
  button: {
    margin: theme.spacing(1),
    width:"100%",
  },
}));

export default function Menu() {
  const classes = useStyles();

  const refBotonUrgencia = React.useRef(null);
  const refBotonMedicos = React.useRef(null);
  const refBotonPlataforma = React.useRef(null);

  const [chatEnable, setChatEnable] = useState(false);
  const [constructorHasRun, setConstructorHasRun] = useState(false);

  const [estadoTab, setEstadoTab] = React.useState(1);

  const constructor = async () => {
    if (constructorHasRun) return;
    console.log("Mobile")

    setConstructorHasRun(true);
  };

  useEffect(() => constructor(), []);

  const handlerClickPlataforma = () => {
    setEstadoTab(1);
    refBotonPlataforma.current.setSeleccionado(true);
    refBotonUrgencia.current.setSeleccionado(false);
    refBotonMedicos.current.setSeleccionado(false);
  };

  const handlerClickUrgencia = () => {
    setEstadoTab(2);
    refBotonPlataforma.current.setSeleccionado(false);
    refBotonUrgencia.current.setSeleccionado(true);
    refBotonMedicos.current.setSeleccionado(false);
  };

  const handlerClickMedicos= () => {
    setEstadoTab(3);
    refBotonPlataforma.current.setSeleccionado(false);
    refBotonUrgencia.current.setSeleccionado(false);
    refBotonMedicos.current.setSeleccionado(true);
  };

  return (
    <>
      <CssBaseline />
      <Paper square className={classes.root} elevation={0}>
        {chatEnable?
            <><EvaluacionRemota/></>
          :
          <div style={{height: "inherit"}}>

          <AppBar position="static">
            <Toolbar variant="dense">
               <div style={{ width: '100%' }}>
                <Box display="flex" direction="row" alignItems="center">
                  <Box  pt={1} flexGrow={1} >
                    <img src={logo_kuery} style={{height:"5vh"}}/>
                  </Box>
                  <Box  style={{ marginLeft: 'auto',marginRight:'auto'}} pr={3} >
                    <DLinkAzul style={{"color":"white",textDecoration: "none"}} to="/registroPacienteMobile">Registrarse </DLinkAzul>
                  </Box>
                  <Box  style={{ marginLeft: 'auto',marginRight:'auto'}}  >
                    <DLinkAzul style={{"color":"white",textDecoration: "none"}} to="/loginMedico">Login Médicos</DLinkAzul>
                  </Box>
                </Box>
              </div>
            </Toolbar>
          </AppBar>

            <div className={classes.title}>

              <Grid container alignItems="center" >

                <Grid item xs={12}  >
                  <Paper square   >
                    <Grid container direction="row" justifyContent="space-between"  >
                      <Box  ml={2}   display="flex" flexDirection="column" alignItems="center" mt={3} >
                        <BotonConIcono ref={refBotonPlataforma} titulo="Plataforma" icono={1} seleccionado={estadoTab==1} clickHandler={handlerClickPlataforma}>
                        </BotonConIcono>
                      </Box>
                      <Box display="flex" flexDirection="column" alignItems="center" mt={3} >
                        <BotonConIcono ref={refBotonUrgencia} titulo="Urgencia" icono={2} seleccionado={estadoTab==2} clickHandler={handlerClickUrgencia}>
                        </BotonConIcono>
                      </Box>
                      <Box mr={2} display="flex" flexDirection="column" alignItems="center" mt={3} >
                        <BotonConIcono ref={refBotonMedicos} titulo="Médicos" icono={3} seleccionado={estadoTab==3} clickHandler={handlerClickMedicos}>
                        </BotonConIcono>
                      </Box>
                    </Grid>

                    {estadoTab==1 &&(
                      <Box ml={2} mr={2}   pb={5}>
                        <Typography pt={6} variant="h5">
                          Atención remota con médicos en linea.
                        </Typography>
                        <br/>
                        <Box width="100%">
                          <DLink to="/loginPaciente">Pacientes</DLink>

                        </Box>
                      </Box>
                    )}

                    {estadoTab==2 &&(
                      <Box ml={2} mr={2}  pb={5} >

                        <Typography   ml={10} variant="h5">
                          Asistente virtual de síntomas
                        </Typography>
                        <br/>
                        <DLink to="/asistenteMobile">Consultar Aquí</DLink>
                      </Box>
                    )}

                    {estadoTab==3 &&(
                      <Box ml={2} mr={2} pb={5}>

                        <Typography pt={20} pt={6} ml={10} variant="h5">
                          Sumate a nuestra plataforma
                        </Typography>
                        <br/>
                        <DLink to="/signupMedicoMobile">Registrarse</DLink>
                      </Box>
                    )}

                    <Box pt={1} alignItems="center">
                      <img src={fondo} style={{width:"100%"}}/>
                    </Box>

                    <Box  display="flex" justifyContent="center" flexDirection="column" alignItems="center">

                      <Box mt={5} display="flex"  alignItems="center">
                        <img src={CORFO} style={{height:"22vh"}}/>
                      </Box>

                      <Box mt={3}>
                        <img src={TEXIS2} style={{height:"12vh"}}/>
                      </Box>

                      <Box mt={3}>
                        <img src={ASECH} style={{height:"12vh"}}/>
                      </Box>

                      <Box mt={3} alignItems="center">
                        <img src={BARBAS} style={{height:"13vh"}}/>
                      </Box>

                    </Box>

                  </Paper>

                </Grid>
              </Grid>

            </div>
          </div>
        }

      </Paper>
    </>
  );
}
