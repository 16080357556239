import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import styledComponents from "styled-components";
import { useInput } from "./../../util/forms";
import { Toast } from "./../../util/notifications";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import fondo from "../../assets/fondo4.jpg";
import logo_kuery from "../../assets/logo_kuery_blanco.png";
import {
  Paper,
  Typography,
  Box,
  AppBar,
  Toolbar,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import BotonConIcono from "./DashboardView/comun/BotonConIcono";

// LOGOS COLABORADORES
import BARBAS from "../../assets/colaboradores/2BARBAS.png";
import ASECH from "../../assets/colaboradores/ASECH.png";
import CORFO from "../../assets/colaboradores/Corfo.png";
//import TTEXIS from '../../assets/colaboradores/cyberdoc.png';
import TEXIS2 from "../../assets/colaboradores/cyberdoc.png";

const Button = styledComponents.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background:${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLinkAzul = styled(Link)({
  margin: "14px 0",
  color: "#fff",
});

const DLink = styled(Link)({
  margin: "14px 0",
  padding: "7px 40px",
  textAlign: "right",
  color: "#fff",
  textDecoration: "none",
  border: "1px solid #3c77e3",
  width: "250px",
  height: "35px",
  background: "#3c77e3",
});

const Signup = () => {
  const classes = useStyles();

  const refBotonUrgencia = React.useRef(null);
  const refBotonMedicos = React.useRef(null);
  const refBotonPlataforma = React.useRef(null);
  const refBotonClinica = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [estadoTab, setEstadoTab] = React.useState(1);

  const history = useHistory();

  const { value: rut, bind: bindRut } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signIn(rut, password);
      console.log(Auth.user);
      localStorage.setItem("rut", Auth.user.username);
      Toast("Muy bien!!", "Has ingresado correctamente", "success");
      history.push("/");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  document.body.style =
    'background-image: url("' +
    fondo +
    '");background-repeat: no-repeat; background-size: cover;resize: both;';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);

  const handlerClickPlataforma = () => {
    setEstadoTab(2);
    refBotonPlataforma.current.setSeleccionado(true);
    refBotonUrgencia.current.setSeleccionado(false);
    refBotonMedicos.current.setSeleccionado(false);
    refBotonClinica.current.setSeleccionado(false);
  };

  const handlerClickUrgencia = () => {
    setEstadoTab(1);
    refBotonPlataforma.current.setSeleccionado(false);
    refBotonUrgencia.current.setSeleccionado(true);
    refBotonMedicos.current.setSeleccionado(false);
    refBotonClinica.current.setSeleccionado(false);
  };

  const handlerClickMedicos = () => {
    setEstadoTab(3);
    refBotonPlataforma.current.setSeleccionado(false);
    refBotonUrgencia.current.setSeleccionado(false);
    refBotonMedicos.current.setSeleccionado(true);
    refBotonClinica.current.setSeleccionado(false);
  };

  const handlerClickClinicas = () => {
    setEstadoTab(4);
    refBotonPlataforma.current.setSeleccionado(false);
    refBotonUrgencia.current.setSeleccionado(false);
    refBotonMedicos.current.setSeleccionado(false);
    refBotonClinica.current.setSeleccionado(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box display="flex" alignItems="center">
            <Box ml={29} pt={1}>
              <img src={logo_kuery} style={{ height: "5vh" }} />
            </Box>
            <Box ml={80}>
              <DLinkAzul
                style={{ color: "white", textDecoration: "none" }}
                to="/signup"
              >
                Registrarse
              </DLinkAzul>
            </Box>
            <Box ml={5}>
              <DLinkAzul
                style={{ color: "white", textDecoration: "none" }}
                to="/signinNormal"
              >
                Ingreso Pacientes
              </DLinkAzul>
            </Box>
            <Box ml={5}>
              <DLinkAzul
                style={{ color: "white", textDecoration: "none" }}
                to="/signinPro"
              >
                Ingreso Médicos
              </DLinkAzul>
            </Box>
            <Box ml={5}>
              <DLinkAzul
                style={{ color: "white", textDecoration: "none" }}
                to="/loginAdmin"
              >
                Ingreso Clínicas
              </DLinkAzul>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <br />
      <br />
      <br />
      <br />

      <Grid container alignItems="center" mt={100}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <Paper square>
            <Grid container direction="row" justifyContent="space-around">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
              >
                <BotonConIcono
                  ref={refBotonUrgencia}
                  titulo="Urgencia"
                  icono={2}
                  seleccionado={estadoTab == 1}
                  clickHandler={handlerClickUrgencia}
                ></BotonConIcono>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
              >
                <BotonConIcono
                  ref={refBotonPlataforma}
                  titulo="Plataforma"
                  icono={1}
                  seleccionado={estadoTab == 2}
                  clickHandler={handlerClickPlataforma}
                ></BotonConIcono>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
              >
                <BotonConIcono
                  ref={refBotonMedicos}
                  titulo="Médicos"
                  icono={3}
                  seleccionado={estadoTab == 3}
                  clickHandler={handlerClickMedicos}
                ></BotonConIcono>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
              >
                <BotonConIcono
                  ref={refBotonClinica}
                  titulo="Clínicas"
                  icono={3}
                  seleccionado={estadoTab == 4}
                  clickHandler={handlerClickClinicas}
                ></BotonConIcono>
              </Box>
            </Grid>

            {estadoTab == 1 && (
              <Box ml={6} pt={5} pb={5}>
                <Typography pt={20} ml={10} variant="h3">
                  Atención de urgencia
                </Typography>
                <br />
                <Typography pt={6} ml={10} variant="h6">
                  Mediante un asistente virtual de síntomas
                </Typography>
                <br />
                <DLink to="/asistente">Consultar Aquí</DLink>
              </Box>
            )}

            {estadoTab == 2 && (
              <Box ml={6} pt={5} pb={5}>
                <Typography pt={20} ml={10} variant="h3">
                  Plataforma para la Salud digital
                </Typography>
                <br />
                <Typography pt={6} ml={10} variant="h6">
                  Atención remota con médicos en linea.
                </Typography>
                <br />
                <DLink to="/signinNormal">Ingresar</DLink>
              </Box>
            )}

            {estadoTab == 3 && (
              <Box ml={6} pt={5} pb={5}>
                <Typography pt={20} ml={10} variant="h3">
                  Registro de médicos
                </Typography>
                <br />
                <Typography pt={6} ml={10} variant="h6">
                  Sumate a nuestra plataforma
                </Typography>
                <br />
                <DLink to="/registroMedico">Registrarse</DLink>
              </Box>
            )}
            {estadoTab == 4 && (
              <Box ml={6} pt={5} pb={5}>
                <Typography pt={20} ml={10} variant="h3">
                  Registro de Clínicas
                </Typography>
                <br />
                <Typography pt={6} ml={10} variant="h6">
                  Sumate a nuestra plataforma
                </Typography>
                <br />
                <DLink to="/registroClinica">Registrarse</DLink>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <div
        style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          maxHeight: "18vh",
          backgroundColor: "#ffffff9e",
          padding: "1vh",
          textAlign: "center",
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th>
                <img src={CORFO} style={{ height: "12vh" }} />
              </th>
              <th>
                <img src={TEXIS2} style={{ width: "18vh" }} />
              </th>
              <th>
                <img src={ASECH} style={{ height: "5vh" }} />
              </th>
              <th>
                <img src={BARBAS} style={{ height: "7vh" }} />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default Signup;
