import React from "react";
import {Grid,TextField,Box,Typography} from '@material-ui/core';
import Calendar from 'react-calendar';

class SelectorFecha extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      etiqueta: props.etiqueta,
      valor: props.valor,
      handlerExterno: props.handlerSeleccionFecha,
    };
    console.log("el valor "+props.valor);
  }

  render(){
    return (
        <Box mt={2} display="flex" flexDirection="row" alignItems="flex-start">
            <Box width={200}>
              <Typography variant="body1">
                {this.state.etiqueta}
              </Typography>
            </Box>
            <Calendar onChange={this.state.handlerExterno}> value={this.state.valor}></Calendar>
        </Box>
    );
  }
};

export default SelectorFecha;
