import React from "react";
import {Grid,Box,TextField,Typography} from '@material-ui/core';

class EtiquetaTextArea extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
      etiqueta: props.etiqueta,
      valor: props.valor,
      handlerExterno: props.handlerTextField
    };
  }

  render(){
    return (
      <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box width={200}>
            <Typography variant="body1">
              {this.state.etiqueta}
            </Typography>
          </Box>
          <TextField  multiline maxRows={4} variant="outlined" value={this.state.valor} label={this.state.etiqueta} onChange={this.state.handlerExterno} ></TextField>
      </Box>

    );
  }
};

export default EtiquetaTextArea;
