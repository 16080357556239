import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "../../util/forms";
import { Toast } from "../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";

import fondo from "../../assets/fondo2.png";
import logo_kuery from "../../assets/logo_kuery_medio.png";
import {
  ServiceGetOrganizaciones,
  ServiceLoginAdmin,
} from "../../api/services";

// LOGOS COLABORADORES
import BARBAS from "../../assets/colaboradores/2BARBAS.png";
import ASECH from "../../assets/colaboradores/ASECH.png";
import CORFO from "../../assets/colaboradores/Corfo.png";
import TEXIS2 from "../../assets/colaboradores/cyberdoc.png";
import { setLocalStorageItem } from "../../lib/localstorage";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const LoginAdmin = (props) => {
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  Auth.signOut();
  const { value: rut, bind: bindRut } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await ServiceLoginAdmin({
        rut: rut,
        password: password,
      });
      if (response.statusCode == 200) {
        const clinica = response.body.organizacion;
        ServiceGetOrganizaciones().then((res) => {
          const isClinicaValida = res?.Items?.find(
            ({ dominio, id }) => dominio === clinica || id === clinica
          );
          if (Boolean(isClinicaValida)) {
            setLocalStorageItem("clinica", isClinicaValida.id);
            setLocalStorageItem(
              "clinicaObject",
              JSON.stringify(isClinicaValida)
            );
          }
        });
        setLocalStorageItem("professional", response.body.id);
        props.setUserPro(response.body.id);
        history.push(`/admin/${response.body.id}`);
      } else {
        Toast("Error!!", "Usuario no encontrado", "danger");
      }
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  document.body.style = 'background-image: url("' + fondo + '");';

  return (
    <>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          /*alignItems:  "center",
        justifyContent: "right"
        */
        }}
        onSubmit={handleSubmit}
      >
        <img src={logo_kuery} style={{ width: "150px", margin: "auto" }} />
        <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
          {" "}
          Ingreso administrador
        </h1>
        <Field label="Rut" {...bindRut} />
        <Field label="Contraseña" type="password" {...bindPassword} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Ingresar
        </Button>
      </form>
      <div
        style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          maxHeight: "18vh",
          backgroundColor: "#ffffff9e",
          padding: "1vh",
          textAlign: "center",
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th>
                <img src={CORFO} style={{ height: "12vh" }} />
              </th>
              <th>
                <img src={TEXIS2} style={{ width: "14vh" }} />
              </th>
              <th>
                <img src={ASECH} style={{ height: "5vh" }} />
              </th>
              <th>
                <img src={BARBAS} style={{ height: "8vh" }} />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default LoginAdmin;
