import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { Grid, Paper, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ServiceActualizaPaciente, ServiceConsultaPaciente } from '../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    table: {
      minWidth: 300,
      maxWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

export default function Recetas(props){
    const classes = useStyles();
    const [dataRecetas, setDataRecetas] = useState({});
    const [open, setOpen] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = async () => {
        if (constructorHasRun) return;
        try{
          traerRecetas();
        }catch(e){
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    const traerRecetas = async () => {
      /*let response = await ServiceConsultaPaciente(localStorage.getItem('rut'));
      console.log("response", response);
      setDataPaciente(response);
      if (response.statusCode === 400) {
          handleOpen();
      }*/
    }
  
    useEffect(() => constructor(), []);
        
    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper>
        <AppBar position="static">
          <Toolbar style={{background : '#2E3B55'}}>
            <Typography variant="h6" className={classes.title}>
              RECETAS
            </Typography>
          </Toolbar>
        </AppBar>
        <Table aria-label="customized table">
          <TableBody>
            <StyledTableRow hover >
              <StyledTableCell component="th" scope="row">
                NOMBRE DEL MEDICO
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                ESPECIALIDAD
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                FECHA
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                TIPO DE RECETA
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                BOTONES
              </StyledTableCell>
            </StyledTableRow>
            
          </TableBody>
        </Table>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          BackdropComponent={Backdrop}
        >
          <Fade in={open}>
            
          </Fade>
        </Modal>
        </Paper>
    )
}