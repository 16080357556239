import React from "react";
import { Container,Grid,Typography} from '@material-ui/core';

const CabeceraTabla = () => {

  return (
        <Container maxWidth="xl" >
          <Grid container spacing={0}  justify = "center" alignItems = "center"  >
            <Grid item xs={3}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Medicamento
              </Typography>
            </Grid >
            <Grid item xs={3}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Presentación
              </Typography>
            </Grid >
            <Grid item xs={3}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Tratamiento
              </Typography>
            </Grid >
            <Grid item xs={3}>
              <Typography variant="h6" style={{ fontWeight: 600 }} >
                Acción
              </Typography>
            </Grid >
          </Grid>
        </Container>
  );
};

export default CabeceraTabla;
