import { Button, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import useScript from 'react-script-hook';
let ZoomMtg = window.ZoomMtg; 
function VideoChat() {
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  useScript({ src: 'https://source.zoom.us/zoom-meeting-1.9.1.min.js', onload: () => console.log('Script loaded!'), });

  const API_KEY = "QYpsx4w0QQysVgpmc5mNkA";
  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  var signatureEndpoint = 'https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/zoom/signature';
  var apiKey =API_KEY;
  var meetingNumber = '84886586383';
  var role = 0;
  var leaveUrl = 'https://d3d5zvnihsts57.cloudfront.net/';
  var userName = `${localStorage.getItem('rut')}`;
  var userEmail = '';
  var passWord = '415743';

  ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.1/lib', '/av');
  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareJssdk();
  const constructor = async () => {
    if (constructorHasRun) return;
    try{

    }catch(e){
        console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };
  useEffect(() => constructor(), []);

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log("init", success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log("join", success);
            document.getElementById('wc-content').style.width = '70%';
            document.getElementById('wc-content').style.marginLeft = '23%';

            //document.getElementById('wc-loading').style.width = '70%';
            //document.getElementById('wc-loading').style.marginLeft = '23%';
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <Paper>
      <main>
        <h1>Consulta de telemedicinat</h1>
          <Button variant="contained" color="primary" onClick={getSignature}>Unirse a la consulta</Button>
      </main>
    </Paper>
  );
}

export default VideoChat;
