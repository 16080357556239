import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { ServiceGetOrganizaciones } from "../api/services";
import { setLocalStorageItem } from "../lib/localstorage";

const ClinicaInitRoute = () => {
  const { clinica } = useParams();
  const [isClinicaValida, setIsClinicaValida] = useState(null);
  useEffect(() => {
    if (!Boolean(clinica)) return;
    ServiceGetOrganizaciones().then((res) => {
      const isClinicaValida = res.Items.find(
        ({ dominio, id }) => dominio === clinica || id === clinica
      );
      if (Boolean(isClinicaValida)) {
        setLocalStorageItem("clinica", isClinicaValida.id);
        setLocalStorageItem("clinicaObject", JSON.stringify(isClinicaValida));
        setIsClinicaValida(true);
      }
      setIsClinicaValida(false);
    });
  }, [clinica]);
  return isClinicaValida === null ? null : isClinicaValida ? (
    <Redirect to="/" push />
  ) : (
    <Redirect to="/" push />
  );
};

export default ClinicaInitRoute;
