import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, TextField } from '@material-ui/core';
import { ServiceActualizaPaciente } from '../../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Autocomplete from '@material-ui/lab/Autocomplete';

const enfermedades= [
  "Mal de Alzheimer y demencia",
  "Artritis",
  "Asma",
  "Cancer",
  "EPOC",
  "Enfermedad de Crohn",
  "Fibrosis quística",
  "Diabetes",
  "Depresión",
  "Obesidad",
  "Hipertensión Arterial",
  "Enfermedad Renal",
  "Cardiopatía Crónica",
  "Inmunocomprometido",
  "Enfermedad Tiroidea"
]

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        minWidth: 200,
    },
    title: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
        maxWidth: 600,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function EnfermedadBase(props) {
    const classes = useStyles();
    const [dataEnfermedadBase, setDataEnfermedadBase] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const [valueEnfermedadBase, setValueEnfermedadBase] = React.useState("");

    const constructor = async () => {
        if (constructorHasRun) return;
        try {
            setDataEnfermedadBase(props.paciente.enfermedadBase ? props.paciente.enfermedadBase : []);
        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    useEffect(() => constructor(), []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValueEnfermedadBase("");
        setOpen(false);
    };

    const actualizarDatosDB = async (nuevasEnfermedadesBase) => {
        let paciente = props.paciente;
        paciente.enfermedadBase=nuevasEnfermedadesBase;

        let response = await ServiceActualizaPaciente(paciente);
        props.setData(response, 1);
    }

    const actualizarDatos = async () => {
        if (valueEnfermedadBase) {
            setDataEnfermedadBase([...dataEnfermedadBase, valueEnfermedadBase])

            let nuevasEnfermedadesBase= [...dataEnfermedadBase, valueEnfermedadBase];
            await actualizarDatosDB(nuevasEnfermedadesBase);
        }
        handleClose();
    }


    const deleteItem = async (elm) => {
        let newdataEnfermedadBase = dataEnfermedadBase.filter(function (item) {
            return item !== elm;
        });
        setDataEnfermedadBase(newdataEnfermedadBase);

        let nuevasEnfermedadesBase=  [...newdataEnfermedadBase];
        await actualizarDatosDB(nuevasEnfermedadesBase);
    }

    return (
        <Paper>
            <List component="nav" className={classes.root} aria-label="contacts">
                {
                    dataEnfermedadBase.map((elm, index) =>
                        <>
                            <ListItem button key={index}>
                                <ListItemIcon>
                                    <LocalHospitalIcon />
                                </ListItemIcon>
                                <ListItemText primary={elm} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                }

            </List>
            <br />
            {
                dataEnfermedadBase.length > 7 ? <></> : <Button variant="contained" color="primary" fullWidth onClick={handleOpen}>AGREGAR</Button>
            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Agregar enfermedad base</h2>

                        <Autocomplete
                            options={enfermedades}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            value={dataEnfermedadBase}
                            onChange={(e,newValue) => setValueEnfermedadBase(newValue)}
                            renderInput={(params) => <TextField {...params} label="Escriba enfermedad" variant="outlined" />}
                          />
                        <br />
                        <div style={{ marginTop: "12px" }}>
                            <Button variant="contained" color="primary" fullWidth disabled={!valueEnfermedadBase != ""} onClick={actualizarDatos}>Agregar</Button>
                            <Button style={{ marginTop: "8px" }} variant="contained" fullWidth onClick={handleClose}>Cancelar</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Paper>
    )
}
