import React, { useEffect, useState } from 'react';
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { Grid, InputBase, Paper, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ServiceActualizaPaciente, ServiceConsultaPaciente } from '../../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import { format } from 'rut.js'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    table: {
      minWidth: 300,
      maxWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '26ch',
      },
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }




  const columns = [
  { field: 'id', headerName: 'ID', width: 140 },
  { field: 'nombre', headerName: 'NOMBRE DEL PACIENTE', width: 370 },
  { field: 'fecha', headerName: 'FECHA', width: 130 },
  { field: 'diagnostico', headerName: 'DIAGNOSTICO', width: 530 },
];



const rowsDatos = [
  { id:1,nombre: 'Gonzalo Romero',fecha: '2021-3-12', diagnostico: 'DIARREA Y GASTROENTERITIS DE PRESUNTO ORIGEN INFECCIOSO'},
  { id:2,nombre: 'Gonzalo Romero',fecha: '2021-5-07', diagnostico: 'RINOFARINGITIS AGUDA (RESFRIADO COMUN)'},
];

export default function Paciente(props){
  const classes = useStyles();
  const [rutPaciente, setRutPaciente] = useState("");
  const [dataRecetas, setDataRecetas] = useState({});
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [constructorHasRun, setConstructorHasRun] = useState(false);

  const constructor = async () => {
      if (constructorHasRun) return;
      try{
        traerRecetas();
      }catch(e){
          console.log("ERR:", e);
      }
      setConstructorHasRun(true);
  };

  const traerRecetas = async () => {
    /*let response = await ServiceConsultaPaciente(localStorage.getItem('rut'));
    console.log("response", response);
    setDataPaciente(response);
    if (response.statusCode === 400) {
        handleOpen();
    }*/
  }

  useEffect(() => constructor(), []);

  const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const buscar = (rut) =>{
    if (rut=='13.544.963-6'){
      setRows(rowsDatos);
    }
  };

  return (
      <Paper>
        <AppBar position="static">
          <Toolbar style={{background : '#2E3B55'}}>
            <Typography variant="h6" className={classes.title}>
              MODULO PACIENTE
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={rutPaciente}
                onChange={e=>{
                  setRutPaciente(format(e.target.value)!="-"?format(e.target.value):"")
                  }}
                placeholder="Buscar paciente por rut"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
           
            <Button variant="contained" onClick={() => buscar(rutPaciente)} color="primary">Buscar</Button>
          </Toolbar>
        </AppBar>

        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          BackdropComponent={Backdrop}
        >
          <Fade in={open}>
          </Fade>
        </Modal>
      </Paper>
  )
}
