import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {Tabs,Container,Paper,Tab,Typography,Box,AppBar,Toolbar} from '@material-ui/core';

import Ficha from './Ficha';
import Alergias from './Alergias';
import EnfermedadBase from './EnfermedadBase';
import FactoresRiesgo from './FactoresRiesgo';
import Medicamentos from './Medicamentos';
import { ServiceConsultaPaciente } from '../../../../api/services';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function MainFicha(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [dataPaciente, setDataPaciente] = useState({});
    const [paciente, setPaciente] = useState({});

    const [rutPaciente, setRutPaciente] = useState("");

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = async () => {

        if (constructorHasRun) return;

        setRutPaciente(props.rutPaciente);

        try {
            traerPaciente();
        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    const traerPaciente = async () => {
        console.log(props.rutPaciente);
        let response = await ServiceConsultaPaciente(props.rutPaciente.replaceAll(".",""));
        setDataPaciente(response);

        if (response.statusCode === 400) {
        //    handleOpen();
        }

        setPaciente(response.body.data);
    }

    useEffect(() => constructor(), []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const updateData = (data, tipo) => {
        if (tipo == 1) {
            traerPaciente();
        } else {
            setPaciente(data);
        }
    }

    return (
        <>
          <Paper elevation={3}>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}>

                    <Tab label="Ficha" {...a11yProps(0)} />
                    <Tab label="Alergias" {...a11yProps(1)} />
                    <Tab label="Factores de riesgo" {...a11yProps(2)} />
                    <Tab label="Medicamentos en uso" {...a11yProps(3)} />
                    <Tab label="Enfermedad de base" {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={value} index={0} style={{ width: 'inherit' }}>
                    <Ficha paciente={paciente} setData={updateData} />
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: 'inherit' }}>
                    <Alergias paciente={paciente} setData={updateData} />
                </TabPanel>
                <TabPanel value={value} index={2} style={{ width: 'inherit' }}>
                    <FactoresRiesgo  paciente={paciente} setData={updateData} />
                </TabPanel>
                <TabPanel value={value} index={3} style={{ width: 'inherit' }}>
                    <Medicamentos paciente={paciente} setData={updateData} />
                </TabPanel>
                <TabPanel value={value} index={4} style={{ width: 'inherit' }}>
                    <EnfermedadBase paciente={paciente} setData={updateData} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    Desarrollo...
                </TabPanel>
                <TabPanel value={value} index={6}>
                    Desarrollo...
                </TabPanel>
            </div>
          </Paper>
        </>

    );
}
