import axios from "axios";

const API_URL = "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/";
const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "POST",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
  },
};

export const Axios = axios.create({
  baseURL: API_URL,
});

export const ENV = {
  Triage: `chatbot/triage`,
  Paciente: `patient`,
  Foto: "image",
  PdfRME: "rme",
  ProfessionalAcount: "professional/account",
  LoginAdmin: "admin",
  Professional: "professional",
  Rme: "rme",
  AnalizarFotoPicadura: "foto-picadura",
  GenerarPreinforme: "preinforme",
};

export const GenerarConsultaPOST = async (path, request) => {
  let response = await Axios.post(path, request, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("ERROR", error);
      return {
        status: error.response.status,
        data: error.response.data,
      };
    });

  return response;
};

export const GenerarConsultaGET = async (path, request) => {
  let response = await Axios.get(`${path}?rut=${request}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("ERROR", error);

      var data_error = "";
      if (error.response === undefined) {
        data_error = "respuesta no definida";
      } else {
        data_error = error.response.data;
      }
      return {
        status: error.response.status,
        data: data_error,
      };
    });

  return response;
};

export const GenerarConsultaProGet = async (path, request) => {
  let response = await Axios.get(`${path}/${request}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("ERROR", error);

      var data_error = "";
      if (error.response === undefined) {
        data_error = "respuesta no definida";
      } else {
        data_error = error.response.data;
      }
      return {
        status: error.response.status,
        data: data_error,
      };
    });

  return response;
};

export const GenerarConsultaPDFGET = async (path, request) => {
  let response = await Axios.get(`${path}/${request}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("ERROR", error);

      var data_error = "";
      if (error.response === undefined) {
        data_error = "respuesta no definida";
      } else {
        data_error = error.response.data;
      }
      return {
        status: error.response.status,
        data: data_error,
      };
    });

  return response;
};
