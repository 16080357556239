import React, { useEffect, useState } from "react";
import { Box, MenuItem, Paper, Select } from "@material-ui/core";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import FijarPassword from "./FijarPassword";
import _ from "lodash";
import SelectOrganizacion from "../../many/SelectOrganizacion";
import { ServiceGetCurrentUser } from "../../../../api/services";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;
  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const userColumns = [
  { field: "id", headerName: "Username", width: 190 },
  {
    field: "email_verified",
    headerName: "Mail Verificado",
    width: 250,
    editable: false,
  },
  {
    field: "name",
    headerName: "Nombre",
    width: 200,
    editable: false,
  },
  {
    field: "family_name",
    headerName: "Apellido",
    width: 200,
    editable: false,
  },
  {
    field: "phone_number",
    headerName: "Telefono",
    width: 200,
    editable: false,
  },
  {
    field: "phone_number_verified",
    headerName: "Telefono Verificado",
    width: 160,
    flex: 1,
  },
];
const Usuarios = () => {
  const [filasSeleccionadas, setfilasSeleccionadas] = useState([]);
  const [mostrarFijarPassword, setmostrarFijarPassword] = useState(false);
  const [listaElementos, setlistaElementos] = useState([]);
  const [listaElementosActivos, setlistaElementosActivos] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [activeOrganizacion, setActiveOrganizacion] = useState("");

  useEffect(() => {
    ServiceGetCurrentUser().then(({ organizacion, administrador }) => {
      // const newIsUserSuperAdmin =
      //   typeof administradorOrganizacion === "boolean"
      //     ? administradorOrganizacion
      //     : administradorOrganizacion === "true";
      setIsUserAdmin(administrador);
      if (!administrador) {
        setActiveOrganizacion(organizacion);
      }
    });
  }, []);

  useEffect(() => {
    if (!activeOrganizacion) return;
    if (activeOrganizacion === "all") {
      setlistaElementosActivos(listaElementos);
      return;
    }
    setlistaElementosActivos(
      listaElementos.filter(
        ({ Attributes }) =>
          Attributes.find(({ Name }) => Name === "custom:organizacion")
            ?.Value === activeOrganizacion
      )
    );
  }, [activeOrganizacion, listaElementos]);

  const refrescarDatosTabla = () => {
    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/todos"
      )
      .then((res) => {
        const usuarios = res.data.body.authData.Users;

        let resultado = [];
        for (let i = 0; i < usuarios.length; i++) {
          let elemento = usuarios[i];
          elemento["id"] = elemento["Username"];

          for (let j = 0; j < elemento["Attributes"].length; j++) {
            let atributo = elemento["Attributes"][j];

            if (atributo.Name == "email_verified") {
              if (atributo.Value == "true") {
                elemento["email_verified"] = "si";
              } else {
                elemento["email_verified"] = "no";
              }
            }

            if (atributo.Name == "name") {
              elemento["name"] = atributo.Value;
            }

            if (atributo.Name == "family_name") {
              elemento["family_name"] = atributo.Value;
            }

            if (atributo.Name == "phone_number") {
              elemento["phone_number"] = atributo.Value;
            }

            if (atributo.Name == "phone_number_verified") {
              if (atributo.Value == "true") {
                elemento["phone_number_verified"] = "si";
              } else {
                elemento["phone_number_verified"] = "no";
              }
            }
          }

          resultado.push(elemento);
        }
        setlistaElementos(resultado);
        setlistaElementosActivos(resultado);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    refrescarDatosTabla();
  }, []);

  const handlerSeleccionFilas = (e) => {
    setfilasSeleccionadas(e);
  };

  const buscarUsuarioSeleccionado = () => {
    let ids = [];
    let jsonSeleccionados = [];

    for (let j = 0; j < listaElementos.length; j++) {
      let actual = listaElementos[j];

      for (let i = 0; i < filasSeleccionadas.length; i++) {
        if (actual["id"] == filasSeleccionadas[i]) {
          ids.push(actual["id"]);
          jsonSeleccionados.push(actual);
        }
      }
    }
    return [ids, jsonSeleccionados];
  };

  const handlerEliminarUsuario = () => {
    let resultado = buscarUsuarioSeleccionado();

    let ids = resultado[0];

    let json = {
      nombreUsuarioBorrar: ids[0],
    };

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/borrar",
        json
      )
      .then(refrescarDatosTabla);
  };

  const handlerFijarPassword = () => {
    setmostrarFijarPassword(true);
  };

  const aceptarFijarPassword = (nuevoPassword) => {
    let resultado = buscarUsuarioSeleccionado();

    let ids = resultado[0];

    let json = {
      nuevoPassword: nuevoPassword,
      nombreUsuario: ids[0],
    };

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/usuario/fijar-password",
        json
      )
      .then((res) => {
        refrescarDatosTabla();
        setmostrarFijarPassword(false);
      });
  };

  const cancelarFijarPassword = () => {
    setmostrarFijarPassword(false);
  };

  return (
    <Paper>
      <Box p={4}>
        <Box mb={2}>
          {isUserAdmin && (
            <SelectOrganizacion alsoRest onChange={setActiveOrganizacion} />
          )}
          <span> </span>
          <Button
            disabled={filasSeleccionadas.length !== 1}
            onClick={handlerEliminarUsuario}
          >
            Eliminar Usuario
          </Button>
          <span> </span>
          <Button
            disabled={filasSeleccionadas.length !== 1}
            onClick={handlerFijarPassword}
          >
            Fijar Password
          </Button>
          <span> </span>
        </Box>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={listaElementosActivos}
            columns={userColumns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            checkboxSelection
            onSelectionModelChange={handlerSeleccionFilas}
            onError={(err) => console.log("ERR[0]", err)}
          />
        </div>

        <br />
      </Box>

      <FijarPassword
        visible={mostrarFijarPassword}
        handlerAceptar={aceptarFijarPassword}
        handlerCancelar={cancelarFijarPassword}
      />
    </Paper>
  );
};

export default Usuarios;
