import { Button, Paper, Box, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

const Integraciones = () => {
  const [codigoComercio, setCodigoComercio] = useState();
  return (
    <Paper>
      <Box p={4}>
        <Box pl={3} pb={3}>
          <Typography variant="h5">Integraciones</Typography>
        </Box>
        <Box pl={3} pb={1}>
          <Typography variant="h6">Transbank</Typography>
          <TextField
            style={{ width: 500 }}
            id="transbank"
            label="Código de comercio Transbank"
            value={codigoComercio}
            onChange={(v) => setCodigoComercio(v.target.value)}
          />
        </Box>
        <Box pl={3} pt={3}>
          <Button onClick={() => {}}>Guardar</Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Integraciones;
