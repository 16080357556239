import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

export default function Telemedicina(props) {
  const classes = useStyles();
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [listadoCitasZoom, setListadoCitasZomm] = useState([]);

  const constructor = async () => {
    if (constructorHasRun) return;
    try {
      let url = `https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/zoom?rut=${props.paciente.rut}`;
      axios
        .get(url, header)
        .then((res) => {
          console.log("Telemedicina", res);
          if (res.data.statusCode === 200) {
            console.log("url");
            console.log(url);
            console.log("res");
            console.log(res);
            setListadoCitasZomm(res.data.data);
          }
        })
        .catch((err) => {
          console.log("Telemedicina", err);
        });
    } catch (e) {
      console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };

  useEffect(() => constructor(), []);

  const openZoom = (url) => {
    console.log("openZoom", url);
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  return (
    <Paper>
      {/*  <iframe allow="camera; microphone" src={`https://dw670f0b4u6we.cloudfront.net/zoom/${props.paciente.nombre}/${props.paciente.rut}/${2386893551}/${0}/${'yLnX80'}`} style={{width:'78vw', height:'80vh'}}></iframe>*/}
      <List className={classes.root}>
        {listadoCitasZoom.map((elm) => (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt="Remy Sharp"
                  src="https://us04st1.zoom.us/static/5.2.2957/image/new/home/meetings.png"
                />
              </ListItemAvatar>
              <ListItemText
                primary="Consulta Telemedicina"
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {elm.cmvDetail.dataMedico == null
                        ? ""
                        : elm.cmvDetail.dataMedico.firstName +
                          " " +
                          elm.cmvDetail.dataMedico.lastName +
                          (elm.cmvDetail?.dataMedico?.specialities[0]?.name
                            ? " - " +
                              elm.cmvDetail?.dataMedico?.specialities[0]?.name
                            : "")}
                    </Typography>
                    <br />
                    {`Fecha de atencion: ${elm.cmvDetail.diaReserva} - Hora de atencion: ${elm.cmvDetail.horaReserva}`}
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => openZoom(elm.detailZoomMeating.body.join_url)}
                >
                  <VideocamIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </List>
    </Paper>
  );
}
