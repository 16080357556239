import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../../util/forms";
import { Toast } from "./../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import Rut from "./DashboardView/comun/Rut.js";
import Correo from "./DashboardView/comun/Correo.js";
import Telefono from "./DashboardView/comun/Telefono.js";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const RecuperarContrasena = () => {
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const { value: name, bind: bindName } = useInput("");
  const { value: lastName, bind: bindLastName } = useInput("");
  const { value: rut, bind: bindRut } = useInput("");
  const { value: rol, bind: bindRol } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  const [valueRut, setValueRut] = React.useState("");
  const [valueRutValido, setValueRutValido] = React.useState(false);

  const [valueCorreo, setValueCorreo] = React.useState("");
  const [valueCorreoValido, setValueCorreoValido] = React.useState(false);

  const [valueTelefono, setValueTelefono] = React.useState("");
  const [valueTelefonoValido, setValueTelefonoValido] = React.useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      Toast(
        "Error!!",
        "Password and Confirm Password should be same",
        "danger"
      );
      return;
    }

    let nuevoRut = valueRut.replaceAll(".", "");

    try {
      await Auth.signUp({
        username: nuevoRut,
        password: confirmPassword,
        attributes: {
          email: valueCorreo,
          name,
          family_name: lastName,
          phone_number: valueTelefono,
          "custom:organizacion": localStorage.getItem("clinica"),
        },
      });
      Toast("Muy bien!!", "Cuenta creada correctamente", "success");
      history.push("/confirmation");
    } catch (error) {
      console.error(error);
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  const handlerRut = (rutValido, valorRut) => {
    if (rutValido) {
      setValueRutValido(true);
      setValueRut(valorRut);
    } else {
      setValueRutValido(false);
    }
  };

  const handlerCorreo = (correoValido, valorCorreo) => {
    if (correoValido) {
      setValueCorreoValido(true);
      setValueCorreo(valorCorreo);
    } else {
      setValueCorreoValido(false);
    }
  };

  const handlerTelefono = (telefonoValido, valorTelefono) => {
    if (telefonoValido) {
      setValueTelefonoValido(true);
      setValueTelefono(valorTelefono);
    } else {
      setValueTelefonoValido(false);
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onSubmit={handleSignUp}
    >
      <h1 style={{ fontSize: "22px", fontWeight: 800 }}> Crear nueva cuenta</h1>
      <Field label="Nombre" {...bindName} />
      <Field label="Apellidos" {...bindLastName} />
      <Rut value={valueRut} handlerRut={handlerRut}></Rut>
      <Correo value={valueCorreo} handlerCorreo={handlerCorreo}></Correo>
      <Telefono
        value={valueTelefono}
        handlerTelefono={handlerTelefono}
      ></Telefono>

      <Field
        label="Contraseña (Al menos 8 carácteres)"
        type="password"
        {...bindPassword}
      />
      <Field
        label="Confirmar Contraseña"
        type="password"
        {...bindConfirmPassword}
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={
          confirmPassword != password ||
          confirmPassword.length < 8 ||
          password.length < 8 ||
          valueTelefonoValido == false ||
          valueRutValido == false ||
          bindName == "" ||
          bindLastName == ""
        }
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Registrarme
      </Button>
      <DLink to="/signin">Volver al inicio &rarr;</DLink>
    </form>
  );
};

export default RecuperarContrasena;
