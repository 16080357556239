import React from "react";
import Calendar from 'react-calendar';
import {Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import EtiquetaSelect from '../../../DashboardView/comun/EtiquetaSelect.js';
import styled from 'styled-components';
import axios from "axios";

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

const estiloDivModal={
      position: 'absolute',
      top: '5%',
      left: '30%',
      right: '30%',
      bottom: '25%',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px',
}

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const ButtonSlot = styled.button`
  color:  ${props => props.bloqueado ? "#ffffff" : "#3c77e3;"};;
  border: ${props => props.bloqueado ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  text-decoration:  ${props => props.bloqueado ? "line-through" : "none;"};;
  width: 200px;
  height: 60px;
  background-color:  ${props => props.bloqueado ? "#ff6700" : "#f0f0f0"};;
  fontWeight: "normal",
`;

const duraciones = [30,45,60,90];

const alternativasDuracion = duraciones.map(item => (
  <MenuItem key={"alternativas-duracion-"+item} value={item}>{item}</MenuItem>
));

const horasInicio = [];

for (let i = 7; i < 21; i++) {
    horasInicio.push(""+i+":00");
    horasInicio.push(""+i+":15");
    horasInicio.push(""+i+":30");
    horasInicio.push(""+i+":45");
}

const alternativasHoras = horasInicio.map(item => (
  <MenuItem key={"alternativas-horas-"+item} value={item}>{item}</MenuItem>
));

function calcularDuracion(inicio,duracionMinutos){

    let partesInicio=inicio.split(":");

    let hora=parseInt(partesInicio[0]);
    let minutos=parseInt(partesInicio[1]);

    duracionMinutos=parseInt(duracionMinutos);

    let minutosFinPeriodo=(minutos+duracionMinutos)%60;
    let restoHoras=Math.floor((minutos+duracionMinutos)/60);

    let horaFinal=hora+restoHoras;

    if (minutosFinPeriodo<10){
      minutosFinPeriodo="0"+minutosFinPeriodo;
    }

    return ""+horaFinal+":"+minutosFinPeriodo;
}


function callbackDibujoSlot(slot, index, array){
    return <ButtonSlot bloqueado={slot.bloqueado} onClick={()=>this.padre.handlerCambiarEstadoBloque(index)} value={index} key={"agenda-medico-slot-"+index}   >de {slot.inicio} a {slot.fin} </ButtonSlot>
}

class BloquesDeTrabajo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idMedico:props.idMedico,
      open: props.open,
      inicio:null,
      fin:null,
      duracion:null,
      pasoActual:1,
      slots:[],
      diaSeleccionado:parseInt(props.diaSeleccionado),
      mesSeleccionado:parseInt(props.mesSeleccionado),
      anioSeleccionado:parseInt(props.anioSeleccionado),
      precioSeleccionado:null,
      actualizarBloques:false,
      handlerCerrarPopup:props.handlerCerrarPopup,
      alternativasPrecios:null
    };
  }

  handlerCambiarEstadoBloque = (indice) =>{
    let slot=this.state.slots[indice];

    if (slot["bloqueado"]){
      slot["bloqueado"]=false;
    }else{
      slot["bloqueado"]=true;
    }
    this.state.slots[indice]=slot;
    this.setState({actualizarBloques:false});
    this.setState({slots:this.state.slots,actualizarBloques:true});

  }

  handlerDesde = (inicio) =>{
      this.setState({inicio:inicio});
  }

  handlerHasta = (fin) =>{
      this.setState({fin:fin});
  }

  handlerDuracion = (duracion) =>{
      this.setState({duracion:duracion});
  }

  addMinutes =(date, minutes) =>{
    return new Date(date.getTime() + minutes*60000);
  }

  agregarCeros = (i) =>{
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  formatearFecha = (date) =>{
      return this.agregarCeros(date.getHours())+":"+this.agregarCeros(date.getMinutes());
  }

  handlerSiguientePaso1 = () => {
      this.setState({pasoActual:2});

      console.log("######### INICIO #########");
      console.log(this.state.inicio);
      const partes = this.state.inicio.split(":");
      let horaInicio=parseInt(partes[0]);
      let minutoInicio=parseInt(partes[1]);

      const partesFin = this.state.fin.split(":");
      let horaFin=parseInt(partesFin[0]);
      let minutoFin=parseInt(partesFin[1]);

      let dateInicio=new Date(this.state.anioSeleccionado,this.state.mesSeleccionado-1,this.state.diaSeleccionado,horaInicio,minutoInicio);

      let dateFin=new Date(this.state.anioSeleccionado,this.state.mesSeleccionado-1,this.state.diaSeleccionado,horaFin,minutoFin);

      console.log("el inicio!!! "+dateInicio);

      let slots=[];

      for (let i = 0; i<100; i++) {

        let actual=dateInicio;

        actual=this.addMinutes(actual,i*this.state.duracion);
        let finBloque=this.addMinutes(actual,this.state.duracion);

        if (finBloque.getTime()>dateFin.getTime()){
          break;
        }
        let stringDia=this.state.anioSeleccionado+"-"+this.state.mesSeleccionado+"-"+this.state.diaSeleccionado;
        let slot={precio:this.state.precioSeleccionado,inicio:this.formatearFecha(actual),fin:this.formatearFecha(finBloque),duracionMinutos:this.state.duracion,horaTomada:false,dia:stringDia,bloqueado:false};
        slots.push(slot);
      }
      this.setState({slots:slots,actualizarBloques:true});
  }

  handlerAnteriorPaso2 = () => {
    this.setState({slots:[],pasoActual:1});
  }

  guardar = () => {

    let slotEnviar=[];

    for (let i = 0; i < this.state.slots.length; i++) {
      let s=this.state.slots[i];

      if (s.bloqueado){
        continue;
      }
      slotEnviar.push(s);
    }

    axios
      .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/bloques-atencion',  {
         "id_medico":""+this.state.idMedico,
         "disponibilidad":slotEnviar
     },header)
     .then(res=>{
        this.setState({open:false});
        this.props.handlerActualizarCalendario();
     });
  }

  handlerPrecio = (tarifa) =>{
      this.setState({precioSeleccionado:tarifa});
  }


    cargarDatos =  () =>{
      axios
        .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/precios/", header)
        .then((res) =>{

            let elementos=res.data.body;

            let alternativasPrecios=[];

            for (let i=0;i<elementos.length;i++){
              let fila=elementos[i];
              if (fila["id"]=="medicina_general"){
                alternativasPrecios.push(<MenuItem key={"alternativas-precios-"+i} value={fila["id"]}>{"Medicina general"} ${fila["valor"]}</MenuItem>);
               }
              if (fila["id"]=="atencion_urgencia"){
                alternativasPrecios.push(<MenuItem key={"alternativas-precios-"+i} value={fila["id"]}>{"Atención urgencia"} ${fila["valor"]}</MenuItem>);
              }
              if (fila["id"]=="especialista"){
                alternativasPrecios.push(<MenuItem key={"alternativas-precios-"+i} value={fila["id"]}>{"Especialidad"} ${fila["valor"]}</MenuItem>);
              }
            }

            this.setState({alternativasPrecios:alternativasPrecios});

            return res;}
                )
         .catch((err) => console.log(err));
    }

    componentDidMount() {
      this.cargarDatos();
    }

  render(){
    return (
      <Modal
          open={this.state.open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >

          <div style={estiloDivModal}>

          <Stepper activeStep={this.state.pasoActual}>
             <Step key={1}>
               <StepLabel >Definir Bloques</StepLabel>
             </Step>
             <Step key={2}>
               <StepLabel >Revisar Bloques</StepLabel>
             </Step>
          </Stepper>


             {this.state.pasoActual==1 && (
               <Box>
                  <h1 id="simple-modal-title" className="titulo2" >Seleccione el inicio, fin y duración de los bloques de trabajo</h1>

                  <EtiquetaSelect etiqueta="Hora inicio bloque atención" alternativas={alternativasHoras} valor={this.state.inicio} handlerSelect={this.handlerDesde} ></EtiquetaSelect>

                  <EtiquetaSelect etiqueta="Hora final bloque atención" alternativas={alternativasHoras} valor={this.state.fin} handlerSelect={this.handlerHasta} ></EtiquetaSelect>

                  <EtiquetaSelect etiqueta="Duracion bloque en minutos:" alternativas={alternativasDuracion} valor={this.state.duracion} handlerSelect={this.handlerDuracion} ></EtiquetaSelect>

                  {this.state.alternativasPrecios!=null &&this.state.alternativasPrecios.length>0 &&(
                    <EtiquetaSelect etiqueta="Precio:" alternativas={this.state.alternativasPrecios} valor={this.state.precioSeleccionado} handlerSelect={this.handlerPrecio} ></EtiquetaSelect>
                  )}
                   <br/>
                   <br/>

                   <Button onClick={this.handlerSiguientePaso1} disabled={this.state.inicio==null || this.state.fin==null || this.state.duracion==null} >
                     Siguiente
                   </Button>
                 </Box>

               )}

               {this.state.pasoActual==2 && (
                  <Box>

                    <h1 id="titulo-paso-2" className="titulo2" >Para eliminar un bloque haga click sobre ellos. Los bloques desactivados se ven en naranja.</h1>


                    { this.state.actualizarBloques &&(
                      <span>
                        {this.state.slots.map(callbackDibujoSlot,{padre:this})}
                      </span>
                    )}

                    <br/>
                    <br/>

                    <Button onClick={this.handlerAnteriorPaso2}  >
                      Anterior
                    </Button>

                    <span>  </span>

                    <Button onClick={this.guardar}>
                      Guardar
                    </Button>

                  </Box>
               )}

            <span> </span>


          </div>
       </Modal>
    );
  }
};

export default BloquesDeTrabajo;
