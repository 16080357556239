import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, TextField } from '@material-ui/core';
import { ServiceActualizaPaciente } from '../../../../api/services';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Autocomplete from '@material-ui/lab/Autocomplete';

const medicamentos= [
  "ABACAVIR / LAMIVUDINA 600 mg/300 mg",
  "ABACAVIR 300 mg",
  "ACICLOVIR 250 mg/10mL ",
  "ACICLOVIR 400 mg ",
  "ACICLOVIR 500 mg/10mL",
  "ACICLOVIR 800 MG",
  "ACIDO  ACETILSALICILICO  100 mg",
  "ACIDO  ACETILSALICILICO 500 mg",
  "ACIDO FOLICO 1 mg",
  "ACIDO FOLICO 5 mg",
  "ACIDO FOLÍNICO10 mg",
  "ACIDO TRANEXAMICO 1000mg/10mL",
  "ACIDO VALPROICO 200 mg",
  "ACIDO VALPROICO 250 MG",
  "ACIDO VALPROICO 500 mg/ 5 mL",
  "AGUA BIDESTILADA ESTERILGENERAL 5 mL Y 10 mL",
  "ALBENDAZOL 200 MG o 400 mg",
  "ALBUMINA 20% Fco 50 mL",
  "ALCOHOL 70°",
  "ALCOHOL 95°",
  "ALCOHOL GEL70°",
  "ALOPURINOL 100 mg",
  "ALOPURINOL 300 mg",
  "ALPRAZOLAM 0,5 mg",
  "ALUMINIO HIDROXIDO 6%",
  "AMIKACINA 500mg",
  "AMINOFILINA 100mg",
  "AMINOFILINA 250 mg/ 10 mL",
  "AMIODARONA 150 mg/3 mL",
  "AMIODARONA 200 mg",
  "AMITRIPTILINA 25 mg",
  "AMOXIC. + AC.CLAV.  875/125 ",
  "AMOXICILINA 500 mg",
  "AMPICILINA + SULBACTAM (UNASYNA ) 1g/0,5 g",
  "AMPICILINA 500 mg o 1 gr",
  "ANFOTERICINA B  50 mg",
  "ANTIESPASMODICO GENERAL",
  "ANTIGRIPAL General",
  "ATAZANAVIR  200 mg",
  "ATAZANAVIR  300 mg",
  "ATENOLOL  50 MG",
  "ATORVASTATINA 10 mg",
  "ATORVASTATINA 20 mg",
  "ATRACURIO BESILATO 25 mg/2,5mL",
  "ATROPINA 1,0 MG/1 mL",
  "AZATRIOPINA  50 mg",
  "AZITROMICINA 500 mg",
  "BENZATINA BENCILPENICILINA  1.200.000 UI",
  "BETAMETASONA  4 mg/1 mL",
  "BETAMETASONA 0,05%",
  "BICARBONATO de SODIO  1 GR",
  "BICARBONATO de SODIO  1/6 M",
  "BICARBONATO de SODIO  2/3 M",
  "BICARBONATO de SODIO  8,4%",
  "BROMEXINA 8mg/5mL",
  "BUDESONIDA INHALADOR  200 mcg/dosis",
  "CALCIO CARBONATO  1 gr",
  "CALCIO GLUCONATO  10%",
  "CAPTOPRIL  25 mg",
  "CARBAMAZEPINA 200 mg",
  "CARVEDILOL  12,5 MG",
  "CARVEDILOL  6,25 MG",
  "CEFADROXILO  500 mg",
  "CEFAZOLINA 1 gr",
  "CEFEPIMA 1 gr ",
  "CEFEPIMA 2 gr",
  "CEFPODOXIMA  200 mg",
  "CEFTRIAXONA 1 gr",
  "CIANOCOBALAMINA VIT  B12 100 mcg/mL",
  "CIPROFLOXACINO 200mg/100mL",
  "CIPROFLOXACINO 500 mg",
  "CLARITROMICINA 500 mg",
  "CLINDAMICINA  600 mg/ 4mL",
  "CLINDAMICINA 300 mg",
  "CLONAZEPAM  2 mg",
  "CLONIXINATO de LISINA 100 mg/2mL",
  "CLONIXINATO de LISINA 125 mg",
  "CLORANFENICOL   0.5 %",
  "CLORANFENICOL  1%",
  "CLORFENAMINA  10 mg/1mL",
  "CLORFENAMINA 4 mg",
  "CLORHEXIDINA  2%",
  "CLORPROMAZINA  25 mg",
  "CLORPROMAZINA 25 mg/2mL",
  "CLOTRIMAZOL  1%",
  "CLOXACILINA 500 mg",
  "CLOXACILINA 500 mg",
  "Codeína Fosfato (CODETOL®)10 mg",
  "COLISTINA 4.500.000 UI",
  "COTRIMOXAZOL (Suspension Adulto)",
  "COTRIMOXAZOL (Suspension Pediatrica)",
  "COTRIMOXAZOL (Trimetoprima 80 mg; Sulfametoxazol 400 mg/5mL)",
  "COTRIMOXAZOL FORTE (Trimetoprima 160 mg; Sulfametoxazol 800 mg)",
  "DAPSONA 100 mg",
  "DARUNAVIR  600 mg",
  "DEXAMETASONA 4 mg/mL",
  "DIAZEPAM  10 mg",
  "DIAZEPAM  10 mg/mL",
  "DICLOFENACO 50 mg",
  "DICLOFENACO 75 mg",
  "DIDANOSINA  250 mg",
  "DIDANOSINA  400 mg",
  "DIGOXINA  0,25 mg",
  "DOBUTAMINA 250 mg/5mL",
  "DOMPERIDONA 10 mg",
  "DOMPERIDONA 10mg/2mL",
  "DOPAMINA 200 mg/5ml",
  "DOSIS FIJAS TBC DIARIA",
  "DOSIS FIJAS TBC TRISEMANAL",
  "DOXICILINA CP  100 mg",
  "DOXORUBICINA  LIPOSOMAL  20mg/10mL",
  "EFAVIRENZ  600 mg",
  "EMTRICITABINA/TENOFOVIR  200 mg/300 mg",
  "ENALAPRIL 10 mg",
  "EPINEFRINA CLORHIDRATO  1 mg/mL",
  "ERTAPENEM 1 gr",
  "ESPIRONOLACTONA  25 mg",
  "ESTREPTOMICINA 1 gr",
  "ETAMBUTOL 200 mg",
  "ETRAVIRINA  200 mg",
  "FENITOINA 100 mg",
  "FENITOINA 250 mg/5mL",
  "FENOBARBITAL  100 mg",
  "FENOBARBITAL  200 mg/2mL",
  "FENOTEROL Bromhidrato/Bromuro IPRATROPIO  50 mg/20 mg por mL",
  "FENTANILO  0.5 mg/10 mL",
  "FERROSO SULFATO 200 mg",
  "FILGRASTIM 300 mcg/mL",
  "FITOMENADIONA (VITAMINA K) 10 mg/mL",
  "FITOMENADIONA (VITAMINA K) 10mg/mL",
  "FLUCONAZOL 150 mg",
  "FLUCONAZOL 200 mg",
  "FLUCONAZOL 200 mg/100ml",
  "FLUMAZEMIL  0,5mg/5mL",
  "FLUOXETINA 20 mg",
  "FOSAMPRENAVIR  700 mg",
  "FOSFATO MONOPOTASICO 0,15",
  "FOSFATO SOLUCION RECTAL ADULTO  GENERAL",
  "FOSFATOS  GENERAL",
  "FUROSEMIDA  40 mg",
  "FUROSEMIDA 20 mg/1mL",
  "GABAPENTINA 400 mg",
  "GANCICLOVIR 500 mg",
  "GEMFIBROZILO 600 mg",
  "GENTAMICINA  0,30%",
  "GENTAMICINA  80 mg/2mL",
  "GENTAMICINA 0,30%",
  "GLIBENCLAMIDA 5 mg",
  "GLUCOSA 10%",
  "GLUCOSA 20%",
  "GLUCOSA 30%",
  "GLUCOSA 5%",
  "GLUCOSALINO 5%",
  "GLUTAMINA",
  "HALOPERIDOL  1 mg",
  "HALOPERIDOL  5 mg",
  "HALOPERIDOL  5 mg/mL",
  "HEPARINA 25.000 UI/5 mL",
  "HEPARINA BAJO PESO MOLECULAR DALTEPARINA 2500 UI",
  "HEPARINA BAJO PESO MOLECULAR DALTEPARINA 5000 UI",
  "HEPARINA BAJO PESO MOLECULAR DALTEPARINA 7500 UI",
  "HIDRALAZINA 50 MG",
  "HIDROCLOROTIAZIDA  50 MG",
  "HIDROCORTISONA 100 mg",
  "HIDROCORTISONA 20 mg",
  "HIDROXIETILALMIDONES 6%",
  "IBUPROFENO 400 mg",
  "IMIPENEN+CILASTINA 500 mg",
  "IMIPRAMINA 25 mg",
  "INSULINA CRISTALINA  100 UI/mL",
  "INSULINA NPH  100 UI/mL",
  "IODIXANOL 270  (MED. CONTRAST)  FCO 50ML",
  "IPRATROPIO BROMURO  200mcg/dosis",
  "ISONIAZIDA 100 mg",
  "ISOSORBIDE 5 MG",
  "ISOSORBIDE Dinitrato  10 mg",
  "ITRACONAZOL 100 mg",
  "IVERMECTINA 6MG/ML",
  "KETOPROFENO .100 mg/2mL",
  "LABETALOL 5MG/ML",
  "LACTULOSA  65 gr/100 mL",
  "LAMIVUDINA  150 mg",
  "LAMIVUDINA/ZIDOVUDINA/ABACAVIR  150MG/300MG/300MG",
  "LANATOSIDO C  0,4 mg/2 mL",
  "LANZOPRAZOL 30 mg",
  "LEVETIRACETAM 100MG/ML",
  "LEVETIRACETAM 500 MG",
  "LEVOFLOXACINA 500 mg",
  "LEVOFLOXACINA 500 mg/100 mL",
  "LEVOFLOXACINA 750 mg",
  "LEVOTIROXINA  100 MCG",
  "LIDOCAINA 2%",
  "LIDOCAINA 4%",
  "LIDOCAINA CLORHIDRATO 2%",
  "LINCOMICINA 500 mg",
  "LINCOMICINA 600 mg",
  "LINEZOLIDA 600 MG ",
  "LINEZOLIDA 600 MG  INY",
  "LOPERAMIDA  2 mg",
  "LOPINAVIR/ RITONAVIR  200 mg/ 50 mg",
  "LORAZEPAM 2 mg",
  "LORAZEPAM 4 mg/2mL",
  "LOSARTAN 50 mg",
  "MAGNESIO SULFATO 25% /5mL",
  "MANITOL  15%",
  "MARAVIROC  150 mg",
  "MARAVIROC  300 mg",
  "MEROPEM  1 gr",
  "MEROPENEM 500 MG",
  "METAMIZOL (SODICO)1 gr/2 mL",
  "METAMIZOL (SODICO)300 mg",
  "METFORMINA  850 mg",
  "METILPREDNISOLONA 1 gr",
  "METILPREDNISOLONA 40 MG",
  "METILPREDNISOLONA 500 mg",
  "METOCLOPRAMIDA 10 mg",
  "METOCLOPRAMIDA 10mg/2mL",
  "METRONIDAZOL  500 mg",
  "METRONIDAZOL  500 mg",
  "METRONIDAZOL  500 mg/100 mL",
  "MIDAZOLAM 15 mg/3mL",
  "MIDAZOLAM 5 mg/1mL",
  "MORFINA 10 mg/mL",
  "N-ACETILCISTEINA  600 mg",
  "NALOXONA  0,4 mg/mL",
  "NAPROXENO 550 mg",
  "NEOSTIGNINA AM  0,5 mg/1mL",
  "NEVIRAPINA  200 mg",
  "NIFEDIPINO  10 mg",
  "NIFEDIPINO  20 mg",
  "NIFURTIMOX  120 mg",
  "NISTATINA 100.000 UI",
  "NISTATINA 500.000 UI",
  "NITRENDIPINO  20 mg",
  "NITROFURANTOINA macrocristales  100 mg",
  "NITROGLICERINA 0,6 mg",
  "NITROGLICERINA 50mg/10 mL",
  "NOREPINEFRINA  4mg/4mL",
  "OMEPRAZOL  20 mg/mL",
  "ONDANSETRON 4mg/2mL",
  "OSELTAMIVIR  75 mg",
  "OXIDO DE ZINC ( PASTA LASSAR)",
  "OXOLAMINA  10 mg/mL",
  "PAPAVERINA  40 mg/mL",
  "PARACETAMOL 1G",
  "PARACETAMOL 500 mg",
  "PARGEVERINA 5 mg/1mL",
  "PARGEVERINA 6 mg/1mL",
  "PENICILINA SÓDICA 1.000.000 UI",
  "PENICILINA SÓDICA 2.000.000 UI",
  "PERMETRINA  1%  (KILNITS)",
  "PERMETRINA 1%  (KILNITS)",
  "PETIDINA  100mg/2 mL",
  "PIPERACILINA/TAZOBACTAM  4g/0,5 g",
  "PIRAZINAMIDA 500 mg",
  "PIRIDOXINA 100 MG",
  "PIRIDOXINA CLORHIDRATO  100 mg/mL",
  "PIRIMETAMINA 25 mg",
  "POLIVITAMINICOS",
  "POTASIO CLORUR  10%",
  "POTASIO CLORURO 600 mg",
  "POTASIO GLUCONATO 31,2%",
  "PREDNISONA 20 mg",
  "PREDNISONA 5 mg",
  "PRIMAQUINA 26,3 MG fosfato (15 mg base)",
  "PROPANOLOL   40 mg",
  "PROPANOLOL 1 MG",
  "PROPARACAINA  0,5 mg/mL",
  "PROPOFOL  1%",
  "QUETIAPINA  25 mg / 100 mg ",
  "RALTEGRAVIR  400 mg",
  "RANITIDINA  50 mg/2 mL",
  "RANITIDINA 150 mg",
  "RANITIDINA 300 mg",
  "RIFAMPICINA 100mg/5 ml",
  "RIFAMPICINA 150 mg",
  "RINGER LACTATO  500 mL",
  "RITONAVIR  100 mg",
  "SACARINA  20 mg",
  "SACCHAROMYCES BOULARDI  250 mg",
  "SALBUTAMOL  100 mcg",
  "SALES REHIDRATANTES (SRO) SO90 meq ",
  "SALMETEROL 25 mcg",
  "SALMETEROL/FLUTICASONA Propionato 25 mcg/250 mcg",
  "SAQUINAVIR  500 mg",
  "SERTRALINA  50 mg",
  "SODIO CLORURO 0,9%",
  "SODIO CLORURO 10%",
  "SUPLEMENTO ALIMENTICIO GENERAL",
  "SUXAMETONIO Succinilcolina Cloruro 100 mg ",
  "TEICOPLANINA 400 mg",
  "TENOFOVIR  300 mg",
  "TIAMINA (VIT  B1)  (100 mg /ml)",
  "TIETILPERAZINA 6,5 mg/1mL",
  "TRAMADOL  100 mg/1mL",
  "TRAMADOL/PARACETAMOL 32,5mg/325 mg",
  "TROPICAMIDA 1%",
  "VACUNAS ANTINEUMOCÓCICAS",
  "VALGANCICLOVIR  450 MG",
  "VANCOMICINA  500 mg o 1 GR",
  "VASELINA AZUFRADA 6%",
  "VASELINA LIQUIDA Esteril",
  "VASELINA LIQUIDA MEDICINAL",
  "VIT B1+B6+B12 100 mg/100 mg/10000mcg",
  "VORICONAZOL 200 mg",
  "VORICONAZOL 200 mg/20 mL",
  "ZIDOVUDINA  10 mg / ml ",
  "ZIDOVUDINA  10 mg/ ml ",
  "ZIDOVUDINA  300 mg",
  "ZIDOVUDINA/ LAMIVUDINA  300mg/150mg",
  "DFC1 (RIFAMPICINA/ISONIAZIDA/PIRAZINAMIDA/ETAMBUTOL)",
  "DFC2 (RIFAMPICINA/ISONIAZIDA)"
]

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        minWidth: 200,
    },
    title: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
        maxWidth: 600,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function Medicamentos(props) {
    const classes = useStyles();
    const [dataMedicamentos, setDataMedicamentos] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const [valueMedicamento, setValueMedicamento] = React.useState("");

    const constructor = async () => {
        if (constructorHasRun) return;
        try {
            let medicamentos=[];

            if (typeof props.paciente.medicamentos != "undefined") {
              medicamentos=props.paciente.medicamentos;
            }

            setDataMedicamentos(medicamentos);
        } catch (e) {
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    useEffect(() => constructor(), []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValueMedicamento("");
        setOpen(false);
    };

    const actualizarDatosDB = async (nuevosMedicamentos) => {
        let paciente = props.paciente;
        paciente.medicamentos=nuevosMedicamentos;

        let response = await ServiceActualizaPaciente(paciente);
        props.setData(response, 1);
    }

    const actualizarDatos = async () => {
        if (valueMedicamento) {
            setDataMedicamentos([...dataMedicamentos, valueMedicamento])
            let nuevosMedicamentos= [...dataMedicamentos, valueMedicamento];
            await actualizarDatosDB(nuevosMedicamentos);
        }
        handleClose();
    }

    const deleteItem = async (elm) => {
        let newdataMedicamentos = dataMedicamentos.filter(function (item) {
            return item !== elm;
        });
        setDataMedicamentos(newdataMedicamentos);

        let nuevosMedicamentos = [...newdataMedicamentos];
        await actualizarDatosDB(nuevosMedicamentos);
    }

    return (
        <Paper>
            <List component="nav" className={classes.root} aria-label="contacts">
                {
                    dataMedicamentos.map((elm, index) =>
                        <>
                            <ListItem button key={index}>
                                <ListItemIcon>
                                    <LocalHospitalIcon />
                                </ListItemIcon>
                                <ListItemText primary={elm} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => { deleteItem(elm) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                }

            </List>
            <br />
            {
                dataMedicamentos.length > 7 ? <></> : <Button variant="contained" color="primary" fullWidth onClick={handleOpen}>AGREGAR</Button>
            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Agregar medicamento</h2>

                        <Autocomplete
                            options={medicamentos}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            value={valueMedicamento}
                            onChange={(e,newValue) => setValueMedicamento(newValue)}
                            renderInput={(params) => <TextField {...params} label="Escriba el nombre del medicamento" variant="outlined" />}
                          />


                        <br />
                        <div style={{ marginTop: "12px" }}>
                            <Button variant="contained" color="primary" fullWidth disabled={!valueMedicamento != ""} onClick={actualizarDatos}>Agregar</Button>
                            <Button style={{ marginTop: "8px" }} variant="contained" fullWidth onClick={handleClose}>Cancelar</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Paper>
    )
}

/*<!--      <TextField style={{ width: "100%", maxWidth: "100%" }} id="outlined-basic" label="Escribir medicamento" variant="outlined" value={valueMedicamento} onChange={(e) => setValueMedicamento(e.target.value)} />
-->*/
