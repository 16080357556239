import React from "react";
import {
  Modal,
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DataGrid } from "@material-ui/data-grid";
import styled, { ThemeProvider } from "styled-components";
import "./AgendaMedico.css";
import Rut from "../../../DashboardView/comun/Rut.js";
import Correo from "../../../DashboardView/comun/Correo.js";
import SelectorFecha from "../../../DashboardView/comun/SelectorFecha.js";
import EtiquetaSelect from "../../../DashboardView/comun/EtiquetaSelect.js";
import { ServiceActualizaPaciente } from "../../../../../api/services";
import EtiquetaTextField from "../../../DashboardView/comun/EtiquetaTextField.js";
import MenuItem from "@material-ui/core/MenuItem";
import Calendar from "react-calendar";
import CalendarioHorasMedicas from "./CalendarioHorasMedicas";

const estiloDivModal = {
  position: "absolute",
  top: "5%",
  left: "30%",
  right: "30%",
  bottom: "5%",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "4px",
  outline: "none",
  padding: "40px",
};

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const columns = [
  { field: "id", headerName: "Rut", width: 150 },
  {
    field: "primerNombre",
    headerName: "Primer Nombre",
    width: 200,
    editable: false,
  },
  {
    field: "segundoNombre",
    headerName: "Segundo Nombre",
    width: 200,
    editable: false,
  },
  {
    field: "apellidoPaterno",
    headerName: "Paterno",
    width: 200,
    editable: false,
  },
  {
    field: "apellidoMaterno",
    headerName: "Materno",
    width: 200,
    editable: false,
  },
  {
    field: "relacion",
    headerName: "Relación",
    width: 160,
    flex: 1,
  },
];

class AgendaMedico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <Box p={4}>
          <CalendarioHorasMedicas />
        </Box>
      </Paper>
    );
  }
}

export default AgendaMedico;
