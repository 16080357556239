import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

import Card from "@material-ui/core/Card";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import Signup from "./Signup";
import SignupMedico from "./SignupMedico";
import Asistente from "./Asistente";
import Confirmation from "./Confirmation";
import LoginMultiple from "./LoginMultiple";
import Login from "./Login";
import { COGNITO } from "./../../configs/aws";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./Dashboard";
import VerificaPDF from "./VerificaPDF";
import ProfessionalDashboard from "./professional/ProfessionalDashboard";
import AdminControlPanel from "./adminControlPanel/AdminControlPanel";
import LoginMed from "./LoginMed";
import LoginAdmin from "./LoginAdmin";
import RecuperarContrasena from "./RecuperarContrasena";
import AgendamientoTest from "./testFolder/AgendamientoTest";
import Slide from "@material-ui/core/Slide";
import "typeface-roboto";
import { useHistory } from "react-router-dom";
import ClinicaInitRoute from "../../pages/ClinicaInitRoute";
import SignupClinica from "./SignupClinica";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const WebAppMultiple = () => {
  const [userPro, setUserPro] = useState(null);
  const history = useHistory();

  return (
    <Switch>
      <Route path="/clinica/:clinica">
        <Card
          style={{
            width: 500,
            height: 500,
            margin: "100px auto",
            padding: "40px",
          }}
        >
          <ClinicaInitRoute />
        </Card>
      </Route>
      <Route path="/recuperarContrasena">
        <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
          <RecuperarContrasena />
        </Card>
      </Route>
      <Route path="/registroMedico">
        <Card style={{ width: 800, margin: "100px auto", padding: "40px" }}>
          <SignupMedico />
        </Card>
      </Route>
      <Route path="/registroClinica">
        <Card style={{ width: 800, margin: "100px auto", padding: "40px" }}>
          <SignupClinica />
        </Card>
      </Route>
      <Route path="/signup">
        <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
          <Signup />
        </Card>
      </Route>
      <Route path="/signinNormal">
        <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
          <Login />
        </Card>
      </Route>
      <Route path="/asistente">
        <Asistente />
      </Route>
      <Route path="/signin">
        <LoginMultiple />
      </Route>
      <Route path="/signinPro">
        <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
          <LoginMed setUserPro={setUserPro} />
        </Card>
      </Route>

      <Route path="/loginAdmin">
        <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
          <LoginAdmin setUserPro={setUserPro} />
        </Card>
      </Route>

      <Route path="/confirmation">
        <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
          <Confirmation />
        </Card>
      </Route>
      <Route
        path="/verificarPdf/:id"
        render={(props) => <VerificaPDF {...props} />}
      />

      <Route path="/professional/:professionalId?/(patient)?/:patientId?">
        <ProtectedRoute component={ProfessionalDashboard} />
      </Route>

      <Route path="/admin/:adminId">
        <ProtectedRoute component={AdminControlPanel} />
      </Route>

      <Route path="/patient">
        <Switch>
          <Route path="/">
            <ProtectedRoute component={Dashboard} />
          </Route>
        </Switch>
      </Route>
      <Route path="/testTransbank">
        <AgendamientoTest />
      </Route>
      <Route path="/">
        <ProtectedRoute component={Dashboard} />
      </Route>
    </Switch>
  );
};

export default WebAppMultiple;
