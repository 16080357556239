import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../../util/forms";
import { Toast } from "./../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import fondo from '../../assets/fondo2.png';
import logo_kuery from '../../assets/logo_kuery_medio.png';
import { Modal } from "@material-ui/core";
import EvaluacionRemota from "./DashboardView/EvaluacionRemota";

import CornerRibbon from "react-corner-ribbon";

// LOGOS COLABORADORES
import BARBAS from '../../assets/colaboradores/2BARBAS.png';
import ASECH from '../../assets/colaboradores/ASECH.png';
import CORFO from '../../assets/colaboradores/Corfo.png';
import TTEXIS from '../../assets/colaboradores/cyberdoc.png';
import TEXIS2 from '../../assets/colaboradores/cyberdoc.png';
import Rut from './DashboardView/comun/Rut.js';

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

const Signup = () => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [nuevoRut, setNuevoRut] = React.useState("");

  const history = useHistory();

  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      let rutSinPuntos=nuevoRut.replaceAll(".","");


      await Auth.signIn(rutSinPuntos, password);
      console.log(Auth.user);
      localStorage.setItem('rut', Auth.user.username);

      

      Toast("Muy bien!!", "Has ingresado correctamente", "success");
      history.push("/");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  document.body.style = 'background-image: url("'+ fondo +'");background-repeat: no-repeat; background-size: cover;resize: both;';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlerRut = (rutValido,valorRut) =>{
    if (rutValido){
      setNuevoRut(valorRut);
    }else{
      setNuevoRut("");
    }
  }

  useEffect(() => {
    setTimeout(()=>{
      setOpen(true)
    }, 3000)
  }, [])

  return (
    <>

      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          /*fontSize:  "1.5rem",
          justifyContent: "right"
          */
        }}
        onSubmit={handleSubmit}
      >

      <CornerRibbon
          position="top-left" // OPTIONAL, default as "top-right"
          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
          backgroundColor="#EE8E4A" // OPTIONAL, default as "#2c7"
          containerStyle={{}} // OPTIONAL, style of the ribbon
          style={{}} // OPTIONAL, style of ribbon content
          className="" // OPTIONAL, css class of ribbon
        >
          Beta
        </CornerRibbon>

        <img src={logo_kuery} style={{ width: "150px",margin:"auto" }}  />
        <h1 style={{ fontSize: "1.5rem", fontWeight: 800 }}>
          {" "}
          Ingresa a tu cuenta de paciente
        </h1>

        <Rut value={nuevoRut} handlerRut={handlerRut} ></Rut>

        <Field label="Contraseña" type="password" {...bindPassword} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
          Ingresar
        </Button>
        <DLink to="/signup">Crear cuenta &rarr;</DLink>
        <DLink to="/recuperarContrasena">Recuperar Contraseña&rarr;</DLink>

        <>


        </>
      </form>
        <div style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          maxHeight: "18vh",
          backgroundColor: "#ffffff9e",
          padding:"1vh",
          textAlign: "center",
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
        }}>
          <table>
        <thead>
          <tr>
            <th><img src={CORFO} style={{height:"12vh"}}/></th>
            <th><img src={TEXIS2} style={{width:"18vh"}}/></th>
            <th><img src={ASECH} style={{height:"5vh"}}/></th>
            <th><img src={BARBAS} style={{height:"7vh"}}/></th>
          </tr>
        </thead>
        </table>
      </div>
    </>
  );
};

export default Signup;

/*

  <DLink to="/signinPro">Cuenta Profesionales &rarr;</DLink>
--popup de atencion remota
<Modal style={{ width: 600,height:150,  margin: "40px" }}
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
  <EvaluacionRemota  width="50%"/>
</Modal>

*/
