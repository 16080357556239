import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Toast } from "../../util/notifications";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Atenciones from "./DashboardView/Atenciones";
import Recetas from "./DashboardView/Recetas";
import Ordenes from "./DashboardView/Ordenes";
import MainFicha from "./DashboardView/ficha/MainFicha";
import VideocamIcon from "@material-ui/icons/Videocam";
import ChatIcon from "@material-ui/icons/Chat";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import VerificarFoto from "./DashboardView/VerificaFoto";
import EvaluacionRemota from "./DashboardView/EvaluacionRemota";
import VideoChat from "./DashboardView/VideoChat";
import Triage from "./DashboardView/Triage";
import Telemedicina from "./DashboardView/Telemedicina";
import Agendamiento from "./DashboardView/Agendamiento";
import EventIcon from "@material-ui/icons/Event";
import Cargas from "./DashboardView/cargas/Cargas";
import Comprobante from "./DashboardView/Comprobante";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import CambiarContrasena from "./CambiarContrasena";
import RecetaHistorica from "./DashboardView/recetaHistorica/RecetaHistorica";
import { useGetLogoURL } from "../../util/logo";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#286db2",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 300,
    maxWidth: 600,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ClippedDrawer(props) {
  const classes = useStyles();
  const history = useHistory();
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [screen, setScreen] = useState("Ficha");
  const [patient, setPatient] = useState({});
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Quiere salir del sistema?")) {
        setfinishStatus(true);
        Auth.signOut();
        history.push("/signin");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const constructor = async () => {
    if (constructorHasRun) return;
    try {
      if (props.location.pathname.includes("/pago/comprobante/")) {
        setScreen("Comprobante");
      }
      console.log(history);
    } catch (e) {
      console.log("ERR:", e);
    }
    setConstructorHasRun(true);
  };

  const actualizarPaciente = (newData) => {
    console.log("actualizarPaciente", newData);
    setPatient(newData);
  };
  useEffect(() => constructor(), []);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      Toast("Cerrar Sesion", "Sesion finalizada", "success");
      history.push("/signin");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
  };

  const changeScreen = (view) => {
    /*  history.push("");*/
    setScreen(view);
  };

  document.body.style = "background-image: none;";

  const logoUrl = useGetLogoURL();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ paddingLeft: "0px" }}>
          <img style={{ width: 300, height: 60 }} src={logoUrl} alt="logo" />
          <Typography variant="h6" noWrap className={classes.title}>
            Portal del paciente
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem button onClick={() => changeScreen("Ficha")}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary={"Mi perfil"} />
            </ListItem>

            <ListItem button onClick={() => changeScreen("historialRecetas")}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Historial Recetas"} />
            </ListItem>

            <ListItem button onClick={() => changeScreen("Agendamiento")}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={"Agendamiento"} />
            </ListItem>

            <ListItem button onClick={() => changeScreen("Cargas")}>
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary={"Cargas Familiares"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={() => changeScreen("video")}>
              <ListItemIcon>
                <VideocamIcon />
              </ListItemIcon>
              <ListItemText primary={"Teleconsulta médica"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("EvaluacionRemota")}>
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary={"Pre-consulta automatica"} />
            </ListItem>
            <ListItem button onClick={() => changeScreen("piel")}>
              <ListItemIcon>
                <ImageSearchIcon />
              </ListItemIcon>
              <ListItemText primary={"Evaluador de picaduras"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={() => changeScreen("cambiarContrasena")}>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText primary={"Cambiar Contraseña"} />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {screen === "historialRecetas" ? (
          <RecetaHistorica />
        ) : screen === "cambiarContrasena" ? (
          <CambiarContrasena />
        ) : screen === "Comprobante" ? (
          <Comprobante pathvalue={props.location.pathname} />
        ) : screen === "Cargas" ? (
          <Cargas />
        ) : screen === "Agendamiento" ? (
          <Agendamiento />
        ) : screen === "Ficha" ? (
          <MainFicha actualizarPaciente={actualizarPaciente} />
        ) : screen === "Recetas" ? (
          <Recetas paciente={patient} />
        ) : screen === "Atenciones" ? (
          <Atenciones paciente={patient} />
        ) : screen === "Ordenes" ? (
          <Ordenes paciente={patient} />
        ) : screen === "piel" ? (
          <VerificarFoto paciente={patient} />
        ) : screen === "EvaluacionRemota" ? (
          <EvaluacionRemota paciente={patient} />
        ) : screen === "triage" ? (
          <Triage paciente={patient} />
        ) : screen === "video" ? (
          <>
            <Telemedicina paciente={patient} />
            {/** <VideoChat/> */}
          </>
        ) : (
          <>.</>
        )}
      </main>
    </div>
  );
}

/*    <ListItem button onClick={() => changeScreen('Atenciones')}>
      <ListItemIcon><AssignmentIcon /></ListItemIcon>
      <ListItemText primary={'Historial Atenciones'} />
    </ListItem>
*/
