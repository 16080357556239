import React from "react";
import { Container, Box, Divider,Grid,Button,TextField} from '@material-ui/core';
import axios from "axios";

class DatosPaciente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      datosCargados: false,
      listaDatos: [],
      rutPaciente: this.props.rutPaciente,
      handlerRutPaciente: this.props.handlerRutPaciente
    };

  }
  handleChange = (event) => {
        this.setState({
          rutPaciente: event.target.value,
        });


      /*  console.log("fijando: "+ event.target.value);*/
      };

  render() {
    const { error, datosCargados, listaDatos,rutPaciente } = this.state;

    return (
        <Container maxWidth="lg">
          <Box pb={3}/>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <h1>Rut paciente: </h1>
            </Grid >
            <Grid item xs={5}>
                <TextField id="rutPaciente" label="1234567-8" value={rutPaciente}  onChange={this.handleChange}/>
            </Grid >
          </Grid>

        </Container>
    );
  }
}

export default DatosPaciente;
