import React, { useEffect, useState } from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper, Grid, Box, TextField, Container } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TarjetaReceta from "./TarjetaReceta";
import {
  ServiceCrearRME,
  ServiceGetOrganizaciones,
} from "../../../../../api/services";
import SweetAlert from "sweetalert2-react";
import BusquedaMedicamento from "./BusquedaMedicamento";
import BusquedaMedicamentoLocal from "./BusquedaMedicamentoLocal";
import CabeceraTabla from "./CabeceraTabla";
import FilaTabla from "./FilaTabla";
import AgregarMedicamento from "./AgregarMedicamento";
import Rut from "./../../../DashboardView/comun/Rut.js";
import axios from "axios";
import styled, { ThemeProvider } from "styled-components";

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function generadorFilas(fila, index, array) {
  let valorEstilo = "#dae2f4";

  if (index % 2 == 1) {
    valorEstilo = "#eeeeee";
  }

  console.log(index);
  console.log(valorEstilo);

  return (
    <FilaTabla
      estilo={valorEstilo}
      indice={index}
      fila={fila}
      handlerEliminarMedicamento={this.padre.handlerEliminarMedicamento}
      handlerMostrarPanelEjecutarPlanDeAccion={
        this.padre.handlerMostrarPanelEjecutarPlanDeAccion
      }
      handlerMostrarPanelVerificarPlanDeAccion={
        this.padre.handlerMostrarPanelVerificarPlanDeAccion
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 300,
    maxWidth: 600,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "38ch",
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function callbackTarjetaReceta(medicamento, index, array) {
  console.log("el medicamento:");
  console.log(medicamento);

  return (
    <TarjetaReceta
      index={index}
      key={index}
      medicamento={medicamento}
      updateItem={updateMedicamento}
      padre={this.padre}
    />
  );
}

class Recetas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rutPaciente: "",
      emailPaciente: "",
      rutValido: false,
      medicamentosEnReceta: [],
      nuevoMedicamento: false,
      urlReceta: "",
      nombresPaciente: "",
      direccionPaciente: "",
      apellidosPaciente: "",
      edadPaciente: "",
      mostrarPanelAgregar: false,
      observaciones: "",
    };
  }

  handlerRutPaciente = (rutValido, valorRut) => {
    if (rutValido) {
      this.setState({ rutValido: true });
      this.setState({ rutPaciente: valorRut });

      let rutBuscar = valorRut.replaceAll(".", "");
      axios
        .get(
          "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/patient?rut=" +
            rutBuscar,
          header
        )
        .then((res) => {
          console.log(res);
          let data = res["data"];
          let body = data["body"];
          data = body["data"];

          let fechaNacimientoString = data["fechaNacimiento"];

          let partes = fechaNacimientoString.split("-");
          let fechaNacimiento = new Date(
            parseInt(partes[0]),
            parseInt(partes[1]),
            parseInt(partes[2])
          );

          let diff_ms = Date.now() - fechaNacimiento.getTime();
          let age_dt = new Date(diff_ms);

          let edad = Math.abs(age_dt.getUTCFullYear() - 1970);

          let partesNombre = data["nombre"].split(" ");

          let nombres = "";
          let apellidos = "";

          console.log("partes");
          console.log(partesNombre.length);

          if (partesNombre.length == 2) {
            nombres = partesNombre[0];
            apellidos = partesNombre[1];
          } else if (partesNombre.length == 4) {
            nombres = partesNombre[0] + " " + partesNombre[1];
            apellidos = partesNombre[2] + " " + partesNombre[3];
          } else if (partesNombre.length == 5) {
            nombres =
              partesNombre[0] + " " + partesNombre[1] + " " + partesNombre[2];
            apellidos = partesNombre[3] + " " + partesNombre[4];
          } else {
            nombres = data["nombre"];
          }

          let direccion = data["direccion"];

          this.setState({
            direccion: direccion,
            nombresPaciente: nombres,
            apellidosPaciente: apellidos,
            emailPaciente: data["email"],
            edadPaciente: edad,
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ rutValido: false });
    }
  };

  handlerNombresPaciente = (e) => {
    let nombre = e.target.value;
    this.setState({ nombresPaciente: nombre });
  };

  handlerApellidosPaciente = (e) => {
    let apellidos = e.target.value;
    this.setState({ apellidosPaciente: apellidos });
  };

  handlerDireccion = (e) => {
    let direccion = e.target.value;
    this.setState({ direccion: direccion });
  };

  handlerEdadPaciente = (e) => {
    let edad = e.target.value;
    this.setState({ edadPaciente: edad });
  };

  handlerEmailPaciente = (e) => {
    let emailPaciente = e.target.value;
    this.setState({ emailPaciente: emailPaciente });
  };

  handlerAceptarAgregarMedicamento = (e) => {
    /*print("por aca!!!");*/
    this.state.medicamentosEnReceta.push(e);
    this.setState({ mostrarPanelAgregar: false });
  };

  generarIndication = (
    lineaMedicamento,
    cantidadNumero,
    unidad,
    frecuencia,
    unidadFrecuencia,
    duracion,
    unidadDuracion,
    inicioTratamiento
  ) => {
    let indication = {
      medication: {
        snomedId: "322246006",
        id: "MC-54274",
        name: lineaMedicamento,
        composition: {
          medicationFormSelected: {
            desc: "Gotas",
            medicationForm: {
              id: "7084245",
              desc: "Gotas orales",
            },
          },
          administrationVia: {
            id: "4589364",
            desc: "oral",
          },
          medicationForm: [
            {
              id: "7084245",
              administerForm: ["Gotas"],
              desc: "Gotas orales",
            },
          ],
          administerForm: [
            {
              desc: "Gotas",
              medicationForm: {
                id: "7084245",
                desc: "Gotas orales",
              },
            },
          ],
          ingredients: [
            {
              measureUnit: {
                id: "mg/mL",
                desc: "miligramo/mililitro",
              },
              name: "paracetamol",
              snomedId: "387517004",
              measure: "100 mg/mL",
              measureValue: 100,
              id: "S-5166991",
            },
          ],
        },
      },
      units: cantidadNumero,
      unitType: unidad,
      frecuencyType: "Diario",
      frecuency: frecuencia,
      frecuencyUnit: unidadFrecuencia,
      duration: duracion,
      durationUnit: unidadDuracion,
      observation: "",
      commercialRecommendation: null,
      indicationStartDate: inicioTratamiento,
    };

    return indication;
  };

  postGenerarReceta = async (datosMedico) => {
    let id = Date.now();

    let professional = {
      email: datosMedico["email"],
      firstName: datosMedico["firstName"],
      id: datosMedico["id"],
      lastName: datosMedico["lastName"],
      run: datosMedico["run"],
      specialities: [datosMedico["specialities"]],
      firma: datosMedico["firma"],
      observaciones: this.state.observaciones,
    };

    let patient = {
      run: this.state.rutPaciente,
      firstName: this.state.nombresPaciente,
      lastName: this.state.apellidosPaciente,
      age: this.state.edadPaciente,
      email: this.state.emailPaciente,
      genre: "Masculino",
      address: this.state.direccion,
      id: "XXX",
    };

    const organizacion = await ServiceGetOrganizaciones(true);

    let hoy = new Date();

    let anioEnRevision = hoy.getFullYear();
    let mesEnRevision = hoy.getMonth() + 1;
    let diaEnRevision = hoy.getDate();

    let linea = "paracetamol 100 mg/mL gotas orales";
    linea = "aaa";

    let cantidad = 10;
    let unidad = "gotas";
    let frecuencia = 8;
    let unidadFrecuencia = "horas";
    let duracion = 88;
    let unidadDuracion = "dias";

    let indications = [];

    let payload = {
      patient: patient,
      professional: professional,
      appointmentId: null,
      creation_day:
        "" + diaEnRevision + "/" + mesEnRevision + "/" + anioEnRevision,
      id: id,
      preview: false,
      organizacion,
    };

    payload.indications = [];

    for (let i = 0; i < this.state.medicamentosEnReceta.length; i++) {
      console.log("iterantod!!!!!");
      let medicamento = this.state.medicamentosEnReceta[i];

      let linea =
        medicamento.principioActivo +
        " " +
        medicamento.forma +
        " " +
        medicamento.nombreComercial;

      let indication = this.generarIndication(
        linea,
        medicamento.dosis,
        medicamento.unidadDosis,
        medicamento.frecuencia,
        medicamento.unidadFrecuencia,
        medicamento.periodoTratamiento,
        medicamento.unidadPeriodo,
        "06/06/2021"
      );
      payload.indications.push(indication);
    }

    /*indications:this.state.medicamentosEnReceta*/
    let response = await ServiceCrearRME(payload);

    /*let response = ServiceCrearRME(payload);*/

    if (response.code == "200") {
      console.log("por aca!!!");
      this.setState({
        urlReceta: `http://d3d5zvnihsts57.cloudfront.net/verificarPdf/${response.data.id}`,
      });
    }
  };

  handlerGenerarReceta = async () => {
    const datosProfesional = localStorage.getItem("professional");

    axios
      .get(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/professional/" +
          datosProfesional,
        header
      )
      .then((res) => {
        let datos = res.data.body;
        this.postGenerarReceta(datos);
        return res;
      })
      .catch((err) => console.log(err));
  };

  handlerMostrarPanelAgregar = () => {
    this.setState({ mostrarPanelAgregar: true });
  };

  handlerCancelar = () => {
    this.setState({ mostrarPanelAgregar: false });
  };

  handlerVerReceta = (e) => {
    window.open(this.state.urlReceta);
  };

  handlerEliminarMedicamento = (indice) => {
    let medicamentosEnReceta = this.state.medicamentosEnReceta;
    medicamentosEnReceta.splice(indice, 1);
    this.setState({ medicamentosEnReceta: medicamentosEnReceta });
  };

  handlerObservaciones = (e) => {
    this.setState({ observaciones: e.target.value });
  };

  render() {
    const { medicamentosEnReceta } = this.state;

    return (
      <Paper>
        <Box p={4}>
          <Container maxWidth="lg">
            <Typography pt={20} ml={10} variant="h6">
              Datos del Paciente
            </Typography>

            <br />

            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Rut del paciente:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Rut
                  value={this.state.rutPaciente}
                  handlerRut={this.handlerRutPaciente}
                ></Rut>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Nombres paciente:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={this.state.nombresPaciente}
                  label=""
                  onChange={this.handlerNombresPaciente}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Apellidos paciente:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={this.state.apellidosPaciente}
                  label=""
                  onChange={this.handlerApellidosPaciente}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Edad:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={this.state.edadPaciente}
                  label=""
                  onChange={this.handlerEdadPaciente}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Dirección
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={this.state.direccion}
                  label=""
                  onChange={this.handlerDireccion}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography pt={20} ml={10} variant="body1">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={this.state.emailPaciente}
                  label=""
                  onChange={this.handlerEmailPaciente}
                ></TextField>
              </Grid>
            </Grid>
            <br />
          </Container>

          <Container maxWidth="lg">
            <Typography pt={20} ml={10} variant="h6">
              Datos de la receta
            </Typography>

            <Box p={4}>
              <Box display="flex" flexDirection="row">
                <Box mr={0.5}>
                  <Button onClick={this.handlerMostrarPanelAgregar}>
                    Agregar Medicamento
                  </Button>
                  <span> </span>
                </Box>
              </Box>

              <br />
              <br />

              <div style={{ height: 300, width: "100%" }}>
                <Box pt={1}>
                  <CabeceraTabla />
                  {this.state.medicamentosEnReceta.map(generadorFilas, {
                    padre: this,
                  })}
                </Box>
              </div>

              <br />
              <br />
            </Box>

            <AgregarMedicamento
              visible={this.state.mostrarPanelAgregar}
              key={"agregar-medicamento"}
              handlerAceptar={this.handlerAceptarAgregarMedicamento}
              handlerCancelar={this.handlerCancelar}
            />

            <Box pb={5}>
              <Typography pt={20} ml={10} variant="h6">
                Observaciones
              </Typography>

              <TextField
                fullWidth
                multiline
                maxRows={4}
                variant="outlined"
                value={this.state.observaciones}
                label={"Observaciones"}
                onChange={this.handlerObservaciones}
              ></TextField>
            </Box>

            <Box display="flex" flexDirection="row">
              <Box pr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    this.state.medicamentosEnReceta.length > 0 ? false : true
                  }
                  onClick={this.handlerGenerarReceta}
                >
                  {" "}
                  Generar receta{" "}
                </Button>
              </Box>
              <Box pr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.urlReceta == ""}
                  onClick={this.handlerVerReceta}
                >
                  {" "}
                  Ver receta{" "}
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Paper>
    );
  }
}

export default Recetas;
