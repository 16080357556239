import React from "react";
import {
  Container,
  Box,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import DatosPaciente from "../../DashboardView/DatosPaciente";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Rut from "../../DashboardView/comun/Rut.js";
import Correo from "../../DashboardView/comun/Correo.js";
import {
  ServiceConsultaPaciente,
  ServiceGetCurrentUser,
} from "../../../../api/services";
import { Toast } from "../../../../util/notifications";
import axios from "axios";

const header = {
  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "access-control-expose-headers": "*",
    "content-type": "application/json",
  },
};

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
});

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

const ButtonGrande = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 270px;
  height: 100px;
  fontWeight: "normal",

  background: #ffffff;
`;

const ButtonSlot = styled.button`
  color: #3c77e3;
  border: ${(props) =>
    props.active ? "3px solid #3c77e3" : "1px solid #3c77e3"};;
  width: 200px;
  height: 60px;
  fontWeight: "normal",

  background: #ffffff;
`;

const BotonCancelar = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background: ${(props) => (props.disabled ? "#9e9e9e" : "#3c77e3")};
`;

class Precios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tarifaGeneral: "",
      especialista: "",
      atencionUrgencia: "",
      isSuperAdmin: false,
    };
  }

  cargarDatos = async () => {
    ServiceGetCurrentUser().then((u) => {
      this.setState({
        isSuperAdmin: u.administrador,
      });
    });
    axios
      .get(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/precios/",
        header
      )
      .then((res) => {
        console.log("los precios!!!!");
        console.log(res.data);
        let elementos = res.data.body;
        console.log("los elementos");
        console.log(elementos);

        for (let i = 0; i < elementos.length; i++) {
          let fila = elementos[i];
          if (fila["id"] == "medicina_general") {
            this.setState({ tarifaGeneral: fila["valor"] });
          }
          if (fila["id"] == "atencion_urgencia") {
            this.setState({ atencionUrgencia: fila["valor"] });
          }
          if (fila["id"] == "especialista") {
            this.setState({ especialista: fila["valor"] });
          }
        }

        return res;
      })
      .catch((err) => console.log(err));
  };

  async componentDidMount() {
    this.cargarDatos();
  }

  handlerCambioTarifaGeneral = (event) => {
    this.setState({
      rutPaciente: event.target.value,
    });
  };

  handlerGuardar = (event) => {
    let jsonPrecios = {
      tarifa_general: "" + this.state.tarifaGeneral,
      atencion_urgencia: "" + this.state.atencionUrgencia,
      especialista: "" + this.state.especialista,
    };

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/precios/actualizar",
        jsonPrecios,
        header
      )
      .then((res) => {
        alert("Precios actualizados");
      });
  };

  handleChangeTarifaGeneral = (event) => {
    const soloNumeros = event.target.value.replace(/[^0-9]/g, "");

    this.setState({
      tarifaGeneral: soloNumeros,
    });
  };

  handleChangeAtencionUrgencia = (event) => {
    const soloNumeros = event.target.value.replace(/[^0-9]/g, "");

    this.setState({
      atencionUrgencia: soloNumeros,
    });
  };

  handleChangeEspecialista = (event) => {
    const soloNumeros = event.target.value.replace(/[^0-9]/g, "");

    this.setState({
      especialista: soloNumeros,
    });
  };

  render() {
    return (
      <Paper>
        <Box pr={4} pb={4}>
          <Box pl={3} pb={3}>
            <Typography variant="h6">
              Precios {this.state.isSuperAdmin ? "de referencia" : ""}
            </Typography>
          </Box>

          <Box pl={3} pb={1}>
            <TextField
              id="tarifaGeneral"
              label="Medicina General"
              value={this.state.tarifaGeneral}
              onChange={this.handleChangeTarifaGeneral}
            />
          </Box>

          <Box pl={3} pb={1}>
            <TextField
              id="atencionUrgencia"
              label="Atención Urgencia"
              value={this.state.atencionUrgencia}
              onChange={this.handleChangeAtencionUrgencia}
            />
          </Box>

          <Box pl={3} pb={1}>
            <TextField
              id="especialista"
              label="Especialista"
              value={this.state.especialista}
              onChange={this.handleChangeEspecialista}
            />
          </Box>

          <Box pl={3} pt={3}>
            <Button onClick={this.handlerGuardar}>Guardar</Button>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default Precios;
