import React,{useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "../../util/forms";
import { Toast } from "../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";

import {Tabs,Container,Paper,Tab,Typography,Box,AppBar,Toolbar,Grid,Card} from '@material-ui/core';

import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";

import fondo from '../../assets/fondo2.png';
import logo_kuery from '../../assets/logo_kuery_blanco.png';
import { ServiceProfessionalCount } from "../../api/services";
import Rut from '../web/DashboardView/comun/Rut.js';

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const LoginPacienteMobile = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [nuevoRut, setNuevoRut] = React.useState("");

  const history = useHistory();
  Auth.signOut();
  const { value: rut, bind: bindRut } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const [usuarioLogin, setUsuarioLogin] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let rutSinPuntos=nuevoRut.replaceAll(".","");

      await Auth.signIn(rutSinPuntos, password);
      console.log(Auth.user);
      localStorage.setItem('rut', Auth.user.username);
      Toast("Muy bien!!", "Has ingresado correctamente", "success");
      history.push(`/principalMobile/`);
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  const handlerRut = (rutValido,valorRut) =>{
    if (rutValido){
      setNuevoRut(valorRut);
    }else{
      setNuevoRut("");
    }
  }

  /*   */

/*  document.body.style = 'background-image: url("'+ fondo +'");';*/

  document.body.style = 'background-color: #ebecf0;'

  return (
    <>
    <AppBar position="static">
      <Toolbar variant="dense">
         <div style={{ width: '100%' }}>
          <Box display="flex" direction="row" alignItems="center">
            <Box  pt={1} flexGrow={1} >
              <img src={logo_kuery} style={{height:"5vh"}}/>
            </Box>

          </Box>
        </div>
      </Toolbar>
    </AppBar>

    <Box p={2} style={{  backgroundColor: "#ffffff"}}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

        }}
        onSubmit={handleSubmit}
      >
        <Typography pt={6} variant="h5">
          Ingresa a tu cuenta
        </Typography>

        <Rut value={nuevoRut} handlerRut={handlerRut} ></Rut>
        <Field label="Contraseña" type="password" {...bindPassword} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
          Ingresar
        </Button>
        <DLink to="/">Volver al inicio &rarr;</DLink>
      </form>
    </Box>

    <div style={{
      position: "fixed",
      left: "0",
      bottom: "0",
      width: "100%",
      maxHeight: "18vh",
      backgroundColor: "#ffffff9e",
      padding:"1vh",
      textAlign: "center",
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
    }}>

  </div>
    </>
  );
};

export default LoginPacienteMobile;
