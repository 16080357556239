import React from "react";
import {MenuItem,Container,Grid,Typography,TextField,Select,Box,Paper} from '@material-ui/core';
import {Link} from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled,{ThemeProvider} from 'styled-components';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 70px;
  height: 25px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

class FilaTabla extends React.Component {

  constructor(props) {
    super(props);
    console.log("en fila");
    console.log(props.fila);

    this.state = {
      fila: props.fila,
      medicamento: props.fila.principioActivo,
      presentacion: props.fila.presentacion,
      dosis: props.fila.dosis,
      periodoTratamiento: props.fila.periodoTratamiento,
    };
  }

  handlerEntrar=()=>{
    console.log("entrando!");
    this.setState({estado:"editar"});
  }

  handlerSalir=()=>{
    console.log("saliendo!");
    this.setState({estado:"mostrar"});
  }

  handlerEjecutarPlanAccion = ()=>{
    this.props.handlerMostrarPanelEjecutarPlanDeAccion(this.props.fila);
  }

  handlerVerificarPlanAccion = ()=>{
    console.log("verificar!");
    this.props.handlerMostrarPanelVerificarPlanDeAccion(this.props.fila);
  }

  handlerEliminarMedicamento = ()=>{
    this.props.handlerEliminarMedicamento(this.props.index);
  }
  render(){

    let responsable=this.props.fila.responsable_plan_accion;
    let verificador=this.props.fila.verificador_plan_accion;
    let labelFecha="Fecha Vencimiento:";
    let fecha=this.props.fila.fecha_vencimiento;

    let mostrarBotonEjecutarPlanAccion=false;
    let mostrarBotonVerificarPlanAccion=false;

    let usuario=localStorage.getItem("usuario");

    if (this.props.fila.estado=="vigente" && this.props.fila.responsable_plan_accion==usuario){
      mostrarBotonEjecutarPlanAccion=true;
    }

    if (this.props.fila.estado=="ejecutado" && this.props.fila.verificador_plan_accion==usuario){
      mostrarBotonVerificarPlanAccion=true;
      responsable=this.props.fila.responsable_plan_accion;
    }

    return (
          <Container maxWidth="xl"   >
          <Box bgcolor={this.props.estilo} pt={1} pb={1}>
            <Grid container spacing={0}   justify = "center" alignItems = "center"  >
              <Grid item xs={12} alignItems="center" justifyContent="center">
                {this.props.fila.principioActivo}   {this.props.fila.nombreComercial}
                <br/>
                {this.props.fila.forma}   {this.props.fila.unidadMedida} {this.props.fila.concentracion} x {this.props.fila.cantidad}
                <br/>
                {this.props.fila.dosis} {this.props.fila.unidadDosis}{" "}cada{" "}
                {this.props.fila.frecuencia} {this.props.fila.unidadFrecuencia}
                 {" "}por{" "}{this.props.fila.periodoTratamiento} {" "} {this.props.fila.unidadPeriodo}
                 <br/>
                <Button onClick={this.handlerEliminarMedicamento}>Eliminar</Button>
              </Grid >
              <Grid item xs={3}>

              </Grid >
              <Grid item xs={3}>
                <Typography variant="body1"  alignItems="center">

                </Typography>
              </Grid>

              <Grid item xs={3} justify = "center" alignItems = "center" >
                <Box mr={0.5}>

                </Box>
              </Grid>

            </Grid>
            </Box>
          </Container>
    );
  }
};

export default FilaTabla;
