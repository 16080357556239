import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { ServiceGetOrganizaciones } from "../../../api/services";

const SelectOrganizacion = ({ onChange, alsoRest = false }) => {
  const [organizaciones, setOrganizaciones] = useState([]);
  const [organizacionActiva, setOrganizacionActiva] = useState("");
  useEffect(() => {
    ServiceGetOrganizaciones().then((res) => {
      if (alsoRest) {
        setOrganizaciones([
          {
            id: "all",
            name: "Todos",
          },
          ...res.Items,
        ]);
        return;
      }
      setOrganizaciones(res.Items);
    });
  }, [alsoRest]);
  return (
    organizaciones.length > 0 && (
      <Select
        value={organizacionActiva || organizaciones[0].id}
        label="Seleccionar Organizacion"
        onChange={({ target: { value } }) => {
          setOrganizacionActiva(value);
          onChange?.(value);
        }}
      >
        {organizaciones.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

export default SelectOrganizacion;
