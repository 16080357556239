import React from "react";
import {Select,Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import EtiquetaTextField from '../../DashboardView/comun/EtiquetaTextField';
import EtiquetaValor from '../../DashboardView/comun/EtiquetaValor';
import EtiquetaSelect from '../../DashboardView/comun/EtiquetaSelect';
import SelectorFecha from '../../DashboardView/comun/SelectorFecha';
import Rut from '../../DashboardView/comun/Rut';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const BotonGrande = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 250px;
  height: 75px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const BotonGrandeRojo = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 250px;
  height: 75px;
  background:${props => props.disabled ? "#ff0000" : "#ff0000"};
`;

const estiloDivModal={
      position: 'absolute',
      top: '5%',
      left: '25%',
      right: '25%',
      bottom: '29%',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px',
}

class DetallePago extends React.Component {

   constructor(props) {

     let tieneDetalles=false;

     if (typeof props.jsonSeleccionado["details"] !== 'undefined'){
       tieneDetalles=true;
     }

     super(props);

     this.state = {
       visible:props.visible,
       idSeleccionado:props.idSeleccionado,
       handlerCerrarExterno:props.handlerCerrarPanel,
       jsonSeleccionado:props.jsonSeleccionado,

       primerNombre:null,
       segundoNombre:null,
       apellidoPaterno:null,
       apellidoMaterno:null,
       email:null,
       telefono:null,
       rol:null,
       pasoActual:1,
       tieneDetalles:tieneDetalles,
     };
  }

  componentDidMount() {
  }

  handlerCerrar = (e) => {
    this.setState({visible:false});
    this.state.handlerCerrarExterno();
  }

  handlerSiguiente = () => {
    this.setState({pasoActual:(this.state.pasoActual+1)});
  }

  handlerAnterior = () => {
    this.setState({pasoActual:(this.state.pasoActual-1)});
  }


  render(){
    return (
      <Modal
        open={this.state.visible}
        onClose={this.handlerCerrar}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >
          <div style={estiloDivModal}>

            <Box display="flex" flexDirection="column" alignItems="center">

              <Box pb={2}>
                <Typography variant="h6">
                  Detalle del Pago
                </Typography>

              </Box>

              <Box>
                <Stepper activeStep={this.state.pasoActual}>
                   <Step key={1}>
                     <StepLabel >Datos Reserva</StepLabel>
                   </Step>
                   <Step key={2}>
                     <StepLabel >Datos Transacción Transbank</StepLabel>
                   </Step>
                   <Step key={3}>
                     <StepLabel >Detalles Pago</StepLabel>
                   </Step>
                </Stepper>
              </Box>

              {this.state.pasoActual==1&&(
                <Box  display="flex" flexDirection="column">

                  <EtiquetaValor etiqueta="Nombre Medico:" valor={this.state.jsonSeleccionado["cmvDetail"]["nombreMedico"]} />
                  <EtiquetaValor etiqueta="Rut Paciente:" valor={this.state.jsonSeleccionado["cmvDetail"]["rutPaciente"]} />
                  <EtiquetaValor etiqueta="Email Paciente:" valor={this.state.jsonSeleccionado["cmvDetail"]["email_paciente"]} />
                  <EtiquetaValor etiqueta="Dia Reserva:" valor={this.state.jsonSeleccionado["cmvDetail"]["diaReserva"]} />
                  <EtiquetaValor etiqueta="Hora Reserva:" valor={this.state.jsonSeleccionado["cmvDetail"]["horaReserva"]} />

                </Box>
              )}

              {this.state.pasoActual==2&&(

                <Box>
                    <EtiquetaValor etiqueta="Fecha Creación:" valor={this.state.jsonSeleccionado["creationDatetime"]} />
                    <EtiquetaValor etiqueta="Status:" valor={this.state.jsonSeleccionado["transbankStatus"]} />
                    <EtiquetaValor etiqueta="Fecha Actualización:" valor={this.state.jsonSeleccionado["updateDatetime"]} />
                    <EtiquetaValor etiqueta="Monto:" valor={this.state.jsonSeleccionado["amount"]} />

                </Box>
              )}

              {this.state.pasoActual==3&& this.state.tieneDetalles &&(
                <Box>
                  <EtiquetaValor etiqueta="Codigo autorización:" valor={this.state.jsonSeleccionado["details"]["authorizationCode"]} />
                  <EtiquetaValor etiqueta="Codigo de compra:" valor={this.state.jsonSeleccionado["details"]["buyOrder"]} />
                  <EtiquetaValor etiqueta="Ultimos 4 digitos tarjeta:" valor={this.state.jsonSeleccionado["details"]["cardNumber"]} />
                  <EtiquetaValor etiqueta="Id session:" valor={this.state.jsonSeleccionado["details"]["transaction"]["sessionId"]} />
                  <EtiquetaValor etiqueta="Cuotas:" valor={this.state.jsonSeleccionado["details"]["transaction"]["detailOutput"]["sharesNumber"]} />
                  <EtiquetaValor etiqueta="Valor Cuota:" valor={this.state.jsonSeleccionado["details"]["transaction"]["detailOutput"]["sharesAmount"]} />
                  <EtiquetaValor etiqueta="Fee transbank:" valor={this.state.jsonSeleccionado["details"]["amountFees"]} />
                  <EtiquetaValor etiqueta="Codigo Comercio:" valor={this.state.jsonSeleccionado["details"]["transaction"]["detailOutput"]["commerceCode"]} />

                </Box>
              )}

              {this.state.pasoActual==3&& this.state.tieneDetalles==false &&(

                <Box pb={2}>
                  <Typography variant="h5">
                      Sin detalles de Pago
                  </Typography>
                </Box>
              )}

              <Box display="flex" flexDirection="row"  pt={10}   >

                <Box pr={1}>

                  <Button disabled={this.state.pasoActual==1} onClick={this.handlerAnterior} >
                    Anterior
                  </Button>
                </Box>

                <Box pr={1}>
                    <Button onClick={this.handlerSiguiente} disabled={this.state.pasoActual==3}  >
                      Siguiente
                    </Button>
                </Box>

                <Box pr={1}>
                  <Button onClick={this.handlerCerrar} >
                    Cerrar
                  </Button>
                </Box>

              </Box>
            </Box>
          </div>
       </Modal>
    );
  }
};

export default DetallePago;
