import { useEffect, useState } from "react";
import { ServiceGetOrganizaciones } from "../api/services";
import logo_kuery2 from "../assets/kuery_portal.png";

export const useGetLogoURL = () => {
  const [logo, setLogo] = useState(logo_kuery2);
  useEffect(() => {
    const currentClinica = localStorage.getItem("clinicaObject");
    if (currentClinica && currentClinica !== "undefined") {
      setLogo(JSON.parse(currentClinica).logoImg);
      return;
    }
    ServiceGetOrganizaciones().then((res) => {
      const clinica = localStorage.getItem("clinica");
      const isClinicaValida = res.Items.find(
        ({ dominio, id }) => dominio === clinica || id === clinica
      );
      localStorage.setItem("clinicaObject", JSON.stringify(isClinicaValida));
      if (!isClinicaValida) return;
      setLogo(isClinicaValida.logoIxmg);
    });
  }, []);
  console.log(logo, "zxc");
  return logo;
};
