
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './menu/Menu';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./../web/ProtectedRoute";
import LoginPacienteMobile from './LoginPacienteMobile';
import LoginMedicoMobile from './LoginMedicoMobile';
import {Tabs,Container,Paper,Tab,Typography,Box,AppBar,Toolbar,Grid,Card} from '@material-ui/core';
import PrincipalMobile from "./PrincipalMobile";
import PrincipalMedicoMobile from "./PrincipalMedicoMobile";
import EvaluacionRemota from "./../web/DashboardView/EvaluacionRemota";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import SingupMedicoMobile from "./singup/SingupMedicoMobile";
import SingupPacienteMobile from "./singup/SingupPacienteMobile";

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth:"none !important",
    height:"100vh",
    width:"100vw",
  },
}));

export default function MovileApp() {
  const classes = useStyles();
  const [constructorHasRun, setConstructorHasRun] = useState(false);

  const constructor = async () => {
    if (constructorHasRun) return;
    console.log("Mobile")
    document.getElementsByTagName("body")[0].style.maxWidth = "100vw";
    setConstructorHasRun(true);
  };

  useEffect(() => constructor(), []);

  return (
    <Router>
      <Switch>
        <Route path="/principalMobile">
          <Box>
            <PrincipalMobile />
          </Box>
        </Route>

        <Route path="/principalMedicoMobile">
          <Box>
            <PrincipalMedicoMobile />
          </Box>
        </Route>
        <Route path="/asistenteMobile">
          <Box>
            <DLink to="/signin">Volver al inicio &rarr;</DLink>
            <EvaluacionRemota/>
          </Box>
        </Route>
        <Route path="/signupMedicoMobile">
          <Box>
            <SingupMedicoMobile />
          </Box>
        </Route>

        <Route path="/registroPacienteMobile">
          <Box>
              <SingupPacienteMobile />
          </Box>
        </Route>
        <Route path="/loginMedico">
          <LoginMedicoMobile/>
        </Route>
        <Route path="/loginPaciente">
          <LoginPacienteMobile/>
        </Route>
        <Route path="/pago/comprobante/:token" render={(props) => <PrincipalMobile {...props} />} />

        <Route path="/">
          <div className={classes.root}>
            <Menu/>
          </div>
        </Route>

      </Switch>

    </Router>
  );
}
