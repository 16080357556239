import logo from "../../assets/cropped-favicon-192x192.png";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Amplify from "aws-amplify";
import Signup from "./Signup";
import RecuperarContrasena from "./RecuperarContrasena";
import Confirmation from "./Confirmation";
import Login from "./Login";
import { COGNITO } from "./../../configs/aws";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./Dashboard";
import VerificaPDF from "./VerificaPDF";
import ProfessionalDashboard from "./professional/ProfessionalDashboard";
import LoginMed from "./LoginMed";
import LoginAdmin from "./LoginAdmin";
import Slide from "@material-ui/core/Slide";
import AgendamientoTest from "./testFolder/AgendamientoTest";
import ClinicaInitRoute from "../../pages/ClinicaInitRoute";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

//style={{ width: 500, margin: "100px auto", padding: "40px" }}

const WebApp = () => {
  const [userPro, setUserPro] = useState(null);
  //document.getElementById('zmmtg-root').style.display = 'contents';

  return (
    <Router>
      <Switch>
        <Route path="/clinica/:clinica">
          <Card
            style={{
              width: 500,
              height: 500,
              margin: "100px auto",
              padding: "40px",
            }}
          >
            <ClinicaInitRoute />
          </Card>
        </Route>
        <Route path="/recuperarContrasena">
          <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
            <RecuperarContrasena />
          </Card>
        </Route>
        <Route path="/signup">
          <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
            <Signup />
          </Card>
        </Route>
        <Route path="/signin">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
                <Login />
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container direction="row" justify="left" alignItems="left">
            <Grid item xs={0}></Grid>
            <Grid item xs={5} style={{ marginTop: 7, marginLeft: 130 }}>
              <h1
                style={{
                  fontSize: 46,
                  fontFamili: "Helvetica",
                  fontWeight: 700,
                  lineHeigth: 36,
                }}
              >
                Plataforma de Salud Digital con el uso de inteligencia
                artificial
              </h1>
              <h1 style={{ fontSize: 22, fontFamili: "Helvetica" }}>
                Al servicio de los Profesionales de la Salud y Centrados en el
                Paciente.
              </h1>
            </Grid>
          </Grid>
        </Route>
        <Route path="/signinPro">
          <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
            <LoginMed setUserPro={setUserPro} />
          </Card>
        </Route>

        <Route path="/signinAdmin">
          <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
            <LoginAdmin setUserPro={setUserPro} />
          </Card>
        </Route>

        <Route path="/confirmation">
          <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
            <Confirmation />
          </Card>
        </Route>
        <Route
          path="/verificarPdf/:id"
          render={(props) => <VerificaPDF {...props} />}
        />

        <Route path="/professional/:professionalId?/(patient)?/:patientId?">
          <ProtectedRoute component={ProfessionalDashboard} />
        </Route>

        <Route path="/patient">
          <Switch>
            <Route path="/">
              <ProtectedRoute component={Dashboard} />
            </Route>
          </Switch>
        </Route>

        <Route
          path="/pago/comprobante/:token"
          render={(props) => <Dashboard {...props} />}
        />

        <Route path="/testTransbank">
          <Card style={{ width: 500, margin: "100px", padding: "40px" }}>
            <AgendamientoTest />
          </Card>
        </Route>
        <Route path="/">
          <ProtectedRoute component={Dashboard} />
        </Route>
      </Switch>
    </Router>
  );
};

export default WebApp;
