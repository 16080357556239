import React from "react";
import {Grid,Box,TextField,Typography} from '@material-ui/core';

class EtiquetaValor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      etiqueta: props.etiqueta,
      valor: props.valor,
    };
  }

  render(){
    return (
      <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box width={200}>
            <Typography variant="body1" style={{ fontWeight: 600 }} >
              {this.state.etiqueta}
            </Typography>
          </Box>
          <Box width={200}>
            <Typography variant="body1">
              {this.state.valor}
            </Typography>
          </Box>
      </Box>
    );
  }
};

export default EtiquetaValor;
