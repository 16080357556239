import React,  {useState }from "react";
import { Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import { useInput } from "../../../util/forms";
import { Toast } from "../../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import SubirArchivo from '../../web/DashboardView/comun/SubirArchivo';
import Rut from '../../web/DashboardView/comun/Rut.js';


const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const SignupMedicoMobile = () => {
  const [loading, setLoading] = React.useState(false);
  const [postulacionEnviada, setPostulacionEnviada] = React.useState(false);

  const [imagenCarnet64, setImagenCarnet64] = useState("");

  const history = useHistory();

  const { value: primer_nombre, bind: bindPrimerNombre } = useInput("");
  const { value: segundo_nombre, bind: bindSegundoNombre } = useInput("");
  const { value: apellido_paterno, bind: bindApellidoPaterno } = useInput("");
  const { value: apellido_materno, bind: bindApellidoMaterno } = useInput("");
  const { value: rol, bind: bindRol } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");

  const [rut, setRut] = React.useState("");

  const [fotoCarnet, setFotoCarnet] = React.useState("");
  const [fotoTitulo, setFotoTitulo] = React.useState("");
  const [fotoFirma, setFotoFirma] = React.useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    const header =  {
        headers: {
            "access-control-allow-headers":"*",
            "access-control-allow-methods":"*",
            "access-control-allow-origin":"*",
            "access-control-expose-headers":"*",
            'content-type': 'application/json',
        }
    };

      axios
        .post('https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion',  {
              "id": ""+rut,
              "primer_nombre": ""+primer_nombre,
              "segundo_nombre": ""+segundo_nombre,
              "apellido_paterno": ""+apellido_paterno,
              "apellido_materno": ""+apellido_materno,
              "email": ""+email,
              "telefono": ""+phone,
              "rol": ""+rol,
              "fotoCarnet":""+fotoCarnet,
              "fotoTitulo":""+fotoTitulo,
              "estado":"revision"
            },header)
       .then(res=>{
           setPostulacionEnviada(true);
       });

    setLoading(false);
  };

  const handlerSubidaFotoCarnet = (url,archivo) => {
    setFotoCarnet(archivo);
  }

  const handlerSubidaFotoTitulo = (url,archivo) => {
    setFotoTitulo(archivo);
  }

  const handlerRut = (valido,rut) => {

    if (valido){
        console.log("rut valido!!!!");
        setRut(rut);
    }else{
        console.log("rut invalido!!!!");
        setRut(null);
    }
  }

  return (
    <Box  >
      {postulacionEnviada==false && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSignUp}
        >
          <h1 style={{ fontSize: "22px", fontWeight: 800,margin:'10px' }}>
            {" "}
            Postular a la plataforma
          </h1>

          <Box display="flex" flexDirection="column" m={1} >
            <Box display="flex" flexDirection="column" style={{width:600}}>
              <Box>
                <Field label="Primer Nombre" {...bindPrimerNombre} />
              </Box>
              <Box>
                <Field label="Segundo Nombre" {...bindSegundoNombre} />
              </Box>
              <Box>
                <Field label="Apellido Paterno" {...bindApellidoPaterno} />
              </Box>
              <Box>
                <Field label="Apellido Materno" {...bindApellidoMaterno} />
              </Box>
              <Box>
                <Rut key="rut-registro-medico" value={rut} handlerRut={handlerRut} />
              </Box>
              <Box>
                <Field label="RNPIS" {...bindRol} />
              </Box>
              <Box>
                <Field label="Email" {...bindEmail} type="email" />
              </Box>
              <Box>
                <Field label="Telefono" {...bindPhone} type="tel" />
              </Box>
            </Box>
            <Box  >
              <Box display="flex" flexDirection="column" mt={3} >
                <Box>
                      <Typography variant="body1">
                        Suba una imagen que contenga su carnet de identidad por ambos lados.
                      </Typography>
                </Box>
                <Box>
                      <SubirArchivo etiquetaBoton="Subir Foto Carnet" handlerArchivo={handlerSubidaFotoCarnet}></SubirArchivo>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" mt={3} >
                <Box>
                      <Typography variant="body1">
                        Suba una imagen de su titulo.
                      </Typography>
                </Box>
                <Box>
                    <SubirArchivo etiquetaBoton="Subir Foto Titulo" handlerArchivo={handlerSubidaFotoTitulo}></SubirArchivo>
                </Box>
              </Box>

              <Box display="flex" flexDirection="row" mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={loading || primer_nombre=="" ||segundo_nombre=="" || apellido_paterno=="" || apellido_materno =="" ||rol =="" || email=="" || phone=="" || fotoCarnet=="" || fotoTitulo=="" }
                  >
                    {loading && <CircularProgress size={10} style={{ marginRight: 1 }} />}
                    Enviar Datos
                  </Button>

              </Box>

            </Box>
          </Box>

          <Box mt={3}>
          </Box>

          <DLink to="/signin">Volver al inicio &rarr;</DLink>
        </form>
      )}

      {postulacionEnviada==true && (
        <Paper  >
          <Box m={3}  pt={3} >
            <Typography variant="h6">
              Postulación enviada
            </Typography>
          </Box>

          <Box m={3} pb={3}>
            <Box mt={4}>
              <Typography variant="body1">
                Su postulación ha sido enviada a la plataforma. Uno de nuestros colaboradores se contactará con ud para los procesos de verificación.
              </Typography>
              <br/>
              <DLink to="/signin">Volver al inicio &rarr;</DLink>
            </Box>
          </Box>
        </Paper>
      )}
    </Box>

  );
};

export default SignupMedicoMobile;
