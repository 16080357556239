import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import ChatIcon from '@material-ui/icons/Chat';
import Typography from '@material-ui/core/Typography';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import logo from '../../../assets/logo_kuery_grande.png';
import { CssBaseline } from '@material-ui/core';

import ChatBot from '../../mobile/chatbot/ChatBot';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    table: {
      minWidth: 300,
      maxWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

export default function Triage(props){
    const classes = useStyles();
    const [dataRecetas, setDataRecetas] = useState({});
    const [open, setOpen] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = async () => {
        if (constructorHasRun) return;
        try{
          traerRecetas();
        }catch(e){
            console.log("ERR:", e);
        }
        setConstructorHasRun(true);
    };

    const traerRecetas = async () => {
      /*let response = await ServiceConsultaPaciente(localStorage.getItem('rut'));
      console.log("response", response);
      setDataPaciente(response);
      if (response.statusCode === 400) {
          handleOpen();
      }*/
    }

    useEffect(() => constructor(), []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper>
          <AppBar position="static">
            <Toolbar style={{background : '#2E3B55'}}>
              <Typography variant="h6" className={classes.title}>
                Evaluación Remota
              </Typography>
            </Toolbar>
          </AppBar>
          <ChatBot className={classes.chat}/>
        </Paper>
    )
}
