import {
  Button,
  makeStyles,
} from '@material-ui/core';
import {
  ChatController,
  MuiChat,
} from 'chat-ui-react';
import React, {useEffect} from 'react';
import { ServiceGuardarTriage,ServiceAnalizarImagenPicadura,ServiceGenerarPreinforme} from '../../../api/services';
import { checkRut } from '../../../util/validaRut';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';

//const API_URL_DIAGNOSTICO = "https://100.24.130.30:5000/diagnostico";
const API_URL_DIAGNOSTICO = "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/diagnostico-sintomas";

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

const catalogo_sintomas = [
  { sintoma: 'ahogos'},
  { sintoma: 'dolor cabeza'},
  { sintoma: 'dolor estomago bajo'},
  { sintoma: 'dolor hombro'},
  { sintoma: 'dolor muela'},
  { sintoma: 'dolor oido'},
  { sintoma: 'fiebre'},
  { sintoma: 'herida rodilla'},
  { sintoma: 'molestia general'},
  { sintoma: 'oidos tapados'},
  { sintoma: 'dolor'},
  { sintoma: 'absceso'},
  { sintoma: 'absceso abdomen'},
  { sintoma: 'absceso ano'},
  { sintoma: 'absceso cabeza'},
  { sintoma: 'absceso coxis'},
  { sintoma: 'absceso cuello'},
  { sintoma: 'absceso en parpado'},
  { sintoma: 'absceso espalda'},
  { sintoma: 'absceso pecho'},
  { sintoma: 'absceso pierna'},
  { sintoma: 'absceso tobillo'},
  { sintoma: 'accidente bicicleta'},
  { sintoma: 'accidente colegio'},
  { sintoma: 'dolor mano'},
  { sintoma: 'accidente escolar'},
  { sintoma: 'dolor cara'},
  { sintoma: 'accidente moto'},
  { sintoma: 'accidente trayecto'},
  { sintoma: 'accidente vehicular'},
  { sintoma: 'accidente'},
  { sintoma: 'dolor costal'},
  { sintoma: 'dolor cuello'},
  { sintoma: 'adormecido brazo'},
  { sintoma: 'adormecimiento cara'},
  { sintoma: 'adormecimento'},
  { sintoma: 'adormecimiento boca'},
  { sintoma: 'adormecimiento brazo'},
  { sintoma: 'adormecimiento lengua'},
  { sintoma: 'dolor pecho o torax'},
  { sintoma: 'adormecimiento pierna'},
  { sintoma: 'adormecimiento brazos'},
  { sintoma: 'adormecimiento costado cuerpo'},
  { sintoma: 'adormecimiento cuerpo'},
  { sintoma: 'adormecimiento piernas'},
  { sintoma: 'adormecimiento'},
  { sintoma: 'afonia'},
  { sintoma: 'malestar general'},
  { sintoma: 'aftas'},
  { sintoma: 'ahogo'},
  { sintoma: 'baja saturacion'},
  { sintoma: 'dificultad respirar'},
  { sintoma: 'tos'},
  { sintoma: 'vomitos'},
  { sintoma: 'resfrio'},
  { sintoma: 'alergia'},
  { sintoma: 'alergia axila'},
  { sintoma: 'alergia axilas'},
  { sintoma: 'alergia en brazos'},
  { sintoma: 'alergia en piernas'},
  { sintoma: 'dolor cintura'},
  { sintoma: 'alergia en cara'},
  { sintoma: 'alergia cabeza'},
  { sintoma: 'granos'},
  { sintoma: 'alergia en cuello'},
  { sintoma: 'alergia en cuerpo completo'},
  { sintoma: 'alergia en cuerpo'},
  { sintoma: 'dolor estomago'},
  { sintoma: 'inflamacion'},
  { sintoma: 'urticaria'},
  { sintoma: 'alergia en espalda'},
  { sintoma: 'alergia general'},
  { sintoma: 'alergia nerviosa'},
  { sintoma: 'alergia ocular'},
  { sintoma: 'alergia ojos'},
  { sintoma: 'alergia piel'},
  { sintoma: 'alergia piernas'},
  { sintoma: 'alergia pies'},
  { sintoma: 'alergia vista'},
  { sintoma: 'diarrea'},
  { sintoma: 'dolor al orinar'},
  { sintoma: 'dificultad respiratoria'},
  { sintoma: 'dolor de cabeza'},
  { sintoma: 'golpe tobillo'},
  { sintoma: 'inflamacion cara'},
  { sintoma: 'picazon'},
  { sintoma: 'ronchas'},
  { sintoma: 'alza de amonio'},
  { sintoma: 'alza de presion'},
  { sintoma: 'amigdalas'},
  { sintoma: 'dolor garganta'},
  { sintoma: 'amigdalitis'},
  { sintoma: 'ampoa pie'},
  { sintoma: 'ampolla boca'},
  { sintoma: 'ampollas'},
  { sintoma: 'dolor rodilla'},
  { sintoma: 'angustia'},
  { sintoma: 'aplastamiento'},
  { sintoma: 'ardor de ojo'},
  { sintoma: 'ardor en ojos'},
  { sintoma: 'ardor esofago'},
  { sintoma: 'ardor pies'},
  { sintoma: 'diabetes'},
  { sintoma: 'ardor vista'},
  { sintoma: 'ardor'},
  { sintoma: 'picazon en pies'},
  { sintoma: 'arritmia'},
  { sintoma: 'presion alta'},
  { sintoma: 'asco'},
  { sintoma: 'hipo'},
  { sintoma: 'dificultad para respirar'},
  { sintoma: 'asfixia'},
  { sintoma: 'asma'},
  { sintoma: 'astilla pie'},
  { sintoma: 'ataque de ansiedad'},
  { sintoma: 'ataque hipo'},
  { sintoma: 'atricion brazo'},
  { sintoma: 'atricion dedo indice'},
  { sintoma: 'atricion dedo mano'},
  { sintoma: 'atricion dedo'},
  { sintoma: 'mano'},
  { sintoma: 'atricion pie'},
  { sintoma: 'herida'},
  { sintoma: 'azucar alta'},
  { sintoma: 'baja de azucar'},
  { sintoma: 'mareos'},
  { sintoma: 'baja de glicemia'},
  { sintoma: 'baja de presion'},
  { sintoma: 'brazo dormido'},
  { sintoma: 'dolor pecho'},
  { sintoma: 'brazo hinchado'},
  { sintoma: 'glicemia alta'},
  { sintoma: 'brazo'},
  { sintoma: 'bronquiolitis'},
  { sintoma: 'bronquitis obstructiva'},
  { sintoma: 'bronquitis'},
  { sintoma: 'caida'},
  { sintoma: 'caida altura'},
  { sintoma: 'caida bicicleta'},
  { sintoma: 'caida brazo'},
  { sintoma: 'caida caballo'},
  { sintoma: 'caida cama'},
  { sintoma: 'caida cara'},
  { sintoma: 'caida costado'},
  { sintoma: 'caida costillas'},
  { sintoma: 'caida de altura'},
  { sintoma: 'caida de cama'},
  { sintoma: 'caida de coche'},
  { sintoma: 'caida de escalera'},
  { sintoma: 'golpe rodilla'},
  { sintoma: 'pie inflamado'},
  { sintoma: 'caida desde altura'},
  { sintoma: 'caida desde cuna'},
  { sintoma: 'golpe cabeza'},
  { sintoma: 'caida desde moto'},
  { sintoma: 'caida en bicicleta'},
  { sintoma: 'herida pierna'},
  { sintoma: 'caida en moto'},
  { sintoma: 'caida moto'},
  { sintoma: 'sangrado'},
  { sintoma: 'dolor brazo'},
  { sintoma: 'dolor muñeca'},
  { sintoma: 'dolor tobillo'},
  { sintoma: 'dolor cadera'},
  { sintoma: 'dolor pierna'},
  { sintoma: 'dolor codo'},
  { sintoma: 'dolor coxis'},
  { sintoma: 'dolor de espalda'},
  { sintoma: 'dolor de rodilla'},
  { sintoma: 'dolor lumbar'},
  { sintoma: 'dolor pie'},
  { sintoma: 'rodilla'},
  { sintoma: 'pierna'},
  { sintoma: 'erosion pomulo'},
  { sintoma: 'erosiones varias'},
  { sintoma: 'golpe abdominal'},
  { sintoma: 'golpe boca'},
  { sintoma: 'golpe mano'},
  { sintoma: 'golpe brazo'},
  { sintoma: 'golpe cadera'},
  { sintoma: 'golpe coxis'},
  { sintoma: 'golpe frente'},
  { sintoma: 'golpe lumbar'},
  { sintoma: 'lesion hombro'},
  { sintoma: 'perdida conocimiento'},
  { sintoma: 'golpe cara'},
  { sintoma: 'golpe dientes'},
  { sintoma: 'golpe codo'},
  { sintoma: 'golpe costado'},
  { sintoma: 'golpe costal'},
  { sintoma: 'golpe dedo mano'},
  { sintoma: 'golpe dedo'},
  { sintoma: 'golpe en boca'},
  { sintoma: 'golpe en brazo'},
  { sintoma: 'golpe en mano'},
  { sintoma: 'colico'},
  { sintoma: 'golpe en cabeza'},
  { sintoma: 'golpe en la cabeza'},
  { sintoma: 'golpe en la cara'},
  { sintoma: 'golpe en la frente'},
  { sintoma: 'golpe en muñeca'},
  { sintoma: 'golpe en rodilla'},
  { sintoma: 'golpe hombro'},
  { sintoma: 'golpe muñeca'},
  { sintoma: 'golpe menton'},
  { sintoma: 'golpe nariz'},
  { sintoma: 'golpe pie'},
  { sintoma: 'golpe'},
  { sintoma: 'herida cabeza'},
  { sintoma: 'perdida conciencia'},
  { sintoma: 'golpes cabeza'},
  { sintoma: 'golpe espalda'},
  { sintoma: 'golpie pie'},
  { sintoma: 'herida boca'},
  { sintoma: 'herida brazo'},
  { sintoma: 'herida ceja'},
  { sintoma: 'herida en pierna'},
  { sintoma: 'herida facial'},
  { sintoma: 'herida menton'},
  { sintoma: 'herida parpado'},
  { sintoma: 'herida pie'},
  { sintoma: 'cabeza'},
  { sintoma: 'extremidades inferiores'},
  { sintoma: 'inflamacion de mano'},
  { sintoma: 'inflamacion pie'},
  { sintoma: 'lesion brazo'},
  { sintoma: 'lesion dedos'},
  { sintoma: 'lesion dedo'},
  { sintoma: 'lesion mano'},
  { sintoma: 'lesion muñeca'},
  { sintoma: 'lesion pie'},
  { sintoma: 'lesion piederecho'},
  { sintoma: 'lesion tobillo'},
  { sintoma: 'molestia pie'},
  { sintoma: 'torcedura de mano'},
  { sintoma: 'torcedura de pie'},
  { sintoma: 'torcedura de tobillo'},
  { sintoma: 'calambre'},
  { sintoma: 'calambres'},
  { sintoma: 'vertigos'},
  { sintoma: 'calculo renal'},
  { sintoma: 'calculo vesicula'},
  { sintoma: 'calculo'},
  { sintoma: 'calor al estomago'},
  { sintoma: 'cansancio'},
  { sintoma: 'le falta aire'},
  { sintoma: 'cara hinchada picazon'},
  { sintoma: 'cara hinchada'},
  { sintoma: 'cara inflamada'},
  { sintoma: 'varicela'},
  { sintoma: 'cara nauseas'},
  { sintoma: 'cefalea'},
  { sintoma: 'molestia facial'},
  { sintoma: 'cuello'},
  { sintoma: 'celulitis infecciosa'},
  { sintoma: 'celulitis pierna'},
  { sintoma: 'celulitis'},
  { sintoma: 'cianosis'},
  { sintoma: 'clavo en pie'},
  { sintoma: 'colico abdominal bajo'},
  { sintoma: 'colico abdominal'},
  { sintoma: 'colico estomacal'},
  { sintoma: 'colico intestinal'},
  { sintoma: 'colico renal'},
  { sintoma: 'dolor orinar'},
  { sintoma: 'sangrado al orinar'},
  { sintoma: 'diarreas'},
  { sintoma: 'hinchazon'},
  { sintoma: 'llanto'},
  { sintoma: 'colicos'},
  { sintoma: 'colitis'},
  { sintoma: 'confusion'},
  { sintoma: 'congestion auditiva'},
  { sintoma: 'disminucion en audicion'},
  { sintoma: 'congestion nasal'},
  { sintoma: 'congestion'},
  { sintoma: 'dolor de garganta'},
  { sintoma: 'dolor oidos'},
  { sintoma: 'garganta'},
  { sintoma: 'flemas'},
  { sintoma: 'dolor  oido'},
  { sintoma: 'congestionado'},
  { sintoma: 'sonido en pecho'},
  { sintoma: 'conjuntivitis'},
  { sintoma: 'constipacion'},
  { sintoma: 'hemorroides'},
  { sintoma: 'contacto con mercurio'},
  { sintoma: 'contracciones'},
  { sintoma: 'contractura'},
  { sintoma: 'contusion codo'},
  { sintoma: 'contusion en pie'},
  { sintoma: 'contusion mano'},
  { sintoma: 'contusion ojo'},
  { sintoma: 'contusion pie'},
  { sintoma: 'contusion pierna'},
  { sintoma: 'contusion rodilla'},
  { sintoma: 'contusiones'},
  { sintoma: 'convulsion'},
  { sintoma: 'convulsiones'},
  { sintoma: 'descompensada'},
  { sintoma: 'descompensado'},
  { sintoma: 'corte ceja'},
  { sintoma: 'corte con metal'},
  { sintoma: 'corte dedo mano'},
  { sintoma: 'corte dedo medio'},
  { sintoma: 'corte dedo pie'},
  { sintoma: 'corte dedo pulgar'},
  { sintoma: 'corte dedo'},
  { sintoma: 'infeccion'},
  { sintoma: 'corte en dedo'},
  { sintoma: 'corte en frente'},
  { sintoma: 'corte en rodilla'},
  { sintoma: 'corte espalda'},
  { sintoma: 'corte mano'},
  { sintoma: 'corte menton'},
  { sintoma: 'corte pierna'},
  { sintoma: 'corte'},
  { sintoma: 'crisis'},
  { sintoma: 'crisis angustia'},
  { sintoma: 'crisis artritis'},
  { sintoma: 'crisis asmatica'},
  { sintoma: 'crisis de angustia'},
  { sintoma: 'crisis de ansiedad'},
  { sintoma: 'crisis de asma'},
  { sintoma: 'crisis de descontrol'},
  { sintoma: 'crisis de dolor'},
  { sintoma: 'crisis de fibromialgia'},
  { sintoma: 'crisis de gota'},
  { sintoma: 'crisis de nervios'},
  { sintoma: 'crisis de panico'},
  { sintoma: 'molestia genera'},
  { sintoma: 'ansiedad'},
  { sintoma: 'crisis depresiva'},
  { sintoma: 'crisis drogas'},
  { sintoma: 'crisis epileptica'},
  { sintoma: 'crisis nerviosa'},
  { sintoma: 'quiste ovario'},
  { sintoma: 'cuadro alergico'},
  { sintoma: 'picadura'},
  { sintoma: 'cuadro asma'},
  { sintoma: 'cuadro depresivo'},
  { sintoma: 'cuadro infeccioso'},
  { sintoma: 'cuerpo extraño dedo pie'},
  { sintoma: 'cuerpo extraño en garganta'},
  { sintoma: 'cuerpo extraño en nariz'},
  { sintoma: 'cuerpo extraño en oido'},
  { sintoma: 'cuerpo extraño en ojo'},
  { sintoma: 'cuerpo extraño nasal'},
  { sintoma: 'cuerpo extraño pierna'},
  { sintoma: 'cuerpo extraño'},
  { sintoma: 'decaida'},
  { sintoma: 'molestia estomago'},
  { sintoma: 'decaido'},
  { sintoma: 'dolor de estomago'},
  { sintoma: 'sin apetito'},
  { sintoma: 'decaimiento'},
  { sintoma: 'dedo pie inflamado'},
  { sintoma: 'deposicion con sangre'},
  { sintoma: 'deposiciones con sangre'},
  { sintoma: 'depresion'},
  { sintoma: 'dermatitis en trasero'},
  { sintoma: 'dermatitis'},
  { sintoma: 'derrame en ojo'},
  { sintoma: 'derrame ocular'},
  { sintoma: 'derrame'},
  { sintoma: 'dolor ojo'},
  { sintoma: 'descompensacion'},
  { sintoma: 'desmayo'},
  { sintoma: 'desvanecimiento'},
  { sintoma: 'presion baja'},
  { sintoma: 'diabetico'},
  { sintoma: 'problema de presion'},
  { sintoma: 'desgarro brazo'},
  { sintoma: 'desgarro en gemelo'},
  { sintoma: 'desgarro pierna'},
  { sintoma: 'desgarro'},
  { sintoma: 'desgarros con sangre'},
  { sintoma: 'desgarros'},
  { sintoma: 'deshidratacion'},
  { sintoma: 'cara morada'},
  { sintoma: 'cara amarilla'},
  { sintoma: 'frio'},
  { sintoma: 'perdida de movilidad costado izquierdo'},
  { sintoma: 'desmayos'},
  { sintoma: 'desorientada'},
  { sintoma: 'desorientado'},
  { sintoma: 'desvanecido'},
  { sintoma: 'diarrea con sangre'},
  { sintoma: 'diarrea de color'},
  { sintoma: 'colon'},
  { sintoma: 'aislamiento vigente'},
  { sintoma: 'inapetente'},
  { sintoma: 'estomago'},
  { sintoma: 'malestar espalda'},
  { sintoma: 'molestias'},
  { sintoma: 'nauseas'},
  { sintoma: 'vertigo'},
  { sintoma: 'presion'},
  { sintoma: 'dificultad al respirar'},
  { sintoma: 'mareos cabeza'},
  { sintoma: 'obstruido'},
  { sintoma: 'dificultad al tragar'},
  { sintoma: 'dificultad en vision'},
  { sintoma: 'dificultad para orinar'},
  { sintoma: 'palida'},
  { sintoma: 'dolor  garganta'},
  { sintoma: 'pecho o torax'},
  { sintoma: 'no toma aire'},
  { sintoma: 'dolor brazos'},
  { sintoma: 'dolor espalda'},
  { sintoma: 'molestia garganta'},
  { sintoma: 'molestia pecho'},
  { sintoma: 'tratamiento neumonia'},
  { sintoma: 'dificultad vision'},
  { sintoma: 'dificultar al orinar'},
  { sintoma: 'difonia'},
  { sintoma: 'dirrea'},
  { sintoma: 'disfonia'},
  { sintoma: 'dolor abdomen'},
  { sintoma: 'dolor abdomen agudo'},
  { sintoma: 'dolor abdomen bajo'},
  { sintoma: 'dolor costado'},
  { sintoma: 'dolor ingle'},
  { sintoma: 'dolor renal'},
  { sintoma: 'problema estomacales'},
  { sintoma: 'indigestion'},
  { sintoma: 'dolor abdominal'},
  { sintoma: 'dolor abdominal agudo'},
  { sintoma: 'dolor abdominal bajo'},
  { sintoma: 'dolor abdominal congestion'},
  { sintoma: 'daño hepatico'},
  { sintoma: 'dolor recto'},
  { sintoma: 'hematuria'},
  { sintoma: 'herida post operatoria'},
  { sintoma: 'ictericia'},
  { sintoma: 'pancreas'},
  { sintoma: 'orina frecuente'},
  { sintoma: 'vesicula'},
  { sintoma: 'dolor abominal'},
  { sintoma: 'mereos'},
  { sintoma: 'dolor adomen'},
  { sintoma: 'dolor aductor'},
  { sintoma: 'dolor al caminar'},
  { sintoma: 'dolor vagina'},
  { sintoma: 'dolor al costado'},
  { sintoma: 'dolor ambas manos'},
  { sintoma: 'dolor andominal bajo'},
  { sintoma: 'dolor ano'},
  { sintoma: 'dolor articulaciones'},
  { sintoma: 'dolor artritis pierna'},
  { sintoma: 'dolor axila'},
  { sintoma: 'dolor bajo vientre'},
  { sintoma: 'dolor boca estomago'},
  { sintoma: 'dolor boca'},
  { sintoma: 'manchas boca'},
  { sintoma: 'dolor costilla'},
  { sintoma: 'dolor piernas'},
  { sintoma: 'malestar'},
  { sintoma: 'taquicardia'},
  { sintoma: 'dolor bucal'},
  { sintoma: 'dolor corporal'},
  { sintoma: 'dolor cuerpo'},
  { sintoma: 'dolor hombros'},
  { sintoma: 'dolor riñones al orinar'},
  { sintoma: 'dolor columna'},
  { sintoma: 'dolor gluteo'},
  { sintoma: 'dolor caderas'},
  { sintoma: 'dolor cerebro'},
  { sintoma: 'dolor cervical'},
  { sintoma: 'dolor ciatico'},
  { sintoma: 'dolor muslo'},
  { sintoma: 'dolor mano derecha'},
  { sintoma: 'dolor clavicula'},
  { sintoma: 'quemadura'},
  { sintoma: 'dolor codos'},
  { sintoma: 'dolor cola'},
  { sintoma: 'espalda baja'},
  { sintoma: 'dolor colon'},
  { sintoma: 'acidez'},
  { sintoma: 'somnoliento'},
  { sintoma: 'dolor costado abdomen'},
  { sintoma: 'dolor costado abdominal'},
  { sintoma: 'dolor costado cadera'},
  { sintoma: 'dolor costado cuerpo'},
  { sintoma: 'dolor costado espalda'},
  { sintoma: 'dolor costillas'},
  { sintoma: 'hinchado'},
  { sintoma: 'vomitos yfiebre'},
  { sintoma: 'dolor intenso al orinar'},
  { sintoma: 'molestia respiratoria'},
  { sintoma: 'hombro'},
  { sintoma: 'dolor costado izquierdo'},
  { sintoma: 'lumbago'},
  { sintoma: 'herpes'},
  { sintoma: 'dolor de abdomen'},
  { sintoma: 'molestia gral'},
  { sintoma: 'dolor de brazo'},
  { sintoma: 'dolor  pierna'},
  { sintoma: 'espalda'},
  { sintoma: 'fibriomialgia'},
  { sintoma: 'dolor de cadera'},
  { sintoma: 'dolor de codo'},
  { sintoma: 'dolor de cuerpo'},
  { sintoma: 'dolor de dedo'},
  { sintoma: 'costal'},
  { sintoma: 'derivacion'},
  { sintoma: 'dolor  cuello'},
  { sintoma: 'piernas'},
  { sintoma: 'vomito'},
  { sintoma: 'sudoracion'},
  { sintoma: 'dolor de frente'},
  { sintoma: 'herida infectada'},
  { sintoma: 'dolor de huesos'},
  { sintoma: 'dolor de muelas'},
  { sintoma: 'dolor de oido'},
  { sintoma: 'dolor de oidos'},
  { sintoma: 'dolor de pie derecho'},
  { sintoma: 'dolor de pie'},
  { sintoma: 'dolor de pierna'},
  { sintoma: 'dolor de piernas'},
  { sintoma: 'dolor de talones'},
  { sintoma: 'dolor dedo'},
  { sintoma: 'dolor dedo indice'},
  { sintoma: 'dolor dedo indice mano'},
  { sintoma: 'dolor dedo mano'},
  { sintoma: 'dolor dedo medio'},
  { sintoma: 'dolor dedo pie'},
  { sintoma: 'dolor uña'},
  { sintoma: 'inflamado'},
  { sintoma: 'torcedura'},
  { sintoma: 'dolor dedo pulgar'},
  { sintoma: 'dolor dedos pie'},
  { sintoma: 'dolor dedos'},
  { sintoma: 'mano izquierda'},
  { sintoma: 'dolor desde cuello a hombro'},
  { sintoma: 'dolor diente'},
  { sintoma: 'dolor dientes'},
  { sintoma: 'dolor empeine pie'},
  { sintoma: 'dolor empeine'},
  { sintoma: 'dolor en brazo'},
  { sintoma: 'dolor en colon'},
  { sintoma: 'dolor en dedo pie'},
  { sintoma: 'dolor en hernia'},
  { sintoma: 'dolor en la cabeza'},
  { sintoma: 'dolor en muñeca'},
  { sintoma: 'dolor en ojo'},
  { sintoma: 'dolor en oreja'},
  { sintoma: 'dolor en pie'},
  { sintoma: 'dolor en pierna'},
  { sintoma: 'dolor en pies'},
  { sintoma: 'dolor en rodilla'},
  { sintoma: 'dolor en talon'},
  { sintoma: 'dolor encia'},
  { sintoma: 'dolor entre piernas'},
  { sintoma: 'dolor espalda baja'},
  { sintoma: 'puntada'},
  { sintoma: 'boca esofago'},
  { sintoma: 'dificultar al respirar'},
  { sintoma: 'dolor riñones'},
  { sintoma: 'manchas cara'},
  { sintoma: 'no puede orinar'},
  { sintoma: 'dolor costaodo'},
  { sintoma: 'dolor espalda arriba'},
  { sintoma: 'sangrado de nariz'},
  { sintoma: 'tercianas'},
  { sintoma: 'dolor muscular'},
  { sintoma: 'dolor facial'},
  { sintoma: 'dolor frente'},
  { sintoma: 'gripe'},
  { sintoma: 'dolor ganglios'},
  { sintoma: 'dolor garganta hasta estomago'},
  { sintoma: 'dolor garganta al comer'},
  { sintoma: 'dolor general'},
  { sintoma: 'dolor genital'},
  { sintoma: 'dolor genitales'},
  { sintoma: 'dolor huesos'},
  { sintoma: 'dificultad caminar'},
  { sintoma: 'dolor inguinal'},
  { sintoma: 'dolor lumbar bajo'},
  { sintoma: 'dolor testiculo'},
  { sintoma: 'infalmacion cara'},
  { sintoma: 'sistitis'},
  { sintoma: 'dolor mama'},
  { sintoma: 'dolor mamario'},
  { sintoma: 'dolor mandibula'},
  { sintoma: 'dolor mejillas'},
  { sintoma: 'inflamacion mejillas'},
  { sintoma: 'dolor meniscos'},
  { sintoma: 'dolor menton'},
  { sintoma: 'dolor meñique pie'},
  { sintoma: 'dolor molestia dedos pie'},
  { sintoma: 'dolor muelas'},
  { sintoma: 'dolor muñeca mano'},
  { sintoma: 'dolor muscular tendones'},
  { sintoma: 'dolor nariz'},
  { sintoma: 'dolor nuca'},
  { sintoma: 'dolor ocular'},
  { sintoma: 'dolor lado derecho cabeza'},
  { sintoma: 'inflamacion ojo'},
  { sintoma: 'irritacion ojo'},
  { sintoma: 'dolor ojos'},
  { sintoma: 'exposicion arco electrico'},
  { sintoma: 'dolor oreja'},
  { sintoma: 'dolor ovarios'},
  { sintoma: 'flujo'},
  { sintoma: 'dolor pecho costado'},
  { sintoma: 'dolor  estomago'},
  { sintoma: 'falta de aire'},
  { sintoma: 'dolor pene'},
  { sintoma: 'dolor pie derecho'},
  { sintoma: 'dedo meñique'},
  { sintoma: 'dolor talon'},
  { sintoma: 'dolor pierna pie'},
  { sintoma: 'cambio coloracion pierna'},
  { sintoma: 'ingle'},
  { sintoma: 'molestia ocular'},
  { sintoma: 'pierna sin movimiento'},
  { sintoma: 'testiculo'},
  { sintoma: 'piernas dormidas'},
  { sintoma: 'dolor pies'},
  { sintoma: 'dolor planta pies'},
  { sintoma: 'dolor post operatorio'},
  { sintoma: 'dolor post procedimiento'},
  { sintoma: 'dolor pulgar'},
  { sintoma: 'dolor pulmon'},
  { sintoma: 'dolor pulmones'},
  { sintoma: 'molestia al orinar'},
  { sintoma: 'problemas al orinar'},
  { sintoma: 'dolor riñon'},
  { sintoma: 'dolor rodillas'},
  { sintoma: 'dolor seno'},
  { sintoma: 'dolor tendon pie'},
  { sintoma: 'dolor testicular'},
  { sintoma: 'dolor testiculos'},
  { sintoma: 'gota'},
  { sintoma: 'reaccion alergica cuerpo'},
  { sintoma: 'dolor tobillos'},
  { sintoma: 'dolor umbilical'},
  { sintoma: 'dolor utero'},
  { sintoma: 'dolor vaginal'},
  { sintoma: 'dolor rectal'},
  { sintoma: 'dolor vejiga'},
  { sintoma: 'dolor vesicula'},
  { sintoma: 'dolor vientre bajo'},
  { sintoma: 'dolor zona genital'},
  { sintoma: 'cintura'},
  { sintoma: 'hinchazon en boca'},
  { sintoma: 'lumbar'},
  { sintoma: 'problema sonda'},
  { sintoma: 'molestia ojo'},
  { sintoma: 'dolores post operatorios'},
  { sintoma: 'dolores'},
  { sintoma: 'efectos secundarios post medicamentos'},
  { sintoma: 'elemento extraño en ojo'},
  { sintoma: 'error toma de medicamento'},
  { sintoma: 'escalofrios'},
  { sintoma: 'baja temperatura'},
  { sintoma: 'espasmos'},
  { sintoma: 'espina clavada en rodilla'},
  { sintoma: 'espina pierna'},
  { sintoma: 'estitica'},
  { sintoma: 'estitiquez'},
  { sintoma: 'estreñimiento'},
  { sintoma: 'falta de sueño'},
  { sintoma: 'faringitis'},
  { sintoma: 'fatiga'},
  { sintoma: 'fecas con sangre'},
  { sintoma: 'fibromialgia'},
  { sintoma: 'fiebre alta'},
  { sintoma: 'fiebre tras operacion'},
  { sintoma: 'dolor coporal'},
  { sintoma: 'oido'},
  { sintoma: 'inflamacion labio'},
  { sintoma: 'glicemia baja'},
  { sintoma: 'granitos en boca'},
  { sintoma: 'molestia'},
  { sintoma: 'varios dias'},
  { sintoma: 'vomitos diarrea'},
  { sintoma: 'flatulencias'},
  { sintoma: 'flemas con sangre'},
  { sintoma: 'flemas decaido'},
  { sintoma: 'flemas garganta'},
  { sintoma: 'congestioncolicos'},
  { sintoma: 'estitico'},
  { sintoma: 'fosas nasales obstruidas'},
  { sintoma: 'fractura'},
  { sintoma: 'fractura ambas muñecas'},
  { sintoma: 'fractura brazo'},
  { sintoma: 'fractura cadera'},
  { sintoma: 'fractura costal'},
  { sintoma: 'fractura de brazo'},
  { sintoma: 'fractura de clavicula'},
  { sintoma: 'fractura de hombro'},
  { sintoma: 'fractura de pelvis'},
  { sintoma: 'fractura de pie'},
  { sintoma: 'fractura dedo mano'},
  { sintoma: 'fractura dedo pie'},
  { sintoma: 'fractura dedo pulgar'},
  { sintoma: 'fractura dedos mano'},
  { sintoma: 'fractura hombro'},
  { sintoma: 'fractura mano'},
  { sintoma: 'fratura brazo'},
  { sintoma: 'frente'},
  { sintoma: 'ganglios inflamados'},
  { sintoma: 'garganta heridas'},
  { sintoma: 'gastritis'},
  { sintoma: 'gatroenteritis'},
  { sintoma: 'gilcemia baja'},
  { sintoma: 'glicemia alterada'},
  { sintoma: 'glicemia'},
  { sintoma: 'glucosa alta'},
  { sintoma: 'golpe cabeza frontal'},
  { sintoma: 'golpe caida'},
  { sintoma: 'golpe ojo'},
  { sintoma: 'golpe corriente'},
  { sintoma: 'golpe costillas'},
  { sintoma: 'golpe dedo pie'},
  { sintoma: 'golpe empeine'},
  { sintoma: 'golpe en cara'},
  { sintoma: 'golpe en dedo'},
  { sintoma: 'golpe en dedo mano'},
  { sintoma: 'golpe en dedo meñique'},
  { sintoma: 'golpe en espalda'},
  { sintoma: 'golpe en frente'},
  { sintoma: 'golpe en la mano'},
  { sintoma: 'golpe en las piernas'},
  { sintoma: 'golpe en muslo'},
  { sintoma: 'golpe en nariz'},
  { sintoma: 'golpe en oreja'},
  { sintoma: 'golpe en pie'},
  { sintoma: 'golpe en pìe'},
  { sintoma: 'golpe en pierna'},
  { sintoma: 'golpe en pies'},
  { sintoma: 'golpe gea'},
  { sintoma: 'golpe genital'},
  { sintoma: 'golpe genitales'},
  { sintoma: 'golpe herida dedo pie'},
  { sintoma: 'golpe labio'},
  { sintoma: 'golpe mandibula'},
  { sintoma: 'golpe ocular'},
  { sintoma: 'golpe pantorrila'},
  { sintoma: 'golpe patada'},
  { sintoma: 'golpe pecho'},
  { sintoma: 'golpe pierna'},
  { sintoma: 'golpe seno'},
  { sintoma: 'golpe testiculo'},
  { sintoma: 'golpe testiculos'},
  { sintoma: 'golpe vagina'},
  { sintoma: 'herida frente'},
  { sintoma: 'cortes'},
  { sintoma: 'golpes'},
  { sintoma: 'granitos cuerpo'},
  { sintoma: 'grano codo'},
  { sintoma: 'granos cara'},
  { sintoma: 'granos cuerpo'},
  { sintoma: 'granos en boca'},
  { sintoma: 'granos en cabeza'},
  { sintoma: 'granos en cara'},
  { sintoma: 'granos en piernas'},
  { sintoma: 'granos garganta'},
  { sintoma: 'granos picadura'},
  { sintoma: 'granos pie'},
  { sintoma: 'irritacion'},
  { sintoma: 'rasguño'},
  { sintoma: 'hemorragia abundante'},
  { sintoma: 'hemorragia alta'},
  { sintoma: 'hemorragia boca'},
  { sintoma: 'hemorragia digestiva'},
  { sintoma: 'hemorragia menstrual'},
  { sintoma: 'purpura'},
  { sintoma: 'hemorragia nasal'},
  { sintoma: 'hemorragia'},
  { sintoma: 'herida ano'},
  { sintoma: 'cara herida'},
  { sintoma: 'herida cara'},
  { sintoma: 'herida encia'},
  { sintoma: 'herida codo'},
  { sintoma: 'herida de cuello'},
  { sintoma: 'herida dedo'},
  { sintoma: 'herida dedo corte'},
  { sintoma: 'herida dedo mano'},
  { sintoma: 'mordedura'},
  { sintoma: 'herida dedo medio'},
  { sintoma: 'herida dedo pie'},
  { sintoma: 'herida dedos'},
  { sintoma: 'herida dedos mano'},
  { sintoma: 'herida dedos pie'},
  { sintoma: 'herida en brazo'},
  { sintoma: 'herida en cabeza'},
  { sintoma: 'contusion'},
  { sintoma: 'herida en cara'},
  { sintoma: 'herida en dedo'},
  { sintoma: 'herida en dedo pie'},
  { sintoma: 'herida en la piel'},
  { sintoma: 'herida en labios'},
  { sintoma: 'herida en mano'},
  { sintoma: 'herida con dedo'},
  { sintoma: 'herida en ojo'},
  { sintoma: 'herida en parpado'},
  { sintoma: 'herida en pene'},
  { sintoma: 'herida en pie'},
  { sintoma: 'ulsera'},
  { sintoma: 'herida labio inferior'},
  { sintoma: 'herida labio'},
  { sintoma: 'herida lengua'},
  { sintoma: 'herida mano'},
  { sintoma: 'herida mano izquierda'},
  { sintoma: 'herida manos'},
  { sintoma: 'herida nariz'},
  { sintoma: 'herida oido'},
  { sintoma: 'herida ojo'},
  { sintoma: 'herida operatoria'},
  { sintoma: 'herida oreja'},
  { sintoma: 'mordedura de perro'},
  { sintoma: 'varice'},
  { sintoma: 'cortadura'},
  { sintoma: 'herida piernas'},
  { sintoma: 'herida planta del pie'},
  { sintoma: 'herida planta pie'},
  { sintoma: 'herida pomulo derecho'},
  { sintoma: 'herida por verruga'},
  { sintoma: 'herida punzante abdominal'},
  { sintoma: 'herida talon'},
  { sintoma: 'herida tobillo'},
  { sintoma: 'heridas cuerpo'},
  { sintoma: 'heridas'},
  { sintoma: 'herido dedo'},
  { sintoma: 'hernia inguinal'},
  { sintoma: 'hernia lumbar'},
  { sintoma: 'hernia umbilical'},
  { sintoma: 'hernia'},
  { sintoma: 'herpes en boca'},
  { sintoma: 'herpes en labio'},
  { sintoma: 'herpes espalda'},
  { sintoma: 'herpes menton'},
  { sintoma: 'herpes ojo'},
  { sintoma: 'herpes pierna'},
  { sintoma: 'hinchada'},
  { sintoma: 'hinchazon de pies'},
  { sintoma: 'hinchazon ojo'},
  { sintoma: 'hiperglicemia'},
  { sintoma: 'hipertenso'},
  { sintoma: 'hipoglicemia'},
  { sintoma: 'hormigueo'},
  { sintoma: 'impetigo boca'},
  { sintoma: 'inconsciente'},
  { sintoma: 'adormecimiento corporal'},
  { sintoma: 'falta apetito'},
  { sintoma: 'infarto'},
  { sintoma: 'infeccion cabeza'},
  { sintoma: 'infeccion de ojos'},
  { sintoma: 'infeccion dedo'},
  { sintoma: 'infeccion dedo corte'},
  { sintoma: 'infeccion dedo mano'},
  { sintoma: 'infeccion dedo pie'},
  { sintoma: 'infeccion dedo pulgar'},
  { sintoma: 'infeccion en brazo'},
  { sintoma: 'infeccion en herida'},
  { sintoma: 'infeccion en heridad mamaria'},
  { sintoma: 'infeccion en oido'},
  { sintoma: 'infeccion en ojo'},
  { sintoma: 'infeccion en pierna'},
  { sintoma: 'infeccion estomacal'},
  { sintoma: 'infeccion garganta'},
  { sintoma: 'infeccion ocular'},
  { sintoma: 'infeccion ojo'},
  { sintoma: 'infeccion ombligo'},
  { sintoma: 'infeccion ojos'},
  { sintoma: 'infeccion oreja'},
  { sintoma: 'infeccion pene'},
  { sintoma: 'infeccion pierna'},
  { sintoma: 'infeccion uña dedo'},
  { sintoma: 'infeccion uña mano'},
  { sintoma: 'infeccion urinaria'},
  { sintoma: 'picaduras'},
  { sintoma: 'quemadura termica'},
  { sintoma: 'inflamacion abdominal'},
  { sintoma: 'inflamacion amigdalas'},
  { sintoma: 'inflamacion ano'},
  { sintoma: 'inflamacion axila'},
  { sintoma: 'inflamacion axilas'},
  { sintoma: 'inflamacion boca'},
  { sintoma: 'inflamacion brazo'},
  { sintoma: 'reaccion alergica'},
  { sintoma: 'inflamacion codo'},
  { sintoma: 'inflamacion cuello'},
  { sintoma: 'inflamacion de hernia'},
  { sintoma: 'inflamacion de rodilla'},
  { sintoma: 'inflamacion de testiculo'},
  { sintoma: 'inflamacion dedo mano'},
  { sintoma: 'inflamacion dedo pie'},
  { sintoma: 'inflamacion dedo'},
  { sintoma: 'inflamacion en brazo'},
  { sintoma: 'inflamacion en cara'},
  { sintoma: 'inflamacion en estomago'},
  { sintoma: 'inflamacion en la pierna'},
  { sintoma: 'inflamacion en mano'},
  { sintoma: 'inflamacion en oreja'},
  { sintoma: 'granos de agua'},
  { sintoma: 'inflamacion en pierna'},
  { sintoma: 'inflamacion encia'},
  { sintoma: 'inflamacion encias'},
  { sintoma: 'inflamacion espalda'},
  { sintoma: 'inflamacion ganglio derecho'},
  { sintoma: 'inflamacion garganta'},
  { sintoma: 'inflamacion genital'},
  { sintoma: 'inflamacion lengua'},
  { sintoma: 'inflamacion mama'},
  { sintoma: 'inflamacion mano'},
  { sintoma: 'inflamacion mano espina'},
  { sintoma: 'inflamacion mano picadura'},
  { sintoma: 'inflamacion muñeca'},
  { sintoma: 'inflamacion nariz'},
  { sintoma: 'inflamacion ocular'},
  { sintoma: 'inflamacion ojos'},
  { sintoma: 'alergia alimentaria'},
  { sintoma: 'inflamacion oreja'},
  { sintoma: 'inflamacion pantorilla'},
  { sintoma: 'inflamacion parpado ojo derecho'},
  { sintoma: 'inflamacion parpado'},
  { sintoma: 'inflamacion picadura'},
  { sintoma: 'inflamacion pierna'},
  { sintoma: 'inflamacion piernas'},
  { sintoma: 'inflamacion pies'},
  { sintoma: 'inflamacion prostata'},
  { sintoma: 'inflamacion quiste espalda'},
  { sintoma: 'inflamacion rodilla'},
  { sintoma: 'inflamacion rostro'},
  { sintoma: 'inflamacion sien'},
  { sintoma: 'inflamacion talon'},
  { sintoma: 'inflamacion testicular'},
  { sintoma: 'inflamacion testiculo'},
  { sintoma: 'inflamacion tobillo'},
  { sintoma: 'picadura pie'},
  { sintoma: 'inflamcion pie'},
  { sintoma: 'ingerir medicamento erroneo'},
  { sintoma: 'ingesta'},
  { sintoma: 'ingesta cloro'},
  { sintoma: 'ingesta cuerpo extraño'},
  { sintoma: 'ingesta de alcohol'},
  { sintoma: 'ingesta de cuerpo extraño'},
  { sintoma: 'ingesta de medicamento'},
  { sintoma: 'ingesta de medicamentos'},
  { sintoma: 'ingesta medicamento'},
  { sintoma: 'ingesta medicamentos'},
  { sintoma: 'ingesta moneda'},
  { sintoma: 'ingesta planta'},
  { sintoma: 'insolacion'},
  { sintoma: 'insomnio'},
  { sintoma: 'insuficiencia cardiaca'},
  { sintoma: 'marcapaso'},
  { sintoma: 'intoxicacion'},
  { sintoma: 'intoxicacion alimentaria'},
  { sintoma: 'intoxicacion por medicamentos'},
  { sintoma: 'irritacion en ojo'},
  { sintoma: 'irritacion ocular'},
  { sintoma: 'itu'},
  { sintoma: 'jaqueca'},
  { sintoma: 'labio inflamado'},
  { sintoma: 'labios hinchados'},
  { sintoma: 'lado derecho de  cabeza dormido'},
  { sintoma: 'lado derecho'},
  { sintoma: 'laringitis'},
  { sintoma: 'lengua caida'},
  { sintoma: 'lesion coxis'},
  { sintoma: 'lesion de rodilla'},
  { sintoma: 'lesion dedo anular'},
  { sintoma: 'lesion dedo indice'},
  { sintoma: 'lesion dedo mano'},
  { sintoma: 'lesion dedo medio'},
  { sintoma: 'lesion dedo pie'},
  { sintoma: 'lesion dedos pie'},
  { sintoma: 'lesion en rodilla'},
  { sintoma: 'lesion ligamento'},
  { sintoma: 'lesion muslo'},
  { sintoma: 'lesion ocular'},
  { sintoma: 'lesion ombligo'},
  { sintoma: 'lesion parpado'},
  { sintoma: 'lesion pierna'},
  { sintoma: 'lesion cara'},
  { sintoma: 'lesion rodilla'},
  { sintoma: 'lesion rodillas'},
  { sintoma: 'lesion uña dedo mano'},
  { sintoma: 'lesion uña dedo pie'},
  { sintoma: 'lesiones boca'},
  { sintoma: 'lesiones dedos manos'},
  { sintoma: 'lunar molestia espalda'},
  { sintoma: 'luxacion'},
  { sintoma: 'malestar cabeza'},
  { sintoma: 'malestar riñon'},
  { sintoma: 'manchas'},
  { sintoma: 'manchas cuerpo'},
  { sintoma: 'manchas rojas'},
  { sintoma: 'dolor articular'},
  { sintoma: 'mandibula trabada'},
  { sintoma: 'mano dormida'},
  { sintoma: 'mano hinchada'},
  { sintoma: 'mano inflamada'},
  { sintoma: 'mareo'},
  { sintoma: 'sudoroso'},
  { sintoma: 'dificultad al hablar'},
  { sintoma: 'dolor de pecho'},
  { sintoma: 'dolor lado izquierdo'},
  { sintoma: 'inapetencia'},
  { sintoma: 'molestia cabeza'},
  { sintoma: 'vision borrosa'},
  { sintoma: 'no escucha'},
  { sintoma: 'problema presion'},
  { sintoma: 'molestia pierna'},
  { sintoma: 'molestia brazo'},
  { sintoma: 'masa debajo oido'},
  { sintoma: 'mastitis'},
  { sintoma: 'materia en ojos'},
  { sintoma: 'material extraño en ojo'},
  { sintoma: 'migraña'},
  { sintoma: 'molestia abdomen'},
  { sintoma: 'molestia al respirar'},
  { sintoma: 'molestia boca'},
  { sintoma: 'molestia cara'},
  { sintoma: 'molestia cicatriz'},
  { sintoma: 'molestia corporal'},
  { sintoma: 'molestia cuello'},
  { sintoma: 'molestia de estomago'},
  { sintoma: 'molestia de oidos'},
  { sintoma: 'molestia dedo mano'},
  { sintoma: 'molestia dedo'},
  { sintoma: 'molestia en boca'},
  { sintoma: 'molestia en espalda'},
  { sintoma: 'molestia en hombro'},
  { sintoma: 'molestia en ojo'},
  { sintoma: 'molestia en ojos'},
  { sintoma: 'molestia en parpado'},
  { sintoma: 'molestia en pecho'},
  { sintoma: 'molestia en pie'},
  { sintoma: 'molestia en planta del pie'},
  { sintoma: 'molestia en rodilla'},
  { sintoma: 'molestia en uña'},
  { sintoma: 'molestia espalda'},
  { sintoma: 'molestia estomacal'},
  { sintoma: 'rotura termometro en boca'},
  { sintoma: 'molestia oidos'},
  { sintoma: 'molestia herida post operatoria'},
  { sintoma: 'molestia hombro'},
  { sintoma: 'molestia labios'},
  { sintoma: 'molestia mano'},
  { sintoma: 'facial'},
  { sintoma: 'molestia oido'},
  { sintoma: 'molestia ojos'},
  { sintoma: 'molestia oreja'},
  { sintoma: 'molestia pelvis'},
  { sintoma: 'molestia pene'},
  { sintoma: 'molestia piernas'},
  { sintoma: 'molestia pies'},
  { sintoma: 'molestia presion'},
  { sintoma: 'molestia rodilla'},
  { sintoma: 'molestia testiculo'},
  { sintoma: 'molestia vagina'},
  { sintoma: 'molestia vista'},
  { sintoma: 'molestias abdominal'},
  { sintoma: 'molestias al orinar'},
  { sintoma: 'molestias al respirar'},
  { sintoma: 'molestias anemia'},
  { sintoma: 'molestias ano'},
  { sintoma: 'molestias brazo'},
  { sintoma: 'molestias cara'},
  { sintoma: 'molestias cardiacas y respiratorias'},
  { sintoma: 'molestias cardiacas'},
  { sintoma: 'molestias codo'},
  { sintoma: 'molestias colon'},
  { sintoma: 'molestias con la presion'},
  { sintoma: 'molestias con parche'},
  { sintoma: 'molestias con yeso'},
  { sintoma: 'molestias corazon'},
  { sintoma: 'molestias cuello'},
  { sintoma: 'molestias de presion'},
  { sintoma: 'molestias dedo pie'},
  { sintoma: 'molestias en boca'},
  { sintoma: 'molestias en garganta'},
  { sintoma: 'molestias en brazo'},
  { sintoma: 'molestias en bronquios'},
  { sintoma: 'molestias en cabeza'},
  { sintoma: 'molestias en cara'},
  { sintoma: 'molestias en codo'},
  { sintoma: 'molestias en dedo'},
  { sintoma: 'molestias en estomago'},
  { sintoma: 'molestias en hombros'},
  { sintoma: 'molestias en mano'},
  { sintoma: 'molestias en oido'},
  { sintoma: 'molestias garganta'},
  { sintoma: 'molestias en oidos'},
  { sintoma: 'molestias en ojo'},
  { sintoma: 'molestias en ojos'},
  { sintoma: 'molestias en pecho'},
  { sintoma: 'molestias en pechos'},
  { sintoma: 'molestias en pie'},
  { sintoma: 'molestias en piel'},
  { sintoma: 'molestias en pierna'},
  { sintoma: 'molestias en piernas'},
  { sintoma: 'molestias en riñones'},
  { sintoma: 'molestias en vision'},
  { sintoma: 'molestias encias'},
  { sintoma: 'molestias espalda'},
  { sintoma: 'molestias estomacales'},
  { sintoma: 'molestias genital'},
  { sintoma: 'molestias ginecologicas'},
  { sintoma: 'molestias herida post operatoria'},
  { sintoma: 'molestias lengua'},
  { sintoma: 'molestias ocular'},
  { sintoma: 'molestias oculares'},
  { sintoma: 'molestias ojo'},
  { sintoma: 'molestias ojos'},
  { sintoma: 'molestias pecho'},
  { sintoma: 'palpitaciones irregulares'},
  { sintoma: 'molestias pene'},
  { sintoma: 'molestias pie'},
  { sintoma: 'molestias pierna'},
  { sintoma: 'inflamada'},
  { sintoma: 'molestias piernas'},
  { sintoma: 'molestias por medicamentos'},
  { sintoma: 'molestias post medicamentos'},
  { sintoma: 'molestias postoperatorias'},
  { sintoma: 'molestias presion'},
  { sintoma: 'vision'},
  { sintoma: 'molestias rodilla'},
  { sintoma: 'molestias seno'},
  { sintoma: 'molestias tendon de aquiles'},
  { sintoma: 'molestias urinarias'},
  { sintoma: 'molestias urologicas'},
  { sintoma: 'mordedura de araña'},
  { sintoma: 'mordedura de avispa'},
  { sintoma: 'mordedura de gato'},
  { sintoma: 'mordedura de raton'},
  { sintoma: 'moreton brazo'},
  { sintoma: 'dolor estomacal'},
  { sintoma: 'dormido labio'},
  { sintoma: 'nervio ciatico'},
  { sintoma: 'neumonitis'},
  { sintoma: 'neuralgia'},
  { sintoma: 'no poder orinar'},
  { sintoma: 'no siente manos'},
  { sintoma: 'nodulos pulmones'},
  { sintoma: 'objeto en cabeza'},
  { sintoma: 'objeto en nariz'},
  { sintoma: 'objeto en oido'},
  { sintoma: 'objeto en ojo'},
  { sintoma: 'ojo'},
  { sintoma: 'obstruida'},
  { sintoma: 'obstruida con alimento'},
  { sintoma: 'obstruida pecho'},
  { sintoma: 'adormeciminto cara'},
  { sintoma: 'asmatico'},
  { sintoma: 'oido tapado'},
  { sintoma: 'oidos cuello'},
  { sintoma: 'oidos mareos'},
  { sintoma: 'ojo hinchado'},
  { sintoma: 'ojos rojos'},
  { sintoma: 'orina con sangre'},
  { sintoma: 'orina sangre'},
  { sintoma: 'palpitaciones bajas'},
  { sintoma: 'palpitaciones irregulares constantes'},
  { sintoma: 'palpitaciones'},
  { sintoma: 'pecho'},
  { sintoma: 'paralisis facial'},
  { sintoma: 'paralisis'},
  { sintoma: 'perdida de habla'},
  { sintoma: 'perdida de movimiento'},
  { sintoma: 'dolor  cabeza'},
  { sintoma: 'perdida de vision'},
  { sintoma: 'peste'},
  { sintoma: 'picadura abdomen'},
  { sintoma: 'picadura brazo'},
  { sintoma: 'picadura cara'},
  { sintoma: 'picadura de abeja'},
  { sintoma: 'dedos morados'},
  { sintoma: 'mano inchada'},
  { sintoma: 'picadura de araña'},
  { sintoma: 'picadura de avispa'},
  { sintoma: 'picadura de garrapata'},
  { sintoma: 'picadura de insecto'},
  { sintoma: 'picadura dedo'},
  { sintoma: 'picadura infectada'},
  { sintoma: 'picadura mano'},
  { sintoma: 'picadura manos y piernas'},
  { sintoma: 'picadura ojo'},
  { sintoma: 'picadura oreja'},
  { sintoma: 'picadura pierna'},
  { sintoma: 'ampoas'},
  { sintoma: 'brazo adormecido'},
  { sintoma: 'picaduras de zancudo'},
  { sintoma: 'picaduras en brazo'},
  { sintoma: 'picaduras infectadas'},
  { sintoma: 'picaduras piernas'},
  { sintoma: 'tobillos inflamados'},
  { sintoma: 'alergias'},
  { sintoma: 'picason zona genital'},
  { sintoma: 'picazon corporal'},
  { sintoma: 'picazon cuerpo y ojos'},
  { sintoma: 'picazon de oreja'},
  { sintoma: 'picazon en manos y pies'},
  { sintoma: 'picazon en ojos'},
  { sintoma: 'pie derecho'},
  { sintoma: 'dedo'},
  { sintoma: 'pie hinchado'},
  { sintoma: 'pie inflamacion'},
  { sintoma: 'piel amarilla'},
  { sintoma: 'pierna y brazo izquierdo inflamados'},
  { sintoma: 'brazos'},
  { sintoma: 'dificultad hablar'},
  { sintoma: 'piernas hinchadas'},
  { sintoma: 'pintas cuerpo'},
  { sintoma: 'plasticina en oido'},
  { sintoma: 'polipo espalda'},
  { sintoma: 'posible fractura'},
  { sintoma: 'posible intoxicacion'},
  { sintoma: 'presion en dedo'},
  { sintoma: 'presion en mano con puerta'},
  { sintoma: 'daño uña'},
  { sintoma: 'presion en ojos'},
  { sintoma: 'presion en pecho'},
  { sintoma: 'presion en pie'},
  { sintoma: 'presion pecho y espalda'},
  { sintoma: 'problema abdomen'},
  { sintoma: 'problema bronquiopulmonar'},
  { sintoma: 'problema cadera'},
  { sintoma: 'problema codo'},
  { sintoma: 'problema dedo mano'},
  { sintoma: 'problema dedo pie'},
  { sintoma: 'problema dermatologico'},
  { sintoma: 'problema en ojo'},
  { sintoma: 'problema estomacal'},
  { sintoma: 'problema glicemia'},
  { sintoma: 'problema herida mano'},
  { sintoma: 'problema herida operatoria'},
  { sintoma: 'problema herida'},
  { sintoma: 'problema nervio ciatico'},
  { sintoma: 'problema oido'},
  { sintoma: 'problema oidos'},
  { sintoma: 'problema ojo'},
  { sintoma: 'problema para orinar'},
  { sintoma: 'problema pene'},
  { sintoma: 'problema pie'},
  { sintoma: 'problema pierna'},
  { sintoma: 'problema renal'},
  { sintoma: 'problema rodilla'},
  { sintoma: 'problema urologico'},
  { sintoma: 'problemas al respirar'},
  { sintoma: 'problemas de movilidad'},
  { sintoma: 'problemas de presion'},
  { sintoma: 'problemas estomacales'},
  { sintoma: 'problemas herida operatoria'},
  { sintoma: 'problemas para caminar'},
  { sintoma: 'problemas vista'},
  { sintoma: 'prurito vulvar'},
  { sintoma: 'puntada pecho'},
  { sintoma: 'puntadas'},
  { sintoma: 'quemadura brazo'},
  { sintoma: 'quemadura cara'},
  { sintoma: 'quemadura dedo mano'},
  { sintoma: 'quemadura en mano y pies'},
  { sintoma: 'quemadura infectada'},
  { sintoma: 'quemadura pie'},
  { sintoma: 'quemadura pierna'},
  { sintoma: 'quemadura piernas'},
  { sintoma: 'quemadura solar'},
  { sintoma: 'quiste'},
  { sintoma: 'quiste sebaceo'},
  { sintoma: 'rasguño de gato'},
  { sintoma: 'rasguño en ojo'},
  { sintoma: 'rash alergico'},
  { sintoma: 'reaccion a alimentos'},
  { sintoma: 'reaccion a medicamento'},
  { sintoma: 'reaccion alergica a medicamento'},
  { sintoma: 'reaccion alergica a picadura'},
  { sintoma: 'inflamacion legua'},
  { sintoma: 'problema labios'},
  { sintoma: 'ronchas en cuerpo'},
  { sintoma: 'retencion urinaria'},
  { sintoma: 'pies hinchados'},
  { sintoma: 'rigidez muscular'},
  { sintoma: 'erupciones cuerpo'},
  { sintoma: 'rodilla inflamada'},
  { sintoma: 'ronchas en brazos'},
  { sintoma: 'ronchas en cara'},
  { sintoma: 'ronchas cara'},
  { sintoma: 'ronchas en piernas'},
  { sintoma: 'ronchas espalda'},
  { sintoma: 'sangrado ano'},
  { sintoma: 'sangrado brazo'},
  { sintoma: 'sangrado cara'},
  { sintoma: 'adormecimiento manos'},
  { sintoma: 'sangrado de oido'},
  { sintoma: 'sangrado herida operatoria'},
  { sintoma: 'sangrado herida'},
  { sintoma: 'sangrado higado'},
  { sintoma: 'sangrado pierna'},
  { sintoma: 'varices roto'},
  { sintoma: 'sangrado rodilla'},
  { sintoma: 'sangrado umbilical'},
  { sintoma: 'cordon'},
  { sintoma: 'sangrado vaginal'},
  { sintoma: 'se entierra clavo'},
  { sintoma: 'se entierra espina'},
  { sintoma: 'se entierra fierro'},
  { sintoma: 'secrecion ocular'},
  { sintoma: 'sensacion de desmayo'},
  { sintoma: 'sensacion de frio'},
  { sintoma: 'sintomas abortivos'},
  { sintoma: 'sobredosis medicamentos'},
  { sintoma: 'stress'},
  { sintoma: 'temblores manos'},
  { sintoma: 'temblores piernas'},
  { sintoma: 'tendon recogido'},
  { sintoma: 'testiculo inflamado'},
  { sintoma: 'tiron pierna'},
  { sintoma: 'tobillo inflamado'},
  { sintoma: 'tobillo'},
  { sintoma: 'torcedura de dedo'},
  { sintoma: 'torcedura de dedo mano'},
  { sintoma: 'torcedura de muñeca'},
  { sintoma: 'torcedura de pies'},
  { sintoma: 'torcedura de rodilla'},
  { sintoma: 'torsion testicular'},
  { sintoma: 'tos con flema'},
  { sintoma: 'tos seca'},
  { sintoma: 'oidos'},
  { sintoma: 'flema'},
  { sintoma: 'obstruccion nasal'},
  { sintoma: 'uña encarnada'},
  { sintoma: 'varice reventada'},
  { sintoma: 'vomitos con sangre'},
  { sintoma: 'descompensacion por medicamentos'},
  { sintoma: 'acidez estomacal'},
  { sintoma: 'yagas en piernas'},
  { sintoma: 'zumbido oido'},
  { sintoma: 'oido taponeado'},
]

function SintomasInput({
  chatController,
  actionRequest,
}) {
  const chatCtl = chatController;

  const defaultProps = {
    options: catalogo_sintomas,
    getOptionLabel: (option) => option.sintoma,
  };





  const [sintoma1, setSintoma1] = React.useState("");
  const [sintoma2, setSintoma2] = React.useState("");
  const [sintoma3, setSintoma3] = React.useState("");
  const [sintoma4, setSintoma4] = React.useState("");
  const [sintoma5, setSintoma5] = React.useState("");
  const [value, setValue] = React.useState(catalogo_sintomas[0]);

  const flatProps = {
    options: catalogo_sintomas.map((option) => option.sintoma),
  };

  /*const setResponse = React.useEffect((): void  => {
    console.log("value:" +value.sintoma)
    const res = { type: 'custom', value: sintoma1.sintoma};
    chatCtl.setActionResponse(actionRequest, res);
  }, [actionRequest, chatCtl]);
*/

  const setResponse = () => {
    console.log("value:" +sintoma1.sintoma)
    let valor_texto=""
    if (sintoma1 !== ""){
        valor_texto=valor_texto+""+sintoma1.sintoma
    }
    if (sintoma2 !== ""){
        valor_texto=valor_texto+"\n"+sintoma2.sintoma
    }
    if (sintoma3 !== ""){
        valor_texto=valor_texto+"\n"+sintoma3.sintoma
    }
    if (sintoma4 !== ""){
        valor_texto=valor_texto+"\n"+sintoma4.sintoma
    }
    if (sintoma5 !== ""){
        valor_texto=valor_texto+"\n"+sintoma5.sintoma
    }

    const res = { type: 'custom', value: valor_texto,sintoma1:sintoma1.sintoma,sintoma2:sintoma2.sintoma,sintoma3:sintoma3.sintoma,sintoma4:sintoma4.sintoma,sintoma5:sintoma5.sintoma};

    chatCtl.setActionResponse(actionRequest, res);
  }



  return (
    <div style={{ width: 300 }}>
     <Autocomplete
       {...defaultProps}
       id="sintoma1"
       onChange={(event, newValue) => {
          setSintoma1(newValue);
        }}
       renderInput={(params) => <TextField {...params} label="Sintoma 1" margin="normal" />}
     />
     <Autocomplete
       {...defaultProps}
       id="disable-close-on-select"
       onChange={(event, newValue) => {
          setSintoma2(newValue);
        }}
       renderInput={(params) => (
         <TextField {...params} label="Sintoma 2" margin="normal" />
       )}
     />
     <Autocomplete
       {...defaultProps}
       id="clear-on-escape"
       onChange={(event, newValue) => {
          setSintoma3(newValue);
        }}
       renderInput={(params) => <TextField {...params} label="Sintoma 3" margin="normal" />}
     />
     <Autocomplete
       {...defaultProps}
       id="disable-clearable"
       onChange={(event, newValue) => {
          setSintoma4(newValue);
        }}
       renderInput={(params) => <TextField {...params} label="Sintoma 4" margin="normal" />}
     />
     <Autocomplete
       {...defaultProps}
       id="include-input-in-list"
       onChange={(event, newValue) => {
          setSintoma5(newValue);
        }}
       renderInput={(params) => (
         <TextField {...params} label="Sintoma 5" margin="normal" />
       )}
     />
     <Button
      type="button"
      onClick={setResponse}
      variant="contained"
      color="primary"
    >
      Enviar
    </Button>
   </div>
  );
}


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'gray',
  },
  container: {
    minHeight: '62vh',
    height: '42vh',
    maxWidth: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowY: 'auto',
    overflowX: 'none',
    padding:'3vh',
    backgroundColor: 'white',
    minHeight: "93vh",
  },
  action: {
    flex: '2 0 auto'

  }

}));

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function ChatBot() {
  const classes = useStyles();
  const [chatCtl] = React.useState(new ChatController());
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");



  const echo = async (chatCtlobj)=>{
    let parametros={};

    await chatCtlobj.addMessage({
      type: 'text',
      content: `Hola Soy Kuery, Tú asistente virtual de atención de urgencias médicas.`,
      self: false,
    });

    await sleep(2000);

    await chatCtlobj.addMessage({
      type: 'text',
      content: `Por selecciona las opciones que mejor describan la Urgencia Médica.`,
      self: false,

    });

    const el_paciente = await chatCtlobj.setActionRequest({
      type: 'select',
      options: [
        {
          value: 'yo',
          text: 'Yo soy el paciente',
        },
        {
          value: 'hijo',
          text: 'Mi Hijo(a)',
        },
        {
          value: 'mi_mujer',
          text: 'Mi Mujer',
        },
        {
          value: 'mi_marido',
          text: 'Mi Marido',
        },
        {
          value: 'otro_familiar',
          text: 'Otro familiar',
        },
      ],
    });

    const ConsultaDiagnostico = async (sintoma1)=>{
       let response = await axios
      .get(API_URL_DIAGNOSTICO+'/'+sintoma1, header)
      .then( res => { return res; })
      .catch( error => {
          console.log("ERROR", error);
          return {
              error:error
          };
      });

      return response;
    }



    let respuesta_paciente = el_paciente.option.value

    let pregunta_genero=""
    parametros.SEXO_PACIENTE = "";

    let pregunta_edad=""

    switch (respuesta_paciente) {
        case 'yo':
          pregunta_genero="Por favor indique su género"
          pregunta_edad="Por favor ingrese su edad"
        break;
        case 'hijo':
          pregunta_genero="Por favor indique el género de su hijo"
          pregunta_edad="Por favor ingrese la edad de su hijo"
        break;
        case 'mi_mujer':
            parametros.SEXO_PACIENTE = "femenino";
            pregunta_edad="Por favor ingrese la edad de su mujer"
        break;
        case 'mi_marido':
          parametros.SEXO_PACIENTE = "masculino";
          pregunta_edad="Por favor ingrese la edad de su marido"
        break;
        case 'otro_familiar':
          pregunta_genero="Por favor indique el género de su familiar"
          pregunta_edad="Por favor ingrese la edad de su familiar"
      default:

    }

    let genero=""

    if (parametros.SEXO_PACIENTE =="") {
      let sexoPaciente = el_paciente.value;
      parametros.SEXO_PACIENTE = sexoPaciente;

      console.log("pregunta_genero "+pregunta_genero)

      console.log("sexoPaciente", sexoPaciente);
      await chatCtlobj.addMessage({
        type: 'text',
        content: pregunta_genero,
        self: false,
      });

      genero = await chatCtlobj.setActionRequest({
        type: 'select',
        options: [
          {
            value: 'masculino',
            text: 'Masculino',
          },
          {
            value: 'femenino',
            text: 'Femenino',
          },
        ],
      });
    }

    await chatCtlobj.addMessage({
      type: 'text',
      content: pregunta_edad,
      self: false,
    });

    let edad = 0;
    edad = await chatCtlobj.setActionRequest({
      type: 'text',
      placeholder: "Ingrese edad",
      self:true
    });

    parametros.EDAD_PACIENTE = edad;

    await chatCtlobj.addMessage({
      type: 'text',
      content: "Presenta alguna de estas condiciones?",
      self: false,
    });

    let letales = await chatCtlobj.setActionRequest({
      type: 'select',
      options: [
        {
          value: 'caida',
          text: 'Caída de más de 2 metros de altura',
        },
        {
          value: 'atropello',
          text: 'Accidente vehicular o atropello',
        },
        {
          value: 'quemadura',
          text: 'Quemadura grave',
        },
        {
          value: 'inmersion',
          text: 'Inmersion o Ahogamiento',
        },
        {
          value: 'hemorragia',
          text: 'Hemorragia',
        },
        {
          value: 'paro',
          text: 'Dolor en pecho con falta de aire',
        },
        {
          value: 'sin_condiciones_letales',
          text: 'No se presentan estas condiciones',
        },
      ],
    });

    parametros.letales = letales

    console.log(parametros.letales.option.value )

    if (parametros.letales.option.value != 'sin_condiciones_letales') {
        alert("Nivel de urgencia", "Su nivel de urgencia no puede ser atentido en forma remota, le recomendamos asistir a un centro de urgencia prontamente");
        return
    }


    await chatCtlobj.addMessage({
      type: 'text',
      content: "Por favor ingrese los síntomas principales asociados a su condición.",
      self: false,
    });

    const custom = await chatCtl.setActionRequest({
      type: 'custom',
      Component: SintomasInput,
    });

    console.log("RECUPERANDO SINTOMA!")
    console.log(custom.sintoma1);

    await chatCtlobj.addMessage({
      type: 'text',
      content: "¿Desde cuando se presentan los síntomas?",
      self: false,
    });

    let tiempo = await chatCtlobj.setActionRequest({
      type: 'select',
      options: [
        {
          value: 'periodo1',
          text: 'Hace 1 hora',
        },
        {
          value: 'periodo2',
          text: 'Hace 1 día',
        },
        {
          value: 'periodo3',
          text: 'Hace 2 días',
        },
        {
          value: 'periodo4',
          text: 'Hace 1 semana',
        },
        {
          value: 'periodo5',
          text: 'Desde hace más de 1 semana',
        }
      ],
    });

    await chatCtlobj.addMessage({
      type: 'text',
      content: "¿Presenta alguna de las siguientes condiciones crónicas?",
      self: false,
    });

    let cronica = await chatCtlobj.setActionRequest({
      type: 'multi-select',
      options: [
        {
          value: 'cronica1',
          text: 'Diabetes',
        },
        {
          value: 'cronica2',
          text: 'Alergia',
        },
        {
          value: 'cronica3',
          text: 'Enfermedad cardiovascular',
        },
        {
          value: 'cronica4',
            text: 'Enfermedad respiratoria crónica',
        },
        {
          value: 'cronica5',
          text: 'Cancer',
        },
        {
          value: 'cronica6',
          text: 'Sin condiciones cronicas',
        }
      ],
    });

    await chatCtlobj.addMessage({
      type: 'text',
      content: "Tiene alguna otra condición u observación que sea de importancia?",
      self: false,
    });

    const otras_condiciones = await chatCtl.setActionRequest({
      type: 'text',
      always: false,
    });

    let listado_sintomas=custom.sintoma1+"_"+custom.sintoma2+"_"+custom.sintoma3+"_"+custom.sintoma4+"_"+custom.sintoma5
    console.log(listado_sintomas);
    let respuesta_diagnostico = await ConsultaDiagnostico(listado_sintomas);

    await chatCtlobj.addMessage({
          type: 'text',
          content: "El diagnostico más probable corresponde a: "+respuesta_diagnostico.data.diagnostico,
          self: false,
    });

    await chatCtlobj.addMessage({
          type: 'text',
          content: "Si quiere una atención médica en vivo, visite la seccion de teleconsulta médica",
          self: false,
    });

    if (""+cronica.cronica1=="undefined"){
      cronica.cronica1="no"
    }else{
      cronica.cronica1="si"
    }

    if (""+cronica.cronica1=="undefined"){
      cronica.cronica1="no"
    }else{
      cronica.cronica1="si"
    }
    if (""+cronica.cronica2=="undefined"){
      cronica.cronica2="no"
    }else{
      cronica.cronica2="si"
    }
    if (""+cronica.cronica3=="undefined"){
      cronica.cronica3="no"
    }else{
      cronica.cronica3="si"
    }
    if (""+cronica.cronica4=="undefined"){
      cronica.cronica4="no"
    }else{
      cronica.cronica4="si"
    }

    if (""+custom.sintoma1=="undefined"){
      custom.sintoma1=""
    }
    if (""+custom.sintoma2=="undefined"){
      custom.sintoma2=""
    }
    if (""+custom.sintoma3=="undefined"){
      custom.sintoma3=""
    }
    if (""+custom.sintoma4=="undefined"){
      custom.sintoma4=""
    }
    if (""+custom.sintoma5=="undefined"){
      custom.sintoma5=""
    }

    let condicion_diabetes= (letales.value.includes("Diabetes")?"si":"no");
    let condicion_hipertension= (letales.value.includes("Hipertensión")?"si":"no");
    let condicion_enfermedad_tiroidea= (letales.value.includes("Enfermedad Tiroidea")?"si":"no");
    let condicion_embarazo= (letales.value.includes("Embarazo")?"si":"no");
    let condicion_alergico= (letales.value.includes("Alergico")?"si":"no");
    let condicion_cancer= (letales.value.includes("Cancer")?"si":"no");
    let condicion_inmunosupresor= (letales.value.includes("Uso de Inmuno Supresor")?"si":"no");

    let ahora=new Date();

    let anio=ahora.getFullYear();
    let mes=ahora.getMonth()+1;  //en javascript parte en 0 el primer mes.
    let dia=ahora.getDate();

    let fecha=new Date().toISOString().split("T")[0];
    let hora=new Date().toISOString().split("T")[1].replace("Z","");

    let parametro_json=  {"fecha":fecha,
      "hora":hora,
      "nombre":"" +nombre_paciente.value,
      "rut": ""+rut_paciente.value,
      "relacion":""+rut_paciente.value,
      "edad":""+edad.value,
      "genero":""+genero.value,
      "sintoma1":""+custom.sintoma1,
      "sintoma2":""+custom.sintoma2,
      "sintoma3":""+custom.sintoma3,
      "sintoma4":""+custom.sintoma4,
      "sintoma5":""+custom.sintoma5,

      "sintomas_desde1":""+tiempos[0],
      "sintomas_desde2":""+tiempos[1],
      "sintomas_desde3":""+tiempos[2],
      "sintomas_desde4":""+tiempos[3],
      "sintomas_desde5":""+tiempos[4],

      "diabetes":condicion_diabetes,
      "hipertension":condicion_hipertension,
      "enfermedad_tiroidea":condicion_enfermedad_tiroidea,
      "embarazo":condicion_embarazo,
      "alergico":condicion_alergico,
      "cancer":condicion_cancer,
      "inmunosupresor":condicion_inmunosupresor,
      "diagnostico_ia":""+respuesta_diagnostico.data.diagnostico,
      "imagen":imagenPicadura,
      "diagnostico_imagen":respuestaIaImagen,
      "otras_condiciones":otras_condiciones.value
      }

    let responde = await ServiceGenerarPreinforme(parametro_json);




/*
    console.log(parametros);
    let response = await ServiceGuardarTriage(parametros);
    console.log(response);
    if (response.data.code === 200){
      if(response.data.nivel > 4 ){
        alert("Nivel de urgencia", "Su nivel de urgencia es alto, le recomendamos asistir a un centro de urgencia prontamente");
      }else{
        alert("Nivel de urgencia", "Su nuvel de urgencia es bajo, le recomendamos sacar hora medica con nuestros especialistas");
      }
    }else{
      alert("ERROR", "A ocurrido un error, favor reintentar");
    }
  */
  }

  const alert = (title, message)=>{
    setTitle(title);
    setMessage(message);
    setOpen(true);
  }

  const handleClose = () => {
    window.location.reload();
  }

  React.useMemo(() => {
    echo(chatCtl);
  }, [chatCtl]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <MuiChat chatController={chatCtl} />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
