import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function BusquedaMedicamento(props) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [strMedicamento, setStrMedicamento] = useState('');
    const [value, setValue] = React.useState(null);

    function filterByValue(string) {
        if(string?.toLowerCase()){
            /**
             * agregar llamada a servicio de busqueda de medicamentos
             */
            return dataARRAY.filter( elm => elm.name?.toLowerCase().includes(string.toLowerCase()) ) ;
        }else{
            return [];
        }
    }

    useEffect(() => {
        let active = true;
        if (!loading || strMedicamento==="") {
            return undefined;
        }

        (async () => {
            if(!strMedicamento){
                return;
            }
            const medicamentos = filterByValue(strMedicamento);
            //await sleep(1e3); // For demo purposes.
            //const countries = await response.json();
            if (active) {
                setOptions(Object.keys(medicamentos).map((key) => medicamentos[key]));
                //props.setMedicinaSeleccionada(Object.keys(medicamentos).map((key) => medicamentos[key]));
            }
        })();

        return () => {
            active = false;
        };
    }, [strMedicamento]);

    useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);

    const selectedOption = (optionD, valueD) => {
        if(valueD){
            props.onMedicinaSeleccionada(valueD);
            console.log("option", valueD.id);
          /*  setValue(null);*/
            console.log("option", valueD.id);
        }
    }


    return (
        <Autocomplete
            value={value}
            renderOption={(option) => option.name}
            selectOnFocus
            clearOnBlur
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
                return option.name;
            }}
            onChange={(event, newValue) => {
                console.log(newValue.id);
                setValue(newValue);
                selectedOption(event, newValue);
            }}
            options={dataARRAY}
            renderInput={(params) => (
                <TextField {...params} label="Medicamento o principio activo" margin="normal" variant="outlined" />
            )}
        />
    );
}

    /**
     * <Autocomplete
      id="asynchronous"
      style={{ width: 500 }}
      open={open}
      onOpen={() => {
          setOpen(true);
      }}
      onClose={() => {
          setOpen(false);
      }}
      getOptionSelected={(option, value) => {
          if(option.id === value.id){
              selectedOption(option, value);
              return true;
          }else{
              return false;
          }
      }}
      getOptionLabel={(option) => { return option.name}}
      options={options}
      loading={loading}
      renderInput={(params) => (
      <TextField
          {...params}
          label="Medicamento o principio activo"
          variant="outlined"
          InputProps={{
          ...params.InputProps,
          endAdornment: (
              <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </>
          ),
          }}
          onChange={e=>{setStrMedicamento(e.target.value)}}
      />
      )}
  />
     */

const dataARRAY = [
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "abatacept",
                    "snomedId": "421777009",
                    "measureValue": 250,
                    "measure": "250 mg",
                    "id": "S-4588936"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5986508",
        "name": "abatacept 250 mg inyectable",
        "snomedId": "421333000"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589381",
                "desc": "nebulización"
            },
            "medicationForm": [
                {
                    "id": "5715357",
                    "administerForm": [
                        "ml",
                        "Ampolla"
                    ],
                    "desc": "solución para nebulización"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "acetilcisteína",
                    "snomedId": "387440002",
                    "measureValue": 100,
                    "measure": "100 mg/mL",
                    "id": "S-4840615"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-421025",
        "name": "acetilcisteína 100 mg/mL solución para nebulización",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "aciclovir",
                    "snomedId": "372729009",
                    "measureValue": 250,
                    "measure": "250 mg",
                    "id": "S-4841356"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7028370",
        "name": "aciclovir 250 mg inyectable",
        "snomedId": "324728003"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "ácido 5 - aminolevulínico",
                    "snomedId": "259496005",
                    "measureValue": 30,
                    "measure": "30 mg/mL",
                    "id": "S-4842161"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5986518",
        "name": "ácido 5 - aminolevulínico 30 mg/mL polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714967",
                    "administerForm": [
                        "Comprimido"
                    ],
                    "desc": "comprimido"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido acetilsalicílico",
                    "snomedId": "387458008",
                    "measureValue": 250,
                    "measure": "250 mg",
                    "id": "S-4844504"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 65,
                    "measure": "65 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 250,
                    "measure": "250 mg",
                    "id": "S-5166991"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7379190",
        "name": "ácido acetilsalicílico 250 mg + cafeína 65 mg + paracetamol 250 mg comprimido",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714967",
                    "administerForm": [
                        "Comprimido"
                    ],
                    "desc": "comprimido"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido acetilsalicílico",
                    "snomedId": "387458008",
                    "measureValue": 300,
                    "measure": "300 mg",
                    "id": "S-4844504"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 45,
                    "measure": "45 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 200,
                    "measure": "200 mg",
                    "id": "S-5166991"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7413865",
        "name": "ácido acetilsalicílico 300 mg + cafeína 45 mg + paracetamol 200 mg comprimido",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido acetilsalicílico",
                    "snomedId": "387458008",
                    "measureValue": 500,
                    "measure": "500 mg",
                    "id": "S-4844504"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 200,
                    "measure": "200 mg",
                    "id": "S-4846670"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7377327",
        "name": "ácido acetilsalicílico 500 mg + ácido ascórbico 200 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5715371",
                    "administerForm": [
                        "ml",
                        "Cucharada",
                        "Cucharadita"
                    ],
                    "desc": "suspensión oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 5,
                    "measure": "5 mg/mL",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 0.22,
                    "measure": "0.22 mg/mL",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 20,
                    "measure": "20 mg/mL",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "pseudoefedrina clorhidrato",
                    "snomedId": "12177002",
                    "measureValue": 3,
                    "measure": "3 mg/mL",
                    "id": "S-5975242"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7640286",
        "name": "ácido ascórbico 25 mg/5 mL + noscapina 1,1 mg/5 mL + paracetamol 100 mg/5 mL + pseudoefedrina clorhidrato 15 mg/5 mL suspensión oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7084245",
                    "administerForm": [
                        "Gotas"
                    ],
                    "desc": "gotas orales"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 25,
                    "measure": "25 mg/mL",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 1.1,
                    "measure": "1.1 mg/mL",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 100,
                    "measure": "100 mg/mL",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "pseudoefedrina clorhidrato",
                    "snomedId": "12177002",
                    "measureValue": 15,
                    "measure": "15 mg/mL",
                    "id": "S-5975242"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7640347",
        "name": "ácido ascórbico 25 mg/mL + noscapina 1,1 mg/mL + paracetamol 100 mg/mL + pseudoefedrina clorhidrato 15 mg/mL gotas orales",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 33,
                    "measure": "33 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 400,
                    "measure": "400 mg",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "clorfenamina",
                    "snomedId": "372914003",
                    "measureValue": 4,
                    "measure": "4 mg",
                    "id": "S-5199623"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7354121",
        "name": "ácido ascórbico 50 mg + cafeína 33 mg + clorfenamina 4 mg + noscapina 10 mg + paracetamol 400 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714915",
                    "administerForm": [
                        "Cápsula"
                    ],
                    "desc": "cápsula"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 33,
                    "measure": "33 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 400,
                    "measure": "400 mg",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "clorfenamina",
                    "snomedId": "372914003",
                    "measureValue": 4,
                    "measure": "4 mg",
                    "id": "S-5199623"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7469721",
        "name": "ácido ascórbico 50 mg + cafeína 33 mg + noscapina 10 mg + paracetamol 400 mg cápsula y ácido ascórbico 50 mg + cafeína 33 mg + clorfenamina 4 mg + noscapina 10 mg + paracetamol 400 mg cápsula",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 33,
                    "measure": "33 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 400,
                    "measure": "400 mg",
                    "id": "S-5166991"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7354106",
        "name": "ácido ascórbico 50 mg + cafeína 33 mg + noscapina 10 mg + paracetamol 400 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 40,
                    "measure": "40 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 650,
                    "measure": "650 mg",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "clorfenamina",
                    "snomedId": "372914003",
                    "measureValue": 4,
                    "measure": "4 mg",
                    "id": "S-5199623"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7353984",
        "name": "ácido ascórbico 50 mg + cafeína 40 mg + clorfenamina 4 mg + noscapina 10 mg + paracetamol 650 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714967",
                    "administerForm": [
                        "Comprimido"
                    ],
                    "desc": "comprimido"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 40,
                    "measure": "40 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 650,
                    "measure": "650 mg",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "clorfenamina",
                    "snomedId": "372914003",
                    "measureValue": 4,
                    "measure": "4 mg",
                    "id": "S-5199623"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7469790",
        "name": "ácido ascórbico 50 mg + cafeína 40 mg + noscapina 10 mg + paracetamol 650 mg comprimido y ácido ascórbico 50 mg + cafeína 40 mg + clorfenamina 4 mg + noscapina 10 mg + paracetamol 650 mg comprimido",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "cafeína",
                    "snomedId": "255641001",
                    "measureValue": 40,
                    "measure": "40 mg",
                    "id": "S-5154129"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 650,
                    "measure": "650 mg",
                    "id": "S-5166991"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7353957",
        "name": "ácido ascórbico 50 mg + cafeína 40 mg + noscapina 10 mg + paracetamol 650 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714915",
                    "administerForm": [
                        "Cápsula"
                    ],
                    "desc": "cápsula"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido ascórbico",
                    "snomedId": "43706004",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-4846670"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "noscapina",
                    "snomedId": "387437002",
                    "measureValue": 9.19,
                    "measure": "9.19 mg",
                    "id": "S-5112882"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "paracetamol",
                    "snomedId": "387517004",
                    "measureValue": 400,
                    "measure": "400 mg",
                    "id": "S-5166991"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "pseudoefedrina clorhidrato",
                    "snomedId": "12177002",
                    "measureValue": 60,
                    "measure": "60 mg",
                    "id": "S-5975242"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7640249",
        "name": "ácido ascórbico 50 mg + noscapina 9,19 mg + paracetamol 400 mg + pseudoefedrina clorhidrato 60 mg cápsula",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "ácido cítrico",
                    "snomedId": "75399008",
                    "measureValue": 12,
                    "measure": "12 g",
                    "id": "S-4847221"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "picosulfato de sodio",
                    "snomedId": "395881001",
                    "measureValue": 10,
                    "measure": "10 mg",
                    "id": "S-5159269"
                },
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "óxido de magnesio",
                    "snomedId": "66389008",
                    "measureValue": 3.5,
                    "measure": "3.5 g",
                    "id": "S-6448117"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7616852",
        "name": "ácido cítrico 12 g + óxido de magnesio 3,5 g + picosulfato de sodio 10 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "ácido cítrico",
                    "snomedId": "75399008",
                    "measureValue": 2.13,
                    "measure": "2.13 g",
                    "id": "S-4847221"
                },
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "bicarbonato de sodio",
                    "snomedId": "387319002",
                    "measureValue": 2.31,
                    "measure": "2.31 g",
                    "id": "S-5153844"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "carbonato de sodio",
                    "snomedId": "420616006",
                    "measureValue": 500,
                    "measure": "500 mg",
                    "id": "S-7469532"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7469572",
        "name": "ácido cítrico 2,13 g + bicarbonato de sodio 2,31 g + carbonato de sodio 500 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "7335698",
                    "administerForm": [
                        "ml",
                        "Sobre",
                        "g",
                        "mg",
                        "cucharada",
                        "cucharadita"
                    ],
                    "desc": "polvo para preparado oral"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "ácido cítrico",
                    "snomedId": "75399008",
                    "measureValue": 2.17,
                    "measure": "2.17 g",
                    "id": "S-4847221"
                },
                {
                    "measureUnit": {
                        "id": "g",
                        "desc": "gramo"
                    },
                    "name": "bicarbonato de sodio",
                    "snomedId": "387319002",
                    "measureValue": 2.84,
                    "measure": "2.84 g",
                    "id": "S-5153844"
                },
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "carbonato de sodio",
                    "snomedId": "420616006",
                    "measureValue": 500,
                    "measure": "500 mg",
                    "id": "S-7469532"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-7469397",
        "name": "ácido cítrico 2,17 g + bicarbonato de sodio 2,84 g + carbonato de sodio 500 mg polvo para preparado oral",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589364",
                "desc": "oral"
            },
            "medicationForm": [
                {
                    "id": "5714967",
                    "administerForm": [
                        "Comprimido"
                    ],
                    "desc": "comprimido"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "measure": "50 mg",
                    "id": "S-4930382",
                    "name": "ácido para aminobenzoico",
                    "measureValue": 50
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-6631238",
        "name": "ácido para aminobenzoico 50 mg comprimido",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "ácido zoledrónico",
                    "snomedId": "395926009",
                    "measureValue": 4,
                    "measure": "4 mg",
                    "id": "S-4855042"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5986876",
        "name": "ácido zoledrónico 4 mg inyectable",
        "snomedId": "134603008"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "measure": "35 mg",
                    "id": "S-6397729",
                    "name": "agalsidasa beta",
                    "measureValue": 35
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7289577",
        "name": "agalsidasa beta 35 mg inyectable",
        "snomedId": "407801001"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "measure": "0 mg",
                    "id": "S-7524170",
                    "name": "sustancia especial",
                    "measureValue": 0
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-6415393",
        "name": "agua estéril para inyectable",
        "snomedId": null
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "g/mL",
                        "desc": "gramo/mililitro"
                    },
                    "name": "albúmina humana",
                    "snomedId": "52454007",
                    "measureValue": 0.2,
                    "measure": "0.2 g/mL",
                    "id": "S-4856115"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5986996",
        "name": "albúmina humana 20% (200 mg/mL) inyectable",
        "snomedId": "406264005"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "g/mL",
                        "desc": "gramo/mililitro"
                    },
                    "name": "albúmina humana",
                    "snomedId": "52454007",
                    "measureValue": 0.05,
                    "measure": "0.05 g/mL",
                    "id": "S-4856115"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-54519",
        "name": "albúmina humana 5% (50 mg/mL) inyectable",
        "snomedId": "406290008"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "7734703",
                "desc": "intracavernosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mcg",
                        "desc": "microgramo"
                    },
                    "name": "alprostadil",
                    "snomedId": "48988008",
                    "measureValue": 10,
                    "measure": "10 mcg",
                    "id": "S-4930599"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7402346",
        "name": "alprostadil 10 mcg inyectable",
        "snomedId": "326673004"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "7734703",
                "desc": "intracavernosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mcg",
                        "desc": "microgramo"
                    },
                    "name": "alprostadil",
                    "snomedId": "48988008",
                    "measureValue": 20,
                    "measure": "20 mcg",
                    "id": "S-4930599"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-7402351",
        "name": "alprostadil 20 mcg inyectable",
        "snomedId": "326674005"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589365",
                "desc": "intramuscular"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mcg/mL",
                        "desc": "microgramo/mililitro"
                    },
                    "name": "alprostadil",
                    "snomedId": "48988008",
                    "measureValue": 500,
                    "measure": "500 mcg/mL",
                    "id": "S-4930599"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5987412",
        "name": "alprostadil 500 mcg/mL inyectable",
        "snomedId": "326142002"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589360",
                "desc": "intravenosa"
            },
            "medicationForm": [
                {
                    "id": "5715210",
                    "administerForm": [
                        "Ampolla",
                        "Vial"
                    ],
                    "desc": "inyectable"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg",
                        "desc": "miligramo"
                    },
                    "name": "alteplasa",
                    "snomedId": "387152000",
                    "measureValue": 50,
                    "measure": "50 mg",
                    "id": "S-6397715"
                }
            ]
        },
        "salesTermRecept": "RM",
        "id": "MC-5870159",
        "name": "alteplasa 50 mg inyectable",
        "snomedId": "319837006"
    },
    {
        "composition": {
            "administrationVia": {
                "id": "4589377",
                "desc": "tópica"
            },
            "medicationForm": [
                {
                    "id": "5714849",
                    "administerForm": [
                        "Aplicación"
                    ],
                    "desc": "barniz de uñas"
                }
            ],
            "ingredients": [
                {
                    "measureUnit": {
                        "id": "mg/mL",
                        "desc": "miligramo/mililitro"
                    },
                    "name": "amorolfina",
                    "snomedId": "391769002",
                    "measureValue": 50,
                    "measure": "50 mg/mL",
                    "id": "S-4931648"
                }
            ]
        },
        "salesTermRecept": "VD",
        "id": "MC-5987572",
        "name": "amorolfina 5% (50 mg/mL) barniz de uñas",
        "snomedId": "332999004"
    }
  ]
