import React from "react";
import {Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import './BuscadorPacientes.css';
import Rut from '../../../../../components/web/DashboardView/comun/Rut.js';
import Correo from '../../../../../components/web/DashboardView/comun/Correo.js';
import SelectorFecha from '../../../../../components/web/DashboardView/comun/SelectorFecha.js';
import EtiquetaSelect from '../../../../../components/web/DashboardView/comun/EtiquetaSelect.js';
import EtiquetaTextField from '../../../../../components/web/DashboardView/comun/EtiquetaTextField.js';

import FichaMedico from '../../../../../components/web/DashboardView/ficha/FichaMedico.js';

import { ServiceActualizaPaciente } from '../../../../../api/services';
import MenuItem from '@material-ui/core/MenuItem';

const estiloDivModal={
      position: 'absolute',
      top: '5%',
      left: '30%',
      right: '30%',
      bottom: '5%',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px',
}

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 150px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const header =  {
    headers: {
        "access-control-allow-headers":"*",
        "access-control-allow-methods":"*",
        "access-control-allow-origin":"*",
        "access-control-expose-headers":"*",
        'content-type': 'application/json',
    }
};

class BuscadorPacientes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rutValido:false,
      rutPaciente:"",
      encontrado:false
    };
  }

  componentDidMount() {
  }

  handlerRut = (rutValido,valorRut) =>{
    if (rutValido){
      this.setState({rutValido:true});
      this.setState({rutPaciente:valorRut});
      this.handlerBuscar("");
    }else{
      this.setState({rutValido:false});
    }
  }

  limpiarRut=(rut)=>{rut.replaceAll(".", "")}

  handlerBuscar = (e)=>{
    let rutBuscar=this.state.rutPaciente.replaceAll(".", "");
    axios
      .get("https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/patient?rut="+rutBuscar, header)
      .then((res) =>{
            console.log(res);

            if (res.data["message"]=="ERROR") {
              this.setState({encontrado:false});
              return;
            }

            let bodyError=res["body"];
            console.log(bodyError);
            if (bodyError=="Not found"){
              this.setState({encontrado:false});
              return;
            }

            let body=res.data["body"];
            console.log(body);
            this.setState({encontrado:true});

          })
       .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper>
          <Box pt={4} pl={4} pr={4} pb={4}>

            <Typography variant="h6" >
              Ingrese el rut del paciente que desea buscar
            </Typography>

            <br/>

            <Grid container spacing={0} >
                 <Grid item xs={3}>
                  <Typography variant="body1" >
                      Rut Paciente:
                   </Typography>
                 </Grid >
                 <Grid item xs={4}>
                    <Rut key="rut-buscar" value={this.state.rutPaciente} handlerRut={this.handlerRut} />
                 </Grid >
            </Grid>

            <br/>

          </Box>

        </Paper>
        <br/>
        {this.state.encontrado==true && this.state.rutValido && (
            <FichaMedico key={this.state.rutPaciente} rutPaciente={this.state.rutPaciente}/>
          )
        }

        {this.state.encontrado==false && (
           <Typography variant="body1" >
              No se encuentra el paciente con el rut indicado.
           </Typography>
        )}
      </Box>
    );
  }
}

export default BuscadorPacientes;
