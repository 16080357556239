import React from "react";
import {Grid,Box,TextField,Typography} from '@material-ui/core';

class TextFieldMobile extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
      etiqueta: props.etiqueta,
      valor: props.valor,
      handlerExterno: props.handlerTextField
    };
  }

  render(){
    return (
      <Box display="flex" flexDirection="column" >
          <TextField value={this.state.valor} label={this.state.etiqueta} onChange={this.state.handlerExterno} ></TextField>
      </Box>

    );
  }
};

export default TextFieldMobile;

/*
<Grid container spacing={0}>
  <Grid item xs={5}>
      <Typography variant="body1">
        {this.state.etiqueta}
      </Typography>
        <h1></h1>
  </Grid >
  <Grid item xs={7}>
      <TextField  value={this.state.valor} label={this.state.etiqueta} onChange={this.state.handlerExterno} ></TextField>
  </Grid >
</Grid>
*/
