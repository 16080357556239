import React from "react";
import {Select,Modal,Container, Box, Divider,Grid,Paper,Stepper,Step,StepLabel,TextField,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataGrid } from '@material-ui/data-grid';
import styled,{ThemeProvider} from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import EtiquetaTextField from './../../../DashboardView/comun/EtiquetaTextField';
import EtiquetaTextArea from './../../../DashboardView/comun/EtiquetaTextArea';
import EtiquetaSelect from './../../../DashboardView/comun/EtiquetaSelect';
import SelectorFecha from './../../../DashboardView/comun/SelectorFecha';
import Autocomplete from '@material-ui/lab/Autocomplete';

const listadoMedicamentos=[
  {principio:'ABACAVIR (ABC)',forma:'Comprimidos',comercial:'(R) ZIAGEN 300mg COMP.X60',um:'300',uc:'MG',cantidad:'60'},
{principio:'ABACAVIR (ABC)',forma:'Solución Oral',comercial:'ZIAGEN 20mg/ml S.O.240ml',um:'20',uc:'MG',cantidad:'240'},
{principio:'ABACAVIR (SULFATO) + LAMIVUDINA',forma:'Comprimidos',comercial:'EXEMVIR (B) 600/300 30COM.REC',um:'600',uc:'MG',cantidad:'30'},
{principio:'ABACAVIR (SULFATO) + LAMIVUDINA',forma:'Comprimidos',comercial:'KIVEXA 600/300 COMP.X30',um:'600',uc:'MG',cantidad:'30'},
{principio:'ABACAVIR+DOLUTEGRAVIR+LAMIVUDINA',forma:'Comprimidos',comercial:'TRIUMEQ 50/300/600 COM.X30',um:'600',uc:'MG',cantidad:'30'},
{principio:'ABATACEPT',forma:'Jeringa Prellenada Monodosis',comercial:'ORENCIA 125mg JER.X4',um:'125',uc:'MG',cantidad:'1'},
{principio:'ABATACEPT',forma:'Ampollas',comercial:'ORENCIA 250mg VIAL X1',um:'250',uc:'MG',cantidad:'1'},
{principio:'AC. GLICIRRETICO+HIALURONATO+PVP+PPG',forma:'Sobres',comercial:'GELCLAIR SACHET',um:'1',uc:'UN',cantidad:'1'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. FLEX 45mm X10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. FLEX 57mm X10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. FLEX 70mm X10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. RIG 45mm 10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. RIG 57mm 10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACCESORIOS DE USO HUMANO',forma:'ACCESORIOS DE USO HUMANO',comercial:'PLACA C. RIG 70mm 10PLACAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'ACEITE DE RICINO',forma:'Solución Oral',comercial:'ACEITE RICINO FRUTILLA 20g',um:'20',uc:'G',cantidad:'20'},
{principio:'ACEITE DE RICINO',forma:'Solución Oral',comercial:'ACEITE RICINO FRUTILLA X20g',um:'20',uc:'G',cantidad:'20'},
{principio:'ACEITE DE RICINO',forma:'Solución Oral',comercial:'ACEITE RICINO PURO 20g',um:'20',uc:'G',cantidad:'20'},
{principio:'ACEITE DE RICINO',forma:'Solución Oral',comercial:'ACEITE RICINO PURO X20g',um:'20',uc:'G',cantidad:'20'},
{principio:'Acenocumarol',forma:'Comprimidos',comercial:'(B) ACEBRON 4mg COMP.X20',um:'4',uc:'MG',cantidad:'20'},
{principio:'ACENOCUMAROL',forma:'Comprimidos',comercial:'COAROL (B) 4mg X20COM.',um:'4',uc:'MG',cantidad:'20'},
{principio:'ACENOCUMAROL',forma:'Comprimidos',comercial:'NEO SINTROM (R) 4mg X20COM.',um:'4',uc:'MG',cantidad:'20'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'(B) EUROVIR 800mg COMP.X5',um:'800',uc:'MG',cantidad:'5'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'ACICLOVIR 200mg COMP.X25',um:'200',uc:'MG',cantidad:'25'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'ACICLOVIR 200mg X25COM.',um:'200',uc:'MG',cantidad:'24'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'ACICLOVIR 400mg COMP.X35',um:'400',uc:'MG',cantidad:'35'},
{principio:'ACICLOVIR',forma:'Crema',comercial:'ACICLOVIR 5% CR.5g',um:'50',uc:'MG',cantidad:'5'},
{principio:'ACICLOVIR',forma:'Solución Oral',comercial:'EUROVIR 200mg SUSP.100ml',um:'200',uc:'MG',cantidad:'100'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'EUROVIR 400mg COMP.X15',um:'400',uc:'MG',cantidad:'15'},
{principio:'ACICLOVIR',forma:'Crema',comercial:'EUROVIR 5% CR.15g',um:'50',uc:'MG',cantidad:'15'},
{principio:'ACICLOVIR',forma:'Crema',comercial:'EUROVIR 5% CR.5g',um:'50',uc:'MG',cantidad:'5'},
{principio:'ACICLOVIR',forma:'Comprimidos',comercial:'EUROVIR 800mg COMP.X35',um:'800',uc:'MG',cantidad:'35'},
{principio:'ACICLOVIR',forma:'Solución Oral',comercial:'EUROVIR FTE.SUSP.100ml',um:'400',uc:'MG',cantidad:'100'},
{principio:'ACICLOVIR',forma:'Ungüento',comercial:'OFTAVIR UNGTO.OFT.3.5g',um:'30',uc:'MG',cantidad:'3.5'},
{principio:'ACICLOVIR',forma:'Solución Oral',comercial:'VIRONIDA 200mg SUSP.100ml',um:'200',uc:'MG',cantidad:'100'},
{principio:'ACICLOVIR',forma:'Solución Oral',comercial:'VIRONIDA 400mg SUSP.100ml',um:'400',uc:'MG',cantidad:'100'},
{principio:'ACICLOVIR',forma:'Ungüento',comercial:'ZOVIRAX 3% UNGTO.OFT.4.5g',um:'30',uc:'MG',cantidad:'4.5'},
{principio:'ACICLOVIR',forma:'Crema',comercial:'ZOVIRAX 5% CR.5g',um:'50',uc:'MG',cantidad:'5'},
{principio:'ACIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(B) AC.ACETIL SALICILICO 500mg COMP.X100',um:'500',uc:'MG',cantidad:'100'},
{principio:'ACIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(R) ASPIRINA PED.COMP.X42',um:'100',uc:'MG',cantidad:'42'},
{principio:'ACIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(R) CARDIOASPIRINA EC 100mg X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'ACIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'AC.ACETIL SALICILICO 100mg X100COM.',um:'100',uc:'MG',cantidad:'100'},
{principio:'ÁCIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(B) AC.ACETIL SALICILICO 500mg COMP.X100',um:'500',uc:'MG',cantidad:'100'},
{principio:'ÁCIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(R) ASPIRINA PED.COMP.X42',um:'100',uc:'MG',cantidad:'42'},
{principio:'ÁCIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'(R) CARDIOASPIRINA EC 100mg X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'ÁCIDO ACETILSALICILICO',forma:'Comprimidos',comercial:'AC.ACETIL SALICILICO 100mg X100COM.',um:'100',uc:'MG',cantidad:'100'},
{principio:'ACIDO FOLICO',forma:'Comprimidos',comercial:'FOLACID 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'ACIDO FOLICO',forma:'Comprimidos',comercial:'FOLISANIN 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ACIDO FOLICO',forma:'Comprimidos',comercial:'FOLVIT 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ACIDO FÓLICO',forma:'Comprimidos',comercial:'FOLACID 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ACIDO FÓLICO',forma:'Comprimidos',comercial:'FOLISANIN 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ACIDO FÓLICO',forma:'Comprimidos',comercial:'FOLVIT 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ACIDO HIALURONICO',forma:'Jeringa Prellenada Monodosis',comercial:'SUPRAHYAL 25mg/2.5ml JP.X1',um:'25',uc:'MG',cantidad:'1'},
{principio:'ACIDO TRANEXAMICO',forma:'Capsulas',comercial:'ESPERCIL 250mg CAPS.X32',um:'250',uc:'MG',cantidad:'32'},
{principio:'ACIDO TRANEXAMICO',forma:'Comprimidos',comercial:'ESPERCIL 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'ACIDO URSODESOXICÓLICO',forma:'Comprimidos',comercial:'SOLVOBIL 250mg COMP.X100',um:'250',uc:'MG',cantidad:'100'},
{principio:'ACIDO URSODESOXICÓLICO',forma:'Capsulas',comercial:'URSOFALK 250mg CAPS.X100',um:'250',uc:'MG',cantidad:'100'},
{principio:'ACIDO URSODESOXICÓLICO',forma:'Capsulas',comercial:'URSOFALK 250mg CAPS.X50',um:'250',uc:'MG',cantidad:'50'},
{principio:'ACIDO URSODESOXICÓLICO',forma:'Solución Oral',comercial:'URSOFALK 250mg SUSP.250ml',um:'250',uc:'MG',cantidad:'250'},
{principio:'ACIDO URSODESOXICÓLICO',forma:'Comprimidos',comercial:'URSOFALK 500mg COM.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'AC.VALPROICO 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'AC.VALPROICO 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ACIDO VALPROICO 200mg X30COM.',um:'200',uc:'MG',cantidad:'30'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ACIDO VALPROICO 500mg X30COM.',um:'500',uc:'MG',cantidad:'30'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ATEMPERATOR 250mg COMP.X20',um:'250',uc:'MG',cantidad:'20'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ATEMPERATOR 250mg COMP.X50',um:'250',uc:'MG',cantidad:'50'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ATEMPERATOR 400mg COMP.X20',um:'400',uc:'MG',cantidad:'20'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'ATEMPERATOR 500mg COMP.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'ACIDO VALPROICO',forma:'Gotario',comercial:'ATEMPERATOR GTAS.25ml',um:'200',uc:'MG',cantidad:'25'},
{principio:'ACIDO VALPROICO',forma:'Jarabe',comercial:'DEPAKENE 250mg/5ml JBE.120ml',um:'250',uc:'MG',cantidad:'120'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'VALCOTE 500mg COMP.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'VALCOTE ER 250mg COMP.X50',um:'250',uc:'MG',cantidad:'50'},
{principio:'ACIDO VALPROICO',forma:'Comprimidos',comercial:'VALCOTE ER 500mg X50COM.REC.',um:'500',uc:'MG',cantidad:'50'},
{principio:'ADALIDUMAB',forma:'Jeringa Prellenada Monodosis',comercial:'Humira 20 mg',um:'20',uc:'MG',cantidad:'2'},
{principio:'ADALIDUMAB',forma:'Jeringa Prellenada Monodosis',comercial:'HUMIRA AC 40mg/0.4ml JP.X2',um:'40',uc:'MG',cantidad:'2'},
{principio:'ADALIDUMAB',forma:'Ampolla Multidosis',comercial:'HUMIRA PED.40mg/0.8ml X2F-A',um:'40',uc:'MG',cantidad:'2'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'AERO CAMARA MEDCELL',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'AEROCAMARA MED CELL',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'AEROCHAMBER AEROCAMARA PED.',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Accesorio medico',comercial:'AEROCHAMBER PLUS AD.MASC.AZUL X1UND',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'OTRO-NINGUNA',comercial:'AEROCHAMBER PLUS CHILD MASC.AMAR 1UND',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Accesorio medico',comercial:'AEROCHAMBER PLUS INF. MASC. ROJA X1UND',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Accesorio medico',comercial:'AEROCHAMBER PLUS SMALL AD.MASC.MORADO',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'AEROFACIDOSE AE AD/ESCOLAR',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'AEROFACIDOSE AE LACTANTE',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Aerocamara',comercial:'NEBULIZADOR REUSABLE SET LC SPRINT',um:'1',uc:'UN',cantidad:'1'},
{principio:'AEROCAMARA',forma:'Accesorio medico',comercial:'Pari O-Pep (flutter)',um:'1',uc:'UN',cantidad:'1'},
{principio:'AGUJA HIPODERMICA',forma:'Aguja insulina',comercial:'AGUJA VER.INS. 31G 5mmX5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'Aguja insulina',comercial:'AGUJA VER.INS. 31G 6mmX5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'Aguja insulina',comercial:'AGUJA VER.INS. 31G 8mmX5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'Aguja insulina',comercial:'AGUJA VER.INS. 32G 4mmX5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'OTRO-NINGUNA',comercial:'AGUJAS BD PEN 31G X5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'Aguja',comercial:'AGUJAS BD PEN 32G X5',um:'1',uc:'UN',cantidad:'5'},
{principio:'AGUJA HIPODERMICA',forma:'OTRO-NINGUNA',comercial:'BD ULTRAFINE 32GX4mm CJX100',um:'1',uc:'UN',cantidad:'100'},
{principio:'ALENDRONATO',forma:'Comprimidos',comercial:'(B) ALDROX 70mg COMP.X10',um:'70',uc:'MG',cantidad:'10'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Solución Oral',comercial:'ENSURE ADVANCE VAINILLA 237ml',um:'1',uc:'UN',cantidad:'1'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE CHOCOLATE 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Solución Oral',comercial:'ENSURE CLINICAL RPB VAINILLA X220ml',um:'1',uc:'ML',cantidad:'1'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE FRUTILLA 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE VAINILLA 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Solución Oral',comercial:'GLUCERNA SHAKE VAINILLA 237ml',um:'1',uc:'UN',cantidad:'237'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN',forma:'Polvo',comercial:'PEDIASURE COMPL VAINILLA 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN SIN SACAROSA',forma:'Solución Oral',comercial:'PEDIASURE COMPL VAIN 237ml',um:'237',uc:'ML',cantidad:'237'},
{principio:'ALIMENTO ENTERAL BÁSICO LIBRE DE LACTOSA Y GLUTEN SIN SACAROSA',forma:'Polvo',comercial:'PEDIASURE COMPL VAINILLA 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'ALIMENTO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE CHOCOLATE 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE FRUTILLA 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO LIBRE DE LACTOSA Y GLUTEN',forma:'Granulados/Polvos',comercial:'ENSURE VAINILLA 850g',um:'1',uc:'G',cantidad:'850'},
{principio:'ALIMENTO LIBRE DE LACTOSA Y GLUTEN',forma:'POLVO',comercial:'GLUCERNA SHAKE VAINILLA 237ml',um:'1',uc:'GRAMOS',cantidad:'850'},
{principio:'ALOPURINOL',forma:'Comprimidos',comercial:'ALOPURINOL 100mg COMP.X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'ALOPURINOL',forma:'Comprimidos',comercial:'ALOPURINOL 100mg X20COM.',um:'100',uc:'MG',cantidad:'20'},
{principio:'ALOPURINOL',forma:'Comprimidos',comercial:'ALOPURINOL 300mg COMP.X20',um:'300',uc:'MG',cantidad:'20'},
{principio:'ALOPURINOL',forma:'Comprimidos',comercial:'ALOPURINOL 300mg X20COM.',um:'300',uc:'MG',cantidad:'20'},
{principio:'ALPRAZOLAM',forma:'Comprimidos',comercial:'(B) ALPRAZOLAM 0.50mg COMP.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'AMIODARONA',forma:'Comprimidos',comercial:'AMIODARONA 200mg COMP.X20',um:'200',uc:'MG',cantidad:'20'},
{principio:'AMIODARONA',forma:'Comprimidos',comercial:'AMIODARONA 200mg X20COM.',um:'200',uc:'MG',cantidad:'20'},
{principio:'AMITRIPTILINA',forma:'Comprimidos',comercial:'AMITRIPTILINA 25mg COMP.X100',um:'25',uc:'MG',cantidad:'100'},
{principio:'AMITRIPTILINA',forma:'Comprimidos',comercial:'AMITRIPTILINA 25mg X100COM.',um:'25',uc:'MG',cantidad:'100'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'(B) AMLODIPINO 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'(B) AMLODIPINO 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'(B) AMPINO 5mg COM.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Tableta',comercial:'(R) NORVASC 5mg TABL.X60',um:'5',uc:'MG',cantidad:'60'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'AMLODIPINO (B) 5mg X30COM.',um:'5',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'AMLODIPINO 10mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'AMLODIPINO 5mg 30COM.',um:'5',uc:'MG',cantidad:'30'},
{principio:'AMLODIPINO',forma:'Comprimidos',comercial:'AMLODIPINO 5mg X30COM.',um:'5',uc:'MG',cantidad:'30'},
{principio:'AMOXICILINA',forma:'Comprimidos',comercial:'AMOBIOTIC 1g COMP.X14',um:'1',uc:'G',cantidad:'14'},
{principio:'AMOXICILINA',forma:'Comprimidos',comercial:'AMOBIOTIC 1g COMP.X20',um:'1',uc:'G',cantidad:'20'},
{principio:'AMOXICILINA',forma:'Solución Oral',comercial:'AMOVAL DUO 1000 SUSP.50ml',um:'1',uc:'G',cantidad:'50'},
{principio:'AMOXICILINA',forma:'Solución Oral',comercial:'AMOVAL DUO 1000 SUSP.90ml',um:'1',uc:'G',cantidad:'90'},
{principio:'AMOXICILINA',forma:'Solución Oral',comercial:'AMOVAL DUO 400 SUSP.70ml',um:'400',uc:'MG',cantidad:'70'},
{principio:'AMOXICILINA',forma:'Solución Oral',comercial:'AMOVAL DUO 800 SUSP.35ml',um:'800',uc:'MG',cantidad:'35'},
{principio:'AMOXICILINA',forma:'Capsulas',comercial:'AMOXICILINA (B) 500mg X6CAP.',um:'500',uc:'MG',cantidad:'6'},
{principio:'AMOXICILINA',forma:'Solución Oral',comercial:'AMOXICILINA 250mg SUSP.60ml',um:'250',uc:'MG',cantidad:'60'},
{principio:'AMOXICILINA',forma:'Jarabe',comercial:'AMOXICILINA 250mg/5ml S.O.X60ml',um:'250',uc:'MG',cantidad:'60'},
{principio:'AMOXICILINA',forma:'Capsulas',comercial:'AMOXICILINA 500mg CAPS.X21',um:'500',uc:'MG',cantidad:'21'},
{principio:'AMOXICILINA',forma:'Capsulas',comercial:'AMOXICILINA 500mg CAPS.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'AMOXICILINA',forma:'Jarabe',comercial:'AMOXICILINA 500mg JBE.60ml',um:'500',uc:'MG',cantidad:'60'},
{principio:'AMOXICILINA',forma:'Capsulas',comercial:'AMOXICILINA 500mg X21CAP.',um:'500',uc:'MG',cantidad:'21'},
{principio:'AMOXICILINA',forma:'Capsulas',comercial:'AMOXICILINA 500mg X6CAP.',um:'500',uc:'MG',cantidad:'6'},
{principio:'AMOXICILINA',forma:'Jarabe',comercial:'AMOXICILINA 500mg/5ml JBE.X60ml',um:'500',uc:'MG',cantidad:'60'},
{principio:'AMOXICILINA',forma:'Comprimidos',comercial:'AMOXICILINA 750mg COMP.X10',um:'750',uc:'MG',cantidad:'10'},
{principio:'AMOXICILINA',forma:'Comprimidos',comercial:'AMOXICILINA 750mg X10COM.',um:'750',uc:'MG',cantidad:'10'},
{principio:'AMOXICILINA',forma:'Comprimidos',comercial:'OPTAMOX 875mg COMP.X14',um:'875',uc:'MG',cantidad:'14'},
{principio:'AMOXICILINA + ACIDO CLAVULANICO',forma:'Comprimidos Recubiertos',comercial:'(B) MAXIMOX 875/125 COMP.X14',um:'875/125',uc:'MG',cantidad:'14'},
{principio:'AMOXICILINA + ACIDO CLAVULANICO',forma:'Comprimidos',comercial:'AMOXICILINA/CLAVUL (B) 500/125 X20COM.',um:'500',uc:'MG',cantidad:'20'},
{principio:'AMOXICILINA + ACIDO CLAVULANICO',forma:'Solución Oral',comercial:'CLAVINEX FTE.DUO.SUSP 35ml',um:'800',uc:'MG',cantidad:'35'},
{principio:'AMOXICILINA-ACIDO CLAVULÁNICO JARABE',forma:'Solución Oral',comercial:'AMBILAN BID SUSP.70ml',um:'400',uc:'MG',cantidad:'70'},
{principio:'AMOXICILINA-ACIDO CLAVULÁNICO JARABE',forma:'Solución Oral',comercial:'AUGMENTIN BID PED.70ml',um:'400',uc:'MG',cantidad:'70'},
{principio:'AMOXICILINA-ACIDO CLAVULÁNICO JARABE',forma:'Solución Oral',comercial:'CLAVINEX DUO SUSP.35ml',um:'400',uc:'MG',cantidad:'35'},
{principio:'AMOXICILINA-ACIDO CLAVULÁNICO JARABE',forma:'Solución Oral',comercial:'CLAVINEX DUO SUSP.70ml',um:'400',uc:'MG',cantidad:'70'},
{principio:'AMPICILINA',forma:'Comprimidos',comercial:'AMPICILINA 500mg COMP.X10',um:'500',uc:'MG',cantidad:'10'},
{principio:'AMPICILINA',forma:'Comprimidos',comercial:'AMPICILINA 500mg X10COM.',um:'500',uc:'MG',cantidad:'10'},
{principio:'ANASTROZOL',forma:'Comprimidos',comercial:'(B) MADELEN 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ANASTROZOL',forma:'Comprimidos',comercial:'Arimidex 1mg COMP.X28',um:'1',uc:'MG',cantidad:'28'},
{principio:'ANASTROZOL',forma:'Comprimidos',comercial:'TROZOLET 1mg COMP.X28',um:'1',uc:'MG',cantidad:'28'},
{principio:'ANTIEMÉTICO ORAL',forma:'Comprimidos',comercial:'(B) ODATRON 4mg COM.BUC.X8',um:'4',uc:'MG',cantidad:'8'},
{principio:'ANTIEMÉTICO ORAL',forma:'Comprimidos',comercial:'(B) ODATRON 8mg COM.BUC.X8',um:'8',uc:'MG',cantidad:'8'},
{principio:'ANTIEMÉTICO ORAL',forma:'Comprimidos',comercial:'(R) IZOFRAN ZYDIS 8mg COMP.X10',um:'8',uc:'MG',cantidad:'10'},
{principio:'ANTIEMÉTICO ORAL',forma:'Ampollas',comercial:'EMISTOP (B) 8mg/4ml SOL.INY X5AMP.',um:'8',uc:'MG',cantidad:'5'},
{principio:'ANTIEMÉTICO ORAL',forma:'Comprimidos',comercial:'IZOFRAN ZYDIS 4mg COMP.X10',um:'4',uc:'MG',cantidad:'10'},
{principio:'ANTIEMÉTICO ORAL',forma:'Comprimidos',comercial:'ONDANSETRON 8mg X10COM.BUC',um:'8',uc:'MG',cantidad:'10'},
{principio:'APREPITANT',forma:'CAPSULA',comercial:'EMEND TRIPACK X1+2CAP.',um:'80/125',uc:'MG',cantidad:'2'},
{principio:'ARIPIPRAZOL',forma:'Comprimidos',comercial:'(B) AZYMOL 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'ARIPIPRAZOL',forma:'Comprimidos',comercial:'(B) AZYMOL 15mg COMP.X30',um:'15',uc:'MG',cantidad:'30'},
{principio:'ARIPIPRAZOL',forma:'Comprimidos',comercial:'(B) AZYMOL 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'ATAZANAVIR',forma:'Capsulas',comercial:'(R) REYATAZ 200mg CAPS.X60',um:'200',uc:'MG',cantidad:'60'},
{principio:'ATAZANAVIR',forma:'Capsulas',comercial:'TOZAR (B) 300mg X30CAP.',um:'300',uc:'MG',cantidad:'30'},
{principio:'ATAZANAVIR+COBICISTAT',forma:'Comprimidos',comercial:'REYDUO COM.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'ATENOLOL',forma:'Capsulas',comercial:'ATENOLOL (B) 100mg X20CAP.',um:'100',uc:'MG',cantidad:'20'},
{principio:'ATENOLOL',forma:'Comprimidos',comercial:'ATENOLOL (B) 50mg X20COM.',um:'50',uc:'MG',cantidad:'20'},
{principio:'ATENOLOL',forma:'Capsulas',comercial:'ATENOLOL 100mg X20CAP.',um:'100',uc:'MG',cantidad:'20'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'(B) ATORVASTATINA 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'(B) HIPOLIXAN 10mg COMP.X60',um:'10',uc:'MG',cantidad:'60'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'(B) LOWDEN 10mg COMP.X60',um:'10',uc:'MG',cantidad:'60'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'ATORVASTATINA (B) 10mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'ATORVASTATINA (B) 20mg X30COM.',um:'20',uc:'MG',cantidad:'30'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'ATORVASTATINA 10mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'ATORVASTATINA',forma:'Comprimidos',comercial:'ATORVASTATINA 20mg X30COM.',um:'20',uc:'MG',cantidad:'30'},
{principio:'ATORVASTATINA',forma:'COMPRIMIDOS',comercial:'LIPOTEN (B) 40mg X28COM.',um:'40',uc:'MG',cantidad:'28'},
{principio:'ATORVASTATINA',forma:'COMPRIMIDOS',comercial:'LIPOX (B) 80mg COMP.X30',um:'80',uc:'MG',cantidad:'30'},
{principio:'AZATIOPRINA',forma:'Comprimidos',comercial:'IMURAN 50mg COMP.X100',um:'50',uc:'MG',cantidad:'100'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(B) AZITROM 500mg COMP.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(B) AZITROMICINA 500mg COMP.X3',um:'500',uc:'MG',cantidad:'3'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(B) AZITROMICINA 500mg COMP.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(B) TREX 500mg COMP.X3',um:'500',uc:'MG',cantidad:'3'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(B) TREX 500mg COMP.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'(R) ZITHROMAX 500mg COMP.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Solución Oral',comercial:'AZITROM 200mg SUSP.15ml',um:'200',uc:'MG',cantidad:'15'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'AZITROMICINA (B) 500mg X3COM.',um:'500',uc:'MG',cantidad:'3'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'AZITROMICINA (B) 500mg X6COM.',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Solución Oral',comercial:'AZITROMICINA 200mg SUSP.15ml',um:'200',uc:'MG',cantidad:'15'},
{principio:'AZITROMICINA',forma:'Solución Oral',comercial:'AZITROMICINA 200mg/5ml S.O.X15ml',um:'200',uc:'MG',cantidad:'15'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'AZITROMICINA 500mg X3COM.',um:'500',uc:'MG',cantidad:'3'},
{principio:'AZITROMICINA',forma:'Comprimidos',comercial:'AZITROMICINA 500mg X6COM.',um:'500',uc:'MG',cantidad:'6'},
{principio:'AZITROMICINA',forma:'Solución Oral',comercial:'TREX 200mg SUSP.15ml',um:'200',uc:'MG',cantidad:'15'},
{principio:'BACLOFENO',forma:'Comprimidos',comercial:'CETRIL 10mg COMP.X50',um:'10',uc:'MG',cantidad:'50'},
{principio:'BACLOFENO',forma:'Comprimidos',comercial:'LIORESYL 10mg COMP.X60',um:'10',uc:'MG',cantidad:'60'},
{principio:'BETAMETASONA (ACETATO Y FOSFATO SODICO)',forma:'Ampollas',comercial:'CIDOTEN RL 2ml JP.X1',um:'6',uc:'MG',cantidad:'1'},
{principio:'BETAMETASONA (ACETATO Y FOSFATO SODICO)',forma:'Ampollas',comercial:'DACAM RL 3mg/ml AMP.+JERINGA',um:'6',uc:'MG',cantidad:'1'},
{principio:'BETAMETASONA (FOSFATO DISODICO Y DIPROPIONATO)',forma:'Ampollas',comercial:'CRONOLEVEL INY.1ml',um:'7',uc:'MG',cantidad:'1'},
{principio:'BETAMETASONA DERMICA',forma:'Ungüento',comercial:'BETAMETASONA 0,05% UNG.X15g',um:'0.5',uc:'MG',cantidad:'15'},
{principio:'BETAMETASONA DERMICA',forma:'Crema',comercial:'BETAMETASONA 0.05% CR.15g',um:'0.5',uc:'MG',cantidad:'15'},
{principio:'BETAMETASONA DERMICA',forma:'Crema',comercial:'BETAMETASONA 0.05% CR.X15g',um:'0.5',uc:'MG',cantidad:'15'},
{principio:'BETAMETASONA DERMICA',forma:'Ungüento',comercial:'BETAMETASONA 0.05% UNGTO.15g',um:'0.5',uc:'MG',cantidad:'15'},
{principio:'BETAMETASONA ORAL',forma:'Solución Oral',comercial:'CIDOTEN GTAS.30ml',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'BETAMETASONA ORAL',forma:'Gotario',comercial:'CORITEX 0.5mg/ml GTAS.30ml',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'BICALUTAMIDA',forma:'COMPRIMIDOS',comercial:'(B) BIOLEV 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'BICALUTAMIDA',forma:'Comprimidos',comercial:'(R) CASODEX 150mg COMP.X28',um:'150',uc:'MG',cantidad:'28'},
{principio:'BICALUTAMIDA',forma:'COMPRIMIDOS',comercial:'LUTAMIDAL 150mg X28COM.REC.',um:'150',uc:'MG',cantidad:'28'},
{principio:'BICALUTAMIDA',forma:'COMPRIMIDOS',comercial:'LUTAMIDAL 50mg X28COM.REC.',um:'50',uc:'MG',cantidad:'28'},
{principio:'BIFOSFONATOS',forma:'Comprimidos',comercial:'(B) ALDROX 70mg COMP.X10',um:'70',uc:'MG',cantidad:'10'},
{principio:'BIFOSFONATOS',forma:'Comprimidos',comercial:'FOSVAL 70mg COMP.X12',um:'70',uc:'MG',cantidad:'12'},
{principio:'BISOPROLOL',forma:'Comprimidos',comercial:'BISOPRIL 1.25mg COMP.X30',um:'1.25',uc:'MG',cantidad:'30'},
{principio:'BISOPROLOL',forma:'Comprimidos',comercial:'BISOPROLOL (B) 2.5mg X30COM.RE',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'BISOPROLOL',forma:'Comprimidos',comercial:'BISOPROLOL FUMARATO (B) 2.5mg 30 COMP',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Comprimidos',comercial:'(B) ODATRON 4mg COM.BUC.X8',um:'4',uc:'MG',cantidad:'8'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Comprimidos',comercial:'(B) ODATRON 8mg COM.BUC.X8',um:'8',uc:'MG',cantidad:'8'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Comprimidos',comercial:'(R) IZOFRAN ZYDIS 8mg COMP.X10',um:'8',uc:'MG',cantidad:'10'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Ampollas',comercial:'EMISTOP (B) 8mg/4ml SOL.INY X5AMP.',um:'8',uc:'MG',cantidad:'5'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Comprimidos',comercial:'IZOFRAN ZYDIS 4mg COMP.X10',um:'4',uc:'MG',cantidad:'10'},
{principio:'BLOQUEADOR DE LOS RECEPTORES 5 HIDROXITRIPTAMINA (5-HT3) ONDANSETRÓN',forma:'Comprimidos',comercial:'ONDANSETRON 8mg X10COM.BUC',um:'8',uc:'MG',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D OPA. N 45mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D OPA. N 57mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D OPA. N 70mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D T. N 45mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D T. N 57mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BOLSA DE DRENAJE',forma:'ACCESORIOS DE USO HUMANO',comercial:'BOLSA D T. N 70mm 10BOLSAS',um:'1',uc:'UN',cantidad:'10'},
{principio:'BROMURO DE UMECLIDINO+VILANTEROL',forma:'Aerosol',comercial:'ANORO 55/22mcg INH.X30DS.',um:'77',uc:'MCG',cantidad:'30'},
{principio:'BUDESONIDA',forma:'Aerosol',comercial:'INFLAMIDE HFA 200mcg INH.150DS.',um:'200',uc:'MCG',cantidad:'150'},
{principio:'BUDESONIDA',forma:'Aerosol',comercial:'NEUMOCORT HFA INH.200DS.',um:'200',uc:'MCG',cantidad:'200'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'BRONLIVAL 160/4,5 INH.120 DS',um:'160',uc:'MCG',cantidad:'120'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'DUORESP SPIROMAX 160/4.5 INH.120DS',um:'160',uc:'MCG',cantidad:'120'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'DUORESP SPIROMAX 320/9 INH.60DS',um:'320',uc:'MCG',cantidad:'60'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'SYMBICORT TBH 160/4.5 120DS.',um:'160',uc:'MCG',cantidad:'120'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'SYMBICORT TBH 320/9 60DS.',um:'320',uc:'MCG',cantidad:'60'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'VANNAIR 160/4.5 X120DS.',um:'160',uc:'MCG',cantidad:'120'},
{principio:'BUDESONIDA + FORMOTEROL',forma:'Aerosol',comercial:'VANNAIR 80/4.5 120DS.',um:'80',uc:'MCG',cantidad:'120'},
{principio:'BUDESONIDA NASAL',forma:'Aerosol',comercial:'RINO-B AQUA 100mcg NAS.10ml',um:'100',uc:'MCG',cantidad:'10'},
{principio:'BUPRENORFINA',forma:'Parche',comercial:'TRANSTEC 35mcg/h X5',um:'20',uc:'MG',cantidad:'5'},
{principio:'BUPROPION',forma:'Comprimidos',comercial:'BUTRINO 150mg COM.X30',um:'150',uc:'MG',cantidad:'30'},
{principio:'BUPROPION',forma:'Comprimidos',comercial:'ELONTRIL XL 150mg COMP.X30',um:'150',uc:'MG',cantidad:'30'},
{principio:'BUPROPION',forma:'Comprimidos',comercial:'ELONTRIL XL 300mg COMP.X30',um:'300',uc:'MG',cantidad:'30'},
{principio:'CABERGOLINA',forma:'Comprimidos',comercial:'CABERTRIX 0.5mg COMP.X2',um:'0.5',uc:'MG',cantidad:'2'},
{principio:'CABERGOLINA',forma:'Comprimidos',comercial:'CABERTRIX 0.5mg COMP.X4',um:'0.5',uc:'MG',cantidad:'4'},
{principio:'CABERGOLINA',forma:'Comprimidos',comercial:'DOSTINEX 0.5mg COMP.X2',um:'0.5',uc:'MG',cantidad:'2'},
{principio:'CALCIO',forma:'Comprimidos',comercial:'CALCEFOR 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'CALCEFOR D FTE.CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'CALCEFOR D FTE.CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO + VITAMINA D',forma:'Comprimidos',comercial:'CALDEVAL COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO + VITAMINA D',forma:'Comprimidos',comercial:'CALDEVAL COMP.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'ELCAL D 500/800 CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'ELCAL D PLUS CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO + VITAMINA D',forma:'Capsulas',comercial:'ELCAL D PLUS CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO ACETATO',forma:'Comprimidos',comercial:'PHOSLO 0.667mg COMP.X60',um:'667',uc:'MG',cantidad:'60'},
{principio:'CALCIO CARBONATO',forma:'Comprimidos',comercial:'CALCEFOR 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO',forma:'Capsulas',comercial:'ELCAL FTE 450mg CAPS.X30',um:'450',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X30',um:'320',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X60',um:'320',uc:'MG',cantidad:'60'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Capsulas',comercial:'CALDAR-D FTE.CAPS.X30',um:'450',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Comprimidos',comercial:'CALDEVAL COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Comprimidos',comercial:'CALDEVAL COMP.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CALCIO CARBONATO + VITAMINA D3',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CALCITRIOL',forma:'Capsulas',comercial:'ROCALTROL 0.25mcg CAPS.X30',um:'0.25',uc:'MG',cantidad:'30'},
{principio:'CALCITRIOL',forma:'Capsulas',comercial:'ROCALTROL 0.50mcg CAPS.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'CAPECITABINA',forma:'Comprimidos',comercial:'XELODA 150mg COMP.X60',um:'150',uc:'MG',cantidad:'60'},
{principio:'CAPECITABINA',forma:'Comprimidos',comercial:'XELODA 500mg COMP.X120',um:'500',uc:'MG',cantidad:'120'},
{principio:'CAPTOPRIL',forma:'Comprimidos',comercial:'CAPTOPRIL 25mg X30COM.',um:'25',uc:'MG',cantidad:'30'},
{principio:'CARBAMAZEPINA',forma:'Comprimidos',comercial:'(R) TEGRETAL CR 200mg COMP.X20',um:'200',uc:'MG',cantidad:'20'},
{principio:'CARBAMAZEPINA',forma:'Comprimidos',comercial:'(R) TEGRETAL CR 400mg COMP.X20',um:'400',uc:'MG',cantidad:'20'},
{principio:'CARBAMAZEPINA',forma:'Comprimidos',comercial:'(R) TEGRETAL CR 400mg COMP.X60',um:'400',uc:'MG',cantidad:'60'},
{principio:'CARBAMAZEPINA',forma:'Comprimidos',comercial:'CARBAMAZEPINA (B) 200mg X20COM.',um:'200',uc:'MG',cantidad:'20'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X30',um:'320',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X60',um:'320',uc:'MG',cantidad:'60'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Capsulas',comercial:'CALDAR-D FTE.CAPS.X30',um:'450',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Comprimidos',comercial:'CALDEVAL COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Comprimidos',comercial:'CALDEVAL COMP.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO (EQUIVALENTE A 320 MG DE CALCIO ELEMENTAL) 800 MG; COLECALCIFEROL (EQUIVALENTE A 125 U.I. DE VITAMINA D3) 3.125 MCG',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X30',um:'320',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Capsulas',comercial:'CALCEFOR D CAPS.X60',um:'320',uc:'MG',cantidad:'60'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Capsulas',comercial:'CALDAR-D FTE.CAPS.X30',um:'450',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Comprimidos',comercial:'CALDEVAL COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Comprimidos',comercial:'CALDEVAL COMP.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'CARBONATO DE CALCIO + COLECALCIFEROL',forma:'Capsulas',comercial:'ELCAL D FTE.CAPS.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'(B) CARVEDILOL 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'(B) LODIPRES 12.5mg COMP.X30',um:'12.5',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'(B) LODIPRES 6.25mg COMP.X30',um:'6.25',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'CARVEDILOL (B) 12.5mg X30COM.',um:'12.5',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'CARVEDILOL (B) 25mg X30COM.',um:'25',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'CARVEDILOL 25mg X30COM.',um:'25',uc:'MG',cantidad:'30'},
{principio:'CARVEDILOL',forma:'Comprimidos',comercial:'LODIPRES (B) 25mg X30COM.',um:'25',uc:'MG',cantidad:'30'},
{principio:'CEFADROXIL0',forma:'Capsulas',comercial:'(B) CEFADROXILO 500mg CAPS.X8',um:'500',uc:'MG',cantidad:'8'},
{principio:'CEFADROXIL0',forma:'Capsulas',comercial:'CEFADROXILO (B) 500mg X8CAP.',um:'500',uc:'MG',cantidad:'8'},
{principio:'CEFADROXIL0',forma:'Jarabe',comercial:'CEFADROXILO 250mg/5ml JBE.X60ml',um:'250',uc:'MG',cantidad:'60'},
{principio:'CEFADROXIL0',forma:'Capsulas',comercial:'CEFADROXILO 500mg X8CAP.',um:'500',uc:'MG',cantidad:'8'},
{principio:'CEFRADINA',forma:'Capsulas',comercial:'CEFRADINA 500mg CAPS.X8',um:'500',uc:'MG',cantidad:'8'},
{principio:'CEFRADINA',forma:'Capsulas',comercial:'CEFRADINA 500mg X8CAP.',um:'500',uc:'MG',cantidad:'8'},
{principio:'CELECCOXIB',forma:'Capsulas',comercial:'(B) ARGIUM 200mg CAP.X10',um:'200',uc:'MG',cantidad:'10'},
{principio:'CELECCOXIB',forma:'Capsulas',comercial:'(B) ARGIUM 200mg CAP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'CELECCOXIB',forma:'CAPSULAS',comercial:'(R) CELEBRA 200mg CAPS.X10',um:'200',uc:'MG',cantidad:'10'},
{principio:'CEPILLO DENTAL',forma:'OTRO-NINGUNA',comercial:'COLG.CEP.360° MED 2X1',um:'1',uc:'UN',cantidad:'1'},
{principio:'CEPILLO DENTAL',forma:'OTRO-NINGUNA',comercial:'PHB CEP.JUNIOR',um:'1',uc:'UN',cantidad:'1'},
{principio:'CEPILLO DENTAL',forma:'OTRO-NINGUNA',comercial:'PHB CEP.PETIT',um:'1',uc:'UN',cantidad:'1'},
{principio:'CETIRIZINA',forma:'Comprimidos',comercial:'CETIRIZINA (B) 10mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'CETIRIZINA',forma:'Comprimidos',comercial:'RIGOTAX (B) 10mg X10COM.',um:'10',uc:'MG',cantidad:'10'},
{principio:'CETIRIZINA',forma:'Comprimidos',comercial:'RIGOTAX 10mg COMP.X10',um:'10',uc:'MG',cantidad:'10'},
{principio:'CETIRIZINA',forma:'Solución Oral',comercial:'RIGOTAX 5mg SUSP.60ml',um:'5',uc:'MG',cantidad:'60'},
{principio:'CICLOBENZAPRINA',forma:'Comprimidos',comercial:'CICLOBENZAPRINA (B) 10mg X20COM.',um:'10',uc:'MG',cantidad:'20'},
{principio:'CICLOBENZAPRINA',forma:'Comprimidos',comercial:'CICLOBENZAPRINA 10mg X20COM.',um:'10',uc:'MG',cantidad:'20'},
{principio:'CICLOFOSFAMIDA',forma:'grageas',comercial:'ENDOXAN 50mg GRAG.X50',um:'50',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(B) EQUORAL 100mg CAPS.X50',um:'100',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(B) EQUORAL 25mg CAPS.X50',um:'25',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(B) EQUORAL 50mg CAPS.X50',um:'50',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(B) SIGMASPORIN 50mg CAPS.X50',um:'50',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(R) SANDIM NEO 100mg CAPS.X50',um:'100',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(R) SANDIM NEO 10mg CAPS.X60',um:'10',uc:'MG',cantidad:'60'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(R) SANDIM NEO 25mg CAPS.X50',um:'25',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Capsulas',comercial:'(R) SANDIM NEO 50mg CAPS.X50',um:'50',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Solución Oral',comercial:'EQUORAL (B) 100mg SOL.50ml',um:'100',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Solución Oral',comercial:'SANDIM 100mg SOL.50ml',um:'100',uc:'MG',cantidad:'50'},
{principio:'CICLOSPORINA',forma:'Solución Oral',comercial:'SANDIM NEO 100mg SOL.50ml',um:'100',uc:'MG',cantidad:'50'},
{principio:'CINACALCET',forma:'Comprimidos',comercial:'GESER 30mg COMP.X30',um:'30',uc:'MG',cantidad:'30'},
{principio:'CINACALCET',forma:'Comprimidos',comercial:'GESER 60mg COMP.X30',um:'60',uc:'MG',cantidad:'30'},
{principio:'CINTAS REACTIVAS PARA CETONAS EN SANGRE',forma:'OTRO-NINGUNA',comercial:'FREESTYLE OPT CETONAS X10',um:'1',uc:'UN',cantidad:'10'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK ACTIVE X25CINTAS',um:'1',uc:'UN',cantidad:'25'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK ACTIVE X50CINTAS',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK GUIDE 25CINTAS',um:'1',uc:'UN',cantidad:'25'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK GUIDE 50CINTAS',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK INSTANT CINT.50',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ACCU CHEK PERFORMA X50',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'FREESTYLE LITE GLUCOSA X50',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'FREESTYLE OPT GLUCOSA X25',um:'1',uc:'UN',cantidad:'25'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'FREESTYLE OPT GLUCOSA X50',um:'1',uc:'UN',cantidad:'50'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ONE TOUCH ULT.X25CINTAS',um:'1',uc:'UN',cantidad:'25'},
{principio:'CINTAS REACTIVAS PARA GLICEMIA',forma:'Cintas Medicion Glicemia',comercial:'ONE TOUCH ULT.X50CINTAS',um:'1',uc:'UN',cantidad:'50'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'(B) CIPROFLOXACINO 500mg COMP.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'(B) CIPROVAL 500mg COMP.X10',um:'500',uc:'MG',cantidad:'10'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'(B) CIPROVAL 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'(R) BAYCIP 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'CIPROFLOXACINO (B) 500mg X6COM.',um:'500',uc:'MG',cantidad:'6'},
{principio:'CIPROFLOXACINO',forma:'Comprimidos',comercial:'CIPROFLOXACINO 500mg X6COM.',um:'500',uc:'MG',cantidad:'6'},
{principio:'CIPROFLOXACINO OFTALMICO COLIRIO',forma:'Solucion Oftalmica',comercial:'CIPROVAL 0.3% SOL.OFT.5ml',um:'3',uc:'MG',cantidad:'5'},
{principio:'CITALOPRAM',forma:'Comprimidos',comercial:'(B) CITALOPRAM 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'CITALOPRAM',forma:'Comprimidos',comercial:'CITALOPRAM (B) 20mg X30COM.REC',um:'20',uc:'MG',cantidad:'30'},
{principio:'CITALOPRAM',forma:'Comprimidos',comercial:'CITALOPRAM (B) 20mg X30COM.REC.',um:'20',uc:'MG',cantidad:'30'},
{principio:'CITALOPRAM',forma:'Comprimidos',comercial:'CITALOPRAM 20mg x28COM.REC',um:'20',uc:'MG',cantidad:'28'},
{principio:'CLARITROMICINA',forma:'Comprimidos',comercial:'(B) EUROMICINA 500mg COMP.X14',um:'500',uc:'MG',cantidad:'14'},
{principio:'CLARITROMICINA',forma:'Comprimidos',comercial:'(B) PRECLAR 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'CLARITROMICINA',forma:'Comprimidos',comercial:'CLARITROMICINA (B) 500mg X14COM.',um:'500',uc:'MG',cantidad:'14'},
{principio:'CLARITROMICINA',forma:'Comprimidos',comercial:'CLARITROMICINA 500mg X14COM.',um:'500',uc:'MG',cantidad:'14'},
{principio:'CLARITROMICINA',forma:'Jarabe',comercial:'CLATIC 125mg SUSP.80ml',um:'125',uc:'MG',cantidad:'80'},
{principio:'CLARITROMICINA',forma:'Comprimidos',comercial:'EUROMICINA (B) 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'CLARITROMICINA',forma:'Solución Oral',comercial:'EUROMICINA 125mg SUSP.80ml',um:'125',uc:'MG',cantidad:'80'},
{principio:'CLARITROMICINA',forma:'Solución Oral',comercial:'EUROMICINA 250mg SUSP.80ml',um:'250',uc:'MG',cantidad:'80'},
{principio:'CLINDAMICINA',forma:'Capsulas',comercial:'DALACIN C 300mg CAPS.X16',um:'300',uc:'MG',cantidad:'16'},
{principio:'CLONAZEPAM',forma:'Comprimidos',comercial:'(B) CLONAZEPAM 2mg COMP.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'CLONAZEPAM',forma:'Comprimidos',comercial:'(B) VALPAX 0.5mg COMP.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'CLONAZEPAM',forma:'Comprimidos',comercial:'(B) VALPAX 2mg COMP.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'CLONAZEPAM',forma:'Solución Oral',comercial:'ACEPRAN 2.5mg GTAS.10ml',um:'2.5',uc:'MG',cantidad:'10'},
{principio:'CLONAZEPAM',forma:'Solución Oral',comercial:'RAVOTRIL 2.5mg GTAS.20ml',um:'2.5',uc:'MG',cantidad:'10'},
{principio:'CLOPIDROGEL',forma:'Comprimidos',comercial:'(B) CLOTROMBRIX 75mg COMP.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'CLOPIDROGEL',forma:'Comprimidos',comercial:'(B) ISKIMIL 75mg COMP.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'CLOPIDROGEL',forma:'Comprimidos',comercial:'FLUYET (B) 75mg X30COM.',um:'75',uc:'MG',cantidad:'30'},
{principio:'CLORAMBUCILO',forma:'Comprimidos',comercial:'LEUKERAN 2mg COMP.X25',um:'2',uc:'MG',cantidad:'25'},
{principio:'CLORANFENICOL OFTALMICO COLIRIO',forma:'Solucion Oftalmica',comercial:'CLORANFENICOL 0.5% SOF.X10ml',um:'5',uc:'MG',cantidad:'10'},
{principio:'CLORANFENICOL OFTALMICO UNGUENTO',forma:'Ungüento',comercial:'CLORANFENICOL 1% UNG.OFT.X3.5g',um:'10',uc:'MG',cantidad:'3.5'},
{principio:'CLORFENAMINA',forma:'Comprimidos',comercial:'CLORFENAMINA (B) 4mg X20COM.',um:'4',uc:'MG',cantidad:'20'},
{principio:'CLORHEXIDINA USO DENTAL',forma:'Colutorio',comercial:'ORALGENE 0.12% COLU.120ml',um:'1.2',uc:'MG',cantidad:'120'},
{principio:'CLORPROMAZINA',forma:'Comprimidos',comercial:'LARGACTIL 100mg COM.X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'CLOXACILINA',forma:'Capsulas',comercial:'CLOXACILINA 500mg CAPS.X12',um:'500',uc:'MG',cantidad:'12'},
{principio:'CLOXACILINA',forma:'Capsulas',comercial:'CLOXACILINA 500mg X12CAP.',um:'500',uc:'MG',cantidad:'12'},
{principio:'CLOZAPINA',forma:'Comprimidos',comercial:'(B) LODUX 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'CLOZAPINA',forma:'Comprimidos',comercial:'LEPONEX 100mg COMP.X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'CLOZAPINA',forma:'Comprimidos',comercial:'LEPONEX 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'CLOZAPINA',forma:'Comprimidos',comercial:'LEPONEX 25mg COMP.X20',um:'25',uc:'MG',cantidad:'20'},
{principio:'COBICISTAT+DARUNAVIR+EMTRICITABINA+TENOFOVIR',forma:'Comprimidos Recubiertos',comercial:'SYMTUZA X30COM.REC',um:'150/800/200/10',uc:'MG',cantidad:'30'},
{principio:'COBICISTATEN +ELVITEGRAVIR+EMTRICI+TENOF',forma:'Comprimidos',comercial:'GENVOYA COM.X30',um:'1',uc:'G',cantidad:'30'},
{principio:'COBICISTATEN +ELVITEGRAVIR+EMTRICI+TENOF',forma:'Comprimidos',comercial:'STRIBILD COM.X30',um:'1',uc:'G',cantidad:'30'},
{principio:'CODEINA + PARACETAMOL',forma:'Comprimidos',comercial:'CODEIPAR 500/30 COMP.X12',um:'30',uc:'MG',cantidad:'12'},
{principio:'COTRIMOXAZOL',forma:'Comprimidos',comercial:'COTRIMOXAZOL COMP.X20',um:'400',uc:'MG',cantidad:'20'},
{principio:'COTRIMOXAZOL',forma:'Comprimidos',comercial:'COTRIMOXAZOL F COMP.X10',um:'800',uc:'MG',cantidad:'10'},
{principio:'COTRIMOXAZOL',forma:'Comprimidos',comercial:'COTRIMOXAZOL FTE.X10COM.',um:'800',uc:'MG',cantidad:'10'},
{principio:'COTRIMOXAZOL',forma:'Solución Oral',comercial:'COTRIMOXAZOL S.O.X100ml',um:'200',uc:'MG',cantidad:'100'},
{principio:'COTRIMOXAZOL',forma:'Solución Oral',comercial:'COTRIMOXAZOL SUSP.100ml',um:'200',uc:'MG',cantidad:'100'},
{principio:'COTRIMOXAZOL',forma:'Comprimidos',comercial:'COTRIMOXAZOL X20COM.',um:'400',uc:'MG',cantidad:'20'},
{principio:'COTRIMOXAZOL FORTE',forma:'Comprimidos',comercial:'COTRIMOXAZOL FTE.X10COM.',um:'800',uc:'MG',cantidad:'10'},
{principio:'COTRIMOXAZOL FORTE',forma:'Comprimidos',comercial:'SEPTRIN FTE.COMP.X14',um:'800',uc:'MG',cantidad:'14'},
{principio:'DARUNAVIR',forma:'Comprimidos',comercial:'PREZISTA 400mg COM.X60',um:'400',uc:'MG',cantidad:'60'},
{principio:'DARUNAVIR',forma:'Comprimidos',comercial:'PREZISTA 600mg COMP.X60',um:'600',uc:'MG',cantidad:'60'},
{principio:'DARUNAVIR + COBICISTAT',forma:'Comprimidos',comercial:'PREZCOBIX COMP.X30',um:'800',uc:'MG',cantidad:'30'},
{principio:'DASATINIB',forma:'Comprimidos',comercial:'SPRYCEL 100mg COM.X30',um:'1',uc:'UN',cantidad:'30'},
{principio:'DASATINIB',forma:'Comprimidos',comercial:'SPRYCEL 20mg COM.X60',um:'1',uc:'UN',cantidad:'60'},
{principio:'DASATINIB',forma:'Comprimidos',comercial:'SPRYCEL 50mg COM.X60',um:'1',uc:'UN',cantidad:'60'},
{principio:'DASATINIB',forma:'Comprimidos',comercial:'SPRYCEL 70mg COM.X60',um:'1',uc:'UN',cantidad:'60'},
{principio:'DEGARELIX ACETATO',forma:'Ampollas',comercial:'FIRMAGON 120mg X2F-A',um:'120',uc:'MG',cantidad:'2'},
{principio:'DEGARELIX ACETATO',forma:'Ampollas',comercial:'FIRMAGON 80mg X1F-A',um:'80',uc:'MG',cantidad:'1'},
{principio:'DESLORATADINA',forma:'Comprimidos',comercial:'(B) D-HISTAPLUS 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'DESLORATADINA',forma:'Jarabe',comercial:'AERIUS 2.5mg/5ml JB X120ml',um:'2.5',uc:'5ML',cantidad:'120'},
{principio:'DESLORATADINA',forma:'Jarabe',comercial:'DESPEX 2.5mg JBE.120ml',um:'2.5',uc:'5ML',cantidad:'120'},
{principio:'DESMOPRESINA',forma:'Aerosol',comercial:'DDAVP 0.1mg INTRAN 2.5ml',um:'10',uc:'MCG',cantidad:'25'},
{principio:'DESMOPRESINA',forma:'Comprimidos',comercial:'DDAVP 0.2mg COMP.X30',um:'0.2',uc:'MG',cantidad:'30'},
{principio:'DESMOPRESINA',forma:'Ampollas',comercial:'OCTOSTIM AMP.X2',um:'15',uc:'MCG',cantidad:'2'},
{principio:'DESMOPRESINA',forma:'Ampollas',comercial:'OCTOSTIM AMP.X5',um:'15',uc:'MCG',cantidad:'5'},
{principio:'DEXAMETASONA',forma:'Comprimidos',comercial:'CORTYK 4mg COMP.X10',um:'4',uc:'MG',cantidad:'10'},
{principio:'DEXAMETASONA ORAL',forma:'Comprimidos',comercial:'CORTYK 4mg COMP.X10',um:'4',uc:'MG',cantidad:'10'},
{principio:'DEXRAZOXANO (CLORHIDRATO)',forma:'Ampolla Multidosis',comercial:'CARDIOXANE 500mg AMP.X1',um:'500',uc:'MG',cantidad:'1'},
{principio:'DIAZEPAM',forma:'Comprimidos',comercial:'(B) DIAZEPAM 10mg COMP.X20',um:'10',uc:'MG',cantidad:'20'},
{principio:'DICLOFENACO COLESTIRAMINA',forma:'Capsulas',comercial:'PRO-LERTUS 140mg CAPS.X10',um:'140',uc:'MG',cantidad:'10'},
{principio:'DICLOFENACO COLESTIRAMINA',forma:'Capsulas',comercial:'PRO-LERTUS 140mg CAPS.X20',um:'140',uc:'MG',cantidad:'20'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Gel',comercial:'DICLOFENACO 1.16% GEL 30g',um:'10',uc:'MG',cantidad:'30'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Gel',comercial:'DICLOFENACO 1.16% GEL X30g',um:'10',uc:'MG',cantidad:'30'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Gel',comercial:'DICLOFENACO 1% GEL 30g',um:'10',uc:'MG',cantidad:'30'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Gel',comercial:'DICLOFENACO 1% GEL X30g',um:'10',uc:'MG',cantidad:'30'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Gel',comercial:'LERTUS 1% GEL 60g',um:'10',uc:'MG',cantidad:'60'},
{principio:'DICLOFENACO DIETILAMINA',forma:'Aerosol',comercial:'MERPAL 1.16% SP.110ml',um:'11.6',uc:'MG',cantidad:'110'},
{principio:'DICLOFENACO POTASICO',forma:'Grageas',comercial:'(R) CATAFLAM 50mg GRAG.X10',um:'50',uc:'MG',cantidad:'10'},
{principio:'DICLOFENACO RESINATO',forma:'Solución Oral',comercial:'LERTUS 15mg GTAS.25ml',um:'15',uc:'MG',cantidad:'25'},
{principio:'DICLOFENACO RESINATO',forma:'Solución Oral',comercial:'PIROFLAM 1.5% GTAS.20ml',um:'15',uc:'MG',cantidad:'20'},
{principio:'DICLOFENACO SODICO',forma:'Comprimidos',comercial:'DICLOFENACO 50mg COMP.X10',um:'50',uc:'MG',cantidad:'10'},
{principio:'DICLOFENACO SODICO',forma:'Comprimidos',comercial:'DICLOFENACO 50mg X10COM.',um:'50',uc:'MG',cantidad:'10'},
{principio:'DICLOFENACO SODICO',forma:'Ampollas',comercial:'DICLOFENACO 75mg AMP.X5',um:'75',uc:'MG',cantidad:'5'},
{principio:'DICLOFENACO SODICO',forma:'Ampollas',comercial:'DICLOFENACO 75mg X5AMP.',um:'75',uc:'MG',cantidad:'5'},
{principio:'DICLOFENACO SODICO',forma:'Capsulas',comercial:'DICLOFENACO RET.100mg CAPS.X8',um:'100',uc:'MG',cantidad:'8'},
{principio:'DICLOFENACO SODICO',forma:'Capsulas',comercial:'DICLOFENACO RET.100mg X8CAP.',um:'100',uc:'MG',cantidad:'8'},
{principio:'DICLOFENACO SODICO',forma:'Ampollas',comercial:'LERTUS (B) 75mg AMP.X5',um:'75',uc:'MG',cantidad:'5'},
{principio:'DICLOFENACO SODICO',forma:'Comprimidos',comercial:'LERTUS 25mg COMP.X20',um:'25',uc:'MG',cantidad:'20'},
{principio:'DICLOFENACO SODICO',forma:'Comprimidos',comercial:'LERTUS SR 75mg COMP.X10',um:'75',uc:'MG',cantidad:'10'},
{principio:'DICLOFENACO SODICO',forma:'Solucion Oftalmica',comercial:'OFTIC 0.1% SOL.OFT.10ml',um:'1',uc:'MG',cantidad:'10'},
{principio:'DIGOXINA',forma:'Comprimidos',comercial:'DIGOXINA 0.25mg X30COM.',um:'0.25',uc:'MG',cantidad:'30'},
{principio:'DIPIRONA',forma:'Ampollas',comercial:'DIPIRONA 1g/2ml AMP.X5',um:'1',uc:'G',cantidad:'5'},
{principio:'DIPIRONA',forma:'Ampollas',comercial:'DIPIRONA 1g/2ml X 5 AMP.',um:'1',uc:'G',cantidad:'5'},
{principio:'DIPIRONA',forma:'Ampollas',comercial:'DIPIRONA 1g/2ml X5AMP.',um:'1',uc:'G',cantidad:'5'},
{principio:'DIPIRONA',forma:'Comprimidos',comercial:'DIPIRONA 300mg COMP.X20',um:'300',uc:'MG',cantidad:'20'},
{principio:'DIPIRONA',forma:'Comprimidos',comercial:'DIPIRONA 300mg X20COM.',um:'300',uc:'MG',cantidad:'20'},
{principio:'DOLUTEGRAVIR',forma:'Comprimidos',comercial:'TIVICAY 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'Dolutegravir/ Lamivudina/ Tenofovir',forma:'Comprimidos',comercial:'ACRIPTEGA (B) 50/300/300X30COM',um:'300',uc:'MG',cantidad:'30'},
{principio:'Dolutegravir/lamivudina',forma:'Comprimidos',comercial:'DOVATO 50mg/300mg X30COM.REC',um:'50/300',uc:'MG',cantidad:'30'},
{principio:'DOMPERIDONA',forma:'Comprimidos',comercial:'(B) DOMPERIDONA 10mg COMP.X20',um:'10',uc:'MG',cantidad:'20'},
{principio:'DOMPERIDONA',forma:'Capsulas',comercial:'(B) IDON 10mg CAPS.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'DOMPERIDONA',forma:'Comprimidos',comercial:'DOMPERIDONA (B) 10mg X20COM.',um:'10',uc:'MG',cantidad:'20'},
{principio:'DOMPERIDONA',forma:'Solución Oral',comercial:'DOMPESIN 1% GTAS.20ml',um:'10',uc:'MG',cantidad:'20'},
{principio:'DOMPERIDONA',forma:'Solución Oral',comercial:'DONEGAL 10mg GTAS.15ml',um:'10',uc:'MG',cantidad:'15'},
{principio:'DOMPERIDONA',forma:'Solución Oral',comercial:'IDON 10mg GTAS.15ml',um:'10',uc:'MG',cantidad:'15'},
{principio:'DOMPERIDONA',forma:'Supositorios',comercial:'IDON AD.60mg SUPOS.X5',um:'60',uc:'MG',cantidad:'5'},
{principio:'DOMPERIDONA',forma:'Solución Oral',comercial:'IDON PED.5mg SUSP.100ml',um:'5',uc:'MG',cantidad:'100'},
{principio:'DOMPERIDONA',forma:'Solución Oral',comercial:'RESTOL 10mg/ml GTAS.20ml',um:'10',uc:'MG',cantidad:'20'},
{principio:'DONEPECILO',forma:'Comprimidos',comercial:'(B) DOPABEN 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'DONEPECILO',forma:'Comprimidos',comercial:'(B) EVIMAL 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'DONEPECILO',forma:'Comprimidos',comercial:'EVOCAZ CD 10mg COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'DONEPECILO',forma:'Comprimidos',comercial:'EVOCAZ CD 5mg COM.',um:'5',uc:'MG',cantidad:'30'},
{principio:'DOXAZOSINA',forma:'Comprimidos',comercial:'ANGICON 2mg COMP.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'DOXAZOSINA',forma:'Comprimidos',comercial:'ANGICON 4 mg x 30 COMP',um:'4',uc:'MG',cantidad:'30'},
{principio:'DOXAZOSINA',forma:'Comprimidos',comercial:'CARDURA (R) 2mg X30COM.',um:'2',uc:'MG',cantidad:'30'},
{principio:'DOXORRUBICINA',forma:'Ampollas',comercial:'DOXORUBICINA 10mg/5ml 1F-A',um:'10',uc:'MG',cantidad:'1'},
{principio:'DUTASTERIDA',forma:'Capsulas',comercial:'AVODART 0.5mg CAPS.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'DUTASTERIDA',forma:'Capsulas',comercial:'AVOLVE 0.5mg CAPS.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'DUTASTERIDA',forma:'Capsulas Liberación Sostenida',comercial:'GOTELY DUO CAPS.X30',um:'0.5/0.4',uc:'MG',cantidad:'30'},
{principio:'DUTASTERIDA + TAMSULOSINA',forma:'Capsulas',comercial:'DUODART 0.5mg CAPS.X90',um:'0.5',uc:'MG',cantidad:'90'},
{principio:'DUTASTERIDA + TAMSULOSINA',forma:'Capsulas',comercial:'DUODART CAPS.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'EFAVIRENZ (EFV)',forma:'Comprimidos',comercial:'EFAVIRENZ (B) 600mg X30COM.REC',um:'600',uc:'MG',cantidad:'30'},
{principio:'EFAVIRENZ + EMTRICITABINA + TENOFOVIR',forma:'Comprimidos',comercial:'ATRIPLA COMP.X30',um:'1',uc:'UN',cantidad:'30'},
{principio:'EFAVIRENZ + EMTRICITABINA + TENOFOVIR',forma:'Comprimidos',comercial:'TENMEFA (B) 30COM.',um:'1',uc:'UN',cantidad:'30'},
{principio:'EFAVIRENZ + EMTRICITABINA + TENOFOVIR',forma:'Comprimidos',comercial:'TRICUS X30COM.REC.',um:'1',uc:'UN',cantidad:'30'},
{principio:'EMTRICITABINA + BICTEGRAVIR + TENOFOVIR',forma:'Comprimidos',comercial:'BIKTARVY X30COM.REC',um:'200/50/25',uc:'MG',cantidad:'30'},
{principio:'EMTRICITABINA+RILPIVRINA+TENOFOVIR',forma:'Comprimidos',comercial:'COMPLERA COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'EMTRICITABINA+RILPIVRINA+TENOFOVIR',forma:'Comprimidos',comercial:'COMPLERA COMP.X30',um:'255',uc:'MG',cantidad:'30'},
{principio:'ENALAPRIL',forma:'Comprimidos',comercial:'(B) ENALTEN- 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'ENALAPRIL',forma:'Comprimidos',comercial:'ENALAPRIL (B) 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'ENALAPRIL',forma:'Comprimidos',comercial:'ENALAPRIL 10mg X20COM.',um:'10',uc:'MG',cantidad:'20'},
{principio:'ENALAPRIL',forma:'Comprimidos',comercial:'ENALAPRIL 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'ENALAPRIL',forma:'Comprimidos',comercial:'ENALTEN 10mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'ENOXAPARINA',forma:'Jeringa Prellenada Monodosis',comercial:'CLEXANE 40mg/0.4ml X2JER.',um:'40',uc:'MG',cantidad:'2'},
{principio:'ENTECAVIR',forma:'Comprimidos',comercial:'BARACLUDE 0.5 mg COMP x 30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'ENTECAVIR',forma:'Comprimidos',comercial:'BARACLUDE 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'ENTECAVIR',forma:'Comprimidos',comercial:'BENVIR (B) 1mg X8COM.REC',um:'1',uc:'MG',cantidad:'8'},
{principio:'ENTECAVIR',forma:'Comprimidos Recubiertos',comercial:'ENTECAVIR (B) 0.5MG X10COM.REC',um:'0.5',uc:'MG',cantidad:'10'},
{principio:'ENZIMAS PANCREÁTICAS',forma:'Capsulas',comercial:'CREON 10.000 CAPS.X20',um:'10000',uc:'U.I.',cantidad:'20'},
{principio:'ENZIMAS PANCREÁTICAS',forma:'Capsulas',comercial:'CREON 25.000 CAPS.X50',um:'25000',uc:'U.I.',cantidad:'50'},
{principio:'ENZIMAS PANCREÁTICAS',forma:'Capsulas',comercial:'PANZYTRAT OK 20g CAP.X20',um:'400',uc:'U.I.',cantidad:'20'},
{principio:'EPOETINA ALFA HUMANA RECOMBINANTE',forma:'Solución Inyectable',comercial:'ERITRELAN 2000 UI/ml x 1F-A',um:'2000',uc:'UI',cantidad:'1'},
{principio:'EPOETINA ALFA HUMANA RECOMBINANTE',forma:'Solución Inyectable',comercial:'ERITRELAN 4000 UI/ml x 1F-A',um:'4000',uc:'UI',cantidad:'1'},
{principio:'EPOETINA ALFA HUMANA RECOMBINANTE',forma:'Solución Inyectable',comercial:'HEPTA 2000UI JER.X1',um:'2000',uc:'UI',cantidad:'1'},
{principio:'EPOETINA ALFA HUMANA RECOMBINANTE',forma:'Solución Inyectable',comercial:'HEPTA 4000UI JP.X1',um:'4000',uc:'UI',cantidad:'1'},
{principio:'Equipo medidor glicemia',forma:'Equipo medidor glicemia',comercial:'ACCU CHEK PERFORMA NANO KIT',um:'1',uc:'UN',cantidad:'1'},
{principio:'EQUIPO PARA DETERMINACION DE GLICEMIA',forma:'Equipo Medidor de Glicemia',comercial:'ACCU CHEK GUIDE MONITOR',um:'1',uc:'UN',cantidad:'1'},
{principio:'EQUIPO PARA DETERMINACION DE GLICEMIA',forma:'Equipo Medidor de Glicemia',comercial:'ACCU CHEK INSTANT KIT',um:'1',uc:'UN',cantidad:'1'},
{principio:'EQUIPO PARA DETERMINACION DE GLICEMIA',forma:'Equipo Medidor de Glicemia',comercial:'FREESTYLE OPTIUM NEO KIT',um:'1',uc:'UN',cantidad:'1'},
{principio:'EQUIPO PARA DETERMINACION DE GLICEMIA',forma:'Equipo Medidor de Glicemia',comercial:'ONE TOUCH ULT.MINI KIT VERDE',um:'1',uc:'UN',cantidad:'1'},
{principio:'ERITROMICINA',forma:'Comprimidos',comercial:'ERITROMICINA 500mg COMP.X8',um:'500',uc:'MG',cantidad:'8'},
{principio:'ERITROMICINA',forma:'Comprimidos',comercial:'ERITROMICINA 500mg X8COM.',um:'500',uc:'MG',cantidad:'8'},
{principio:'ERITROPOYETINA HUMANA (BETA RECOMBINANTE',forma:'Ampollas',comercial:'RECORMON 2000UI AMP.X1',um:'2000',uc:'U.I.',cantidad:'1'},
{principio:'ESCITALOPRAM',forma:'Comprimidos',comercial:'(B) IPRAN 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'ESCITALOPRAM',forma:'Comprimidos',comercial:'ESCITALOPRAM (B) 10mg X30COM.REC.',um:'10',uc:'MG',cantidad:'30'},
{principio:'ESCITALOPRAM',forma:'Comprimidos',comercial:'IPRAN (B) 10mg X30COM.REC',um:'10',uc:'MG',cantidad:'30'},
{principio:'ESCITALOPRAM',forma:'Comprimidos',comercial:'IPRAN (B) 10mg X40COM.REC',um:'10',uc:'MG',cantidad:'40'},
{principio:'ESOMEPRAZOL',forma:'COMPRIMIDOS',comercial:'ESOMEPRAZOL (B) 20mg X30COM.REC',um:'20',uc:'MG',cantidad:'30'},
{principio:'ESOMEPRAZOL',forma:'CAPSULAS',comercial:'ESOMEPRAZOL (B) 40mg X30CAP',um:'40',uc:'MG',cantidad:'30'},
{principio:'ESPIRONOLACTONA',forma:'Comprimidos',comercial:'ESPIRONOLACTONA (B) 25mg 20COM.',um:'25',uc:'MG',cantidad:'20'},
{principio:'ESPIRONOLACTONA',forma:'Comprimidos',comercial:'ESPIRONOLACTONA 25mg X20COM.',um:'25',uc:'MG',cantidad:'20'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'EXOPIL 3mg COMP.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NIRVAN 2mg COMP.X40',um:'2',uc:'MG',cantidad:'40'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NIRVAN 3mg COMP.X40',um:'3',uc:'MG',cantidad:'40'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NOPTIC 2mg COMP.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NOPTIC 3mg COMP.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NOVO INSOMNIUM 2mg COM.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'NOVO INSOMNIUM 3mg COM.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'PLESSIR 3mg COMP.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'ESZOPICLONA',forma:'Comprimidos',comercial:'ZOPINOM 3mg COMP.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'ETANERCEPT',forma:'Jeringa Prellenada Monodosis',comercial:'ENBREL 25mg JP.X4',um:'25',uc:'MG',cantidad:'4'},
{principio:'ETANERCEPT',forma:'Ampollas',comercial:'ENBREL 50mg AUTOINYECTOR',um:'50',uc:'MG',cantidad:'4'},
{principio:'ETORICOXIB',forma:'Comprimidos',comercial:'ARCOXIA 60mg X14COM.REC.',um:'60',uc:'MG',cantidad:'14'},
{principio:'ETORICOXIB',forma:'Comprimidos',comercial:'XUMER 60mg COMP.X14',um:'60',uc:'MG',cantidad:'14'},
{principio:'ETRAVIRINA',forma:'Comprimidos',comercial:'INTELENCE 200mg COMP.X60',um:'200',uc:'MG',cantidad:'60'},
{principio:'EVEROLIMUS',forma:'Comprimidos',comercial:'CERTICAN 0.25mg COMP.X60',um:'0.25',uc:'MG',cantidad:'60'},
{principio:'EVEROLIMUS',forma:'Comprimidos',comercial:'CERTICAN 0.5mg COMP.X60',um:'0.5',uc:'MG',cantidad:'60'},
{principio:'EVEROLIMUS',forma:'Comprimidos',comercial:'CERTICAN 0.75mg COMP.X60',um:'0.75',uc:'MG',cantidad:'60'},
{principio:'EXEMESTANO',forma:'Comprimidos',comercial:'(B) EXEVITAE 25mg COMP. X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'EXEMESTANO',forma:'Comprimidos',comercial:'(B) NODUTAX 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'EXEMESTANO',forma:'Comprimidos',comercial:'AROMASIN (R) 25mg X30CAP.',um:'25',uc:'MG',cantidad:'30'},
{principio:'FACTOR IX (ANTIHEMOFILIA B)',forma:'Ampolla Multidosis',comercial:'ALPHANINE 500UI 1F-A',um:'500',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR IX (ANTIHEMOFILIA B)',forma:'Ampolla Multidosis',comercial:'FACTOR IX 500UI F-A X1',um:'500',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR IX (ANTIHEMOFILIA B)',forma:'Ampolla Multidosis',comercial:'OCTANINE FACTOR IX 1000UI',um:'1000',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Ampolla Multidosis',comercial:'FACTOR VIII ALPH 1000UI VIAL',um:'1000',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Ampolla Multidosis',comercial:'FACTOR VIII ALPH 250UI VIAL',um:'250',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Ampolla Multidosis',comercial:'FACTOR VIII ALPH 500UI VIAL',um:'500',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Ampolla Multidosis',comercial:'FANHDI 500/600UI',um:'500',uc:'U.I.',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Solución Inyectable',comercial:'OCTANATE FACTOR VIII 250UI X1 VIAL',um:'250',uc:'UI',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Solución Inyectable',comercial:'OCTANATE FACTOR VIII 500UI X1AMP.',um:'500',uc:'UI',cantidad:'1'},
{principio:'FACTOR VIII (ANTIHEMOFILIA A)',forma:'Ampolla Multidosis',comercial:'OCTANATE FACTOR VIII X1VIAL',um:'1000',uc:'U.I.',cantidad:'1'},
{principio:'FAMOTIDINA',forma:'Comprimidos',comercial:'FAMOTIDINA (B) 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'FAMOTIDINA',forma:'Comprimidos',comercial:'FAMOTIDINA 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'FAMOTIDINA',forma:'Comprimidos',comercial:'FAMOTIDINA 40mg COMP.X10',um:'40',uc:'MG',cantidad:'10'},
{principio:'FAMOTIDINA',forma:'Comprimidos',comercial:'FAMOTIDINA 40mg X10COM.',um:'40',uc:'MG',cantidad:'10'},
{principio:'FENITOINA',forma:'Comprimidos',comercial:'FENITOINA 100mg X30COM.',um:'100',uc:'MG',cantidad:'30'},
{principio:'FENOBARBITAL',forma:'Comprimidos',comercial:'FENOBARBITAL 100mg COMP.X10',um:'100',uc:'MG',cantidad:'10'},
{principio:'FENOBARBITAL',forma:'Comprimidos',comercial:'FENOBARBITAL 100mg X10COM.',um:'100',uc:'MG',cantidad:'10'},
{principio:'FIERRO',forma:'Ampolla',comercial:'CHELTIN 100mg/5ml AMP.X1',um:'100',uc:'MG',cantidad:'1'},
{principio:'FIERRO (HIDROXIDO FERRICO - SACAROSA)',forma:'Ampollas',comercial:'CHELTIN 100mg/5ml AMP.X6',um:'100',uc:'MG',cantidad:'6'},
{principio:'FIERRO (HIDROXIDO FERRICO - SACAROSA)',forma:'Ampollas',comercial:'VENOFER 100mg X5AMP.',um:'100',uc:'MG',cantidad:'5'},
{principio:'FILGRASTIM',forma:'Ampolla Multidosis',comercial:'FOLTRAN 300mcg VIAL X1',um:'300',uc:'MCG',cantidad:'1'},
{principio:'FILGRASTIM',forma:'Jeringa Prellenada Monodosis',comercial:'LIOPLIN 300MUI F-A X1',um:'300',uc:'MCG',cantidad:'1'},
{principio:'FILGRASTIM',forma:'Jeringa Prellenada Monodosis',comercial:'NEUPOGEN 30MUI/0.5ml JP.X1',um:'300',uc:'MCG',cantidad:'1'},
{principio:'FILGRASTIM',forma:'Jeringa Prellenada Monodosis',comercial:'TEVAGRASTRIM 30MUI/0.5ml JP.X1',um:'300',uc:'MCG',cantidad:'1'},
{principio:'FILTRO SOLAR',forma:'Locion',comercial:'AVENE SOLAR LECHE PF50+ 250ml',um:'1',uc:'ML',cantidad:'200'},
{principio:'FILTRO SOLAR',forma:'Crema',comercial:'NUXE SUN LECH.FAC/CPO.F30 X150ml',um:'1',uc:'ML',cantidad:'150'},
{principio:'FILTRO SOLAR',forma:'Crema',comercial:'NUXE SUN PRT.SOLAR CR.FAC.F30 X 50 ml',um:'1',uc:'ML',cantidad:'50'},
{principio:'FILTROS COMPRESOR',forma:'FILTRO DE AIRE X12UND',comercial:'FILTRO DE AIRE X12UND',um:'1',uc:'UN',cantidad:'1'},
{principio:'FINASTERIDE',forma:'Comprimidos',comercial:'(B) VASTUS-5 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'FINASTERIDE BAJAS DOSIS',forma:'Comprimidos',comercial:'(B) FOLIPIL 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'FLUCLOXACILINA',forma:'Jarabe',comercial:'FLUCLOXACILINA 250mg JBE.60ml',um:'250',uc:'MG',cantidad:'60'},
{principio:'FLUCLOXACILINA',forma:'Solución Oral',comercial:'FLUCLOXACILINA 250mg/5ml S.O.X60ml',um:'250',uc:'MG',cantidad:'60'},
{principio:'FLUCLOXACILINA',forma:'Capsulas',comercial:'FLUCLOXACILINA 500mg CAPS.X6',um:'500',uc:'MG',cantidad:'6'},
{principio:'FLUCLOXACILINA',forma:'Comprimidos',comercial:'FLUCLOXACILINA 500mg COMP.X12',um:'500',uc:'MG',cantidad:'12'},
{principio:'FLUCLOXACILINA',forma:'Comprimidos',comercial:'FLUCLOXACILINA 500mg X12COM.',um:'500',uc:'MG',cantidad:'12'},
{principio:'FLUCONAZOL',forma:'Capsulas',comercial:'(B) FELSOL 150mg CAPS.X1',um:'150',uc:'MG',cantidad:'1'},
{principio:'FLUORURO SODIO',forma:'Solución Oral',comercial:'CARISTOP 0.2% 250ml',um:'2',uc:'MG',cantidad:'250'},
{principio:'FLUOXETINA',forma:'Comprimidos',comercial:'FLUOXETINA (B) 20mg 20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'FLUOXETINA',forma:'Comprimidos',comercial:'FLUOXETINA (B) 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'FLUOXETINA',forma:'Comprimidos',comercial:'LIVERTOX (B) 20mg X20COM.REC.',um:'20',uc:'MG',cantidad:'20'},
{principio:'FLUPENTIXOL',forma:'Ampollas',comercial:'FLUANXOL DEPOT 20mg/ml AMP.X1',um:'20',uc:'MG',cantidad:'1'},
{principio:'FLUTAMIDA',forma:'Comprimidos',comercial:'ETACONIL 250mg COMP.X20',um:'250',uc:'MG',cantidad:'20'},
{principio:'FLUTAMIDA',forma:'Comprimidos',comercial:'ETACONIL 250mg COMP.X90',um:'250',uc:'MG',cantidad:'90'},
{principio:'FLUTAMIDA',forma:'Comprimidos',comercial:'FLUTAMIDA 250mg COM.X90',um:'250',uc:'MG',cantidad:'90'},
{principio:'FLUTAMIDA',forma:'Comprimidos',comercial:'FLUTAMIDA 250mg X90COM.',um:'250',uc:'MG',cantidad:'90'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'BREXOVENT LF 125mcg 120DS.',um:'125',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLIXOTIDE LF 125mcg INH.120DS.',um:'125',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLIXOTIDE LF 125mcg INH.60DS.',um:'125',uc:'MCG',cantidad:'60'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLIXOTIDE LF 250mcg INH.120DS.',um:'250',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLIXOTIDE LF 50mcg INH.120DS.',um:'50',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLUSONA 125mcg INH.120DS.',um:'125',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLUSONA 250mcg INH.120DS.',um:'250',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA',forma:'Aerosol',comercial:'FLUTICORT HFA 125mcg INH.120DS.',um:'125',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA DERMICA',forma:'Crema',comercial:'FLUTIVATE 0.05% CR.15g',um:'0.5',uc:'MG',cantidad:'15'},
{principio:'FLUTICASONA NASAL',forma:'Aerosol',comercial:'ALENYS 27.5 MCG NASAL',um:'27.5',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA NASAL',forma:'Aerosol',comercial:'FLIXONASE 50mcg NAS.120DS.',um:'50',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA NASAL',forma:'Aerosol',comercial:'NEBULEX 50mcg NAS.120DS.',um:'50',uc:'MCG',cantidad:'120'},
{principio:'FLUTICASONA/SALMETEROL',forma:'Capsulas',comercial:'SALFLUTOP 100/50 UF CAP.X60',um:'150',uc:'MG',cantidad:'60'},
{principio:'FLUTICASONA/SALMETEROL',forma:'Capsulas',comercial:'SALFLUTOP 250/50 UF CAP.X60',um:'250',uc:'MG',cantidad:'60'},
{principio:'FORMULA HIDRATANTE Y EMOLIENTE',forma:'Crema',comercial:'LUBRIDERM CR.LIQ.400ml',um:'1',uc:'ML',cantidad:'400'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'NAN 1 BIFIDUS 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'NAN 1 OPTIPRO 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'NAN 2 BIFIDUS 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'NAN 2 OPTIPRO 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'NIDO INST BOLSA 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'PROMIL GOLD 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'S 26 HA GOLD LATA 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FORMULA PARA LACTANTES',forma:'Granulados/Polvos',comercial:'SIMILAC 1 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'FUROSEMIDA',forma:'Comprimidos',comercial:'FUROSEMIDA (B) 40mg X12COM.',um:'40',uc:'MG',cantidad:'12'},
{principio:'GABAPENTINA',forma:'Capsulas',comercial:'(B) RITMENAL 300mg CAPS.X30',um:'300',uc:'MG',cantidad:'30'},
{principio:'GABAPENTINA',forma:'Capsulas',comercial:'(B) RITMENAL 400mg CAPS.X30',um:'400',uc:'MG',cantidad:'30'},
{principio:'GENTAMICINA DERMICA',forma:'Crema',comercial:'GENTAMICINA 0.1% CR.X10g',um:'1',uc:'MG',cantidad:'10'},
{principio:'GENTAMICINA OFTALMICA COLIRIO',forma:'Solucion Oftalmica',comercial:'GENTAMICINA 0.3% SOF.X5ml',um:'3',uc:'MG',cantidad:'5'},
{principio:'GLATIRAMER ACETATO',forma:'Jeringa Prellenada Monodosis',comercial:'COPAXONE 40mg/ml JP.X12',um:'40',uc:'MG',cantidad:'12'},
{principio:'GLIBENCLAMIDA',forma:'Comprimidos',comercial:'GLIBENCLAMIDA (B) 5mg X60COM.',um:'5',uc:'MG',cantidad:'60'},
{principio:'GLUCAGON',forma:'Jeringa Prellenada Monodosis',comercial:'GLUCAGEN HIPOKIT 1mg X1',um:'1',uc:'MG',cantidad:'1'},
{principio:'GOSERELINA',forma:'Dispositivo Entrega Programada',comercial:'ZOLADEX 10.8mg IMPLANTE',um:'10.8',uc:'MG',cantidad:'1'},
{principio:'GRANISETRON',forma:'Comprimidos',comercial:'KYTRIL 1mg COMP.X2',um:'1',uc:'MG',cantidad:'2'},
{principio:'GRANISETRON',forma:'Ampollas',comercial:'KYTRIL 3mg AMP.X1',um:'3',uc:'MG',cantidad:'1'},
{principio:'HALOPERIDOL',forma:'Comprimidos',comercial:'(B) HALOPERIDOL 1mg X30COM.',um:'1',uc:'MG',cantidad:'30'},
{principio:'HALOPERIDOL',forma:'Comprimidos',comercial:'(B) HALOPERIDOL 5mg X20COM.',um:'5',uc:'MG',cantidad:'20'},
{principio:'HALOPERIDOL',forma:'Solución Oral',comercial:'HALDOL 2mg/ml GTAS.30ml',um:'2',uc:'MG',cantidad:'30'},
{principio:'HALOPERIDOL DECANOATO',forma:'Ampollas',comercial:'HALDOL 5mg/ml AMP.X1',um:'5',uc:'MG',cantidad:'1'},
{principio:'HIDROCLOROTIAZIDA',forma:'Comprimidos',comercial:'HIDROCLOROTIAZIDA (B) 50mg X20',um:'50',uc:'MG',cantidad:'20'},
{principio:'HIDROCLOROTIAZIDA',forma:'Comprimidos',comercial:'HIDROCLOROTIAZIDA (B) 50mg X20COM.',um:'50',uc:'MG',cantidad:'20'},
{principio:'HIDROCLOROTIAZIDA',forma:'Comprimidos',comercial:'HIDROCLOROTIAZIDA 50mg X20COM.',um:'50',uc:'MG',cantidad:'20'},
{principio:'HIDROCORTISONA',forma:'Comprimidos',comercial:'HIDROCORTISONA 20mg X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'HIDROCORTISONA',forma:'Comprimidos',comercial:'HIDROCORTISONA 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'HIDROCORTISONA DERMICA',forma:'Ungüento',comercial:'HIPOGE 1% UNGTO.10g',um:'10',uc:'MG',cantidad:'10'},
{principio:'HIDROXICARBAMIDA',forma:'Comprimidos',comercial:'HIDROXICARBAMIDA 500mg X100COM.',um:'500',uc:'MG',cantidad:'100'},
{principio:'HIDROXICLOROQUINA',forma:'Comprimidos',comercial:'(B) REUMAZINE 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'HIDROXICLOROQUINA',forma:'Tableta',comercial:'(R) PLAQUINOL 200mg TABS.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'HIDROXICLOROQUINA',forma:'Comprimidos',comercial:'ILINOL 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'HIDROXICLOROQUINA',forma:'Comprimidos',comercial:'ILINOL 200mg X30COM.REC.',um:'200',uc:'MG',cantidad:'30'},
{principio:'HIDROXIZINA',forma:'Comprimidos',comercial:'FASARAX 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'IBANDRONATO SODICO MONOH',forma:'Comprimidos',comercial:'(B) IDENA 150mg COMP.X1',um:'150',uc:'MG',cantidad:'1'},
{principio:'IBANDRONATO SODICO MONOH',forma:'Comprimidos',comercial:'RECAXIN (B) 150 mg x 1 COM.Rec',um:'150',uc:'MG',cantidad:'1'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'(B) IBUPROFENO 400mg COMP.X20',um:'400',uc:'MG',cantidad:'20'},
{principio:'IBUPROFENO',forma:'Capsulas',comercial:'(R) ACTRON 400mg CAPS.X10',um:'400',uc:'MG',cantidad:'10'},
{principio:'IBUPROFENO',forma:'Jarabe',comercial:'DEUCODOL FTE.200mg SUSP.120ml',um:'200',uc:'MG',cantidad:'120'},
{principio:'IBUPROFENO',forma:'Solución Oral',comercial:'IBUPIRAC FTE.200mg SUSP.100ml',um:'200',uc:'MG',cantidad:'100'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'IBUPROFENO (B) 200mg 20COM.REC.',um:'200',uc:'MG',cantidad:'20'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'IBUPROFENO (B) 200mg X20COM.REC.',um:'200',uc:'MG',cantidad:'20'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'IBUPROFENO (B) 400mg X20COM.',um:'400',uc:'MG',cantidad:'20'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'IBUPROFENO (B) 600mg 20COM.REC.',um:'600',uc:'MG',cantidad:'10'},
{principio:'IBUPROFENO',forma:'Comprimidos',comercial:'IBUPROFENO (B) 600mg X20COM.',um:'600',uc:'MG',cantidad:'10'},
{principio:'IBUPROFENO',forma:'Jarabe',comercial:'IBUPROFENO 100mg JBE.100ml',um:'100',uc:'MG',cantidad:'100'},
{principio:'IBUPROFENO',forma:'Jarabe',comercial:'IBUPROFENO 100mg/5ml JBE.X100ml',um:'100',uc:'MG',cantidad:'100'},
{principio:'IBUPROFENO',forma:'Solución Oral',comercial:'IPSON 100mg SUSP.120ml',um:'100',uc:'MG',cantidad:'120'},
{principio:'IBUPROFENO TOPICO',forma:'Crema',comercial:'DOLORUB 5% CR.60g',um:'50',uc:'MG',cantidad:'60'},
{principio:'IFOSFAMIDA',forma:'Ampolla Multidosis',comercial:'HOLOXAN 1g POL. X1F-A',um:'1',uc:'G',cantidad:'1'},
{principio:'IMATINIB MESILATO',forma:'Comprimidos',comercial:'(B) KADIR 100mg COM.REC.X180',um:'100',uc:'MG',cantidad:'180'},
{principio:'IMATINIB MESILATO',forma:'Comprimidos',comercial:'(B) KADIR 400mg COM.REC.X30',um:'400',uc:'MG',cantidad:'30'},
{principio:'IMATINIB MESILATO',forma:'Comprimidos',comercial:'(R) GLIVEC 100mg COM.X60',um:'100',uc:'MG',cantidad:'60'},
{principio:'IMATINIB MESILATO',forma:'Comprimidos',comercial:'(R) GLIVEC 400mg COMP.X30',um:'400',uc:'MG',cantidad:'30'},
{principio:'IMATINIB MESILATO',forma:'Cápsulas',comercial:'REDISTRA (B) 100mg X60CAP',um:'100',uc:'MG',cantidad:'60'},
{principio:'IMATINIB MESILATO',forma:'Capsulas',comercial:'REDISTRA (B) 400mg X30CAP',um:'400',uc:'MG',cantidad:'30'},
{principio:'INFLIXIMAB',forma:'Ampolla Multidosis',comercial:'REMICADE 100mg VIAL X1',um:'100',uc:'MG',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'FLEBOGAMMA 5g VIAL',um:'5',uc:'G',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'FLEBOGAMMA IV 5% 10g/200ml',um:'10',uc:'G',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'FLEBOGAMMA IV 5% 2.5g/50ml',um:'2.5',uc:'G',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'HIZENTRA 1g/5ml VIAL X1',um:'1',uc:'G',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'HIZENTRA 2g/10ml VIAL X1',um:'2',uc:'G',cantidad:'1'},
{principio:'INMUNOGLOBULINA HUMANA 10%',forma:'Ampollas',comercial:'HIZENTRA 4g/20ml VIAL X1',um:'200',uc:'MG',cantidad:'1'},
{principio:'INSULINA ASPARTATO',forma:'Jeringa Prellenada Multidosis',comercial:'FIASP 100UI/ml FLEX TOUCH X1DISP.PRE',um:'100',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA ASPARTATO',forma:'Ampolla Multidosis',comercial:'FIASP 100UI/ml SOL.INY 10ml X1',um:'100',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA ASPARTATO',forma:'Jeringa Prellenada Monodosis',comercial:'FIASP 100UI/ml. 3ml PENFIL X5CARTUCHO',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA ASPARTO BIFASICA',forma:'Jeringa Prellenada Multidosis',comercial:'NOVOMIX 30 100UI/ml FLEXPEN X5',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA ASPARTO RECOMBINANTE',forma:'ampolla',comercial:'NOVORAPID 100UI/ml 10ml',um:'100',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA ASPARTO RECOMBINANTE',forma:'Ampolla Multidosis',comercial:'NOVORAPID 100UI/ml 3ml X5',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA ASPARTO RECOMBINANTE',forma:'Jeringa Prellenada Multidosis',comercial:'NOVORAPID FLEX PENF 3ml X5',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA DEGLUDEC',forma:'cartucho',comercial:'HUMULIN N 100UI KWIK PEN.X5',um:'300',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA DEGLUDEC',forma:'Ampolla Multidosis',comercial:'TRESIBA 100UI/ml 3ml X1F-A',um:'100',uc:'U.I.',cantidad:'3'},
{principio:'INSULINA DETEMIR',forma:'Ampolla Multidosis',comercial:'LEVEMIR 100UI FLEXPEN X5',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA GLARGINA',forma:'Jeringa Prellenada Multidosis',comercial:'BASAGLAR 100UI/ml KWIKPEN X5',um:'300',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA GLARGINA',forma:'Jeringa Prellenada Multidosis',comercial:'LANTUS 100UI CARTR.X1',um:'100',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA GLARGINA',forma:'Ampolla Multidosis',comercial:'LANTUS SOLOSTAR 100UI CARTR.X1',um:'300',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA GLARGINA',forma:'Jeringa Prellenada Multidosis',comercial:'TOUJEO 300UI/ml LAP.PX1.5ml',um:'300',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA GLULISINA HUMANA RECOMBINANTE',forma:'Ampolla Multidosis',comercial:'APIDRA 100UI CARTR.X1',um:'100',uc:'U.I.',cantidad:'300'},
{principio:'INSULINA GLULISINA HUMANA RECOMBINANTE',forma:'Ampolla Multidosis',comercial:'APIDRA SOLOSTAR 100 UI CARTR.X1',um:'100',uc:'U.I.',cantidad:'1'},
{principio:'INSULINA ISOFANA (NPH, ZINC Y PROTAMINA)',forma:'Ampollas',comercial:'HUMULIN N 100UI 3ml CARTRI.X5',um:'300',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA ISOFANA (NPH, ZINC Y PROTAMINA)',forma:'Ampolla Multidosis',comercial:'HUMULIN N 100UI/ml 10ml F-A X1',um:'100',uc:'U.I.',cantidad:'1000'},
{principio:'INSULINA ISOFANA (NPH, ZINC Y PROTAMINA)',forma:'Ampolla Multidosis',comercial:'INSULATARD HM 100UI/ml 10ml',um:'100',uc:'U.I.',cantidad:'1000'},
{principio:'INSULINA ISOFANA (NPH, ZINC Y PROTAMINA)',forma:'Ampollas',comercial:'INSULATARD HM 100UI/ml 3ml X5',um:'300',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA LISPRO',forma:'Ampolla Multidosis',comercial:'HUMALOG 100UI 3ml CARTRI.X5',um:'100',uc:'U.I.',cantidad:'15'},
{principio:'INSULINA LISPRO',forma:'Ampolla Multidosis',comercial:'HUMALOG 100UI KWIK 3ml PEN X5',um:'100',uc:'U.I.',cantidad:'15'},
{principio:'INSULINA LISPRO',forma:'Ampolla Multidosis',comercial:'HUMALOG 100UI/ml 10ml F-A X1',um:'100',uc:'U.I.',cantidad:'10'},
{principio:'INSULINA LISPRO 75/25 (PROTAMINA/LISPRO)',forma:'Jeringa Prellenada Multidosis',comercial:'HUMALOG MIX 25 KWIK 3ml PEN X5',um:'100',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA REGULAR (20,30%); INSULINA ISOFANA (80,70%)',forma:'Ampolla Multidosis',comercial:'HUMULIN 70/30 10ml F-A X1',um:'100',uc:'U.I.',cantidad:'1000'},
{principio:'INSULINA REGULAR (ZINC CRISTALIZADA)',forma:'Ampolla Multidosis',comercial:'ACTRAPID HM 100UI/ml 10ml',um:'1',uc:'U.I.',cantidad:'1000'},
{principio:'INSULINA REGULAR (ZINC CRISTALIZADA)',forma:'Ampollas',comercial:'ACTRAPID HM 100UI/ml 3ml X5',um:'300',uc:'U.I.',cantidad:'5'},
{principio:'INSULINA REGULAR (ZINC CRISTALIZADA)',forma:'Ampolla Multidosis',comercial:'HUMULIN R 100UI/ml 10ml F-A X1',um:'1',uc:'U.I.',cantidad:'1000'},
{principio:'INTERFERON BETA 1 A',forma:'Jeringa Prellenada Multidosis',comercial:'AVONEX PLUMA 30ug INY.KIT X4',um:'30',uc:'MCG',cantidad:'4'},
{principio:'INTERFERON BETA 1 A',forma:'Ampolla Multidosis',comercial:'REBIF NF 44mcg 4CARTUCHOS',um:'44',uc:'MCG',cantidad:'4'},
{principio:'INTERFERON BETA 1 B',forma:'Ampollas',comercial:'BETAFERON KIT 15VIALES',um:'8000000',uc:'U.I.',cantidad:'15'},
{principio:'IPRATROPIO BROMURO',forma:'Aerosol',comercial:'AEROTROP HFA 20mcg INH.250DS.',um:'20',uc:'MCG',cantidad:'250'},
{principio:'IPRATROPIO BROMURO',forma:'Solución Oral',comercial:'ATROVENT 0.025% SOL.20ml',um:'250',uc:'MCG',cantidad:'20'},
{principio:'IPRATROPIO BROMURO',forma:'Aerosol',comercial:'ATROVENT HFA 20mcg INH.200DS',um:'20',uc:'MCG',cantidad:'200'},
{principio:'IPRATROPIO BROMURO',forma:'Solución Oral',comercial:'BROMURO IPRATROPIO 0.25mg/ml SOL.X20ml',um:'250',uc:'MCG',cantidad:'20'},
{principio:'IPRATROPIO BROMURO',forma:'Aerosol',comercial:'INFLUMIX 20mcg INH.X200DS',um:'20',uc:'MCG',cantidad:'200'},
{principio:'IPRATROPIO BROMURO DE USO NASAL',forma:'Aerosol',comercial:'ATROVENT 0.03% SP.NAS.15ml',um:'21',uc:'MCG',cantidad:'15'},
{principio:'ISOCAPROATO DE TESTOSTERONA,CAPROATO DE',forma:'Ampollas',comercial:'SUSTENAN 250 AMP.X1',um:'250',uc:'MG',cantidad:'1'},
{principio:'ISONIAZIDA',forma:'Comprimidos',comercial:'ISONIAZIDA 100mg X100COM.',um:'100',uc:'MG',cantidad:'100'},
{principio:'ISOTRETINOINA SISTEMICA',forma:'Capsulas',comercial:'ACNOTIN 10mg CAPS.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'ITRACONAZOL',forma:'Capsulas',comercial:'ITRAC 100 100mg CAPS.X15',um:'100',uc:'MG',cantidad:'15'},
{principio:'ITRACONAZOL',forma:'Capsulas',comercial:'SPORANOX 100mg CAPS.X15',um:'100',uc:'MG',cantidad:'15'},
{principio:'JERINGAS',forma:'jeringa insulina',comercial:'BD JER.INS.100UI 31G X6mm BOL.10',um:'1',uc:'UN',cantidad:'1'},
{principio:'JERINGAS',forma:'Jeringas Insulina',comercial:'BD JER.INS.30UI 31G X6mm BOL.X10',um:'1',uc:'UN',cantidad:'1'},
{principio:'JERINGAS',forma:'jeringa insulina',comercial:'BD JER.INS.50UI 31G X6mm BOL.X10',um:'1',uc:'UN',cantidad:'1'},
{principio:'JERINGAS',forma:'jeringa insulina',comercial:'BD JERINGA INSUL.100UI BOL.X10',um:'1',uc:'UN',cantidad:'1'},
{principio:'JERINGAS',forma:'Jeringas Insulina',comercial:'BD JERINGA INSUL.30UI BOL.X10',um:'1',uc:'UN',cantidad:'1'},
{principio:'JERINGAS',forma:'Jeringas Insulina',comercial:'BD JERINGA INSUL.50UI BOL.X10',um:'1',uc:'UN',cantidad:'1'},
{principio:'KETOCONAZOL',forma:'Comprimidos',comercial:'KETOCONAZOL 200mg X10COM.',um:'200',uc:'MG',cantidad:'30'},
{principio:'KETOPROFENO',forma:'Capsulas',comercial:'KETOPROFENO (B) 50mg X20CAP.',um:'50',uc:'MG',cantidad:'20'},
{principio:'KETOPROFENO',forma:'Comprimidos',comercial:'KETOPROFENO 200mg X10COM.',um:'200',uc:'MG',cantidad:'10'},
{principio:'KETOROLACO',forma:'Comprimidos',comercial:'KETOROLACO 10mg COMP.X10',um:'10',uc:'MG',cantidad:'10'},
{principio:'KETOROLACO',forma:'Comprimidos',comercial:'KETOROLACO 10mg X10COM.',um:'10',uc:'MG',cantidad:'10'},
{principio:'LACTULOSA',forma:'Solución Oral',comercial:'LACTULOSA 65% SOL.ORAL 200ml',um:'650',uc:'MG',cantidad:'200'},
{principio:'LAMIVUDINA (3TC)',forma:'Comprimidos',comercial:'(R) EPIVIR 3TC 150mg COMP.X60',um:'150',uc:'MG',cantidad:'60'},
{principio:'LAMIVUDINA (3TC)',forma:'Solución Oral',comercial:'EPIVIR 3 TC 10mg/ml 240ml',um:'10',uc:'MG',cantidad:'240'},
{principio:'LAMIVUDINA + ZIDOVUDINA + ABACAVIR SULFA',forma:'Comprimidos',comercial:'TRICIVIR X60COM.',um:'150',uc:'MG',cantidad:'60'},
{principio:'LAMIVUDINA + ZIDOVUDINA + ABACAVIR SULFA',forma:'Comprimidos',comercial:'TRICIVIR X60COM.',um:'150',uc:'MG',cantidad:'30'},
{principio:'LAMIVUDINA+ZIDOVUDINA',forma:'Comprimidos',comercial:'(R) COMBIVIR COMP.X60',um:'150',uc:'MG',cantidad:'60'},
{principio:'LAMIVUDINA+ZIDOVUDINA',forma:'Comprimidos',comercial:'BIVIR (B) X60COM.REC.',um:'150',uc:'MG',cantidad:'60'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'(B) LAFIGIN 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'(B) LAFIGIN 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'(B) LETICO 50mg COM.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'(B) TRADOX 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'(B) TRADOX 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'LAFIGIN DT 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'MEGANOX 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'MEGANOX 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'MEGANOX 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'MEGANOX 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'LAMOTRIGINA',forma:'Comprimidos',comercial:'TRADOX FT 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'LANCETAS',forma:'Lancetas medicion Glicemia',comercial:'ACCU CHEK FASTCLIX X102 LANCETAS',um:'1',uc:'UN',cantidad:'102'},
{principio:'LANCETAS',forma:'Lancetas medicion Glicemia',comercial:'ACCU CHEK FASTCLIX X24 LANCETAS',um:'1',uc:'UN',cantidad:'24'},
{principio:'LANCETAS',forma:'Lancetas medicion Glicemia',comercial:'ACCU CHEK SOFTCLIX X200',um:'1',uc:'UN',cantidad:'200'},
{principio:'LANCETAS',forma:'Lancetas medicion Glicemia',comercial:'FREESTYLE LANCETS X100',um:'1',uc:'UN',cantidad:'100'},
{principio:'LANCETAS',forma:'Lancetas medicion Glicemia',comercial:'ONE TOUCH ULT.LANC X25',um:'1',uc:'UN',cantidad:'25'},
{principio:'LANREOATIDA',forma:'Jeringa Prellenada Monodosis',comercial:'SOMATULINE 120mg JP.X1',um:'120',uc:'MG',cantidad:'1'},
{principio:'LANREOATIDA',forma:'Jeringa Prellenada Monodosis',comercial:'SOMATULINE 90mg JP.X1',um:'90',uc:'MG',cantidad:'1'},
{principio:'LATANOPROST',forma:'Solucion Oftalmica',comercial:'LATOF 0.005% SOL.OFT.2.5ml',um:'50',uc:'MCG',cantidad:'2.5'},
{principio:'LEFLUNOMIDA',forma:'Comprimidos',comercial:'(B) ARTROTIN 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'LEFLUNOMIDA',forma:'Comprimidos Recubiertos',comercial:'ARTRILAB 20mg X30COM.REC.',um:'20',uc:'MG',cantidad:'30'},
{principio:'LENALIDOMIDA',forma:'Capsulas',comercial:'ADELINE (B) 10mg 21CAP.',um:'10',uc:'MG',cantidad:'21'},
{principio:'LENALIDOMIDA',forma:'Capsulas',comercial:'ADELINE (B) 25mg 21CAP.',um:'25',uc:'MG',cantidad:'21'},
{principio:'LENALIDOMIDA',forma:'Capsulas',comercial:'LENALIDOMIDA 10mg X21CAP.',um:'10',uc:'MG',cantidad:'21'},
{principio:'LETROZOL',forma:'Comprimidos',comercial:'(B) LETROVITAE 2.5mg COMP. X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'LETROZOL',forma:'Comprimidos',comercial:'(B) TIADIS 2.5mg COMP.X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'LEUPROLIDE',forma:'Dispositivo Entrega Programada',comercial:'LUPRON DEPOT 11.25mg X1F-A',um:'11.75',uc:'MG',cantidad:'1'},
{principio:'LEUPROLIDE',forma:'Jeringa Prellenada Monodosis',comercial:'LUPRON DEPOT 30mg X1F-A',um:'30',uc:'MG',cantidad:'1'},
{principio:'LEUPROLIDE',forma:'Ampolla Multidosis',comercial:'LUTRATE DEPOT POLV.SUSP 22.5mg X1F-A',um:'22.5',uc:'MG',cantidad:'1'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B) KOPODEX 1000mg COMP.X30',um:'1000',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B) KREDIT 1000mg COMP.X30',um:'1000',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B) KREDIT 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B)KOPODEX 500 mg COMP x 30',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B)KOPODEX 500 mg COMP x 31',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B)KOPODEX 500 mg COMP x 32',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(B)KOPODEX 500 mg COMP x 33',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(R) KEPPRA 1000mg COMP.X30',um:'1000',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(R) KEPPRA 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'LEVETIRACETAM',forma:'Comprimidos',comercial:'(R) KEPPRA 500mg COMP.X60',um:'500',uc:'MG',cantidad:'60'},
{principio:'LEVETIRACETAM',forma:'Solución Oral',comercial:'KEPPRA 100mg/ml 300ml',um:'100',uc:'MG',cantidad:'300'},
{principio:'LEVOCETIRIZINA',forma:'Comprimidos',comercial:'(B) LEVORIGOTAX 5mg COM.X30',um:'1',uc:'UN',cantidad:'30'},
{principio:'LEVOCETIRIZINA',forma:'Comprimidos',comercial:'NEO ALERTOP (B) 5mg COMP.X30',um:'1',uc:'UN',cantidad:'30'},
{principio:'LEVOCETIRIZINA',forma:'Gotas',comercial:'XUZAL (B) 5mg GTAS.20ml',um:'5',uc:'MG/ML',cantidad:'20'},
{principio:'LEVOCETIRIZINA',forma:'jarabe',comercial:'XUZAL 2.5mg JBE.150ml',um:'2.5',uc:'mg',cantidad:'150 ml'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Comprimidos',comercial:'LEVIBENSE 200/50 X30COM.',um:'250',uc:'MG',cantidad:'30'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Comprimidos',comercial:'MADOZIDE (B) 200/50 X30COM',um:'200',uc:'MG',cantidad:'30'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Comprimidos',comercial:'MELITASE 200/50 X30COM',um:'200',uc:'MG',cantidad:'30'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Comprimidos',comercial:'PROLOPA 100/25 COMP.X30',um:'125',uc:'MG/KG',cantidad:'30'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Comprimidos',comercial:'PROLOPA 200/50 COMP.X30',um:'250',uc:'MG',cantidad:'30'},
{principio:'LEVODOPA + BENZERAZIDA',forma:'Capsulas',comercial:'PROLOPA HBS 100/25 CAPS.X30',um:'125',uc:'MG',cantidad:'30'},
{principio:'LEVODOPA + CARBIDOPA',forma:'Comprimidos',comercial:'LEVOFAMIL 250/25 COMP.X30',um:'250',uc:'MG',cantidad:'30'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(B) T4-BAGO 100mcg COMP.X100',um:'100',uc:'MCG',cantidad:'100'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(B) T4-BAGO 100mcg COMP.X50',um:'100',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(B) T4-BAGO 150mcg COMP.X50',um:'150',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(B) T4-BAGO 50mcg COMP.X50',um:'50',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Tableta',comercial:'(R) EUTIROX 100mcg TABL.X50',um:'100',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 112mcg COMP.X50',um:'112',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Tableta',comercial:'(R) EUTIROX 125mcg TABL.X50',um:'125',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 137mcg COMP.X50',um:'137',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 150mcg COMP.X50',um:'150',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 175mcg COMP.X50',um:'175',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 200mcg COMP.X50',um:'200',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 25mcg COMP.X50',um:'25',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimido',comercial:'(R) EUTIROX 50mcg TABL.X50',um:'50',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 75mcg COMP.X50',um:'75',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'(R) EUTIROX 88mcg COMP.X50',um:'88',uc:'MCG',cantidad:'50'},
{principio:'LEVOTIROXINA',forma:'Comprimidos',comercial:'T4-BAGO (B) 75mcg X50COM.FLEXIDOSIS',um:'75',uc:'MCG',cantidad:'50'},
{principio:'LIDOCAINA DE USO DERMICO',forma:'parches',comercial:'VERSATIS 5% PCHE.X5',um:'50',uc:'MG',cantidad:'5'},
{principio:'LINEZOLID',forma:'Comprimidos',comercial:'(R) ZYVOX 600mg COMP.X10',um:'600',uc:'MG',cantidad:'10'},
{principio:'LINEZOLIDA',forma:'Comprimidos',comercial:'(R) ZYVOX 600mg COMP.X10',um:'600',uc:'MG',cantidad:'10'},
{principio:'LINEZOLIDA',forma:'Comprimidos',comercial:'LINEZOLIDA (B) 600mg COM.X10',um:'600',uc:'MG',cantidad:'10'},
{principio:'LITIO CARBONATO',forma:'Comprimidos',comercial:'CARBORON 300mg COMP.X50',um:'300',uc:'MG',cantidad:'50'},
{principio:'LITIO CARBONATO',forma:'Comprimidos',comercial:'CARBORON RET 400mg COMP.X50',um:'400',uc:'MG',cantidad:'50'},
{principio:'LITIO CARBONATO',forma:'Comprimidos',comercial:'CARBORON RET.400mg COMP.X30',um:'400',uc:'MG',cantidad:'30'},
{principio:'LOPERAMIDA',forma:'Comprimidos',comercial:'LOPERAMIDA 2mg COMP.X6',um:'2',uc:'MG',cantidad:'6'},
{principio:'LOPERAMIDA',forma:'Comprimidos',comercial:'LOPERAMIDA 2mg X6COM.',um:'2',uc:'MG',cantidad:'6'},
{principio:'LORATADINA',forma:'Comprimidos',comercial:'LORATADINA (B)1 0mg X30COM.',um:'10',uc:'MG',cantidad:'30'},
{principio:'LORATADINA',forma:'Jarabe',comercial:'LORATADINA 5mg/5ml JBE. X90ml',um:'5',uc:'MG',cantidad:'90'},
{principio:'LORATADINA',forma:'Jarabe',comercial:'LORATADINA 5mg/5ml JBE.X60ml',um:'5',uc:'MG',cantidad:'60'},
{principio:'LORAZEPAM',forma:'Comprimidos',comercial:'(B) LORAZEPAM 2mg COMP.X30',um:'2',uc:'MG',cantidad:'30'},
{principio:'LOSARTAN',forma:'Comprimidos',comercial:'LOSAPRES (B) 100mg X30COM.REC.',um:'100',uc:'MG',cantidad:'30'},
{principio:'LOSARTAN',forma:'Comprimidos',comercial:'LOSARTAN (B) 50mg X30COM.',um:'50',uc:'MG',cantidad:'30'},
{principio:'LOSARTAN',forma:'Comprimidos',comercial:'LOSARTAN 50mg X30COM.',um:'50',uc:'MG',cantidad:'30'},
{principio:'LOVASTATINA',forma:'Comprimidos',comercial:'LOVASTATINA 20mg COMP.X28',um:'20',uc:'MG',cantidad:'28'},
{principio:'LOVASTATINA',forma:'Comprimidos',comercial:'LOVASTATINA 20mg X28COM.',um:'20',uc:'MG',cantidad:'28'},
{principio:'MALTODEXTRINA',forma:'Polvo',comercial:'NESSUCAR 550g',um:'1',uc:'G',cantidad:'550'},
{principio:'MALTOSA DEXTRINA ALIMENTO ENTERAL',forma:'Polvo',comercial:'NESSUCAR 550g',um:'1',uc:'G',cantidad:'550'},
{principio:'MARAVIROC',forma:'Comprimidos',comercial:'CELSENTRI 150mg COMP.X60',um:'150',uc:'MG',cantidad:'60'},
{principio:'MARAVIROC',forma:'Comprimidos',comercial:'CELSENTRI 300mg COMP.X60',um:'300',uc:'MG',cantidad:'60'},
{principio:'MASCARILLA',forma:'Accesorio medico',comercial:'MASCARILLA COMPRESOR PARI PED. BLUBBLES',um:'1',uc:'UNIDAD',cantidad:'1'},
{principio:'MELFALAN',forma:'Comprimidos Recubiertos',comercial:'ALKERAN 2mg COMP.X25',um:'2',uc:'MG',cantidad:'25'},
{principio:'MELOXICAM',forma:'Comprimidos',comercial:'MELOXICAM 7.5mg X15COM.',um:'7.5',uc:'MG',cantidad:'10'},
{principio:'MELOXICAM',forma:'Comprimidos',comercial:'MELOXICAM 7.5mg X15COM.',um:'7.5',uc:'MG',cantidad:'3'},
{principio:'MELOXICAM',forma:'Comprimidos',comercial:'MELOXICAM 7.5mg X15COM.',um:'7.5',uc:'MG',cantidad:'30'},
{principio:'MELOXICAM',forma:'Ampollas',comercial:'MELOXICAM 7.5mg X15COM.',um:'7.5',uc:'MG',cantidad:'3'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'(B) MELIC 15mg COMP.X10',um:'15',uc:'MG',cantidad:'10'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'(B) MELIC 15mg COMP.X30',um:'15',uc:'MG',cantidad:'30'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'(B) TENARON 15mg COMP.X10',um:'15',uc:'MG',cantidad:'10'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'(R) MOBEX 15mg COMP.X30',um:'15',uc:'MG',cantidad:'30'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'MELOXICAM 15mg COMP.X10',um:'15',uc:'MG',cantidad:'10'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'MELOXICAM 15mg X10COM.',um:'15',uc:'MG',cantidad:'10'},
{principio:'MELOXICAN',forma:'Ampollas',comercial:'MOBEX 15mg AMP.X3',um:'15',uc:'MG',cantidad:'3'},
{principio:'MELOXICAN',forma:'Comprimidos',comercial:'MOBEX 15mg COMP.X10',um:'15',uc:'MG',cantidad:'10'},
{principio:'MEMANTINA',forma:'Comprimidos',comercial:'EUTEBROL 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'MEMANTINA',forma:'Comprimidos',comercial:'MEMANVITAE (B) 10mg X56COM.',um:'10',uc:'MG',cantidad:'56'},
{principio:'MERCAPTOPURINA',forma:'Comprimidos',comercial:'PURINETHOL 50mg COMP.X25',um:'50',uc:'MG',cantidad:'25'},
{principio:'MESNA',forma:'Ampollas',comercial:'UROMITEXAN 400mg/4ml SOL. 4AMP.',um:'400',uc:'MG',cantidad:'15'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'(B) GLAUPAX 500mg COM.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'(B) HIPOGLUCIN LP 1000mg COMP.X30',um:'1000',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos Liberacion Prolongada',comercial:'(B) HIPOGLUCIN LP 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos Liberacion Prolongada',comercial:'(B) HIPOGLUCIN LP 500mg COMP.X33',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos Liberacion Prolongada',comercial:'(B) HIPOGLUCIN LP 500mg COMP.X35',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'(B) HIPOGLUCIN LP 750mg COMP.X30',um:'750',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'GLAFORNIL XR (R) 1000 COMP.X30',um:'1000',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'GLAUPAX (B) 850mg 30COM.REC.',um:'850',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'GLAUPAX XR (B) 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'GLICENEX (B) 500mg X30COM.REC.',um:'500',uc:'MG',cantidad:'30'},
{principio:'METFORMINA',forma:'Comprimidos',comercial:'HIPOGLUCIN 1g X30COM.REC.',um:'1000',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'GLICENEX 500/2.5 COMP.X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'GLICENEX 500/5 COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'GLUCOVANCE 500/2.5 COMP.X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'GLUCOVANCE 500/5 COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'HIPOGLUCIN DA 500/2.5 COMP.X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'METFORMINA + GLIBENCLAMIDA',forma:'Comprimidos',comercial:'HIPOGLUCIN DA 500/5 COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'METILFENIDATO',forma:'Comprimidos',comercial:'(B) ARADIX 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'METILFENIDATO',forma:'Comprimidos',comercial:'ARADIX RET.20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'METILPREDNISOLONA',forma:'Comprimidos',comercial:'MEDROL 16mg COMP.X14',um:'16',uc:'MG',cantidad:'14'},
{principio:'METILPREDNISOLONA',forma:'Comprimidos',comercial:'MEDROL 4mg COMP.X20',um:'4',uc:'MG',cantidad:'20'},
{principio:'METILPREDNISOLONA',forma:'Ampollas',comercial:'SOLU MEDROL 1000mg AMP.X1',um:'1',uc:'G',cantidad:'1'},
{principio:'METILPREDNISOLONA',forma:'Ampollas',comercial:'SOLU MEDROL 500mg AMP.X1',um:'500',uc:'MG',cantidad:'1'},
{principio:'METILPREDNISOLONA + LIDOCAINA',forma:'Ampollas',comercial:'DEPO-MEDROL/LIDOC AMP.X1',um:'40',uc:'MG',cantidad:'1'},
{principio:'METILPREDNISOLONA CM',forma:'Comprimidos',comercial:'MEDROL 4mg COMP.X20',um:'4',uc:'MG',cantidad:'20'},
{principio:'METILPREDNISOLONA FA',forma:'Ampollas',comercial:'SOLU MEDROL 500mg AMP.X1',um:'500',uc:'MG',cantidad:'1'},
{principio:'METILPREDNISOLONA FA AL 1%',forma:'Ampollas',comercial:'SOLU MEDROL 500mg AMP.X1',um:'500',uc:'MG',cantidad:'1'},
{principio:'METOCLOPRAMIDA',forma:'Comprimidos',comercial:'(B) ITAN 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'METOCLOPRAMIDA',forma:'Comprimidos',comercial:'METOCLOPRAMIDA (B) 10mg X24COM.',um:'10',uc:'MG',cantidad:'24'},
{principio:'METOTREXATO',forma:'Comprimidos',comercial:'(R) METOTREXATO 2.5mg COMP.X100',um:'2.5',uc:'MG',cantidad:'100'},
{principio:'METOTREXATO',forma:'Comprimidos',comercial:'METOTREXATO 2.5mg X100COM.',um:'2.5',uc:'MG',cantidad:'100'},
{principio:'METOTREXATO CM',forma:'Comprimidos',comercial:'(R) METOTREXATO 2.5mg COMP.X100',um:'2.5',uc:'MG',cantidad:'100'},
{principio:'METOTREXATO CM',forma:'Comprimidos',comercial:'METOTREXATO 2.5mg COMP.X100',um:'2.5',uc:'MG',cantidad:'100'},
{principio:'METOTREXATO CM',forma:'Comprimidos',comercial:'METOTREXATO 2.5mg X100COM.',um:'2.5',uc:'MG',cantidad:'100'},
{principio:'METRONIDAZOL',forma:'Comprimidos',comercial:'(B) METROPAST 500mg COMP.X10',um:'500',uc:'MG',cantidad:'10'},
{principio:'METRONIDAZOL',forma:'Comprimidos',comercial:'(B) METROPAST 500mg COMP.X20',um:'500',uc:'MG',cantidad:'20'},
{principio:'METRONIDAZOL OVULOS',forma:'Ovulos/Supos vaginales',comercial:'METRONIDAZOL 500mg OVUL.X10',um:'500',uc:'MG',cantidad:'10'},
{principio:'METRONIDAZOL OVULOS',forma:'Ovulos/Supos vaginales',comercial:'METRONIDAZOL 500mg X10OVULOS',um:'500',uc:'MG',cantidad:'10'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'(B) LAMUCON 250mg CAP.X100',um:'250',uc:'MG',cantidad:'100'},
{principio:'MICOFENOLATO MOFETIL',forma:'Comprimidos',comercial:'(B) LAMUCON 500mg COMP.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'MICOFENOLATO MOFETIL',forma:'Comprimidos',comercial:'(B) LINFONEX 250mg COMP.X60',um:'250',uc:'MG',cantidad:'60'},
{principio:'MICOFENOLATO MOFETIL',forma:'Comprimidos',comercial:'(B) LINFONEX 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'(R) CELLCEPT 250mg CAPS.X100',um:'250',uc:'MG',cantidad:'100'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'(R) CELLCEPT 500mg CAPS.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'MICOFENOLATO (B) 250mg X100CAP.',um:'250',uc:'MG',cantidad:'100'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'MICOFENOLATO (B) 500mg X50COM.',um:'500',uc:'MG',cantidad:'50'},
{principio:'MICOFENOLATO MOFETIL',forma:'Capsulas',comercial:'SUPRIMUN (B) 250mg X100CAP.',um:'250',uc:'MG',cantidad:'100'},
{principio:'MICOFENOLATO MOFETIL',forma:'Comprimidos',comercial:'SUPRIMUN (B) 500mg X50COM.REC.',um:'500',uc:'MG',cantidad:'50'},
{principio:'MICOFENOLATO SODICO',forma:'Capsulas',comercial:'MYFORTIC 180mg CAPS.120',um:'180',uc:'MG',cantidad:'120'},
{principio:'MICOFENOLATO SODICO',forma:'Capsulas',comercial:'MYFORTIC 360mg CAPS.120',um:'360',uc:'MG',cantidad:'120'},
{principio:'MIDAZOLAM',forma:'Comprimidos',comercial:'(R) DORMONID 7.5mg COMP.X30',um:'7.5',uc:'MG',cantidad:'30'},
{principio:'MIRTAZAPINA',forma:'Comprimidos',comercial:'(B) CIBLEX 15mg COMP.REC.X30',um:'15',uc:'MG',cantidad:'30'},
{principio:'MIRTAZAPINA',forma:'Comprimidos',comercial:'(B) CIBLEX 30mg COMP.REC.X30',um:'30',uc:'MG',cantidad:'30'},
{principio:'MIRTAZAPINA',forma:'Comprimidos',comercial:'(B) DIVARIL 30mg COMP.X30',um:'30',uc:'MG',cantidad:'30'},
{principio:'MODAFINIL',forma:'Comprimidos',comercial:'MOVIGIL 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'MODAFINIL',forma:'Comprimidos',comercial:'MOVIGIL 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'MOMETASONA FUROATO PARA INHALACION',forma:'Aerosol',comercial:'NASONEX 50mcg NAS.280DS.',um:'50',uc:'MCG',cantidad:'280'},
{principio:'MOMETASONA FUROATO PARA INHALACION',forma:'Aerosol',comercial:'PLUSTER 50mcg NEB.120DS.',um:'50',uc:'MCG',cantidad:'120'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'(B) BRONDILAT 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'(B) MONKAST 10mg COMP.X28',um:'10',uc:'MG',cantidad:'28'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'(R) SINGULAIR 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'(R) SINGULAIR 4mg COMP.X30',um:'4',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'(R) SINGULAIR 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'ASVENTOL 4mg COMP.X30',um:'4',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'ASVENTOL 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'BRONDILAT 4mg COMP.X30',um:'4',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Comprimidos',comercial:'BRONDILAT 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'MONTELUKAST',forma:'Sobres',comercial:'LUKANEX PED.4mg SOB.X40',um:'4',uc:'MG',cantidad:'40'},
{principio:'MONTELUKAST',forma:'Sobres',comercial:'SINGULAIR PED 4mg SOB.X30',um:'4',uc:'MG',cantidad:'30'},
{principio:'MOXIFLOXACINO OFTALMICO',forma:'Solucion Oftalmica',comercial:'MOXOF 0.5% SOL.OFT.5ml',um:'5',uc:'MG',cantidad:'5'},
{principio:'NAPROXENO',forma:'Comprimidos',comercial:'(B) EUROGESIC 275mg COMP.X10',um:'275',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO',forma:'Comprimidos',comercial:'(B) NAPROXENO 550mg COMP.X10',um:'550',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO',forma:'Solución Oral',comercial:'EUROGESIC 125mg SUSP.60ml',um:'125',uc:'MG',cantidad:'60'},
{principio:'NAPROXENO',forma:'Comprimidos',comercial:'NAPROXENO (B) 550mg COMP.X10',um:'550',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO',forma:'Comprimidos',comercial:'NAPROXENO (B) 550mg X10COM.',um:'550',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO',forma:'Comprimidos',comercial:'NAPROXENO 550mg X10COM.',um:'550',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO + ESOMEPRAZOL',forma:'Comprimidos',comercial:'FLECTANE 500/20 X10COM.REC.',um:'500 / 20',uc:'MG',cantidad:'10'},
{principio:'NAPROXENO + ESOMEPRAZOL',forma:'Comprimidos',comercial:'FLECTANE 500/20 X30COM.REC.',um:'500 / 20',uc:'MG',cantidad:'30'},
{principio:'NEBULIZACIÓN RH-DORNASA-ALFA',forma:'Comprimidos',comercial:'COMPRESOR PARI TREK S BATERIA',um:'1',uc:'UN',cantidad:'1'},
{principio:'NEBULIZACIÓN RH-DORNASA-ALFA',forma:'Ampollas',comercial:'VISCOZYME 2.5mg AMP.X30',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'NEBULIZADOR',forma:'Compresor',comercial:'COMPRESOR PARI TREK S',um:'1',uc:'UN',cantidad:'1'},
{principio:'NEBULIZADOR',forma:'Comprimidos',comercial:'COMPRESOR PARI TREK S BATERIA',um:'1',uc:'UN',cantidad:'1'},
{principio:'NEBULIZADOR',forma:'Aerocamara',comercial:'PARI O-PEP',um:'1',uc:'UN',cantidad:'1'},
{principio:'NETUPITANT/PALONOSETRÓN',forma:'CAPSULA',comercial:'AKYNZEO 300mg/0.5mg X1CAP.',um:'300/0.5',uc:'MG',cantidad:'1'},
{principio:'NEVIRAPINA (NVP)',forma:'Comprimidos',comercial:'(R) VIRAMUNE 200mg COMP.X60',um:'200',uc:'MG',cantidad:'60'},
{principio:'NEVIRAPINA (NVP)',forma:'Jarabe',comercial:'VIRAMUNE 50mg/5ml 240ml',um:'50',uc:'MG',cantidad:'240'},
{principio:'NIFEDIPINO',forma:'Comprimidos',comercial:'CARDICON RET.20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'NIFEDIPINO',forma:'Comprimidos',comercial:'NIFEDIPINO RET.20mg X30COM.REC.',um:'20',uc:'MG',cantidad:'30'},
{principio:'NILOTINIB',forma:'Capsulas',comercial:'TASIGNA 150mg CAP.X112',um:'150',uc:'MG',cantidad:'112'},
{principio:'NILOTINIB',forma:'Capsulas',comercial:'TASIGNA 200mg CAP.X112',um:'200',uc:'MG',cantidad:'112'},
{principio:'NISTATINA ORAL',forma:'Comprimidos',comercial:'NISTATINA 500000UI X12COM.',um:'500',uc:'U.I.',cantidad:'12'},
{principio:'NITROFURANTOINA',forma:'Capsulas',comercial:'(B) MACROSAN 50mg CAPS.X20',um:'50',uc:'MG',cantidad:'20'},
{principio:'OCTREOTIDA',forma:'Ampollas',comercial:'SANDOSTATIN LAR 20mg AMP.X1',um:'20',uc:'MG',cantidad:'1'},
{principio:'OCTREOTIDA',forma:'Ampollas',comercial:'SANDOSTATIN LAR 30mg AMP.X1',um:'30',uc:'MG',cantidad:'1'},
{principio:'OLANZAPINA',forma:'Comprimidos',comercial:'(B) SINCRIS 10mg COMP.X30',um:'10',uc:'MG',cantidad:'30'},
{principio:'OLANZAPINA',forma:'Comprimidos Recubiertos',comercial:'(B) SINCRIS 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'OLANZAPINA',forma:'Comprimidos',comercial:'OLANVITAE (B) 10mg 28COM.REC',um:'10',uc:'MG',cantidad:'28'},
{principio:'OLANZAPINA',forma:'Ampolla Multidosis',comercial:'ZYPREXA 10mg INY IM F-A X1',um:'10',uc:'MG',cantidad:'1'},
{principio:'OMEPRAZOL',forma:'Comprimidos',comercial:'(B) OMEPRAZOL 20mg COM.X60',um:'20',uc:'MG',cantidad:'60'},
{principio:'OMEPRAZOL',forma:'Capsulas',comercial:'LOMEX 20mg CAPS.X14',um:'20',uc:'MG',cantidad:'14'},
{principio:'OMEPRAZOL',forma:'Capsulas',comercial:'LOMEX 20mg CAPS.X35',um:'20',uc:'MG',cantidad:'35'},
{principio:'OMEPRAZOL',forma:'Tableta',comercial:'LOSEC MUPS (R) 20mg TAB.X28',um:'20',uc:'MG',cantidad:'28'},
{principio:'OMEPRAZOL',forma:'Comprimidos',comercial:'LOSEC MUPS 20mg COM.X14',um:'20',uc:'MG',cantidad:'14'},
{principio:'OMEPRAZOL',forma:'Capsulas',comercial:'OMEPRAZOL (B) 20mg CAPS.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'OMEPRAZOL',forma:'Capsulas',comercial:'OMEPRAZOL (B) 20mg X30CAP.',um:'20',uc:'MG',cantidad:'28'},
{principio:'OMEPRAZOL',forma:'Comprimidos',comercial:'OMEPRAZOL (B) 20mg X60COM.',um:'20',uc:'MG',cantidad:'60'},
{principio:'OMEPRAZOL',forma:'Capsulas',comercial:'OMEPRAZOL 20mg X30CAP.',um:'20',uc:'MG',cantidad:'28'},
{principio:'OMEPRAZOL',forma:'Comprimidos',comercial:'OMEPRAZOL 20mg X60COM.',um:'20',uc:'MG',cantidad:'60'},
{principio:'ONDANSETRON',forma:'Comprimidos',comercial:'(B) ODATRON 4mg COM.BUC.X8',um:'4',uc:'MG',cantidad:'8'},
{principio:'ONDANSETRON',forma:'Comprimidos',comercial:'(B) ODATRON 8mg COM.BUC.X8',um:'8',uc:'MG',cantidad:'8'},
{principio:'ONDANSETRON',forma:'Comprimidos',comercial:'(R) IZOFRAN ZYDIS 8mg COMP.X10',um:'8',uc:'MG',cantidad:'10'},
{principio:'ONDANSETRON',forma:'Comprimidos',comercial:'IZOFRAN ZYDIS 4mg COMP.X10',um:'4',uc:'MG',cantidad:'10'},
{principio:'ONDANSETRON',forma:'Comprimidos',comercial:'ONDANSETRON 8mg X10COM.BUC',um:'8',uc:'MG',cantidad:'10'},
{principio:'OXIBUTININA',forma:'Comprimidos',comercial:'URAZOL 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'OXIBUTININA',forma:'Comprimidos',comercial:'URAZOL CR 10mg COMP.X10',um:'10',uc:'MG',cantidad:'10'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'(B) KITADOL 500mg COMP.X24',um:'500',uc:'MG',cantidad:'24'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'(B) PARACETAMOL 500mg X16',um:'500',uc:'MG',cantidad:'16'},
{principio:'PARACETAMOL',forma:'Solución Oral',comercial:'KITADOL INF.120mg JBE.60ml',um:'120',uc:'MG',cantidad:'60'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'PANADOL ADVANCE COMP.X10',um:'500',uc:'MG',cantidad:'10'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'PANADOL ADVANCE COMP.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'PARACETAMOL (B) 500mg X16COM.',um:'500',uc:'MG',cantidad:'16'},
{principio:'PARACETAMOL',forma:'Gotario',comercial:'PARACETAMOL 100mg GTAS.15ml',um:'100',uc:'MG',cantidad:'10'},
{principio:'PARACETAMOL',forma:'Solución Oral',comercial:'PARACETAMOL 100mg/ml GTAS.X15ml',um:'100',uc:'MG',cantidad:'10'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'PARACETAMOL 80mg X16COM.MAST.',um:'80',uc:'MG',cantidad:'16'},
{principio:'PARACETAMOL',forma:'Supositorios',comercial:'PARACETAMOL PED.125mg SUP.X6',um:'125',uc:'MG',cantidad:'5'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'SUPRACALM (B) 1g x 20 COMP.',um:'1',uc:'G',cantidad:'20'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'TAPSIN INF.80mg COM.X16',um:'80',uc:'MG',cantidad:'16'},
{principio:'PARACETAMOL',forma:'Comprimidos',comercial:'XUMADOL (B) 1g X20COM.',um:'1',uc:'G',cantidad:'20'},
{principio:'PARACETAMOL',forma:'Sobres',comercial:'XUMADOL 1g SOB.X20',um:'1',uc:'G',cantidad:'20'},
{principio:'PARACETAMOL + TRAMADOL',forma:'Comprimidos',comercial:'DOLOTEN COMP.X30',um:'325',uc:'MG',cantidad:'30'},
{principio:'PARACETAMOL + TRAMADOL',forma:'Comprimidos',comercial:'DOLOTEN ODT COM.X20',um:'325',uc:'MG',cantidad:'20'},
{principio:'PARACETAMOL + TRAMADOL',forma:'Comprimidos',comercial:'PARACETAMOL/TRAMADOL (B) 325/37.5mg 30COM.REC.',um:'325',uc:'MG',cantidad:'30'},
{principio:'PARACETAMOL + TRAMADOL',forma:'Comprimidos',comercial:'ZALEDOR P COMP.X30',um:'325',uc:'MG',cantidad:'30'},
{principio:'PARACETAMOL JB',forma:'Jarabe',comercial:'GESIDOL INF.120mg/5ml JBE.100ml',um:'120',uc:'MG',cantidad:'100'},
{principio:'PARACETAMOL JB',forma:'Solución Oral',comercial:'KITADOL INF.120mg JBE.60ml',um:'120',uc:'MG',cantidad:'60'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'NEXCARE PCHE.OCULAR ADULTO REMOCION SUAV',um:'1',uc:'UN',cantidad:'14'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'NEXCARE PCHE.OCULAR NIÑOS REMOCÍON SUAVE X14',um:'1',uc:'UNIDAD',cantidad:'12'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'NEXCARE PCHE.OCULAR OPTICLUDE ADULTO X5',um:'1',uc:'UN',cantidad:'5'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'OPTICLUDE AD.PCHE.OCUL.P/S 12',um:'1',uc:'UN',cantidad:'12'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'OPTICLUDE AD.X5',um:'1',uc:'UN',cantidad:'5'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'OPTICLUDE PED.PCHE.OCULAR P/SENS.X12',um:'1',uc:'UNIDAD',cantidad:'12'},
{principio:'PARCHE OCULAR',forma:'Parche',comercial:'OPTICLUDE PED.PCHE.OCULAR P/SENS.X12',um:'1',uc:'UNIDAD',cantidad:'5'},
{principio:'PAROXETINA',forma:'Comprimidos',comercial:'DEROXAT CR 12.5mg COMP.X30',um:'12.5',uc:'MG',cantidad:'30'},
{principio:'PAROXETINA',forma:'Comprimidos',comercial:'DEROXAT CR 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'PAROXETINA',forma:'Comprimidos Recubiertos',comercial:'SERETRAN (B) 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'PASTA DENTAL',forma:'Pasta',comercial:'AQF BIG TEETH PAS.93,75g',um:'1',uc:'UN',cantidad:'100'},
{principio:'PASTA DENTAL',forma:'Pasta',comercial:'AQF INTENSE WHITE 121.9g',um:'1',uc:'UN',cantidad:'100'},
{principio:'PASTA DENTAL',forma:'Pasta',comercial:'COLGATE TOTAL 97.5g',um:'1',uc:'UN',cantidad:'100'},
{principio:'PASTA DENTAL',forma:'Pasta',comercial:'ORAL B 3D WHITE BRILLIANT PAS.X100g',um:'1',uc:'UN',cantidad:'100'},
{principio:'PAZOPANIB',forma:'Comprimidos',comercial:'VOTRIENT 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'PAZOPANIB',forma:'Comprimidos',comercial:'VOTRIENT 400mg COMP.X30',um:'400',uc:'MG',cantidad:'30'},
{principio:'PEDIASURE',forma:'Polvo',comercial:'PEDIASURE COMPL VAINILLA 900g',um:'1',uc:'G',cantidad:'900'},
{principio:'PEGINTERFERON ALFA 2 A',forma:'Jeringa Prellenada Monodosis',comercial:'PEGASYS 180mcg AMP.X1',um:'180',uc:'MCG',cantidad:'1'},
{principio:'PEGINTERFERON BETA 1 A',forma:'Jeringa Prellenada Monodosis',comercial:'PLEGRIDY 125mcg SOL.INY.X2',um:'125',uc:'MCG',cantidad:'2'},
{principio:'PEGINTERFERON BETA 1 A',forma:'Jeringa Prellenada Monodosis',comercial:'PLEGRIDY 63mcg/94mcg SOL.INY.X2',um:'78.5',uc:'MCG',cantidad:'2'},
{principio:'POLIMIXINA B + BACITRACINA',forma:'Ungüento',comercial:'DERMABIOTICO UNGTO.15g',um:'3000',uc:'U.I.',cantidad:'15'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'(R) SIFROL 0.25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'(R) SIFROL ER 0.375mg COMP.X30',um:'0.375',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'(R) SIFROL ER 1.5mg COMP.X30',um:'1.5',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'BIOPSOL (B) 0.25mg X30COM.',um:'0.25',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'BIOPSOL 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'PARMITAL 0.25mg X30COM',um:'0.25',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos Liberación Prolongada',comercial:'PRAXIS 0.75mg (B) 30COM.PROL.',um:'0.75',uc:'MG',cantidad:'30'},
{principio:'PRAMIPEXOLE',forma:'Comprimidos',comercial:'SIFROL ER 0.75mg COMP.X30',um:'0.75',uc:'MG',cantidad:'30'},
{principio:'PREDNISOLONA ACETATO COLIRIO',forma:'Solucion Oftalmica',comercial:'PRED FTE.SOL.OFT.5ml',um:'10',uc:'MG',cantidad:'5'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) BERSEN 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) BERSEN 5mg COMP.X20',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) CORTIPREX 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) PREDNISONA 20mg COM.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) PREDNISONA 5mg COMP.X20',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'(B) PROCION 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Jarabe',comercial:'BERSEN 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA',forma:'Solución Oral',comercial:'BERSEN MD 2mg/ml SUSP.60ml',um:'2',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA',forma:'Solución Oral',comercial:'CORTIPREX 1mg SUSP.100ml',um:'1',uc:'MG',cantidad:'100'},
{principio:'PREDNISONA',forma:'Solución Oral',comercial:'CORTIPREX 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'PREDNISONA (B) 20mg X20COM.',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'PREDNISONA (B) 5mg X20COM.',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA',forma:'Comprimidos',comercial:'PREDNISONA 5mg X20COM.',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA CM',forma:'Comprimidos',comercial:'(B) BERSEN 5mg COMP.X20',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA CM',forma:'Comprimidos',comercial:'(B) PREDNISONA 5mg COMP.X20',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA CM',forma:'Comprimidos',comercial:'PREDNISONA (B) 5mg X20COM.',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA CM',forma:'Comprimidos',comercial:'PREDNISONA 5mg X20COM.',um:'5',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA COMPRIMIDO',forma:'Comprimidos',comercial:'(B) BERSEN 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA COMPRIMIDO',forma:'Comprimidos',comercial:'(B) CORTIPREX 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA COMPRIMIDO',forma:'Comprimidos',comercial:'(B) PROCION 20mg COMP.X20',um:'20',uc:'MG',cantidad:'20'},
{principio:'PREDNISONA COMPRIMIDO',forma:'Jarabe',comercial:'BERSEN 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA EN JARABE',forma:'Jarabe',comercial:'BERSEN 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA EN JARABE',forma:'Solución Oral',comercial:'BERSEN MD 2mg/ml SUSP.60ml',um:'2',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA EN JARABE',forma:'Solución Oral',comercial:'CORTIPREX 1mg SUSP.100ml',um:'1',uc:'MG',cantidad:'100'},
{principio:'PREDNISONA EN JARABE',forma:'Solución Oral',comercial:'CORTIPREX 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA JB',forma:'Jarabe',comercial:'BERSEN 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA JB',forma:'Solución Oral',comercial:'BERSEN MD 2mg/ml SUSP.60ml',um:'2',uc:'MG',cantidad:'60'},
{principio:'PREDNISONA JB',forma:'Solución Oral',comercial:'CORTIPREX 1mg SUSP.100ml',um:'1',uc:'MG',cantidad:'100'},
{principio:'PREDNISONA JB',forma:'Solución Oral',comercial:'CORTIPREX 20mg SUSP.60ml',um:'20',uc:'MG',cantidad:'60'},
{principio:'PREGABALINA',forma:'Comprimidos',comercial:'(B) PREGALEX 75mg COMP.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'PREGABALINA',forma:'Capsulas',comercial:'(B) PREGASTAR 150mg CAPS.X28',um:'150',uc:'MG',cantidad:'28'},
{principio:'PROGESTERONA',forma:'Capsulas',comercial:'PROGENDO 100mg CAPS.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'PROPANOLOL',forma:'Comprimidos',comercial:'PROPRANOLOL 10mg X20COM.',um:'10',uc:'MG',cantidad:'20'},
{principio:'PROPANOLOL',forma:'Comprimidos',comercial:'PROPRANOLOL 40mg X20COM.',um:'40',uc:'MG',cantidad:'20'},
{principio:'PROTEINEX',forma:'Granulados/Polvos',comercial:'PROTEINEX PVO.275g',um:'1',uc:'UN',cantidad:'275'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(B) QUETIDIN 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(B) QUETIDIN 200mg COMP.X30',um:'200',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(B) QUETIDIN 25mg COMP.X30',um:'25',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(R) SEROQUEL 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(R) SEROQUEL XR 300mg COMP.X30',um:'300',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'(R) SEROQUEL XR 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'QUETIAPINA',forma:'Comprimidos',comercial:'QUETIDIN XR 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'RALTEGRAVIR',forma:'Comprimidos',comercial:'ISENTRESS 400mg COMP.X60',um:'400',uc:'MG',cantidad:'60'},
{principio:'RALTEGRAVIR',forma:'Comprimidos',comercial:'ISENTRESS HD 600mg COMP.X60',um:'600',uc:'MG',cantidad:'60'},
{principio:'RALTERNAVIR',forma:'Comprimidos',comercial:'ISENTRESS 400mg COMP.X60',um:'400',uc:'MG',cantidad:'60'},
{principio:'RANITIDINA',forma:'Comprimidos',comercial:'RANITIDINA (B) 300mg X10COM.',um:'300',uc:'MG',cantidad:'10'},
{principio:'RANITIDINA',forma:'Comprimidos',comercial:'RANITIDINA 300mg COMP.X10',um:'300',uc:'MG',cantidad:'10'},
{principio:'RANITIDINA',forma:'Comprimidos',comercial:'RANITIDINA 300mg X10COM.',um:'300',uc:'MG',cantidad:'10'},
{principio:'RIBAVIRINA',forma:'Comprimidos',comercial:'COPEGUS 200mg COMP.X168',um:'200',uc:'MG',cantidad:'168'},
{principio:'RIBAVIRINA',forma:'Comprimidos',comercial:'COPEGUS 200mg X168COM.',um:'200',uc:'MG',cantidad:'168'},
{principio:'RISPERIDONA',forma:'Comprimidos',comercial:'(B) RISPYL 2mg COMP.X20',um:'2',uc:'MG',cantidad:'20'},
{principio:'RISPERIDONA',forma:'Comprimidos Recubiertos',comercial:'(B) SPIRON 1mg COMP.X30',um:'1',uc:'MG',cantidad:'30'},
{principio:'RISPERIDONA',forma:'Comprimidos',comercial:'DAGOTIL 3mg COMP.X30',um:'3',uc:'MG',cantidad:'30'},
{principio:'RISPERIDONA',forma:'Gotario',comercial:'SPIRON 1mg GTAS.30ml',um:'1',uc:'MG',cantidad:'30'},
{principio:'RITONAVIR',forma:'Comprimidos',comercial:'(R) NORVIR 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'RITONAVIR + LOPINAVIR',forma:'Comprimidos',comercial:'KALETRA 200/50 COMP.X120',um:'50',uc:'MG',cantidad:'120'},
{principio:'RITONAVIR + LOPINAVIR',forma:'Solución Oral',comercial:'KALETRA SOL.ORAL 160ml',um:'20',uc:'MG',cantidad:'160'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'AEROLIN LF INH.200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'BROPIL LF 100mcg 200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'BUTOTAL INH.250DS',um:'100',uc:'MCG',cantidad:'250'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'FESEMA LF INH.200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'SALBUTAMOL 100mcg INH.X200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL',forma:'Jarabe',comercial:'SALBUTAMOL 2mg/5ml JBE.X120ml',um:'2',uc:'MG',cantidad:'120'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'SALBUTAMOL INH.200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL',forma:'Aerosol',comercial:'SINASMAL SF 100mcg INH.200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL + IPRATROPIO BROMURO',forma:'Aerosol',comercial:'SALBUTRAL AC INH.250DS.',um:'120',uc:'MCG',cantidad:'250'},
{principio:'SALBUTAMOL INHALACION',forma:'Aerosol',comercial:'BROPIL LF 100mcg 200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALBUTAMOL INHALACION',forma:'Aerosol',comercial:'SALBUTAMOL 100mcg INH.X200DS.',um:'100',uc:'MCG',cantidad:'200'},
{principio:'SALMETEROL',forma:'Aerosol',comercial:'SEREVENT LF 25mcg 120DS.',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'AURITUSS 25/125 INH.120DS.',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'AURITUSS 25/250 INH.120DS.',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'BREXOTIDE LF 125/25 120DS.',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'BREXOTIDE LF 250/25 INH.X120DS.',um:'25',uc:'MCG',cantidad:'250'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'BREXOTIDE LF 50/25 120DS.',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'BROMEX SF 25/250 AER.120DS.',um:'25/250',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'FLUXAMOL 125/25 AEROSOL PARA INHALACIÓN 120 DS',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SALMETEROL + FLUTICASONA',forma:'Aerosol',comercial:'FLUXAMOL 250/25 AEROSOL PARA INHALACIÓN 120 DS',um:'25',uc:'MCG',cantidad:'120'},
{principio:'SEDA DENTAL',forma:'OTRO-NINGUNA',comercial:'ORAL B HILO CON CERA FLOSS X2',um:'1',uc:'UN',cantidad:'2'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'(B) SERTAC 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'ELEVAL (B) 100mg COMP.X30',um:'100',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'ELEVAL (B) 50mg X30COM.REC.',um:'50',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'ELEVAL 50mg X60COM.REC.',um:'50',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'SERTAC (B) 100mg X30COM.REC.',um:'100',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'COMP',comercial:'SERTRALINA (B) 100mg X30COM.',um:'100',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'SERTRALINA (B) 100mg X30COM.',um:'100',uc:'MG',cantidad:'30'},
{principio:'SERTRALINA',forma:'Comprimidos',comercial:'SERTRALINA (B) 50mg X30COM.',um:'50',uc:'MG',cantidad:'30'},
{principio:'SIROLIMUS',forma:'Grageas',comercial:'RAPAMUNE 0.5mg GRA.X30',um:'0.5',uc:'MG',cantidad:'30'},
{principio:'SIROLIMUS',forma:'Grageas',comercial:'RAPAMUNE 1mg GRAG.X60',um:'1',uc:'MG',cantidad:'60'},
{principio:'SIROLIMUS',forma:'Solución Oral',comercial:'RAPAMUNE 1mg/ml SOL.ORAL 60ml',um:'1',uc:'MG',cantidad:'60'},
{principio:'SODIO MONO Y DIFOSFATO',forma:'Solución Oral',comercial:'(B) FOSFOSODA ORAL 45ml',um:'76',uc:'G',cantidad:'45'},
{principio:'SOFOSBUVIR / LEDIPASVIR',forma:'Comprimidos',comercial:'HARVONI COM.X28',um:'490',uc:'MG',cantidad:'28'},
{principio:'SOFOSBUVIR + DACLATASVIR',forma:'Comprimidos',comercial:'DAKLINZA 60mg COM.REC.X28',um:'60',uc:'MG',cantidad:'28'},
{principio:'SOFOSBUVIR + DACLATASVIR',forma:'Comprimidos',comercial:'DAKLINZA 60mg X28COM.REC.',um:'60',uc:'MG',cantidad:'28'},
{principio:'SOFOSBUVIR + DACLATASVIR',forma:'Comprimidos',comercial:'SOVALDI 400mg COM.REC.X28',um:'400',uc:'MG',cantidad:'28'},
{principio:'SOFOSBUVIR+VELPATASVIR',forma:'Comprimidos',comercial:'EPCLUSA 400/100 COM.X28',um:'500',uc:'MG',cantidad:'28'},
{principio:'SOMATROPINA',forma:'Solución Inyectable',comercial:'SAIZEN 12mg JP.X1',um:'12',uc:'MG',cantidad:'1'},
{principio:'SOMATROPINA',forma:'Solución Inyectable',comercial:'SAIZEN 12mg JP.X1',um:'20',uc:'MG',cantidad:'1'},
{principio:'SOMATROPINA',forma:'Jeringa Prellenada Monodosis',comercial:'SAIZEN 6mg JP.X1',um:'6',uc:'MG',cantidad:'1'},
{principio:'SOMATROPINA',forma:'Ampolla Multidosis',comercial:'SAIZEN 8mg CLICK EASY',um:'8',uc:'MG',cantidad:'1'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE 500mg 100COM.',um:'500',uc:'MG',cantidad:'100'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE 500mg 60COM.',um:'500',uc:'MG',cantidad:'60'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE 500mg COMP.X30',um:'500',uc:'MG',cantidad:'30'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE EN 500mg COMP.X100',um:'500',uc:'MG',cantidad:'100'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE EN 500mg COMP.X40',um:'500',uc:'MG',cantidad:'40'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE EN 500mg FCO 100COM.REC.',um:'500',uc:'MG',cantidad:'100'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'AZULFIDINE EN 500mg FCO 60COM.REC.',um:'500',uc:'MG',cantidad:'60'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'EUROZINA 500mg COMP.X100',um:'500',uc:'MG',cantidad:'100'},
{principio:'SULFASALAZINA',forma:'Comprimidos',comercial:'EUROZINA 500mg COMP.X50',um:'500',uc:'MG',cantidad:'50'},
{principio:'SUNITINIB',forma:'Capsulas',comercial:'SUTENT 12.5mg X28CAP.',um:'12.5',uc:'MG',cantidad:'28'},
{principio:'SUNITINIB',forma:'Capsulas',comercial:'SUTENT 25mg X28CAP.',um:'25',uc:'MG',cantidad:'28'},
{principio:'SUNITINIB',forma:'Capsulas',comercial:'SUTENT 50mg CAPS.X28',um:'50',uc:'MG',cantidad:'28'},
{principio:'SUPLEMENTO NUTRICIONAL ORAL, CON FRUCTOLIGOSACÁRIDOS Y OSMOLARIDAD MODERADA',forma:'Solución Oral',comercial:'ENSURE ADVANCE VAINILLA 237ml',um:'1',uc:'UN',cantidad:'1'},
{principio:'SUPLEMENTO NUTRICIONAL ORAL, CON FRUCTOLIGOSACÁRIDOS Y OSMOLARIDAD MODERADA',forma:'Solución Oral',comercial:'ENSURE CLINICAL RPB VAINILLA X220ml',um:'1',uc:'ML',cantidad:'1'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(B) CIDIMUS 0.5mg CAPS.X50',um:'0.5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(B) CIDIMUS 1mg CAPS.X100',um:'1',uc:'MG',cantidad:'100'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(B) CIDIMUS 5mg CAPS.X50',um:'5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(B) T-INMUN 1mg CAPS.X60',um:'1',uc:'MG',cantidad:'60'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(R) PROGRAF 0.5mg CAPS.X50',um:'0.5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(R) PROGRAF 5mg CAPS.X50',um:'5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(R) PROGRAF XL 0.5mg CAPS.X50',um:'0.5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(R) PROGRAF XL 1mg CAPS.X50',um:'1',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'(R) PROGRAF XL 5mg CAPS.X50',um:'5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'CAPSULAS',comercial:'CIDIMUS (B) 2mg X50CAP.',um:'2',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Capsulas',comercial:'PROGRAF (R) 1mg X100CAP.',um:'1',uc:'MG',cantidad:'100'},
{principio:'TACROLIMUS',forma:'Cápsulas Liberación prolongada',comercial:'TACNI XR (B) 0.5mg X50CAP.PROL',um:'0.5',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Cápsulas Liberación prolongada',comercial:'TACNI XR (B) 1mg 50CAP.PROL',um:'1',uc:'MG',cantidad:'50'},
{principio:'TACROLIMUS',forma:'Cápsulas Liberación prolongada',comercial:'TACNI XR (B) 5mg 50CAP.PROL',um:'5',uc:'MG',cantidad:'50'},
{principio:'TALIDOMIDA',forma:'Comprimidos',comercial:'INMUNOPRIN 100mg COMP.X100',um:'100',uc:'MG',cantidad:'100'},
{principio:'TALIDOMIDA',forma:'Comprimidos',comercial:'INMUNOPRIN 100mg X100COM.',um:'100',uc:'MG',cantidad:'100'},
{principio:'TAMOXIFENO',forma:'Comprimidos',comercial:'(B) TAXUS 20mg COMP.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'TAMOXIFENO',forma:'Comprimidos',comercial:'NOLVADEX D (R) 20mg X30COM.',um:'20',uc:'MG',cantidad:'30'},
{principio:'TAMSULOSINA',forma:'Capsulas',comercial:'GOTELY 0.4mg CAPS.X30',um:'0.4',uc:'MG',cantidad:'30'},
{principio:'TENOFIR',forma:'Comp. Recubiertos',comercial:'(R) VIREAD 300mg COMP.X30',um:'300',uc:'MG',cantidad:'120'},
{principio:'TENOFOVIR',forma:'Comprimidos',comercial:'VEMLIDY 25mg X30COM.REC',um:'25',uc:'MG',cantidad:'30'},
{principio:'TENOFOVIR (TDF)',forma:'Comprimidos',comercial:'(R) VIREAD 300mg COMP.X30',um:'300',uc:'MG',cantidad:'30'},
{principio:'TENOFOVIR + EMTRICITABINA',forma:'Comprimidos',comercial:'ANBAX 30COM.REC',um:'300',uc:'MG',cantidad:'30'},
{principio:'TENOFOVIR + EMTRICITABINA',forma:'Comprimidos',comercial:'TENOFEM (B) 30COM.',um:'300',uc:'MG',cantidad:'30'},
{principio:'TENOFOVIR + EMTRICITABINA',forma:'Comprimidos',comercial:'TRUVADA COMP.X30',um:'300',uc:'MG',cantidad:'30'},
{principio:'TEOFILINA',forma:'Capsulas',comercial:'ELIXINE LENTO 125mg CAPS.X20',um:'125',uc:'MG',cantidad:'20'},
{principio:'TEOFILINA',forma:'Capsulas',comercial:'ELIXINE LENTO 250mg CAPS.X20',um:'250',uc:'MG',cantidad:'20'},
{principio:'TERIFLUNOMIDA',forma:'Comprimidos',comercial:'AUBAGIO 14mg COMP.X28',um:'14',uc:'MG',cantidad:'28'},
{principio:'TESTOSTERONA',forma:'Ampollas',comercial:'NEBIDO 1g/4ml AMP.X1',um:'1000',uc:'MG',cantidad:'1'},
{principio:'TIMOLOL + DORZOLAMIDA',forma:'Solucion Oftalmica',comercial:'GLAUCOTENSIL T SOL.OFT.5ml',um:'5',uc:'MG',cantidad:'5'},
{principio:'TIOGUANINA',forma:'Comprimidos',comercial:'LANVIS 40mg COMP.X25',um:'40',uc:'MG',cantidad:'25'},
{principio:'TOBRAMICINA',forma:'Ampollas',comercial:'TUBERBUT 300mg/5ml 56AMP',um:'300',uc:'MG',cantidad:'56'},
{principio:'TOBRAMICINA',forma:'Ampollas',comercial:'ZOTEON 300mg/5ml AMP.X56',um:'300',uc:'MG',cantidad:'56'},
{principio:'TOBRAMICINA',forma:'Capsulas',comercial:'ZOTEON PODHALER 28mg X224CAP.',um:'28',uc:'MG',cantidad:'224'},
{principio:'TOBRAMICINA + DEXAMETASONA COLIRIO',forma:'Solucion Oftalmica',comercial:'XOLOF-D SOL.OFT.5ml',um:'3',uc:'MG',cantidad:'5'},
{principio:'TOBRAMICINA + DEXAMETASONA UNG OFT',forma:'Ungüento',comercial:'XOLOF-D UNG.OFT.3,5g',um:'3',uc:'MG',cantidad:'3.5'},
{principio:'TRAMADOL',forma:'Solución Oral',comercial:'TRAMADOL (B) 100mg/ml GTAX10ml',um:'100',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Solución Oral',comercial:'TRAMADOL 100mg GTAS.X10ml',um:'100',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Capsulas',comercial:'TRAMADOL 50mg X10CAP.',um:'50',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Comprimidos',comercial:'TRAMAL LONG 100mg COMP.X10',um:'100',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Comprimidos',comercial:'TRAMAL LONG 150mg COMP.X10',um:'150',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Comprimidos',comercial:'TRAMAL LONG 200mg COMP.X10',um:'200',uc:'MG',cantidad:'10'},
{principio:'TRAMADOL',forma:'Ampollas',comercial:'ZODOL 100mg AMP.X3',um:'100',uc:'MG',cantidad:'3'},
{principio:'TRAMADOL',forma:'Solución Oral',comercial:'ZODOL 50mg GTAS.20ml',um:'50',uc:'MG',cantidad:'20'},
{principio:'TRAZODONA',forma:'Comprimidos',comercial:'TRITTICO 100mg COMP.X20',um:'100',uc:'MG',cantidad:'20'},
{principio:'TRAZODONA',forma:'Comprimidos',comercial:'TRITTICO 25 mg caps.X20',um:'25',uc:'MG',cantidad:'20'},
{principio:'TRAZODONA',forma:'Comprimidos',comercial:'TRITTICO AC 150mg COMP.X20',um:'150',uc:'MG',cantidad:'20'},
{principio:'TRETINOINA',forma:'Capsulas',comercial:'VESANOID 10mg CAP.X100',um:'1',uc:'UN',cantidad:'100'},
{principio:'TRIHEXIFENIDILO',forma:'Comprimidos',comercial:'TONARIL 2mg COMP.X100',um:'2',uc:'MG',cantidad:'100'},
{principio:'TRIPTORELINA',forma:'Ampollas',comercial:'DECAPEPTYL 11,25mg X1KIT',um:'11.25',uc:'MG',cantidad:'1'},
{principio:'TRIPTORELINA',forma:'Ampollas',comercial:'DECAPEPTYL 22,5mg F-A X1',um:'22.5',uc:'MG',cantidad:'1'},
{principio:'TRIPTORELINA',forma:'Ampollas',comercial:'DECAPEPTYL 3,75mg INY.CON SOLVENTE X1F-A',um:'3.75',uc:'MG',cantidad:'1'},
{principio:'VACUNA ANTI HEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'AVAXIM VAC HEPATITIS A AD',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTI HEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'AVAXIM VAC HEPATITIS A PED',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTI HEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'HAVRIX VAC HEPATITIS PED',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTI HEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'VAQTA 25U/0.5ml JP X1',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTI HEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'VAQTA 50U/1ml X1JP',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTIHEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'AVAXIM VAC HEPATITIS A AD',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTIHEPATITIS A',forma:'Jeringa Prellenada Monodosis',comercial:'HAVRIX VAC HEPATITIS PED',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA ANTIINFLUENZA',forma:'Jeringa Prellenada Monodosis',comercial:'INFLUVAC VACU ANTIGRIPAL',um:'0.5',uc:'ML',cantidad:'1'},
{principio:'VACUNA ANTIINFLUENZA',forma:'Jeringa Prellenada Monodosis',comercial:'VAXIGRIP VAC ANTIG',um:'0.5',uc:'ML',cantidad:'1'},
{principio:'VACUNA ANTIVARICELA',forma:'Jeringa Prellenada Monodosis',comercial:'VARILIX VAC VARICELA',um:'0.5',uc:'ML',cantidad:'1'},
{principio:'VACUNA ANTIVARICELA',forma:'Jeringa Prellenada Monodosis',comercial:'VARIVAX 0.5ml PVO.INY.X1F-A',um:'0.5',uc:'ML',cantidad:'1'},
{principio:'VACUNA ANTIVARICELA',forma:'Jeringa Prellenada Monodosis',comercial:'ZOSTAVAX 0.65ml JP.X1',um:'0.5',uc:'ML',cantidad:'1'},
{principio:'VACUNA BCG',forma:'Ampollas',comercial:'BCG MYCOBACTERIUM 4DS.',um:'1',uc:'UN',cantidad:'4'},
{principio:'VACUNA PNEUMO 23',forma:'Ampollas',comercial:'PNEUMOVAX 23 VACUNA ANTINEUMO',um:'0.025',uc:'MG',cantidad:'1'},
{principio:'VACUNA PNEUMOCÓCICA 23 VALENTE',forma:'Ampollas',comercial:'PREVENAR 13 AMP.X1',um:'1',uc:'UN',cantidad:'1'},
{principio:'VACUNA PNEUMOCOCO',forma:'Ampollas',comercial:'PREVENAR 13 AMP.X1',um:'1',uc:'UN',cantidad:'1'},
{principio:'VALGANCICLOVIR',forma:'Comprimidos',comercial:'VALIXA 450mg COMP.X60',um:'450',uc:'UN',cantidad:'60'},
{principio:'VASELINA LIQUIDA USO EXTERNO',forma:'Locion',comercial:'VASELINA MEDICINAL 250ml',um:'1',uc:'ML',cantidad:'250'},
{principio:'VASELINA LIQUIDA USO EXTERNO',forma:'Locion',comercial:'VASELINA USO EXTERNO 125ml',um:'1',uc:'ML',cantidad:'125'},
{principio:'VENLAFAXINA',forma:'Capsulas',comercial:'(B) ECTIEN XR 150mg CAPS.X30',um:'150',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Capsulas',comercial:'(B) ECTIEN XR 37,5mg CAPS.X30',um:'37.5',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Capsulas',comercial:'(B) ECTIEN XR 75mg CAPS.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Capsulas Liberación Prolongada',comercial:'DEPUROL RET.(B) 75mg CAPS.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Capsulas Liberación Prolongada',comercial:'DEPUROL RET.150mg CAPS.X30',um:'150',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Capsulas Liberación Prolongada',comercial:'DEPUROL RET.37.5mg CAPS.X30',um:'37.5',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Comprimidos',comercial:'SENEXON SR 75mg COMP.X30',um:'75',uc:'MG',cantidad:'30'},
{principio:'VENLAFAXINA',forma:'Comprimidos',comercial:'VENLAX 50mg COMP.X30',um:'50',uc:'MG',cantidad:'30'},
{principio:'VILANTEROL + FLUTICASONA',forma:'Aerosol',comercial:'RELVAR 184/22mcg 30DS.',um:'40',uc:'MCG',cantidad:'30'},
{principio:'VILANTEROL + FLUTICASONA',forma:'Aerosol',comercial:'RELVAR 92/22mcg INH.30DS.',um:'40',uc:'MCG',cantidad:'30'},
{principio:'VILANTEROL + FLUTICASONA+UMECLIDINIO',forma:'Aerosol',comercial:'TRELEGY ELLIPTA 92/55/22 mgc 30DS.',um:'92',uc:'MCG',cantidad:'30'},
{principio:'VINCRISTINA',forma:'Ampollas',comercial:'VINCRISTINA 1mg X1F-A',um:'1',uc:'MG',cantidad:'1'},
{principio:'VITAMINAS LIPOSOLUBLES AQUA A.D.E.K.',forma:'Gotario',comercial:'DEKAS PLUS GOTAS 60ml',um:'1',uc:'MG',cantidad:'60'},
{principio:'VITAMINAS LIPOSOLUBLES AQUA A.D.E.K.',forma:'Capsulas',comercial:'DEKAS PLUS SOFTGEL X60',um:'1',uc:'UN',cantidad:'60'},
{principio:'VITAMINAS LIPOSOLUBLES AQUAA.D.E.K.',forma:'Gotario',comercial:'DEKAS PLUS GOTAS 60ml',um:'1',uc:'MG',cantidad:'60'},
{principio:'VITAMINAS LIPOSOLUBLES AQUAA.D.E.K.',forma:'Capsulas',comercial:'DEKAS PLUS SOFTGEL X60',um:'1',uc:'UN',cantidad:'60'},
{principio:'WARFARINA',forma:'Comprimidos',comercial:'CAVAMED 5mg COMP.X30',um:'5',uc:'MG',cantidad:'30'},
{principio:'WARFARINA',forma:'Comprimidos',comercial:'COUMADIN 2.5mg X30TAB.',um:'2.5',uc:'MG',cantidad:'30'},
{principio:'WARFARINA',forma:'Comprimidos',comercial:'COUMADIN 5mg X30TAB.',um:'5',uc:'MG',cantidad:'30'},
{principio:'ZIDOVUDINA',forma:'COMPRIMIDOS',comercial:'ZIDOVUDINA (B) 300mg X60COM.REC',um:'300',uc:'MG',cantidad:'60'},
{principio:'ZIDOVUDINA (AZT)',forma:'COMPRIMIDO',comercial:'AZAVIRAL (B) 300mg X60COM.',um:'300',uc:'MG',cantidad:'60'},
{principio:'ZIDOVUDINA (AZT)',forma:'Jarabe',comercial:'RETROVIR 50mg JBE.200ml',um:'50',uc:'MG',cantidad:'200'},
{principio:'ZIPRASIDONA',forma:'Capsulas',comercial:'ZELDOX 20mg CAPS.X30',um:'20',uc:'MG',cantidad:'30'},
{principio:'ZIPRASIDONA',forma:'Capsulas',comercial:'ZELDOX 40mg CAPS.X30',um:'40',uc:'MG',cantidad:'30'},
{principio:'ZIPRASIDONA',forma:'Capsulas',comercial:'ZELDOX 60mg CAPS.X30',um:'60',uc:'MG',cantidad:'30'},
{principio:'ZIPRASIDONA',forma:'Capsulas',comercial:'ZELDOX 80mg CAPS.X30',um:'80',uc:'MG',cantidad:'30'},
];

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

const estiloDivModal={
      position: 'absolute',
      top: '1%',
      left: '28%',
      right: '28%',
      bottom: '1%',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px',
}

const dosis = ["capsulas","gotas","unidades"];

const alternativasUnidadDosis = dosis.map(item => (
  <MenuItem key={item} value={item}>{item}</MenuItem>
));

const uf = ["horas","dias","semana"];

const alternativasUnidadFrecuencia = uf.map(item => (
  <MenuItem key={item} value={item}>{item}</MenuItem>
));

const up = ["dias","semanas","meses"];

const alternativasUnidadPeriodo = up.map(item => (
  <MenuItem key={item} value={item}>{item}</MenuItem>
));

class AgregarMedicamento extends React.Component {

   constructor(props) {
     super(props);
     this.state = {

       descripcionSeleccionada:"",
       medicamentosPosibles:[],

       correoResponsableSeleccionado:"",
       descripcionSeleccionada:"",

       principioActivo:"",
       forma:"",
       nombreComercial:"",
       unidadMedida:"",
       concentracion:"",
       cantidad:"",
       dosis:0,
       unidadDosis:"unidades",
       frecuencia:0,
       unidadFrecuencia:"horas",
       periodoTratamiento:0,
       unidadPeriodo:"dias",

     };
  }

  handlerDescripcion = (e) =>{
      this.setState({descripcionSeleccionada:e.target.value});
  }
  handlerPrincipioActivo = (e) =>{
    this.setState({principioActivo:e.target.value});
  }
  handlerForma = (e) =>{
    this.setState({forma:e.target.value});
  }
  handlerNombreComercial = (e) =>{
    this.setState({nombreComercial:e.target.value});
  }
  handlerUnidadMedida = (e) =>{
    this.setState({unidadMedida:e.target.value});
  }
  handlerConcentracion = (e) =>{
    this.setState({concentracion:e.target.value});
  }
  handlerCantidad = (e) =>{
    this.setState({cantidad:e.target.value});
  }

  handlerCerrar = (e) =>{
      this.setState({visible:false});
  }

  handlerSeleccionarSector = (sector) =>{
    this.setState({sectorSeleccionado:sector});
  }

  handlerDescripcion = (e) =>{
      this.setState({descripcionSeleccionada:e.target.value});
  }

  handlerDosis = (e) =>{
      this.setState({dosis:e.target.value});
  }

  handlerPeriodoTratamiento = (e) =>{
      this.setState({periodoTratamiento:e.target.value});
  }

  handlerSeleccionFecha =  (value, event) => {
    let anio=value.getFullYear();
    let mes=value.getMonth()+1;  //en javascript parte en 0 el primer mes.
    let dia=value.getDate();

    this.setState({vencimientoSeleccionado:""+anio+"-"+mes+"-"+dia+" 23:59:00"});
  }

  enviarNotificacion = (titulo,cuerpo,destino) =>{
      let datos={
        "titulo":titulo,
        "cuerpo":cuerpo,
        "destino":destino
      }

      axios
          .post("metodos/notificar-post",datos)
          .then((res) =>{
                let resultado=res.data;
                  console.log();
                })
           .catch((err) => console.log(err));

  }

  handlerAutocompletarMedicamento = (event, newValue)=>{
    this.setState({principioActivo:newValue["principio"],forma:newValue["forma"],nombreComercial:newValue["comercial"],unidadMedida:newValue["um"],concentracion:newValue["uc"],cantidad:newValue["cantidad"]});
  }

  handlerAceptar = () => {

     let jsonValores={
        "principioActivo": this.state.principioActivo,
        "forma": this.state.forma,

        "nombreComercial": this.state.nombreComercial,
        "unidadMedida": this.state.unidadMedida,

        "concentracion": this.state.concentracion,
        "cantidad":  this.state.cantidad,

        "dosis":  this.state.dosis,
        "unidadDosis":  this.state.unidadDosis,

        "frecuencia":  this.state.frecuencia,
        "unidadFrecuencia":  this.state.unidadFrecuencia,

        "periodoTratamiento":  this.state.periodoTratamiento,
        "unidadPeriodo": this.state.unidadPeriodo,
    };


    this.props.handlerAceptar(jsonValores);
    this.limpiarFormulario();
  }

  handlerCancelar = () => {
    this.props.handlerCancelar();
    this.limpiarFormulario();
  }

  handleUnidadDosis = (event)=>{
    this.setState({unidadDosis:event.target.value});
  }

  handleUnidadPeriodo = (event)=>{
    this.setState({unidadPeriodo:event.target.value});
  }

  handlerFrecuencia  = (event)=>{
    this.setState({frecuencia:event.target.value});
  }

  limpiarFormulario = ()=>{
    this.setState({
      principioActivo:"",
      forma:"",
      nombreComercial:"",
      unidadMedida:"",
      concentracion:"",
      cantidad:"",
      dosis:0,
      unidadDosis:"unidades",
      frecuencia:0,
      unidadFrecuencia:"horas",
      periodoTratamiento:0,
      unidadPeriodo:"dias"});
  }

  render(){
    return (
      <Modal
        open={this.props.visible}
        onClose={this.handlerCerrar}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >
          <div style={estiloDivModal}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="column"   >
                <Box pb={2}>
                  <Typography variant="h6">
                    Datos Medicamento
                  </Typography>
                </Box>

                <Box pb={1}>

                  <Autocomplete
                    key={"agendamiento-principio"}
                    options={listadoMedicamentos}
                    getOptionLabel={(option) => option.principio + " " + option.comercial}
                    onChange={this.handlerAutocompletarMedicamento}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField  {...params} label="Escriba el nombre del medicamento" />}
                  />

                  </Box>

                <Box pb={1}>

                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Principio"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.principioActivo} label={"Principio Activo"} onChange={this.handlerPrincipioActivo} ></TextField>
                  </Box>
                </Box>

                <Box pb={1}>

                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Forma"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.forma} label={"Forma"} onChange={this.handlerForma} ></TextField>
                  </Box>

                </Box>
                <Box pb={1}>

                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Nombre Comercial"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.nombreComercial} label={"Forma"} onChange={this.handlerNombreComercial} ></TextField>
                  </Box>

                </Box>
                <Box pb={1}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Unidad Medida"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.unidadMedida} label={"Unidad Medida"} onChange={this.handlerUnidadMedida} ></TextField>
                  </Box>
                </Box>

                <Box pb={1}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Unidad Concentración"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.concentracion} label={"Unidad Concentración"} onChange={this.handlerConcentracion} ></TextField>
                  </Box>
                </Box>

                <Box pb={1}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                      <Box width={200}>
                        <Typography variant="body1">
                          {"Cantidad"}
                        </Typography>
                      </Box>
                      <TextField  value={this.state.cantidad} label={"Cantidad"} onChange={this.handlerCantidad} ></TextField>
                  </Box>
                </Box>

                <Box pt={2}>
                  <Typography variant="h6">
                    Datos Tratamiento
                  </Typography>
                </Box>

                <Box pb={1}>

                  <Box display="flex" flexDirection="row"   >
                    <Box pr={1}>
                      <EtiquetaTextArea etiqueta="Dosis" value={this.state.dosis} handlerTextField={this.handlerDosis}  />
                    </Box>
                    <Box>
                      <Select label="Unidad" value={this.state.unidadDosis}  onChange={this.handleUnidadDosis}   >
                          {alternativasUnidadDosis}
                      </Select>
                    </Box>
                  </Box>

                </Box>
                <Box pb={1}>
                  <Box display="flex" flexDirection="row"   >
                    <Box pr={1}>
                      <EtiquetaTextArea etiqueta="cada" value={this.state.frecuencia} handlerTextField={this.handlerFrecuencia}  />
                    </Box>
                    <Box>
                      <Select label="Unidad" value={this.state.unidadFrecuencia}  onChange={this.handleUnidadFrecuencia}   >
                          {alternativasUnidadFrecuencia}
                      </Select>
                    </Box>
                  </Box>
                </Box>

                <Box pb={1}>

                  <Box display="flex" flexDirection="row"   >
                    <Box pr={1}>
                      <EtiquetaTextArea etiqueta="por" value={this.state.periodoTratamiento} handlerTextField={this.handlerPeriodoTratamiento}  />
                    </Box>
                    <Box>
                    <Select label="Unidad" value={this.state.unidadPeriodo}  onChange={this.handleUnidadPeriodo}   >
                        {alternativasUnidadPeriodo}
                      </Select>
                    </Box>
                  </Box>
                </Box>

              </Box>

              <Box display="flex" flexDirection="row"  pt={10}   >

                <Box pr={1}>

                  <Button disabled={this.state.principioActivo==""  || this.state.forma=="" || this.state.nombreComercial=="" || this.state.unidadMedida=="" || this.state.unidadConcentracion =="" || this.state.cantidad ==""} onClick={()=>{this.handlerAceptar()}} >
                    Aceptar
                  </Button>
                </Box>
                <Box >
                  <Button onClick={this.handlerCancelar}  >
                    Cancelar
                  </Button>
                </Box>

              </Box>
            </Box>
          </div>
       </Modal>
    );
  }
};

export default AgregarMedicamento;
