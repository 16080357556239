import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../../util/forms";
import { Toast } from "./../../util/notifications";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import fondo from '../../assets/fondo2.png';
import logo_kuery from '../../assets/logo_kuery_medio.png';
import { Modal } from "@material-ui/core";
import EvaluacionRemota from "./DashboardView/EvaluacionRemota";

import CornerRibbon from "react-corner-ribbon";

// LOGOS COLABORADORES
import BARBAS from '../../assets/colaboradores/2BARBAS.png';
import ASECH from '../../assets/colaboradores/ASECH.png';
import CORFO from '../../assets/colaboradores/Corfo.png';
import TTEXIS from '../../assets/colaboradores/cyberdoc.png';
import TEXIS2 from '../../assets/colaboradores/cyberdoc.png';

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const Asistente = () => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const { value: rut, bind: bindRut } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signIn(rut, password);
      console.log(Auth.user);
      localStorage.setItem('rut', Auth.user.username);
      Toast("Muy bien!!", "Has ingresado correctamente", "success");
      history.push("/");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  document.body.style = 'background-image: url("'+ fondo +'");';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("aca!");
  /*  setOpen(false);*/
  };

  useEffect(() => {
    setTimeout(()=>{
      setOpen(true)
    }, 1000)
  }, [])

  return (
    <>

      <CornerRibbon
          position="top-left" // OPTIONAL, default as "top-right"
          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
          backgroundColor="#EE8E4A" // OPTIONAL, default as "#2c7"
          containerStyle={{}} // OPTIONAL, style of the ribbon
          style={{}} // OPTIONAL, style of ribbon content
          className="" // OPTIONAL, css class of ribbon
        >
          Beta
        </CornerRibbon>

        <Modal style={{ width: 600,height:150,  margin: "40px" }}
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
          <EvaluacionRemota  width="50%"/>
        </Modal>



        <div style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          maxHeight: "18vh",
          backgroundColor: "#ffffff9e",
          padding:"1vh",
          textAlign: "center",
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
        }}>
          <table>
        <thead>
          <tr>
            <th><img src={CORFO} style={{height:"12vh"}}/></th>
            <th><img src={TEXIS2} style={{width:"18vh"}}/></th>
            <th><img src={ASECH} style={{height:"5vh"}}/></th>
            <th><img src={BARBAS} style={{height:"7vh"}}/></th>
          </tr>
        </thead>
        </table>
      </div>
    </>
  );
};

export default Asistente;
