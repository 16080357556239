import React, { useEffect, useState } from 'react';
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Paper,Grid,Box,TextField} from '@material-ui/core';

class Telefono extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      telefono: props.value,
      handlerTelefonoExterno: props.handlerTelefono,
      key: props.key
    };
    this.telefonoValido(props.value);
  }

   telefonoValido= (telefono) => {
     const telefonoRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
     return telefonoRegex.test(telefono);
  }

  handlerTelefono = (e) => {

    let telefono=e.target.value;

    this.setState({telefono:e.target.value});

    if (this.telefonoValido(telefono)){
        this.setState({telefonoValido:true});
        this.setState({telefono:telefono});
        this.state.handlerTelefonoExterno(true,telefono);
    }else{
        this.setState({telefonoValido:false});
        this.state.handlerTelefonoExterno(false,telefono);
    }
  }

  render() {
    return (

       <TextField
       error={! (this.state.telefono === "") && !(this.state.telefonoValido)}
       helperText={(! (this.state.telefono === "") && !(this.state.telefonoValido)) ? 'Telefono invalido!' : ''}
       keys={"textfield"+this.state.key} value={this.state.telefono} label="+56 987654321" onChange={this.handlerTelefono} ></TextField>

    );
  }
}

export default Telefono;
