import React from "react";
import {MenuItem,Container,Grid,Typography,TextField,Select,Box,Paper} from '@material-ui/core';
import {Link} from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled,{ThemeProvider} from 'styled-components';

const Button = styled.button`
  color: #fff;
  border: 1px solid #3c77e3;
  width: 170px;
  height: 35px;

  background:${props => props.disabled ? "#9e9e9e" : "#3c77e3"};
`;

class Fila extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render(){

    return (

            <Box display="flex"  flexDirection="row"   >
              <Grid container spacing={1}  >
                <Grid item xs={5} >
                  <Typography variant="body" style={{ fontWeight: 600 }} align="right">
                    {this.props.clave}
                  </Typography>
                </Grid >
                <Grid item xs={7}>
                  <Typography variant="body" >
                    {this.props.valor}
                  </Typography>
                </Grid >
              </Grid>
            </Box>


    );
  }
};

export default Fila;
/*<Box bgcolor={this.props.estilo} pt={1} pb={1}>*/
