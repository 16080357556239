import React from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useInput } from "../../util/forms";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import SubirArchivo from "./DashboardView/comun/SubirArchivo";
import Rut from "./DashboardView/comun/Rut";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const SignupClinica = () => {
  const [loading, setLoading] = React.useState(false);
  const [postulacionEnviada, setPostulacionEnviada] = React.useState(false);

  const { value: nombre, bind: bindNombre } = useInput("");
  const { value: nombreClinica, bind: bindNombreClinica } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: dominio, bind: bindDominio } = useInput("");
  const { value: direccion, bind: bindDireccion } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");

  const [rut, setRut] = React.useState("");
  const [fotoLogo, setFotoLogo] = React.useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    const header = {
      headers: {
        "access-control-allow-heaaders": "*",
        "access-control-allow-methods": "*",
        "access-control-allow-origin": "*",
        "access-control-expose-headers": "*",
        "content-type": "application/json",
      },
    };

    axios
      .post(
        "https://fz8elh7az5.execute-api.us-east-1.amazonaws.com/dev/postulacion",
        {
          isClinica: true,
          nombre,
          dominio,
          direccion,
          email,
          phone,
          fotoLogo,
          nombreClinica,
          rut,
          password,
        },
        header
      )
      .then((res) => {
        setPostulacionEnviada(true);
      })
      .catch(console.log);

    setLoading(false);
  };
  const handlerSubidaLogo = (_, archivo) => {
    setFotoLogo(archivo);
  };

  const handlerRut = (valido, rut) => {
    if (valido) {
      setRut(rut);
    } else {
      setRut(null);
    }
  };

  return (
    <Box>
      {postulacionEnviada == false && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSignUp}
        >
          <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
            Registrar tu clínica
          </h1>
          <Box display="flex" flexDirection="row" mt={3}>
            <Box display="flex" flexDirection="column" style={{ width: 600 }}>
              <Box>
                <Field label="Nombre Admin" {...bindNombre} />
              </Box>
              <Box>
                <Field label="Nombre Clínica" {...bindNombreClinica} />
              </Box>
              <Box>
                <Field label="Contraseña" type="password" {...bindPassword} />
              </Box>
              <Box>
                <Box>
                  <Field label="Dominio" {...bindDominio} />
                </Box>
                <Box>
                  <Typography variant="caption">
                    El dominio tiene que ser un slug (ejemplo:
                    clinica-valparaiso)
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Field label="Dirección" {...bindDireccion} />
              </Box>
              <Box>
                <Rut value={rut} handlerRut={handlerRut} />
              </Box>
              <Box>
                <Field label="Email" {...bindEmail} type="email" />
              </Box>
              <Box>
                <Field label="Telefono" {...bindPhone} type="tel" />
              </Box>
            </Box>
            <Box style={{ width: 400 }}>
              <Box display="flex" flexDirection="column" mt={3}>
                <Box>
                  <Typography variant="body1">
                    Suba una imagen de su Logo.
                  </Typography>
                  <Typography variant="body1">
                    Recomendamos que sea una imagen horizontal de relación 2:1
                  </Typography>
                </Box>
                <Box>
                  <SubirArchivo
                    etiquetaBoton="Subir Logo"
                    handlerArchivo={handlerSubidaLogo}
                  ></SubirArchivo>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={
                    loading ||
                    nombre == "" ||
                    direccion == "" ||
                    dominio == "" ||
                    email == "" ||
                    phone == "" ||
                    fotoLogo == ""
                  }
                >
                  {loading && (
                    <CircularProgress size={20} style={{ marginRight: 20 }} />
                  )}
                  Enviar Datos
                </Button>
              </Box>
            </Box>
          </Box>

          <Box mt={3}></Box>

          <DLink to="/signin">Volver al inicio &rarr;</DLink>
        </form>
      )}

      {postulacionEnviada == true && (
        <Paper>
          <Box m={3} pt={3}>
            <Typography variant="h6">Postulación enviada</Typography>
          </Box>

          <Box m={3} pb={3}>
            <Box mt={4}>
              <Typography variant="body1">
                Su postulación ha sido enviada a la plataforma. Uno de nuestros
                colaboradores se contactará con ud para los procesos de
                verificación.
              </Typography>
              <br />
              <DLink to="/signin">Volver al inicio &rarr;</DLink>
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default SignupClinica;
